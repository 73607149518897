import { Box, Stack, Avatar } from '@mui/material';
import { PrimaryButton } from 'mbd-components/primary-button';
import { Text } from 'mbd-components/text';
import { colors } from 'helper/colors';
import { React } from 'react';



export function FeedTemplateCard(props){

  const {title, description, imgUrl, api, creatorPfp, creatorName, selectTemplate} = props;
  const CreatorChip = () => 
  <Stack 
      direction='row' 
      height={30} 
      spacing={1} 
      mt={0.5}
      mb={1}
      p={0.5}
      alignItems='center' 
      sx={{ 
        border: '1px solid ' + colors.gray, 
        borderRadius: '6px', 
        backgroundColor: '#E9DBDB', 
        width: creatorName.length * 12 + 20
      }}
  >
      <Avatar src={creatorPfp} alt='pfp' sx={{ width: 25, height: 25}}/>
      <Text variant='body'>{creatorName}</Text>
  </Stack>

  return (
    <Box bgcolor={'#fff'} p={0.2} my={2} mr={2} borderRadius={4} borderColor={colors['gray']} width={564} height={208} sx={{borderWidth: 2}}>
      <Stack direction='row' spacing={2}>
        <Box width={180}>
          <img src={imgUrl} alt='template-img' style={{ width: 200, height: 200, borderRadius: '14px', objectFit: 'cover' }}/>
        </Box>
        <Stack direction='column' pt={1} pr={1} width={360} justifyContent='space-between'>
          <Box sx={{flexGrow: 0, flexShrink: 0, flexBasis:'auto'}}>
            <Text variant='h2' fontWeight={500}>
              {title}
            </Text>
            <CreatorChip />
            <Text variant='body' sx={{my:1, fontFamily: 'Space Mono'}}>
              {description}
            </Text>
          </Box>
          <Stack direction='row' alignItems='center' justifyContent='space-between' pb={1}>
            <Text variant='body' size={12}>
              API: {api}
            </Text>
            <PrimaryButton onClick={selectTemplate}>
              Use Feed
            </PrimaryButton>
          </Stack>
        </Stack>
      </Stack>

    </Box>
  );
}