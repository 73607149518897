import { Button } from '@mui/material';
import { colors } from 'helper/colors';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    gray: {
      main: colors['gray-light'],
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});
export function SecondaryButton(props){

  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="outlined"
        color="gray"
        onClick={props.onClick}
        startIcon={props.startIcon || <></>}
      >
        {props.children}
      </Button>
    </ThemeProvider>
  );
}