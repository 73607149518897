import { Button } from '@mui/material';
import { colors } from 'helper/colors';

export function PrimaryButton(props){

  let sx = { backgroundColor: colors.brand, '&:hover': { backgroundColor: '#ed9ed2' }, height: '40px'};
  if (props.sx) {
    sx = { ...sx, ...props.sx };
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={props.onClick}
      sx={sx}
    >
        {props.children}
    </Button>
  );
}