// FeedTemplateShowcase.js
import React from 'react';
import { Box, Grid, ButtonBase } from '@mui/material';
import { Text } from 'mbd-components/text';
import { Card } from 'mbd-components/card'
import { PrimaryButton } from 'mbd-components/primary-button';  

const CARD_COLORS = [
  "#A5B8E0", "#76C7F2", "#80D6B7", "#8CD790",
  "#5BC8C7", "#A3D477", "#F39C9F", "#FFB785", 
  "#FFD67B", "#FFF07C", "#FFB07C", "#FF9AA2", 
  "#FF99A8", "#F8A5A8", "#D6A3F9"
];


// FeedTemplateShowcase.js

export function FeedTemplateShowcase({ publicFeeds, privateFeeds, createNewFeed, selectTemplate, editFeed }) {

  const FeedTemplateCard = (props) => {
    const feed = props.feed;
    const color = props.color;
    const feedType = props.feedType || 'public';

    return (
    <Box fulllWidth sx={{backgroundColor: '#222222', borderRadius: 3, borderWidth: 1, borderColor: '#f0f0f0', borderLeftWidth: 0, borderLeftColor: color, width: 320, minHeight: 150, margin:2, padding: 2, justifyContent: 'flex-start', textAlign: 'left', backgroundImage: `url(${props.backgroundImage})`, backgroundSize: 'cover'}}>
      <Text variant='h1' color='white'>{feed.name}</Text>
      <Text variant='body' color='gray-lighter'>{feed.description}</Text>
      <Box display='flex' flexDirection='row' mt={4}>
        <Box flexGrow={1}>
          <Text variant='small' color='gray-lighter'>Endpoint: {feed.endpoint}</Text>
        </Box>
        <Text variant='small' color='brand-light'>by: {feedType === 'private' ? 'you' : 'mbd team'}</Text>

       </Box>

    </Box>
    )
  }

  return (
    <Box display='flex' flexDirection='column' alignContent='flex-start'>
      {privateFeeds && privateFeeds.length > 0 && 
        <>
      <Box>
        <Text variant='h1'>My Feeds</Text>
      </Box>
    
        <Grid mt={4} container spacing={0} >
          {privateFeeds.map((feed, index) => (
              <ButtonBase
                sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                onClick={() => editFeed(feed)} // Trigger edit function when a private feed is clicked
              >
                <FeedTemplateCard feed={feed} color={CARD_COLORS[index % CARD_COLORS.length]} backgroundImage={feed.feed_image_url} feedType={'private'} />
              </ButtonBase>
          ))}
        </Grid>
        </>
      // : (
      //   <Box my={10}>
      //     <Text variant='body' color='gray'>
      //       You have not created any feeds yet. Create a new feed or select a template from below to get started.
      //     </Text>
      //     <Box mt={4}>
      //       <PrimaryButton onClick={() => createNewFeed()}>Create New Feed</PrimaryButton>
      //     </Box>
      //   </Box>
      // )
    }
    <Card 
      title='Feed Templates'
      description='Some useful feed templates to help you get started.'
      >

      {publicFeeds && publicFeeds.length > 0 ? (
        <Grid mt={4} container spacing={0}>
          {publicFeeds.map((feed, index) => (
              <ButtonBase
                sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                onClick={() => selectTemplate(feed)} // Trigger selectTemplate function when a public template is clicked
              >
                <FeedTemplateCard feed={feed} color={CARD_COLORS[index % CARD_COLORS.length]} backgroundImage={feed.feed_image_url} feedType={'public'} />
              </ButtonBase>
          ))}
        </Grid>
      ) : (
        <Box my={10}>
          <Text variant='body' color='gray'>
            No templates found. Please try again later.
          </Text>
        </Box>
      )}
      </Card>
    </Box>
  );
}
