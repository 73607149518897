import { useState } from 'react';
import { Stack, Box, Avatar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import { Event} from '../../components/lib';
import { Text } from '../text';
import logo_image from '../../assets/logo_image.png';

const stackStyle = {
  backgroundColor: '#fff', 
  borderRadius: 4,  
  borderWidth: 1, 
  borderColor: '#eeeeee',
}

const avatarStyle = {
  width: 35, 
  height: 35
}

// taken from Material UI docs
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(270deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
  ],
}));

export function BrandedBox(props){
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    const prefix = props.eventPrefix && props.eventPrefix !== '' ? `${props.eventPrefix}_` : '';
    const event = expanded ? 'collapse_text' : 'expand_text';
    Event(`${prefix}${event}`);
    setExpanded(!expanded);
  };
  
  return (

    <Stack direction='row' sx={stackStyle}>
      <Box mt={4} ml={4}>
        <Avatar src={logo_image} alt="Company Logo" style={avatarStyle}/>
      </Box>
      <Box sx={{ m: 4}}>

        {
          props.title &&
          <Typography variant="h5" sx={{mb: 4}}>
            {props.title}
          </Typography>
        }
        <Box flex={1} sx={{mb: 4}}>
          <Text variant='body' color='gray'>
            {props.shortText}
          </Text>
        </Box>
        <Box sx={{display: 'flex'}}>
          <Box flex={1}>

          </Box>
          <Box sx={{justifyContent: 'flex-end', marginTop: -4}}>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
          </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{mb: 4}}>
            <Text variant='body' color='gray'>
              {props.expandedText}
            </Text>
          </Box>
        </Collapse>
      </Box>
    </Stack>

  );
}