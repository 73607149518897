import { useEffect, useContext, useState } from 'react';
import { Stack, Avatar, Box } from '@mui/material';
import '@farcaster/auth-kit/styles.css';
import { SignInButton, useProfile} from '@farcaster/auth-kit';

import { AuthContext } from 'components/lib';
import { Text } from 'mbd-components/text';
import { updateUser } from 'helper/utils.js';
import { colors } from 'helper/colors.js';

export function ConsoleUser(props){

  const authContext = useContext(AuthContext);
  const { profile: farcasterProfile } = useProfile();
  const [ showSignOut, setShowSignOut ] = useState(false);
  const [ farcasterSignedIn, setFarcasterSignedIn ] = useState(false);
  const [ interacted, setInteracted ] = useState(false);

  useEffect(() => {
    if (authContext.user?.farcaster_profile && Object.keys(authContext.user?.farcaster_profile).length > 0) {
      setFarcasterSignedIn(true);
    }
    else {
      setFarcasterSignedIn(false);
    }

  }, [authContext.user]);

  useEffect(() => {
    if (farcasterProfile && Object.keys(farcasterProfile).length > 0) {
      authContext.update({
        ...authContext.user || {}, // Provide empty object as fallback
        farcaster_profile: farcasterProfile
      });
      if (interacted) {
        updateUser({
          farcaster_profile: farcasterProfile
        });
      }
    }
  }, [farcasterProfile]);



  const SignOutDropdown = () =>
    <Box 
      justifyItems={'center'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        position: 'absolute',
        top: '100%',
        left: -20,
        right: 0,
        display: 'flex',
        width: 150,
        bgcolor: 'white',
        border: '1px solid #e0e0e0',
        borderRadius: 1,
        mt: 1,
        p: 1,
        cursor: 'pointer',
        '&:hover': { bgcolor: colors['gray-lightest'] }
      }}
      onClick={() => {
        console.log('signing out, setting: ', { ...authContext.user, farcaster_profile: null });
        authContext.update({ ...authContext.user, farcaster_profile: null });
        setShowSignOut(false);
        setFarcasterSignedIn(false);
        authContext.update({
          ...authContext.user || {}, // Provide empty object as fallback
          farcaster_profile: null
        });
        updateUser({
          farcaster_profile: null
        });
      }}
    >
      <Text noWrap={true} variant='body' sx={{width: 150, textAlign: 'center'}}>Farcaster Sign Out</Text>
    </Box>


  return (
    <div >
      {
        farcasterSignedIn ?
          <>
            <Stack 
              direction='row' 
              alignItems='center' 
              spacing={1} 
              onClick={() => {setInteracted(true); setShowSignOut(!showSignOut)}}
              sx={{cursor: 'pointer', border: '2px solid #e0e0e0', borderRadius: 22, height: 46, pr: 2.5}}
            >
              <Avatar src={authContext.user?.farcaster_profile?.pfpUrl} alt={authContext.user?.farcaster_profile?.displayName} sx={{width: 42, height: 42}} />
              <Stack direction='column' spacing={0} sx={{py: 1, pl: 1}}>
                <Text variant='body'>{authContext.user?.farcaster_profile?.displayName}</Text>
                <Text variant='small' color='gray-light' > {`@${authContext.user?.farcaster_profile?.username} (${authContext.user?.farcaster_profile?.fid})`}</Text>
              </Stack>
            </Stack>
            {showSignOut && (
              <SignOutDropdown />
            )}
          </>
        :
          <Stack direction='row' alignItems='center' spacing={1} onClick={() => setInteracted(true)}>
            <Text variant='body'>{authContext.user?.name}</Text>
            {/* <div style={{transform: "scale(0.7)", backgroundColor: '#855DCD', borderRadius: 5}}>
              <SignInButton />
            </div> */}
          </Stack>
      }
    </div>
  );
}