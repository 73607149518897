import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { Loader } from 'components/lib';
import { AuthContext, ViewContext, Event } from 'components/lib';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Autocomplete,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
  Drawer,
  useMediaQuery, 
  useTheme,
  Grid,
  Chip,
  Stack,
  IconButton,
  Tooltip,
  ButtonBase
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import { DeleteOutline as DeleteIcon, 
          DifferenceOutlined as CopyIcon, 
          CloseOutlined as CloseIcon,
          EditOutlined as EditIcon,
          RemoveCircleOutline as RemoveIcon,
          UndoOutlined as UndoIcon,
          InfoOutlined as InfoIcon,
          HelpOutlineOutlined as QuestionIcon
} from '@mui/icons-material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
// import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedDarkAtom, vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { aiLabelsOptions, embedDomainsOptions, endpointOptions, scoringOptions, publicationTypesOptions, appFidOptions } from 'helper/constants';
import { geoLocations } from '../../helper/geo_locations';
import { PageIntro } from 'mbd-components/page-intro';
import { Text } from 'mbd-components/text';
import { PrimaryButton } from 'mbd-components/primary-button';
import { BrandedDialog } from 'mbd-components/branded-dialog';
import { FeedTemplateShowcase } from './feedTemplateShowcase';
import { colors } from 'helper/colors';
import { getLocationName, getGeloLocationDisplayString } from 'helper/utils';
import { languages } from 'helper/languages';
import { Slider } from 'mbd-components/slider';
import { explanations } from './explanations';

const customStyle = {
  borderRadius: '5px',
  backgroundColor: '#002b36',
  color: '#93a1a1',
  padding: '15px',
  margin: '10px 0',
  fontSize: '13px',
  width: '100%',
  height: 'auto',
  textAlign: 'left', // Align text to the left
  alignItems: 'left',
  overflowX: 'auto',
  '@media (max-width: 768px)': {
    fontSize: '11px',
    padding: '12px',
  },
  '@media (max-width: 480px)': {
    fontSize: '10px',
    padding: '10px',
  },
};

const primaryColor = '#e01296';

const DEFAULT_TIME_PERIOD = 30;

export function FeedConfig() {
  const [visibility, setVisibility] = useState('private');
  const [isEditMode, setIsEditMode] = useState(false);
  const [feedId, setFeedId] = useState('');
  const [feedName, setFeedName] = useState('');
  const [description, setDescription] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [status, setStatus] = useState('active');
  const [scoring, setScoring] = useState('');
  const [impressionCount, setImpressionCount] = useState(0);
  const [timePeriod, setTimePeriod] = useState(DEFAULT_TIME_PERIOD);
  const [timePeriodUnit, setTimePeriodUnit] = useState('days');
  const [promotionTypeValue, setPromotionTypeValue] = useState('feed');
  const [appFids, setAppFids] = useState([]);
  const [selectedGeoLocations, setSelectedGeoLocations] = useState([]);
  const [excludeGeoLocations, setExcludeGeoLocations] = useState([]);
  const [includesAILABELS, setIncludeAILABELS] = useState([]);
  const [excludeAILABELS, setExcludeAILABELS] = useState([]);
  const [includeAuthorIds, setIncludeAuthorIds] = useState([]);
  const [excludeAuthorIds, setExcludeAuthorIds] = useState([]);
  const [channels, setChannels] = useState([]);
  const [embedDomains, setEmbedDomains] = useState([]);
  const [includePublicationTypes, setIncludePublicationTypes] = useState([]);
  const [includeLanguages, setIncludeLanguages] = useState([]);
  const [includeParams, setIncludeParams] = useState([]);
  const [excludeParams, setExcludeParams] = useState([]);
  const [openIncludeDialog, setOpenIncludeDialog] = useState(false);
  const [openExcludeDialog, setOpenExcludeDialog] = useState(false);
  const [openContextualInfoDialog, setOpenContextualInfoDialog] = useState(false);
  const [openLabelOptionsDialog, setOpenLabelOptionsDialog] = useState(false);
  const [openExcludeLabelOptionsDialog, setOpenExcludeLabelOptionsDialog] = useState(false);
  const [selectedIncludeParams, setSelectedIncludeParams] = useState([]);
  const [selectedExcludeParams, setSelectedExcludeParams] = useState([]);
  const [selectedContextualInfoParams, setSelectedContextualInfoParams] = useState([]);
  const [contextualAppFid, setContextualAppFid] = useState([]);
  const [selectedContextualGeoLocation, setSelectedContextualGeoLocation] = useState([]);
  const [publicFeeds, setPublicFeeds] = useState([]); // State for public feeds
  const [privateFeeds, setPrivateFeeds] = useState([]); // State for private feeds
  const [isNewFeed, setIsNewFeed] = useState(false);
  const [authorOptions, setAuthorOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [savedConfigId, setSavedConfigId] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSaveAs, setShowSaveAs] = useState(true);
  const [loading, setLoading] = useState(false);
  const [promotionName, setPromotionName] = useState('');
  const [selectedPromotionFeedId, setSelectedPromotionFeedId] = useState('');
  const [selectedPromotionFeedName, setSelectedPromotionFeedName] = useState('');
  const [promotionPercentage, setPromotionPercentage] = useState(50);
  const [aiLabelThresholds, setAiLabelThresholds] = useState({});
  const [removeAILabelThresholds, setRemoveAILabelThresholds] = useState({});
  const [showPromotionSection, setShowPromotionSection] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(true);
  const [itemsList, setItemsList] = useState([{ item_id: '', rank: '' }]);
  const [currentAiLabel, setCurrentAiLabel] = useState('');
  const [currentAiLabelThreshold, setCurrentAiLabelThreshold] = useState(0);
  const [isShowcaseMode, setIsShowcaseMode] = useState(false);
  const [iframeKey, setIframeKey] = useState(Date.now());
  const [showInteracted, setShowInteracted] = useState(false);
  const [showFallbackSection, setShowFallbackSection] = useState(false);
  const [fallbackFeedId, setFallbackFeedId] = useState('');
  const [fallbackFeedName, setFallbackFeedName] = useState('');
  const [coldStartFeedId, setColdStartFeedId] = useState('');
  const [coldStartFeedName, setColdStartFeedName] = useState('');
  const [showColdStartSection, setShowColdStartSection] = useState(false);
  const [geoLocationWarningVisible, setGeoLocationWarningVisible] = useState(false);
  const [authorIdWarningVisible, setAuthorIdWarningVisible] = useState(false);
  const [dontReturnGlobalFallback, setDontReturnGlobalFallback] = useState(false);
  const [useTimeDecay, setUseTimeDecay] = useState(false);
  const [timeDecayValue, setTimeDecayValue] = useState(0);
  const [showFeedDiversitySection, setShowFeedDiversitySection] = useState(false);
  const [maxPostsPerAuthor, setMaxPostsPerAuthor] = useState(0);
  const [minDistanceBetweenPostsFromSameAuthor, setMinDistanceBetweenPostsFromSameAuthor] = useState(0);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [infoDialogTitle, setInfoDialogTitle] = useState('');
  const [infoDialogText, setInfoDialogText] = useState([]);

  const playgroundUrl = process.env.REACT_APP_PLAYGROUND_URL;

  
  const location = useLocation();
  const { feedId: initialFeedId, mode, new: initialNewFeed } = location.state || {};

  const promotionTypeList = [
    { value: 'feed', label: 'Feed' },
    { value: 'items', label: 'Items' }
  ]

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const togglePreviewDrawer = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };


  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  const statusOptions = ['active', 'inactive'];

  // Fetch feeds based on visibility type
  const fetchFeeds = async (type) => {
    try {
      let result = await Axios({
        url: '/api/feed/configs',
        method: 'post',
        data: {
          visibility: type,
          console_account_id: authContext?.user?.account_id || null,
        },
      });
      return result?.data?.data || [];
    } catch (error) {
      console.error(`Error fetching ${type} feeds:`, error);
      setSnackbarMessage(`Error fetching ${type} feeds`);
      setShowSnackbar(true);
      return [];
    }
  };

  // Fetch both public and private feeds simultaneously
  const fetchAllFeeds = async () => {
    setLoading(true);
    try {
      const [publicFeedsData, privateFeedsData] = await Promise.all([
        fetchFeeds('public'),
        fetchFeeds('private'),
      ]);
      setPublicFeeds(publicFeedsData);
      setPrivateFeeds(privateFeedsData);

    } catch (error) {
      console.error('Error fetching all feeds:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch channels details based on channel ids
  const fetchChannelsDetails = async (channels) => {
    if (!channels || channels.length === 0) return [];
    try {
      let channelsString = channels.join(',');
      let result = await Axios({
        url: '/api/farcaster/channel/bulk',
        method: 'get',
        params: { ids: channelsString, type: 'parent_url' },
      });
      return result?.data?.data || [];
    } catch (error) {
      console.error('Error fetching channels details:', error);
      setSnackbarMessage('Error fetching channel details');
      setShowSnackbar(true);
      return [];
    }
  };

  // Fetch author details based on author ids
  const fetchAuthorDetails = async (authorIds) => {
    if (!authorIds || authorIds.length === 0) return [];
    let authorIdList = [];
    let nonAuthorIdList = [];
    let resultList = [];

    authorIds.filter((id) => {
      if(id === 'user/user_id/following'){
       resultList.push({fname: 'following', fid: 'user/user_id/following'});
      }
      else if(id.startsWith('user/')){
        let fidVal = id.split('/')[1];
        nonAuthorIdList.push(fidVal);
      }else{
        authorIdList.push(id);
      }
    });

    try {
      let result = await Axios({
        url: '/api/farcaster/users/details',
        method: 'post',
        data: { fids: authorIdList },
      });
      resultList = resultList.concat(result?.data?.data || []);

      result  = await Axios({
        url: '/api/farcaster/users/details',
        method: 'post',
        data: { fids: nonAuthorIdList },
      });

      result?.data?.data.map((item) => {
          resultList.push({fname: `${item.fname}:following`, fid: `user/${item.fid}/following`});
      });

      return resultList || [];
    } catch (error) {
      console.error('Error fetching author details:', error);
      setSnackbarMessage('Error fetching author details');
      setShowSnackbar(true);
      return [];
    }
  };

  // Fetch autocomplete options based on user input and type
  const fetchAutocompleteOptions = async (query, type) => {
    if (!query) return [];
    try {
      if (type === 'author_ids') {
        let result = await Axios({
          url: '/api/farcaster/users/search',
          method: 'get',
          params: { query: query },
        });
        const options = result?.data?.data.map((item) => ({ label: item.fname, value: item.fid })) || [];

        // Add ":following" option for each result
        let resultOptions = [];
        const followingOptions = options.map((item) => (
          resultOptions.push({ label: item.label, value: item.value }),
          resultOptions.push({ label: `${item.label}:following`, value: `user/${item.value}/following` })
        ));
  
        return resultOptions || [];
      }
      if (type === 'channels') {
        let result = await Axios({
          url: '/api/farcaster/channel/search',
          method: 'get',
          params: { q: query, limit: 20 },
        });
        return result?.data?.data?.map((item) => ({ label: item.label, value: item.value })) || [];
      }
      return [];
    } catch (error) {
      console.error(`Error fetching ${type} options:`, error);
      setSnackbarMessage(`Error fetching ${type} options`);
      setShowSnackbar(true);
      return [];
    }
  };

  const handleVisibilityChange = (event, newVisibility) => {
    if (newVisibility !== null && visibility !== newVisibility) {
      setVisibility(newVisibility);
      setFeedId(''); // Reset feed ID when changing visibility
      resetFormFields(); // Reset form fields when visibility changes
      const feedsToUse = newVisibility === 'public' ? publicFeeds : privateFeeds;
      // Auto-select the first feed in the new visibility state if available
      if (feedsToUse.length > 0) {
        const firstFeed = feedsToUse[0];
        setFeedId(firstFeed.config_id);
        populateFeedDetails(firstFeed);
      }

      if(newVisibility === 'public') {
        Event('feed_builder_explore_feeds');
        setShowSaveAs(true);
        setIsEditMode(false);
        setIsShowcaseMode(true);
      }
      else {
        Event('feed_builder_manage_my_feeds');
        setIsShowcaseMode(false);
      }
      // setIsPreviewOpen(true);
    }
  };

  const handleCreateNewFeed = () => {
    Event("feed_builder_create_new_feed");
    setIsNewFeed(true);
    resetFormFields();
  }

  const handleFeedSelection = async (event) => {
    const selectedFeedId = event.target.value;
    if (selectedFeedId === 'new') {
      Event("feed_builder_create_new_feed");
      setIsNewFeed(true);
      resetFormFields();
    } else {
      Event("feed_builder_select_my_feed_from_dropdown");
      setIsNewFeed(false);
      setFeedId(selectedFeedId);
      const feeds = visibility === 'public' ? publicFeeds : privateFeeds;
      const selectedFeed = feeds.find((feed) => feed.config_id === selectedFeedId);
      if (selectedFeed) {
        populateFeedDetails(selectedFeed);
      }
      // setIsPreviewOpen(true);
    }
  };

  const resetFormFields = () => {
    setFeedId('');
    setFeedName('');
    setDescription('');
    setEndpoint('');
    setStatus('');
    setScoring('');
    setAppFids([]);
    setSelectedGeoLocations([]);
    setExcludeGeoLocations([]);
    setIncludeAILABELS([]);
    setExcludeAILABELS([]);
    setIncludeAuthorIds([]);
    setExcludeAuthorIds([]);
    setIncludePublicationTypes([]);
    setIncludeLanguages([]);
    setChannels([]);
    setEmbedDomains([]);
    setIncludeParams([]);
    setExcludeParams([]);
    setAuthorOptions([]);
    setChannelOptions([]);
    setPromotionName('');
    setSelectedPromotionFeedId('');
    setSelectedPromotionFeedName('');
    setSelectedExcludeParams([]);
    setSelectedIncludeParams([]);
    setPromotionPercentage(0);
    setItemsList([{ item_id: '', rank: '' }]);
    setShowPromotionSection(false);
    setShowColdStartSection(false);
    setShowInteracted(false);
    setColdStartFeedId(''); 
    setTimePeriod(DEFAULT_TIME_PERIOD);
    setDontReturnGlobalFallback(false);
    setShowFallbackSection(false);
    setFallbackFeedId('');
    setFallbackFeedName('');
    setUseTimeDecay(false);
    setTimeDecayValue(0);
    setShowFeedDiversitySection(false);
    setMaxPostsPerAuthor(0);
    setMinDistanceBetweenPostsFromSameAuthor(0);
  };

  const populateFeedDetails = async (feed) => {
    setFeedName(feed.name);
    setDescription(feed.description);
    setEndpoint(feed.endpoint);
    setStatus(feed.status);
    setScoring((feed.endpoint === 'casts/feed/trending' || feed.endpoint === 'casts/feed/popular') ? '1day' : 'all');
    setImpressionCount(feed.config.impression_count || 0);
    setUseTimeDecay(feed.config.hasOwnProperty('time_decay'));
    setTimeDecayValue(feed.config.time_decay || 0);
    if (feed.config.filters.start_timestamp) {
      if (feed.config.filters.start_timestamp.includes(':')) {
        setTimePeriod(parseInt(feed.config.filters.start_timestamp.split(':')[1]) || DEFAULT_TIME_PERIOD);
        setTimePeriodUnit(feed.config.filters.start_timestamp.split(':')[0] === 'days_ago' ? 'days' : 'hours');
      } else {
        setTimePeriod(parseInt(feed.config.filters.start_timestamp) || DEFAULT_TIME_PERIOD);
        setTimePeriodUnit('days');
      }
    }
    setShowInteracted(feed.config.show_interacted_items || false);
    if (feed.config.hasOwnProperty('feed_diversity_config')) {
      setShowFeedDiversitySection(true);
      if (feed.config.feed_diversity_config.max_posts_per_author) {
        setMaxPostsPerAuthor(feed.config.feed_diversity_config.max_posts_per_author);
      }
      if (feed.config.feed_diversity_config.min_distance_between_posts_from_same_author) {
        setMinDistanceBetweenPostsFromSameAuthor(feed.config.feed_diversity_config.min_distance_between_posts_from_same_author);
      }
    }

    if (feed.config.hasOwnProperty('return_global_fallback')){
      setDontReturnGlobalFallback(!feed.config.return_global_fallback);
    }
    else {
      setDontReturnGlobalFallback(false);
    }

    if(feed?.config?.cold_start?.cold_start_feed?.feed_id){
      setShowColdStartSection(true);
      setColdStartFeedId(feed.config.cold_start?.cold_start_feed?.feed_id || '');
    }else{
      setShowColdStartSection(false);
      setColdStartFeedId('');
    }

    if(feed?.config?.global_fallback?.global_fallback_feed?.feed_id){
      setShowFallbackSection(true);
      setFallbackFeedId(feed.config.global_fallback?.global_fallback_feed?.feed_id || '');
    }else{
      setShowFallbackSection(false);
      setFallbackFeedId('');
    }

    // Initialize AI label thresholds
    let initialIncludeThresholds = {};
    let initialExcludeThresholds = {};

    // Populate promotion fields based on existing config
    if (feed.config.promotion_filters && feed.config.promotion_filters.length > 0) {
      const promotion = feed.config.promotion_filters[0];
      if(promotion.promotion_type === 'feed'){
        setPromotionTypeValue(promotion.promotion_type || '');
        setPromotionName(promotion.promotion_name || '');
        setSelectedPromotionFeedId(promotion.feed_id || '');
        setSelectedPromotionFeedName(
          [...publicFeeds, ...privateFeeds].find((f) => f.config_id === promotion.feed_id)?.name || ''
        );
        setPromotionPercentage(promotion.percent || 0);
        setItemsList([{ item_id: '', rank: '' }]);
      }else if(promotion.promotion_type === 'items'){
        setPromotionName(promotion.promotion_name || '');
        setPromotionTypeValue(promotion.promotion_type || '');
        setItemsList(promotion.promoted_items || []);
        setPromotionPercentage(50);
        setSelectedPromotionFeedId('');
        setSelectedPromotionFeedName('');
      }
      setShowPromotionSection(true);
    }else{
        setPromotionName('');
        setSelectedPromotionFeedId('');
        setSelectedPromotionFeedName('');
        setPromotionPercentage(50);
        setItemsList([{ item_id: '', rank: '' }]);
        setShowPromotionSection(false);
    }
    if (feed?.config?.filters?.app_fids && feed?.config?.filters?.app_fids?.length > 0) { 
      setAppFids(feed.config.filters.app_fids);
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'app_fids'])]);
    }
    else {
      setAppFids([]);
      setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'app_fids'));
    }
    if (feed?.config?.filters?.geo_locations && feed?.config?.filters?.geo_locations?.length > 0) { 
      setSelectedGeoLocations(feed.config.filters.geo_locations.map( savedGeoLocation => getGeloLocationDisplayString(savedGeoLocation)));
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'geo_locations'])]);
    }
    else {
      setSelectedGeoLocations([]);
      setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'geo_locations'));
    }
    if (feed?.config?.filters?.remove_geo_locations && feed?.config?.filters?.remove_geo_locations?.length > 0) { 
      setExcludeGeoLocations(feed.config.filters.remove_geo_locations.map( savedGeoLocation => getGeloLocationDisplayString(savedGeoLocation)));
      setSelectedExcludeParams((prev) => [...new Set([...prev, 'geo_locations'])]);
    }
    else {
      setExcludeGeoLocations([]);
      setSelectedExcludeParams((prev) => prev.filter((param) => param !== 'geo_locations'));
    }

    if (feed?.config?.filters?.channels && feed?.config?.filters?.channels?.length > 0) {
      setIncludeParams((prev) => [...new Set([...prev, 'channels'])]);
      let channelsDetails = await fetchChannelsDetails(feed.config.filters.channels);
      setChannels(channelsDetails.map((ch) => ({ label: ch.name, value: ch.url })));
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'channels'])]);
    }else{
        setChannels([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'channels')); 
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'channels'));
    }

    // Set thresholds for include AI labels
    if (feed?.config?.filters?.ai_labels && feed.config.filters.ai_labels.length > 0) {
        const aiLabelsWithThresholds = feed.config.filters.ai_labels.map(label => {
        const [labelName, threshold] = label.split(':');
        if (threshold) {
            initialIncludeThresholds[labelName] = parseFloat(threshold); // Store the threshold value
        }
        return labelName; // Store the label name
        });
        setIncludeAILABELS(aiLabelsWithThresholds);
        setSelectedIncludeParams(prev => [...new Set([...prev, 'ai_labels'])]);
    } else {
        setIncludeAILABELS([]);
        setSelectedIncludeParams(prev => prev.filter(param => param !== 'ai_labels'));
    }

    if(feed?.config?.filters?.publication_types && feed?.config?.filters?.publication_types?.length > 0){
        setIncludeParams((prev) => [...new Set([...prev, 'publication_types'])]);
        setIncludePublicationTypes(feed.config.filters.publication_types.filter((type) =>publicationTypesOptions.includes(type)).map((type) => ({ label: type, value: type })));
        setSelectedIncludeParams((prev) => [...new Set([...prev, 'publication_types'])]);
    }else{
        setIncludePublicationTypes([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'publication_types'));
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'publication_types'));
    }
    if(feed?.config?.filters?.languages && feed?.config?.filters?.languages?.length > 0){
      setIncludeParams((prev) => [...new Set([...prev, 'languages'])]);
      setIncludeLanguages(feed.config.filters.languages);
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'languages'])]);
    }else{
        setIncludeLanguages([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'languages'));
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'languages'));
    }

    // Set thresholds for exclude AI labels
    if (feed?.config?.filters?.remove_ai_labels && feed.config.filters.remove_ai_labels.length > 0) {
        const aiLabelsWithThresholds = feed.config.filters.remove_ai_labels.map(label => {
        const [labelName, threshold] = label.split(':');
        if (threshold) {
            initialExcludeThresholds[labelName] = parseFloat(threshold); // Store the threshold value
        }
        return labelName; // Store the label name
        });
        setExcludeAILABELS(aiLabelsWithThresholds);
        setSelectedExcludeParams(prev => [...new Set([...prev, 'ai_labels'])]);
    } else {
        setExcludeAILABELS([]);
        setSelectedExcludeParams(prev => prev.filter(param => param !== 'ai_labels'));
    }

    // Set the initial AI label thresholds
    setAiLabelThresholds(initialIncludeThresholds);
    setRemoveAILabelThresholds(initialExcludeThresholds);

    if (feed?.config?.filters?.author_ids && feed?.config?.filters?.author_ids?.length > 0) {
      setIncludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
      let authorDetails = await fetchAuthorDetails(feed.config.filters.author_ids);
      setIncludeAuthorIds(authorDetails.map((id) => ({ label: id.fname, value: id.fid })));
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
    }else{
        setIncludeAuthorIds([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'author_ids')); 
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'author_ids'));
    }

    if (feed?.config?.filters?.remove_author_ids && feed?.config?.filters?.remove_author_ids?.length > 0) {
      setExcludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
      let authorDetails = await fetchAuthorDetails(feed.config.filters.remove_author_ids);
      setExcludeAuthorIds(authorDetails.map((id) => ({ label: id.fname, value: id.fid })));
      setSelectedExcludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
    }else{
        setExcludeAuthorIds([]);
        setExcludeParams((prev) => prev.filter((param) => param !== 'author_ids')); 
        setSelectedExcludeParams((prev) => prev.filter((param) => param !== 'author_ids'));
    }

    if (feed?.config?.filters?.embed_domains && feed?.config?.filters?.embed_domains?.length > 0) {
      setIncludeParams((prev) => [...new Set([...prev, 'embed_domains'])]);
      setEmbedDomains(feed.config.filters.embed_domains);
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'embed_domains'])]);
    }else{
        setEmbedDomains([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'embed_domains')); 
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'embed_domains'));
    }
    if (feed?.config?.context?.app_fid && feed?.config?.context?.app_fid !== '') { 
      setContextualAppFid(feed.config.context.app_fid);
      setSelectedContextualInfoParams((prev) => [...new Set([...prev, 'app_fid'])]);
    }
    else {
      setContextualAppFid('');
      setSelectedContextualInfoParams((prev) => prev.filter((param) => param !== 'app_fid'));
    }
    if (feed?.config?.context?.geo_location && feed?.config?.context?.geo_location !== '') { 
      const geoLocationInfo = geoLocations.find( location => location.location === 'geo:' + feed.config.context.geo_location);
      setSelectedContextualGeoLocation(geoLocationInfo ? feed.config.context.geo_location + ' (' + geoLocationInfo.city || geoLocationInfo.county + ', ' + geoLocationInfo.country + ')' : '');
      setSelectedContextualInfoParams((prev) => [...new Set([...prev, 'geo_location'])]);
    }
    else {
      setSelectedContextualGeoLocation('');
      setSelectedContextualInfoParams((prev) => prev.filter((param) => param !== 'geo_location'));
    }
  };

  const handleScoringChange = (event) => {
    setScoring(event.target.value);
  };

  const handleEndpointChange = (event) => {
    setEndpoint(event.target.value);
    setScoring('');
    if(event.target.value !== 'casts/feed/trending' && event.target.value !== 'casts/feed/popular'){
        setAiLabelThresholds({});
        setRemoveAILabelThresholds({});
    }
  };

  const handleIncludeParamsDialog = () => {
    setOpenIncludeDialog(true);
  };

  const handleExcludeParamsDialog = () => {
    setOpenExcludeDialog(true);
  };

  const handleItemChange = (value, index, field) => {
    const newList = [...itemsList];
    newList[index][field] = value;
    setItemsList(newList);
  };

  const addItem = () => {
    setItemsList([...itemsList, { item_id: '', rank: '' }]);
  };
  
  const removeItem = (index) => {
    const newList = itemsList.filter((_, i) => i !== index);
    setItemsList(newList);
  };
  
  

  const handleIncludeParamsChange = (event) => {
    const { name, checked } = event.target;
    setSelectedIncludeParams((prev) => (checked ? [...prev, name] : prev.filter((item) => item !== name)));
  
    if (!checked) {
      switch (name) {
        case 'app_fids':
          setAppFids([]);
          break;
        case 'geo_locations':
          setSelectedGeoLocations([]);
          break;
        case 'author_ids':
          setIncludeAuthorIds([]);
          break;
        case 'channels':
          setChannels([]);
          break;
        case 'ai_labels':
          setIncludeAILABELS([]);
          setAiLabelThresholds({}); // Reset thresholds when AI labels are unchecked
          break;
        case 'embed_domains':
          setEmbedDomains([]);
          break;
        case 'publication_types':
          setIncludePublicationTypes([]);
          break;
        default:
          break;
      }
    }
  };

  const handleExcludeParamsChange = (event) => {
    const { name, checked } = event.target;
    setSelectedExcludeParams((prev) => (checked ? [...prev, name] : prev.filter((item) => item !== name)));

    if (checked){
      if (name === 'geo_locations' && selectedIncludeParams.includes('geo_locations')){
        setGeoLocationWarningVisible(true);
      }
      else if (name === 'author_ids' && selectedIncludeParams.includes('author_ids')){
        setAuthorIdWarningVisible(true);
      }
    }
    else {
      switch (name) {
        case 'author_ids':
          setExcludeAuthorIds([]);
          setAuthorIdWarningVisible(false);
          break;
        case 'ai_labels':
          setExcludeAILABELS([]);
          setRemoveAILabelThresholds({}); // Reset thresholds when AI labels are unchecked
          break;
        case 'geo_locations':
          setExcludeGeoLocations([]);
          setGeoLocationWarningVisible(false);
          break;
        default:
          break;
      }
    }
  };  

  const handleContextualInfoParamsChange = (event) => {
    const { name, checked } = event.target;
    setSelectedContextualInfoParams((prev) => (checked ? [...prev, name] : prev.filter((item) => item !== name)));
  
    if (!checked) {
      switch (name) {
        case 'app_fids':
          setContextualAppFid('');
          break;
        case 'geo_locations':
          setSelectedContextualGeoLocation('');
          break;
        default:
          break;
      }
    }
  };  

  const handleSave = async () => {
    Event('feed_builder_save_feed');
    const config = {
      console_account_id: authContext?.user?.account_id || null,
      name: feedName,
      api_key: 'all',
      description,
      endpoint,
      status: 'active',
      visibility: 'private',
      config: {
        // context: {
        //   app_fid: contextualAppFid || '',
        //   geo_location: selectedContextualGeoLocation && selectedContextualGeoLocation !== '' && selectedContextualGeoLocation.includes(' ') ? selectedContextualGeoLocation.split(' ')[0] : '',
        // },
        filters: {
          channels: channels.length > 0 ? channels.map((ch) => ch.value) : [],
          app_fids: appFids,
          geo_locations: selectedGeoLocations.map(location => location.split(' ')[0]),
          remove_geo_locations: excludeGeoLocations.map(location => location.split(' ')[0]),
          ai_labels: includesAILABELS.map(label => aiLabelThresholds[label] ? `${label}:${aiLabelThresholds[label]}` : label),
          publication_types: includePublicationTypes.length > 0 ? includePublicationTypes.map((type) => type.value): [],
          languages: includeLanguages.length > 0 ? includeLanguages : [],
          remove_ai_labels: excludeAILABELS.map(label => removeAILabelThresholds[label] ? `${label}:${removeAILabelThresholds[label]}` : label),
          author_ids: includeAuthorIds.length > 0 ? includeAuthorIds.map((id) => id.value) : [],
          remove_author_ids: excludeAuthorIds.length > 0 ? excludeAuthorIds.map((id) => id.value) : [],
          embed_domains: embedDomains.length > 0 ? embedDomains : [],
        },
        return_global_fallback: !dontReturnGlobalFallback,
      },
    };

    if (showFeedDiversitySection && (maxPostsPerAuthor > 0 || minDistanceBetweenPostsFromSameAuthor > 0)) {
      let feedDiversityConfig = {}
      if (maxPostsPerAuthor > 0){
        feedDiversityConfig.max_posts_per_author = Number(maxPostsPerAuthor);
      }
      if (minDistanceBetweenPostsFromSameAuthor > 0){
        feedDiversityConfig.min_distance_between_posts_from_same_author = Number(minDistanceBetweenPostsFromSameAuthor);
      }
      config.config.feed_diversity_config = feedDiversityConfig;
    }
  
    if (showPromotionSection && promotionTypeValue && promotionTypeValue === 'feed' && promotionName && promotionName !== '' && selectedPromotionFeedId && selectedPromotionFeedId !== '') {
      config.config.promotion_filters = [{
        promotion_type: promotionTypeValue,
        promotion_name: promotionName,
        feed_id: selectedPromotionFeedId,
        percent: promotionPercentage
      }];
    }

    if (showPromotionSection && promotionTypeValue && promotionTypeValue === 'items' &&  promotionName && promotionName !== '' && itemsList.length > 0) {
      config.config.promotion_filters = [{
        promotion_name: promotionName,
        promotion_type: promotionTypeValue,
        promoted_items: itemsList
      }];
    }

    if(showInteracted){
      config.config.show_interacted_items = true;
    }

    if(useTimeDecay){
      config.config.time_decay = timeDecayValue;
    }

    if(showFallbackSection && fallbackFeedId && fallbackFeedId !== '') {
      config.config.global_fallback = {
        global_fallback_feed: {
          feed_id: fallbackFeedId
        }
      };
    } else {
      delete config.config.global_fallback;
    }

    if (showColdStartSection && coldStartFeedId && coldStartFeedId !== '') {
      config.config.cold_start = {
        cold_start_feed: {
          feed_id: coldStartFeedId
        }
      };
    } else {
      delete config.config.cold_start;
    }


    if(timePeriod && timePeriod > 0){
      config.config.filters.start_timestamp = timePeriodUnit === 'days' ? `days_ago:${timePeriod}` : `hours_ago:${timePeriod}`;
    }
  
    try {
      if (isNewFeed) {
        if (scoring && scoring !== '') {
          config.config.scoring = scoring;
        }
        config.config.scoring = (config.endpoint === 'casts/feed/trending' || config.endpoint === 'casts/feed/popular') ? '1day' : 'all';
        if(impressionCount && impressionCount >= 0){
          config.config.impression_count = impressionCount;
        }
        const response = await Axios.post('/api/feed/config', config);
        setSavedConfigId(response?.data?.data?.config_id);
        setFeedId(response?.data?.data?.config_id);
        setSnackbarMessage(`Feed created successfully! Your feed ID is ${response?.data?.data?.config_id}. Please use this feed ID in the API.`);
        setShowSnackbar(true);
        setShowSaveAs(true);
        setIsNewFeed(false);
        // setIsPreviewOpen(true);
        setIsEditMode(false);
      } else {
        if (scoring && scoring !== '') {
          config.config.scoring = scoring;
        }
        if(impressionCount && impressionCount > 0){
          config.config.impression_count = Number(impressionCount);
        }
        config.config_id = feedId;
        await Axios.patch('/api/feed/config', config);
        setSnackbarMessage('Feed updated successfully');
        setShowSnackbar(true);
        setShowSaveAs(true);
        // setIsPreviewOpen(true);
        setIsEditMode(false);
      }
      setIframeKey(Date.now());
    } catch (error) {
      console.error('Error saving feed configuration:', error);
      setSnackbarMessage(`Failed to save configuration ${error}`);
      setShowSnackbar(true);
    } finally {
      // Re-fetch both public and private feeds after saving or updating
      fetchAllFeeds();
    }
  }; 

  const handleSaveAs = () => {
    setIsNewFeed(true);
    setFeedId('');
    setVisibility('private');
    setFeedName(`${feedName}-copy`);
    setSnackbarMessage('Prepare to save as a new feed');
    setShowSnackbar(true);
    setShowSaveAs(false);
    setIsEditMode(true); 
    // setIsPreviewOpen(true);
  };

  const handleDelete = async () => {
    viewContext.modal.show({

      title: 'Delete Feed',
      form: {
        config_id: {
          type: 'hidden',
          value: feedId,
        }
      },
      buttonText: 'Delete Feed',
      url: '/api/feed/config',
      method: 'DELETE',
      destructive: true,
      text: 'Are you sure you want to delete this feed? ' +
      'If you are using this feed_id in your application, it will stop working.',

    }, () => {

      Event('feed_builder_delete_feed');
      handlePostDelete();

    });
  }

  const handlePostDelete = async () => {
    try {
  
      // Show success message in snackbar
      setSnackbarMessage('Feed Config deleted successfully');
      setShowSnackbar(true);
  
    const [publicFeedsData, privateFeedsData] = await Promise.all([
        fetchFeeds('public'),
        fetchFeeds('private'),
    ]);

    // Set the state for public and private feeds
    setPublicFeeds(publicFeedsData);
    setPrivateFeeds(privateFeedsData);
      
      // Reset the form fields and select the first feed from the updated list
      const updatedFeeds = visibility === 'public' ? publicFeedsData : privateFeedsData;
      if (updatedFeeds.length > 0) {
        const firstFeed = updatedFeeds[0];
        resetFormFields(); // Clear all form fields
        setFeedId(firstFeed.config_id);
        populateFeedDetails(firstFeed);
        // setIsPreviewOpen(true);
      } else {
        // If no feeds are left, reset the fields
        resetFormFields();
      }
  
    } catch (error) {
      console.error('Error deleting feed configuration:', error);
      setSnackbarMessage('Failed to delete the feed configuration');
      setShowSnackbar(true);
    }
  };

  const handleCancelEdit = async () => {
    // viewContext.modal.show({

    //   title: 'Cancel Edit',
    //   buttonText: 'OK',
    //   destructive: true,
    //   text: 'Cancel editing this feed? Unsaved changes will be lost.'

    // }, async () => {

      // TODO: show a confirmation dialog before cancelling edit

      setIsEditMode(false);
      const myFeeds = await fetchFeeds('private');
      const currentFeed = myFeeds.find((feed) => feed.config_id === feedId);
      populateFeedDetails(currentFeed);

    // });
  }

  const handleDeleteIncludeAILabel = (event, labelToDelete) => {
    event.stopPropagation();
    const newIncludesAILabels = includesAILABELS.filter((label) => label !== labelToDelete);
    setIncludeAILABELS(newIncludesAILabels);
  }

  const handleDeleteExcludeAILabel = (event, labelToDelete) => {
    event.stopPropagation();
    const newExcludeAILabels = excludeAILABELS.filter((label) => label !== labelToDelete);
    setExcludeAILABELS(newExcludeAILabels);
  }
  

  useEffect(() => {
    const fetchInitialFeeds = async () => {
      // Fetch feeds data directly
      const [publicFeedsData, privateFeedsData] = await Promise.all([
        fetchFeeds('public'),
        fetchFeeds('private'),
      ]);
  
      // Set the state for public and private feeds
      setPublicFeeds(publicFeedsData);
      setPrivateFeeds(privateFeedsData);
  
      // Determine which feeds to use based on visibility and `feedId`
      const initialFeeds = visibility === 'public' ? publicFeedsData : privateFeedsData;


      
      // If `feedId` exists in query, find and select it
      if ( mode !== 'template') {
        if (initialFeedId) {
          const selectedFeed = [...publicFeedsData, ...privateFeedsData].find(feed => feed.config_id === initialFeedId);
          if (selectedFeed) {
            setIsShowcaseMode(false);
            setVisibility(mode === 'public' ? 'public' : 'private');
            setFeedId(selectedFeed.config_id);
            populateFeedDetails(selectedFeed);

            return;
          }
        }
    
        // Default to the first available feed if no `feedId` is provided
        if (initialFeeds.length > 0) {
          const firstFeed = initialFeeds[0];
          resetFormFields(); // Clear all form fields
          setFeedId(firstFeed.config_id); // Set the Feed ID
          populateFeedDetails(firstFeed); // Populate the form fields with feed details
        }
      }
    };

    fetchInitialFeeds();

    if(location.state?.initialNewFeed){
      populateFeedDetails(location.state.initialNewFeed);
      setIsNewFeed(true);
      setIsShowcaseMode(false);
      setVisibility('private');
    }
  
  }, []);  

  useEffect(() => {
    if (selectedIncludeParams.includes('geo_locations') && selectedExcludeParams.includes('geo_locations')){
      setGeoLocationWarningVisible(true);
    }
    else {
      setGeoLocationWarningVisible(false);
    }
    if (selectedIncludeParams.includes('author_ids') && selectedExcludeParams.includes('author_ids')){
      setAuthorIdWarningVisible(true);
    }
    else {
      setAuthorIdWarningVisible(false);
    }
  }, [selectedIncludeParams, selectedExcludeParams]);

  const findNewItem = (oldList, newList) => {
    return newList.find((item) => !oldList.some((oldItem) => oldItem === item));
  }

  const selectTemplate = (feed) => {  
    Event('feed_builder_select_template');
    setFeedId(feed.config_id);
    populateFeedDetails(feed);
    setIsShowcaseMode(false);
  }

  const createNewFeed = () => {
    resetFormFields();
    setIsNewFeed(true);
    setFeedName('New Feed');
    setDescription('New Feed');
    setEndpoint('casts/feed/trending');
    setStatus('active');
    setIsShowcaseMode(false);
    setIsEditMode(true);
    setVisibility('private');
  }

  const editFeed = (feed) => {
    Event('feed_builder_select_my_feed');
    setFeedId(feed.config_id);
    populateFeedDetails(feed);
    setIsEditMode(true);
    setIsShowcaseMode(false);
    setVisibility('private');
  }
  
  const NonEditableTextField = (props) => 
    <Box  mr={4} mt={4} sx={{display:'flex', flexDirection: props.horizontal ? 'row' : 'column', alignItems: props.horizontal ? 'center' : 'flex-start'}} >
      <Text variant='fieldHeading' color='brand-light'>
        {props.heading}
        {
            props.showExplanation &&
            <IconButton
              ml={1}
              onClick={() => {setOpenInfoDialog(true); 
                setInfoDialogTitle(props.explanationTitle); 
                setInfoDialogText(props.explanationDescription)
              }}
            >
              <Tooltip title={`What is ${props.heading.toLowerCase()}?`}>
                <QuestionIcon sx={{color:colors['gray-lighter']}}/>
              </Tooltip>
            </IconButton>
          }
      </Text>
      <Text variant='fieldValue' color={props.value === 'None' || props.valueNotSet ? 'gray-light' : 'gray'} sx={{marginLeft: props.horizontal ? 2 : 0, marginTop: props.horizontal ? 0 : 2}}>
        {props.value}
      </Text>
    </Box>



  //
  // This causes problems at the moment used in as a component - text field loses focus after every key stroke.  Probably something is triggering a re-render
  //  
  const EditableTextField = (props) =>
    <Box  mr={4} mt={4} sx={{display:'flex', flexDirection: props.horizontal ? 'row' : 'column', alignItems: props.horizontal ? 'center' : 'flex-start'}} fullWidth >
      <Text variant='fieldHeading' color='brand-light' sx={{minWidth: 80}}>
        {props.heading}
      </Text>
      <TextField 
        fullWidth
        multiline={props.multiline ? true : false}
        rows={props.rows ? props.rows : 1}
        value={props.value} 
        onChange={props.onChange} 
        variant="outlined" 
        sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginLeft: props.horizontal ? 2 : 0, marginTop: props.horizontal ? 0 : 2}} 
      />
    </Box>

  const SelectField = (props) =>
  {
    return (
      <Box sx={{display:'flex', flexDirection: props.horizontal ? 'row' : 'column', alignItems: props.horizontal ? 'center' : 'flex-start'}} fullWidth >
        <Text variant='fieldHeading' color='brand-light'>
          {props.heading}
        </Text>
        <FormControl variant="outlined" sx={{width: props.width ? props.width : '100%'}}>
          <Select 
            value={props.value} 
            onChange={props.onChange} 
            width='600px'
            sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor }, marginLeft: props.horizontal ? 2 : 0, marginTop: props.horizontal ? 0 : 2}}
          >
            {props.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }
  
  const CountField = (props) =>
    <Box sx={{display:'flex', flexDirection: props.horizontal ? 'row' : 'column', alignItems: props.horizontal ? 'center' : 'flex-start'}} fullWidth >
      <Text noWrap={true} variant='fieldHeading' color='brand-light' sx={{minWidth: props.minWidth || 170}}>
          {props.heading}
          {
            props.showExplanation &&
            <IconButton
              ml={1}
              onClick={() => {setOpenInfoDialog(true); 
                setInfoDialogTitle(props.explanationTitle); 
                setInfoDialogText(props.explanationDescription)
              }}
            >
              <Tooltip title={`What is ${props.heading.toLowerCase()}?`}>
                <QuestionIcon sx={{color:colors['gray-lighter']}}/>
              </Tooltip>
            </IconButton>
          }
      </Text>
      <FormControl fullWidth variant="outlined">
        <Box display="flex" alignItems="center" gap={2} sx={{marginLeft: props.horizontal ? 2 : 0, marginTop: props.horizontal ? 0 : 2}}>
          <TextField
            type="number"
            value={props.value}
            onChange={props.onChagne}
            variant='outlined'
            step={props.step ? props.step : 1}
            inputProps={{
              min: 0,
              style: { textAlign: 'center' } // Center align the input text
            }}
            sx={{width: 120}}
          />
        </Box>
      </FormControl>
    </Box>

  const AutoCompleteField = (props) =>
    <Box mt={4}>
      <Text variant='fieldHeading' color='brand-light'>
        {props.heading}
      </Text>
      <Autocomplete
        multiple
        options={props.options}
        value={props.value}
        onInputChange={props.onInputChange}
        onChange={props.onChange}
        renderInput={props.renderInput}
        sx={{ my: 2 }}
      />
    </Box>
  const NonEditableSelections = (props) =>
    <Box>
      <Box my={2}>
        <Text variant='fieldHeading' color='brand-light'>{props.heading}</Text>
      </Box>
      {
        props.selections.length > 0 ? 
          <Stack direction='row' spacing={1} sx={{flexWrap: 'wrap', gap: '16px 8px'}}>
            {props.selections.map((selection) => <Chip margin={2} label={selection} variant='outlined' key={selection} sx={{color:'#97979A'}}/>)}
          </Stack>
        : 
          <Text variant='fieldValue' color='gray-light'>None</Text>
      }
    </Box>

  const Promotion = () =>

      <Box>
        <NonEditableTextField horizontal={true} heading="Promotion Type" value={promotionTypeValue}/> 
        {
          promotionTypeValue === 'feed' ?
          <Box>
            <Box display="flex" flexDirection={'row'}>
              <NonEditableTextField horizontal={true} heading="Feed ID" value={selectedPromotionFeedId}/>
              <NonEditableTextField horizontal={true} heading="Feed Name" value={selectedPromotionFeedName}/>
            </Box>
            <Box display="flex" flexDirection={'row'}>
              <NonEditableTextField horizontal={true} heading="Promotion Name" value={promotionName}/>
              <NonEditableTextField horizontal={true} heading="Percentage" value={promotionPercentage}/>
            </Box>
          </Box>
        :
          <Box mt={2}>

            <Stack direction='row' spacing={4}>
              <Box>
                <Text variant='fieldHeading' color='brand-light'> Item ID </Text>
                {
                  itemsList.map((item) => <Text variant='fieldValue' color='gray-light'> {item.item_id} </Text>)
                }
              </Box>
              <Box ml={4}>
                <Text variant='fieldHeading' color='brand-light'> Rank </Text>
                {
                  itemsList.map((item) => <Text variant='fieldValue' color='gray-light'> {item.rank} </Text>)
                }
              </Box>
            </Stack>
          </Box>
        }
      </Box>

    const FallbackConfigurations = () =>
        
      <Box mt={4}>
       <Text variant='h2'>Fallback Feed</Text>
        <Box display="flex" flexDirection={'row'}>
          <NonEditableTextField horizontal={true} heading="Feed Id" value={fallbackFeedId}/>
        </Box>
      </Box>

    const ColdStartUserConfigurations = () =>
        
        <Box mt={4}>
         <Text variant='h2'>Cold Users Feed</Text>
          <Box display="flex" flexDirection={'row'}>
            <NonEditableTextField horizontal={true} heading="Feed Id" value={coldStartFeedId}/>
          </Box>
        </Box>
    


  
  const NonEditableFeedDetails = () =>
    <Box my={1}>

      <Box display="flex" flexDirection={'row'} alignItems={'center'}>
        <Box sx={{flexGrow: visibility === 'private' ? 1 : 0}} mr={ visibility === 'public' ? 10 : 0}>
          <Text variant='h1'>Feed Info</Text>
        </Box>
        {visibility === 'private' ? 
          <Stack direction={'row'}>
              <IconButton onClick={ 
                () => {
                  Event('feed_builder_clone_feed');
                  handleSaveAs();
                }} 
                color="error" sx={{width: 40, padding: 1, 
                fontSize: 'small'}}>
                  <Tooltip title="Clone this feed as a new feed">
                    <CopyIcon />
                  </Tooltip>
              </IconButton> 
              <IconButton onClick={handleDelete} color="error" sx={{width: 40, marginRight: 1}}>
                <Tooltip title="Delete Feed">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
              <PrimaryButton onClick={() => { Event('feed_builder_edit_feed'); setIsEditMode(true)}}> Edit Feed </PrimaryButton>  
            </Stack>
          : 
          <PrimaryButton onClick={ 
            () => {
              Event('feed_builder_use_template');       
              handleSaveAs()
            }}> 
            Use Template 
          </PrimaryButton>  
        }
      </Box>
      <Box display="flex" flexDirection={'row'}>
        <NonEditableTextField horizontal={true} heading="Feed ID" value={feedId}/>
        <NonEditableTextField horizontal={true} heading="Feed Name" value={feedName}/>
      </Box>
      <NonEditableTextField heading="Endpoint" value={endpoint}/>
      <NonEditableTextField heading="Description" value={description}/>
      <Box mt={4}>
        <Text variant='h1'>Feed Configurations</Text>
      </Box>

      <Box mt={4}>
        <Text variant='h2'>Feed Freshness</Text>
      </Box>

      <Box display="flex" flexDirection={'row'}>
        {/* <NonEditableTextField horizontal={true} heading="scoring" value={scoring}/> */}
        <NonEditableTextField horizontal={true} heading="Time Period" value={`${timePeriod} ${timePeriodUnit}`}/>
        <NonEditableTextField horizontal={true} heading="Impression Count" value={impressionCount}/>
      </Box>
      <Box mt={2}>
        <NonEditableTextField horizontal={true} heading="Time Decay" value={ useTimeDecay ? timeDecayValue : 'None'}/>
      </Box>
      <Box mt={3} gap={2}>
        <Text variant='fieldHeading' color='brand-light' sx={{ mt:4 }}>
          Other Options
        </Text>
        <Stack direction='row' spacing={1} sx={{ mt:2 }}>
          <Text variant='fieldHeading' color='gray-lighter' >
            Show items user has already interacted with?
          </Text>
          <Text variant='fieldValue' >
            { showInteracted ? 'Yes' : 'No' }
          </Text>
        </Stack>
        <Stack direction='row' spacing={1} sx={{ mt:2 }}>
          <Text variant='fieldHeading' color='gray-lighter' >
            Disable global fallback feed?
          </Text>
          <Text variant='fieldValue' >
            { dontReturnGlobalFallback ? 'Yes' : 'No' }
          </Text>
        </Stack>
      </Box>
      <Box mt={4}>
        <Text variant='h2'>Feed Diversity</Text>
      </Box>
      <Box my={2}>
        {
          showFeedDiversitySection ?
            <>
              <NonEditableTextField horizontal={true} heading="Maximum Posts from Same Author" value={maxPostsPerAuthor > 0 ? maxPostsPerAuthor : 'None'}/>
              <NonEditableTextField horizontal={true} heading="Minimum Distance Between Posts from Same Author" value={minDistanceBetweenPostsFromSameAuthor > 0 ? minDistanceBetweenPostsFromSameAuthor : 'None'}/>
            </>
          :
          <Text variant='fieldValue' color='gray-light'>None</Text>
        }
      </Box>
      <Box mt={4}>
        <Text variant='h2'>Include Params</Text>
        <NonEditableSelections heading="Source App FIDs" selections={ appFids }/>
        <NonEditableSelections heading="Geo Locations" selections={ selectedGeoLocations }/>
        <NonEditableSelections heading="Author IDs" selections={ includeAuthorIds.map((author) => author.label)}/>
        <NonEditableSelections heading="Channels" selections={channels.map((channel) => channel.label)}/>
        <NonEditableSelections heading="AI Labels" selections={includesAILABELS}/>
        <NonEditableSelections heading="Embed Domains" selections={embedDomains}/>
        <NonEditableSelections heading="Publication Types" selections={includePublicationTypes.map(type => type.label)}/>
        <NonEditableSelections heading="Languages" selections={ includeLanguages}/>
      </Box>
      <Box mt={4}>
        <Text variant='h2'>Exclude Params</Text>
        <NonEditableSelections heading="Author IDs" selections={excludeAuthorIds.map((author) => author.label)}/>
        <NonEditableSelections heading="AI Labels" selections={excludeAILABELS}/>
        <NonEditableSelections heading="Geo Locations" selections={excludeGeoLocations}/>
      </Box>
      <Box mt={4}>
        <Text variant='h2' sx={{mb: 2}}>Promotions</Text>
        {
          showPromotionSection ?
            <Promotion /> 
          : 
            <Text variant='fieldValue' color='gray-light'>None</Text>
        }
      </Box>
      <Box mt={4}>
        <Text variant='h1' sx={{mb: 2}} > Fallback Configurations</Text>
        {
          showFallbackSection ?
         <FallbackConfigurations />
          : 
          <Text variant='fieldValue' color='gray-light'>None</Text>
        }
      </Box>


      <Box mt={4}>
        <Text variant='h1' sx={{mb: 2}} > Cold Start User Configurations</Text>
        {
          showColdStartSection ?
         <ColdStartUserConfigurations />
          : 
          <Text variant='fieldValue' color='gray-light'>None</Text>
        }
      </Box>
      {/* <Box mt={4}>
        <Text variant='h1' gap={2} > Contextual Information</Text>

        <NonEditableTextField horizontal={false} heading="App FID" value={contextualAppFid !== '' ? contextualAppFid : 'None'}/>
        <NonEditableTextField horizontal={false} heading="Geo Location" value={selectedContextualGeoLocation !== '' ? selectedContextualGeoLocation : 'None'}/>

      </Box> */}
    </Box>

  return (
    <Grid container justifyContent="center" spacing={4} p={4} >
      <BrandedDialog 
        open={openInfoDialog} 
        title={infoDialogTitle} 
        texts={infoDialogText} 
        onClose={() => setOpenInfoDialog(false)} 
        buttonText="Got It 👀 "
      />

      {/* <Dialog open={openInfoDialog} onClose={() => setOpenInfoDialog(false)}>
        <DialogTitle>{infoDialogTitle}</DialogTitle>
        <DialogContent>

            <Stack direction={'row'} spacing={1} mt={1} alignItems={'center'}>
              <InfoIcon sx={{color:colors['gray-lighter']}}/>
              <Text variant='body'>
                {infoDialogText}
              </Text>
            </Stack>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={() => setOpenInfoDialog(false)}>Got It 👀 </PrimaryButton>
        </DialogActions>
      </Dialog> */}
      {/* <Grid item xs={12}>

        <Box display="flex" justifyContent="center" mb={3}>
          <ToggleButtonGroup
            value={visibility}
            exclusive
            onChange={handleVisibilityChange}
            aria-label="visibility"
          >
            <ToggleButton value="public" aria-label="public" >
              Explore Feeds
            </ToggleButton>
            <ToggleButton value="private" aria-label="private">
              Manage My Feeds
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid> */}
      { visibility !== 'public' &&
          <Grid item xs={12} sm={isShowcaseMode ? 12 : 8}>
            <Box mb={4}>
              <Typography variant="h5" mb={2} sx={{fontWeight: 500}}>Your Feeds</Typography>
              <Typography variant="body2" color="textSecondary">
                Select a feed
              </Typography>
              <Stack direction='row' spacing={2} alignItems={'center'}>
                <FormControl fullWidth margin="dense" variant="outlined">
                  {/* <InputLabel shrink={!!feedId}>Select a feed</InputLabel> */}
                  <Select value={feedId} onChange={handleFeedSelection}>
                    {/* {visibility === 'private' && <MenuItem value="new">Create New Feed</MenuItem>} */}
                    {(visibility === 'public' ? publicFeeds : privateFeeds).sort((a, b) => {
                      const numA = parseInt(a.config_id.split('_')[1], 10);
                      const numB = parseInt(b.config_id.split('_')[1], 10);
                      return numB - numA; // Descending order
                    }).map((feed) => (
                      <MenuItem key={feed.config_id} value={feed.config_id}>{`${feed.name} (${feed.config_id})`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <PrimaryButton onClick={handleCreateNewFeed} sx={{width: '120px'}}>New Feed</PrimaryButton>
              </Stack>
            </Box>
          </Grid>
      }
      { visibility === 'public' && !isShowcaseMode &&
        <Grid item xs={12} sm={12} mb={4} sx={{width:'100%'}}>
          <Button sx={{textTransform: 'none', padding: 0, color: primaryColor}}  onClick={() => { Event('feed_builder_browse_feeds'); setIsShowcaseMode(true)}}>
            {`< Back to Browse Templates`}
          </Button>
        </Grid>
      }
      <Grid item xs={12} md={isShowcaseMode ? 12 : 6} sm={isShowcaseMode ? 12 : 8} pr={isShowcaseMode ? 0 : 2}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
            <Loader />
          </Box>
        ) : (
          <>
          {/* <PageIntro 
              title="Feed Configurations" 
              intro="Feed configurations makes it possible to change the behaviour of a feed here in mbd console 
              without any code change in the frontend.  To get started, clone a public template as a base, or craft 
              one from scratch.  Once you save a feed, a feed_id is generated, which can be used in API calls in 
              place of the selected params." /> */}

          {
            isShowcaseMode ? <FeedTemplateShowcase 
              publicFeeds={publicFeeds}
              privateFeeds={privateFeeds}
              createNewFeed={createNewFeed}
              selectTemplate={selectTemplate}
              editFeed={editFeed}
              /> 
            : 
            <Box>

              { !isEditMode ? <NonEditableFeedDetails />  :
                  <>
                    <Box pt={1} display="flex" flexDirection={'row'} alignItems={'center'} fullWidth>
                      <Box sx={{flexGrow: 1}}>
                        <Text variant='h1'>Feed Info</Text>
                      </Box>
                      <Stack direction={'row'}>
                        { !isNewFeed &&
                          <IconButton onClick={handleCancelEdit} color="error" sx={{width: 40, padding: 1, fontSize: 'small'}}>
                            <Tooltip title="Cancel editing feed">
                              <UndoIcon />
                            </Tooltip>
                          </IconButton> 
                        }
                        { !isNewFeed &&
                          <IconButton onClick={() => {Event("feed_builder_clone_feed"); handleSaveAs()}} color="error" sx={{width: 40, padding: 1, fontSize: 'small'}}>
                            <Tooltip title="Clone this feed as a new feed">
                              <CopyIcon />
                            </Tooltip>
                          </IconButton> 
                        }
                        { !isNewFeed &&
                          <IconButton onClick={handleDelete} color="error" sx={{width: 40, marginRight: 1}}>
                            <Tooltip title="Delete Feed">
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        }
                        <PrimaryButton onClick={() => {Event("feed_builder_clone_feed"); handleSave()}}> Save Feed </PrimaryButton>   
                      </Stack>
                    </Box>    
                    <Box display="flex" mt={4} fullWidth>
                    
                      <NonEditableTextField horizontal={true} heading="Feed ID" value={isNewFeed ? '(feed_id generated when saved)' : feedId} showExplanation={true} explanationTitle={explanations.feedId.title} explanationDescription={explanations.feedId.description}/>
                      <Box flexGrow={1}>
                        <Box display="flex" mt={4} fullWidth sx={{alignItems: 'center'}}>
                            <Text variant='fieldHeading' color='brand-light' sx={{minWidth: 80}}>
                              Feed Name
                            </Text>
                            <TextField 
                              fullWidth
                              value={feedName} 
                              onChange={(e) => setFeedName(e.target.value)} 
                              variant="outlined" 
                              sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginLeft: 2}} 
                            />
                          </Box>        
                      </Box>
                    </Box>
                    <Box display="flex" mt={4}>
                      <SelectField heading="Endpoint" value={endpoint} onChange={handleEndpointChange} options={endpointOptions.map(option => ({label: option, value: option}))} width='350px'/>
                    </Box>
                    <Box mt={4}>
                      <Box  mr={4} mt={4} sx={{display:'flex', flexDirection: 'column', alignItems: 'flex-start'}} fullWidth >
                        <Text variant='fieldHeading' color='brand-light' sx={{minWidth: 80}}>
                          Description
                        </Text>
                        <TextField 
                          fullWidth
                          multiline
                          rows={4}
                          value={description} 
                          onChange={(e) => setDescription(e.target.value)} 
                          variant="outlined" 
                          sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginTop: 2}} 
                        />
                      </Box>
                    </Box>
                    <Box mt={4}>
                        <Text variant='h1'>Feed Configurations</Text>
                    </Box>
                    <Box mt={4}>
                        <Text variant='h2'>Feed Freshness</Text>
                    </Box>
                    <Stack direction='column' spacing={4} mt={4} >
                      <Box display="flex" alignItems="center" gap={1} mt={4}>
                        <CountField horizontal heading="Time Period" value={timePeriod} onChagne={(e) => setTimePeriod(e.target.value)} sx={{ width: '20px' }}/>
                        <Select variant='standard' value={timePeriodUnit} onChange={(e) => setTimePeriodUnit(e.target.value)} sx={{ml: 2}}>
                          <MenuItem value="days">days</MenuItem>
                          <MenuItem value="hours">hours</MenuItem>
                        </Select>
                      </Box>
                      <Box display="flex" alignItems="center" gap={1} mt={4}> 
                        <CountField horizontal heading="Impression Count" value={impressionCount} onChagne={(e) => setImpressionCount(e.target.value)} showExplanation={true} explanationTitle={explanations.impressionCount.title} explanationDescription={explanations.impressionCount.description}/>
                      </Box>
                    </Stack>
                    <Stack direction='row' spacing={2} mt={6} alignItems={'center'} fullWidth>
                      <Text variant='fieldHeading' color='brand-light' sx={{minWidth: 140}}>
                          Time Decay
                          <IconButton
                            ml={1}
                            onClick={() => {setOpenInfoDialog(true); 
                              setInfoDialogTitle(explanations.timeDecay.title); 
                              setInfoDialogText(explanations.timeDecay.description)
                            }}
                          >
                            <Tooltip title="What is time decay?">
                              <QuestionIcon sx={{color:colors['gray-lighter']}}/>
                            </Tooltip>
                          </IconButton>
                      </Text>

                      <Checkbox
                        checked={useTimeDecay}
                        onChange={(e) => setUseTimeDecay(e.target.checked)}
                        sx={{ '&.Mui-checked': { color: primaryColor }}}
                      />
                      {
                        useTimeDecay ? 
                          <Stack direction='row' alignItems={'center'} gap={2} fullWidth flexGrow={1}>
                            <Text variant='body'>{timeDecayValue}</Text>
                            <Slider
                              valueLabelDisplay='auto'
                              value={timeDecayValue}
                              onChange={(e, value) => setTimeDecayValue(value)}
                              min={0}
                              max={1}
                              step={0.01}
                              sx={{width: '100%', mx: isMobile ? 2 : 8}}
                            />
                          </Stack>
                        :
                        <Text variant='body' color='gray-light'>(not using time decay)</Text>
                      }
                    </Stack>
                    <Box mt={2} gap={2}>
                      <Text variant='fieldHeading' color='brand-light' sx={{ mt:4 }}>
                        Other Options
                      </Text>
                      <Stack direction='row' sx={{ mt:4 }} alignItems={'center'}>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={showInteracted} 
                                onChange={(e) => setShowInteracted(e.target.checked)} 
                                sx={{ '&.Mui-checked': { color: primaryColor }}} 
                              />
                            }
                            label=""
                          />
                        </Box>
                        <Text variant='body'>Show items user has already interacted with (hidden by default)</Text>
                      </Stack>

                      <Stack direction='row' sx={{ mt:1 }} alignItems={'center'}>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={dontReturnGlobalFallback} 
                                onChange={(e) => setDontReturnGlobalFallback(e.target.checked)} 
                                sx={{ '&.Mui-checked': { color: primaryColor }}} 
                              />
                            }
                            label=""
                          />
                        </Box>
                        <Text variant='body'>Disable global fallback feed when feed exhausted? (enabled by default)</Text>
                      </Stack>
                    </Box>
                    <Box display="flex" alignItems="center" gap={2} mt={4}>
                      <Text variant='h2' > Feed Diversity</Text>
                      {
                        isEditMode &&
                        <Button onClick={() => {
                          setShowFeedDiversitySection(!showFeedDiversitySection);
                          }}>
                          <Tooltip title="Use feed diversity configurations">
                            <EditIcon sx={{color:primaryColor}}/>
                          </Tooltip>
                        </Button>
                      }
                    </Box>
                      {showFeedDiversitySection && (
                        <Box mt={4}>
                          <Box display="flex" alignItems="center" gap={1} mt={4}> 
                            <CountField horizontal minWidth={400} heading="Maximum Posts from Same Author" value={maxPostsPerAuthor} onChagne={(e) => setMaxPostsPerAuthor(e.target.value)} showExplanation={true} explanationTitle={explanations.maxPostsPerAuthor.title} explanationDescription={explanations.maxPostsPerAuthor.description}/>
                          </Box>
                          <Box display="flex" alignItems="center" gap={1} mt={4}> 
                            <CountField horizontal minWidth={400} heading="Minimum Distance Between Posts from Same Author" value={minDistanceBetweenPostsFromSameAuthor} onChagne={(e) => setMinDistanceBetweenPostsFromSameAuthor(e.target.value)} showExplanation={true} explanationTitle={explanations.minDistanceBetweenPosts.title} explanationDescription={explanations.minDistanceBetweenPosts.description}/>
                          </Box>                          
                        </Box>
                      ) 
                      }
                      {
                        !showFeedDiversitySection &&
                        maxPostsPerAuthor <= 0 &&
                        minDistanceBetweenPostsFromSameAuthor <= 0 &&
                        <Text variant='body' color='gray-lighter' sx={{ my:4 }}>
                          Not configured
                        </Text>
                        
                      }

                    {/* Include Params Section */}
                    <Box display="flex" alignItems="center" gap={2} mt={6}>
                      <Text variant='h2'>Include Params</Text>
                      {
                        isEditMode &&
                        <Button onClick={handleIncludeParamsDialog}>
                          <Tooltip title="Add or remove inclusion rules">
                            <EditIcon sx={{color:primaryColor}}/>
                          </Tooltip>
                        </Button>
                      }
                    </Box>

                    <Dialog open={openIncludeDialog} onClose={() => setOpenIncludeDialog(false)}>
                      <DialogTitle>Select Include Params</DialogTitle>
                      <DialogContent>
                        {['app_fids', 'geo_locations', 'author_ids', 'channels', 'ai_labels', 'embed_domains', 'publication_types', 'languages'].map((param) => (
                          <FormControlLabel
                            key={param}
                            control={<Checkbox checked={selectedIncludeParams.includes(param)} onChange={handleIncludeParamsChange} name={param} />}
                            label={param}
                            disabled={visibility === 'public'} // Disable in public mode
                          />
                        ))}
                        {
                          authorIdWarningVisible &&
                          <Stack direction={'row'} spacing={1} mt={1} alignItems={'center'}>
                            <InfoIcon sx={{color:colors['gray-lighter']}}/>
                            <Text variant='warning' color='gray-lighter'>
                              Note that author_ids in exclude params is ignored if author_ids is set here.
                            </Text>
                          </Stack>
                        }
                        {
                          geoLocationWarningVisible &&
                          <Stack direction={'row'} spacing={1} mt={1} alignItems={'center'}>
                            <InfoIcon sx={{color:colors['gray-lighter']}}/>
                            <Text variant='warning' color='gray-lighter'>
                              Note that geo_locations in exclude params is ignored if geo_locations is set here.
                            </Text>
                          </Stack>
                        }
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpenIncludeDialog(false)}>Close</Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog open={openLabelOptionsDialog} onClose={() => setOpenLabelOptionsDialog(false)}>
                      <DialogTitle>Set AI Label Threshold</DialogTitle>
                      <DialogContent>
                        <NonEditableTextField horizontal heading="AI Label" value={currentAiLabel}/>
                        <NonEditableTextField horizontal heading="Threshold" value={aiLabelThresholds[currentAiLabel] || '(not set - using mbd default)'} valueNotSet={ aiLabelThresholds[currentAiLabel] ? false : true}/>
                        {/* <CountField horizontal heading="Threshold" value={aiLabelThresholds[currentAiLabel] || 0} onChagne={(e, value) => setAiLabelThresholds(prev => ({ ...prev, [currentAiLabel]: value }))} step={0.01}/> */}
                        <Box px={2} mt={4}>
                        <Slider
                          value={currentAiLabelThreshold || 0} // Use the stored threshold value or default to 0
                            onChange={(e, value) => {
                              setCurrentAiLabelThreshold(value); 
                              setAiLabelThresholds(prev => ({ ...prev, [currentAiLabel]: value })) } }
                          step={0.01}
                          min={0}
                          max={1}
                          valueLabelDisplay="auto" // Display value on the slider
                          sx={{ color: aiLabelThresholds[currentAiLabel] ? colors['brand-light'] : colors['gray-lighter'], minWidth: 400 }}
                        />
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button  sx={{ color: primaryColor }} onClick={() => setOpenLabelOptionsDialog(false)} >OK</Button>
                      </DialogActions>
                    </Dialog>


                    <Box my={2}>
                      {selectedIncludeParams.includes('app_fids') && (
                          <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Source App (type App FID and press enter to add)
                            </Text>
                            <Autocomplete
                              multiple
                              freeSolo
                              options={appFidOptions}
                              value={appFids}
                              onChange={(e, newValue) => setAppFids(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ mt: 2 }}
                            />
                          </Box>
                      ) }
                      {selectedIncludeParams.includes('geo_locations') && (
                          <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Geo Locations
                            </Text>
                            <Autocomplete
                              multiple
                              freeSolo
                              options={geoLocations.map((location) => location.location.split('geo:')[1] + ' (' + getLocationName(location) + ')')}
                              value={selectedGeoLocations}
                              onChange={(e, newValue) => setSelectedGeoLocations(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ mt: 2 }}
                            />
                          </Box>
                      ) }
                      {selectedIncludeParams.includes('author_ids') && (
                          <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Author Ids
                            </Text>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={includeAuthorIds.some((item) => item.value === 'user/user_id/following')}
                                  onChange={(e) => {
                                    const hasFollowing = includeAuthorIds.some((item) => item.value === 'user/user_id/following');

                                    if (e.target.checked) {
                                      if (!hasFollowing) {
                                        // Add "following" entry if not present
                                        setIncludeAuthorIds([{ label: 'following', value: 'user/user_id/following' }]);
                                        setSnackbarMessage('Only one entry for this type of author_ids are allowed at a time');
                                        setShowSnackbar(true);
                                      }
                                    } else {
                                      // Remove "following" entry
                                      setIncludeAuthorIds((prev) => prev.filter((item) => item.value !== 'user/user_id/following'));
                                    }
                                                                
                                  }}
                                />
                              }
                              label="following (include Author IDs which user is following)"
                              sx={{ mt:1}}
                            />
                            <Autocomplete
                              multiple
                              options={authorOptions}
                              value={includeAuthorIds}
                              onInputChange={async (e, newValue) => {
                                if (newValue) {
                                  const options = await fetchAutocompleteOptions(newValue, 'author_ids');
                                  setAuthorOptions(options);
                                }
                              }}
                              onChange={(e, newValue) => {
                                const hasFollowing = newValue.some((item) =>
                                  item.value.includes('following')
                                );
                                if (hasFollowing) {
                                  // Allow only one entry if "following" or "user_id:following" is selected
                                  setIncludeAuthorIds([newValue.find((item) => item.value.includes('following'))]);
                                  setSnackbarMessage('Only one entry for this type of author_ids are allowed at a time');
                                  setShowSnackbar(true);
                                } else {
                                  // Allow multiple entries otherwise
                                  setIncludeAuthorIds(newValue);
                                }
                              }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ mb: 2 }}
                            />
                          </Box>
                      )}
                      {selectedIncludeParams.includes('channels') && (
                          <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Channels
                            </Text>
                            <Autocomplete
                              multiple
                              options={channelOptions}
                              value={channels}
                              onInputChange={async (e, newValue) => {
                                if (newValue) {
                                  const options = await fetchAutocompleteOptions(newValue, 'channels');
                                  setChannelOptions(options);
                                }
                              }}
                              onChange={(e, newValue) => setChannels(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ my: 2 }}
                            />
                          </Box>
                        
                      )}
                      {selectedIncludeParams.includes('ai_labels') && (
                            <Box mt={4}>
                                <Text variant="fieldHeading" color='brand-light'>AI Labels</Text>
                                <Autocomplete
                                  multiple
                                  options={aiLabelsOptions}
                                  value={includesAILABELS}
                                  onChange={(e, newValue) => { 
                                    const newLabel = findNewItem(includesAILABELS, newValue); 
                                    setIncludeAILABELS(newValue);
                                    setCurrentAiLabel(newLabel); 
                                    setCurrentAiLabelThreshold(aiLabelThresholds[newLabel] || 0);
                                    setOpenLabelOptionsDialog(true)
                                  }}
                                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                      const { key, ...tagProps } = getTagProps({ index });
                                      return (
                                        <Chip disabled={(endpoint !== 'casts/feed/trending' && endpoint !== 'casts/feed/popular')} label={option} key={key} {...tagProps} 
                                          onClick={
                                            (endpoint === 'casts/feed/trending' || endpoint === 'casts/feed/popular') ? 
                                              () => { 
                                                setCurrentAiLabel(option); 
                                                setCurrentAiLabelThreshold(aiLabelThresholds[option] || 0);
                                                setOpenLabelOptionsDialog(true)
                                              }
                                            : () => {}
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={
                                                (event) => handleDeleteIncludeAILabel(event, option)
                                              }
                                            />
                                          }  
                                        />
                                      );
                                    })
                                  }
                          
                                  sx={{ my: 2 }}
                                />


                            </Box>
                      )}
                      {selectedIncludeParams.includes('embed_domains') && (

                          <Box mt={4}>
                          <Text variant='fieldHeading' color='brand-light'>
                            Embed Domains (press enter to add domain not in suggested list)
                          </Text>
                          <Autocomplete
                                  multiple
                                  freeSolo
                                  options={embedDomainsOptions}
                                  value={embedDomains}
                                  onChange={(e, newValue) => setEmbedDomains(newValue)}
                                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                                  sx={{ my: 2 }}
                          />
                          </Box>

                      )}
                      {selectedIncludeParams.includes('publication_types') && (
                        <Box mt={4}>
                          <Text variant='fieldHeading' color='brand-light'>
                            Publication Types
                          </Text>
                          <Autocomplete
                              multiple
                              options={publicationTypesOptions.map(option => ({label: option, value: option}))}
                              value={includePublicationTypes}
                              onChange={(e, newValue) => setIncludePublicationTypes(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ my: 2 }}
                            />
                          </Box>
                      )}
                      {selectedIncludeParams.includes('languages') && (
                        <Box mt={4}>
                          <Text variant='fieldHeading' color='brand-light'>
                            Languages
                          </Text>
                          <Autocomplete
                              multiple
                              options={languages}
                              value={includeLanguages}
                              onChange={(e, newValue) => setIncludeLanguages(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ my: 2 }}
                            />
                          </Box>
                      )}
                      {
                        !selectedIncludeParams.includes('author_ids') &&
                        !selectedIncludeParams.includes('channels') &&
                        !selectedIncludeParams.includes('ai_labels') &&
                        !selectedIncludeParams.includes('embed_domains') &&
                        !selectedIncludeParams.includes('publication_types') &&
                        !selectedIncludeParams.includes('languages') &&

                        <Text variant='body' color='gray-lighter' sx={{ my:4 }}>
                          Not configured
                        </Text>
                        
                      }
                    </Box>

                    {/* Exclude Params Section */}
                    <Box display="flex" alignItems="center" gap={2} mt={4}>
                      <Text variant='h2'>Exclude Params</Text>
                      {
                        isEditMode &&
                        <Button onClick={handleExcludeParamsDialog}>
                          <Tooltip title="Add or remove exclusion rules">
                            <EditIcon sx={{color:primaryColor}}/>
                          </Tooltip>
                        </Button> 
                      }
                    </Box>

                    <Dialog open={openExcludeDialog} onClose={() => setOpenExcludeDialog(false)}>
                      <DialogTitle>Select Exclude Params</DialogTitle>
                      <DialogContent>
                        {['author_ids', 'ai_labels', 'geo_locations'].map((param) => (
                          <FormControlLabel
                            key={param}
                            control={<Checkbox checked={selectedExcludeParams.includes(param)} onChange={handleExcludeParamsChange} name={param} />}
                            label={param}
                            disabled={visibility === 'public'} // Disable in public mode
                          />
                        ))}
                        {
                          authorIdWarningVisible &&
                          <Stack direction={'row'} spacing={1} mt={1} alignItems={'center'}>
                            <InfoIcon sx={{color:colors['gray-lighter']}}/>
                            <Text variant='warning' color='gray-lighter'>
                              Note that author_ids here is ignored if author_ids is set in include params.
                            </Text>
                          </Stack>
                        }
                        {
                          geoLocationWarningVisible &&
                          <Stack direction={'row'} spacing={1} mt={1} alignItems={'center'}>
                            <InfoIcon sx={{color:colors['gray-lighter']}}/>
                            <Text variant='warning' color='gray-lighter'>
                              Note that geo_locations here is ignored if geo_locations is set in include params.
                            </Text>
                          </Stack>
                        }
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpenExcludeDialog(false)}>Close</Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog open={openExcludeLabelOptionsDialog} onClose={() => setOpenExcludeLabelOptionsDialog(false)}>
                      <DialogTitle>Set AI Label Threshold</DialogTitle>
                      <DialogContent>
                        <NonEditableTextField horizontal heading="AI Label" value={currentAiLabel}/>
                        <NonEditableTextField horizontal heading="Threshold" value={removeAILabelThresholds[currentAiLabel] || '(not set - using mbd default)'} valueNotSet={ removeAILabelThresholds[currentAiLabel] ? false : true}/>
                        <Box px={2} mt={4}>
                        <Slider
                          value={currentAiLabelThreshold || 0} // Use the stored threshold value or default to 0
                            onChange={(e, value) => {
                              setCurrentAiLabelThreshold(value); 
                              setRemoveAILabelThresholds(prev => ({ ...prev, [currentAiLabel]: value })) } }
                          step={0.01}
                          min={0}
                          max={1}
                          valueLabelDisplay="auto" // Display value on the slider
                          sx={{ color: removeAILabelThresholds[currentAiLabel] ? colors['brand-light'] : colors['gray-lighter'], minWidth: 400 }} // Display value on the slider
                        />
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button  sx={{ color: primaryColor }} onClick={() => setOpenExcludeLabelOptionsDialog(false)} >OK</Button>
                      </DialogActions>
                    </Dialog>

                    <Box my={2}>
                      {selectedExcludeParams.includes('author_ids') && (
                        <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Exclude Author Ids
                            </Text>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={excludeAuthorIds.some((item) => item.value === 'user/user_id/following')}
                                  onChange={(e) => {
                                    const hasFollowing = excludeAuthorIds.some((item) => item.value === 'user/user_id/following');

                                    if (e.target.checked) {
                                      if (!hasFollowing) {
                                        // Add "following" entry if not present
                                        setExcludeAuthorIds([{ label: 'following', value: 'user/user_id/following' }]);
                                        setSnackbarMessage('Only one entry for this type of author_ids are allowed at a time');
                                        setShowSnackbar(true);
                                      }
                                    } else {
                                      // Remove "following" entry
                                      setExcludeAuthorIds((prev) => prev.filter((item) => item.value !== 'user/user_id/following'));
                                    }
                                  }}
                                />
                              }
                              label="following (exclude Author IDs which user is following)"
                              sx= {{ mt:1 }}
                            />
                            <Autocomplete
                              multiple
                              options={authorOptions}
                              value={excludeAuthorIds}
                              onInputChange={async (e, newValue) => {
                                if (newValue) {
                                  const options = await fetchAutocompleteOptions(newValue, 'author_ids');
                                  setAuthorOptions(options);
                                }
                              }}
                              onChange={(e, newValue) =>{
                                const hasFollowing = newValue.some((item) =>
                                  item.value.includes('following')
                                );
                                if (hasFollowing) {
                                  // Allow only one entry if "following" or "user_id:following" is selected
                                  setExcludeAuthorIds([newValue.find((item) => item.value.includes('following'))]);
                                  setSnackbarMessage('Only one entry for this type of author_ids are allowed at a time');
                                  setShowSnackbar(true);
                                } else {
                                  // Allow multiple entries otherwise
                                  setExcludeAuthorIds(newValue);
                                }
                              }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ mb: 2 }}
                            />
                        </Box>

                      )}
                      {selectedExcludeParams.includes('ai_labels') && (
                          <>
                                <Text variant="fieldHeading" color='brand-light'>AI Labels</Text>
                                <Autocomplete
                                  multiple
                                  options={aiLabelsOptions}
                                  value={excludeAILABELS}
                                  onChange={(e, newValue) => { 
                                    const newLabel = findNewItem(excludeAILABELS, newValue); 
                                    setExcludeAILABELS(newValue);
                                    setCurrentAiLabel(newLabel); 
                                    setCurrentAiLabelThreshold(aiLabelThresholds[newLabel] || 0);
                                    setOpenExcludeLabelOptionsDialog(true)
                                  }}
                                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                      const { key, ...tagProps } = getTagProps({ index });
                                      return (
                                        <Chip disabled={(endpoint !== 'casts/feed/trending' && endpoint !== 'casts/feed/popular')} label={option} key={key} {...tagProps} 
                                          onClick={
                                            (endpoint === 'casts/feed/trending' || endpoint === 'casts/feed/popular') ? 
                                                () => { 
                                                  setCurrentAiLabel(option); 
                                                  setCurrentAiLabelThreshold(removeAILabelThresholds[option] || 0);
                                                  setOpenExcludeLabelOptionsDialog(true)
                                                }
                                              : () => {}
                                          } 
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={
                                                (event) => handleDeleteExcludeAILabel(event, option)
                                              }
                                            />
                                          }                                            
                                        />
                                      );
                                    })
                                  }
                          
                                  sx={{ my: 2 }}
                                />

                          </>
                      )}
                      {selectedExcludeParams.includes('geo_locations') && (
                          <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Geo Locations
                            </Text>
                            <Autocomplete
                              multiple
                              options={geoLocations.map((location) => location.location.split('geo:')[1] + ' (' + getLocationName(location) + ')')}
                              value={excludeGeoLocations}
                              onChange={(e, newValue) => setExcludeGeoLocations(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ mt: 2 }}
                            />
                          </Box>
                      ) }
                      {
                        !selectedExcludeParams.includes('author_ids') &&
                        !selectedExcludeParams.includes('ai_labels') &&
                        !selectedExcludeParams.includes('geo_locations') &&

                        <Text variant='body' color='gray-lighter' sx={{ my:4 }}>
                          Not configured
                        </Text>
                        
                      }
                    </Box>
                    {/* Promotion Section */}
                    <Box display="flex" alignItems="center" gap={2} mt={4}>
                      
                      <Text variant='h2'>
                        Promotion
                        <IconButton
                              ml={1}
                              onClick={() => {setOpenInfoDialog(true); 
                                setInfoDialogTitle(explanations.promotion.title); 
                                setInfoDialogText(explanations.promotion.description)
                              }}
                            >
                              <Tooltip title="What is promotion?">
                                <QuestionIcon sx={{color:colors['gray-lighter']}}/>
                              </Tooltip>
                            </IconButton>
                      </Text>
                      {
                        isEditMode &&
                        <Button onClick={() => {
                          setShowPromotionSection(!showPromotionSection);
                          if (!showPromotionSection) {
                              // Reset promotion fields when closing
                              setPromotionName('');
                              setSelectedPromotionFeedId('');
                              setSelectedPromotionFeedName('');
                              setPromotionPercentage(50);
                              setItemsList([{ item_id: '', rank: '' }]);
                          }
                          }}>
                          <Tooltip title="Add or remove a promotion">
                            <EditIcon sx={{color:primaryColor}}/>
                          </Tooltip>
                        </Button>
                      }
                    </Box>

                    {showPromotionSection ? (
                      <Box gap={2} my={2}>
                        <SelectField heading="Promotion Type" value={promotionTypeValue} onChange={(e) => setPromotionTypeValue(e.target.value)} options={promotionTypeList} fullWidth/>
                        <Box mt={4} sx={{display:'flex', flexDirection: 'column', alignItems: 'flex-start'}} fullWidth >
                              <Text variant='fieldHeading' color='brand-light' sx={{minWidth: 80}}>
                                Promotion Name
                              </Text>
                              <TextField 
                                fullWidth
                                value={promotionName} 
                                onChange={(e) => setPromotionName(e.target.value)} 
                                variant="outlined" 
                                sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginTop : 2}} 
                              />
                            </Box>
                        {/* Render fields for "feed" promotion type */}
                        {promotionTypeValue === 'feed' && (
                          <>
                            <Box mt={2}>
                              <SelectField heading="Select Feed for Promotion" value={selectedPromotionFeedId} onChange={(e) => {
                                    const feedId = e.target.value;
                                    setSelectedPromotionFeedId(feedId);
                                    const feed = [...publicFeeds, ...privateFeeds].find((f) => f.config_id === feedId);
                                    setSelectedPromotionFeedName(feed ? feed.name : '');
                                  }}
                                  options={publicFeeds.concat(privateFeeds).filter(
                                    (f) => f.endpoint === endpoint && f.config_id !== feedId
                                  ).map(
                                    (feed) => ({label: `${feed.name} (${feed.config_id})`, value: feed.config_id})
                                  )}/>
                            </Box>

                            <Box display="flex" gap={2} my={2}>
                              <NonEditableTextField horizontal={true} heading="Feed ID" value={selectedPromotionFeedId}/>
                              <NonEditableTextField horizontal={true} heading="Feed Name" value={selectedPromotionFeedName}/>
                            </Box>

                            <Box mt={4} mb={4}>
                              <Text variant='fieldHeading' color='brand-light'>Percentage</Text>
                              <Box mt={2} px={2}>
                              <Slider
                                value={promotionPercentage}
                                onChange={(e, value) => setPromotionPercentage(value)}
                                step={1}
                                min={1}
                                max={100}
                                valueLabelDisplay="auto"
                                sx={{ color: '#97979a' }}
                              />
                              </Box>
                            </Box>
                          </>
                        )}

                        {/* Render fields for "items" promotion type */}
                        {promotionTypeValue === 'items' && (
                          <>
                            {itemsList.map((item, index) => (
                              <Box display="flex" gap={2} my={2} key={index} alignItems={'center'} alignContent={'center'}>
                                <Box flexGrow={1}>
                                  <Box sx={{display:'flex', flexDirection: 'row', alignItems:'center' }} >
                                    <Text variant='fieldHeading' color='brand-light'>
                                      Item ID
                                    </Text>
                                    <TextField 
                                      fullWidth
                                      value={item.item_id} 
                                      onChange={(e) => handleItemChange(e.target.value, index, 'item_id')} 
                                      variant="outlined" 
                                      sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginLeft: 1}} 
                                    />

                                  </Box>                                
                                </Box>
                                <Box>
                                  <Box sx={{display:'flex', flexDirection: 'row', alignItems:'center' }} >
                                    <Text variant='fieldHeading' color='brand-light'>
                                      Rank
                                    </Text>
                                    <TextField 
                                      type="number"
                                      step={1}
                                      value={item.rank} 
                                      onChange={(e) => handleItemChange(e.target.value, index, 'rank')} 
                                      variant="outlined" 
                                      sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor} , marginLeft: 1}} 
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: 'center' } // Center align the input text
                                      }}
                                    />
                                  </Box>                                
                                </Box>

                                <Box sx={{height: '100%', alignItems: 'center'}} py={2}>
                                  <IconButton mt={2} onClick={() => removeItem(index)} color="error" sx={{width: 40, marginRight: 1}}>
                                      <RemoveIcon />
                                  </IconButton>
                                </Box>

                              </Box>
                            ))}
                            <Box my={4}>
                              <PrimaryButton onClick={addItem}> + Add Another Item </PrimaryButton> 
                            </Box>
                          </>
                        )}
                      </Box>
                    ) : 
                      <Text variant='body' color='gray-lighter' sx={{ my:4 }}>
                        Not configured
                      </Text>
                    }
                    <Box display="flex" alignItems="center" gap={2} mt={4}>
                      <Text variant='h1' >Fallback Configurations
                        <IconButton
                            ml={1}
                            onClick={() => {setOpenInfoDialog(true); 
                              setInfoDialogTitle(explanations.fallback.title); 
                              setInfoDialogText(explanations.fallback.description)
                            }}
                          >
                            <Tooltip title="What is Fallback Configuration?">
                              <QuestionIcon sx={{color:colors['gray-lighter']}}/>
                            </Tooltip>
                        </IconButton>
                      </Text>
                      {
                        isEditMode &&
                        <Button onClick={() => {
                          setShowFallbackSection(!showFallbackSection);
                          if (!showFallbackSection) {
                              // Reset fallback feed
                              setFallbackFeedId('');
                          }
                          }}>
                          <Tooltip title="Add or remove fallback configurations">
                            <EditIcon sx={{color:primaryColor}}/>
                          </Tooltip>
                        </Button>
                      }
                    </Box>
                      {showFallbackSection ? (
                        <Box mt={4}>
                          <Text variant='h2'>Fallback Feed</Text>
                            <Box display="flex" flexDirection={'row'} mt={4}>
                            <SelectField heading="Select Feed for Fallback" value={fallbackFeedId} onChange={(e) => {
                                      const feedId = e.target.value;
                                      setFallbackFeedId(feedId);
                                      const feed = [...publicFeeds, ...privateFeeds].find((f) => f.config_id === feedId);
                                      setFallbackFeedName(feed ? feed.name : '');
                                    }}
                                    options={publicFeeds.concat(privateFeeds).filter(
                                      (f) => f.endpoint === endpoint && f.config_id !== feedId
                                    ).map(
                                      (feed) => ({label: `${feed.name} (${feed.config_id})`, value: feed.config_id})
                                    )}/>
                            </Box>
                        </Box>
                      ) : 
                        <Text variant='body' color='gray-lighter' sx={{ my:4 }}>
                          Not configured
                        </Text>
                      }
                    <Box display="flex" alignItems="center" gap={2} mt={4}>
                      <Text variant='h1' > Cold Start User Configurations
                        <IconButton
                            ml={1}
                            onClick={() => {setOpenInfoDialog(true); 
                              setInfoDialogTitle(explanations.coldStart.title); 
                              setInfoDialogText(explanations.coldStart.description)
                            }}
                          >
                            <Tooltip title="What is cold start user configuration?">
                              <QuestionIcon sx={{color:colors['gray-lighter']}}/>
                            </Tooltip>
                        </IconButton>
                      </Text>
                      {
                        isEditMode &&
                        <Button onClick={() => {
                          setShowColdStartSection(!showColdStartSection);
                          if (!showColdStartSection) {
                              // Reset promotion fields when closing
                              setColdStartFeedId('');
                              setColdStartFeedName('');
                          }
                          }}>
                          <Tooltip title="Add or remove cold start user configurations">
                            <EditIcon sx={{color:primaryColor}}/>
                          </Tooltip>
                        </Button>
                      }
                    </Box>
                      {showColdStartSection ? (
                        <Box mt={4}>
                          <Text variant='h2'>Cold Users Feed</Text>
                            <Box display="flex" flexDirection={'row'} mt={4}>
                            <SelectField heading="Select Feed for Cold Start User" value={coldStartFeedId} onChange={(e) => {
                                      const feedId = e.target.value;
                                      setColdStartFeedId(feedId);
                                      const feed = [...publicFeeds, ...privateFeeds].find((f) => f.config_id === feedId);
                                      setColdStartFeedName(feed ? feed.name : '');
                                    }}
                                    options={publicFeeds.concat(privateFeeds).filter(
                                      (f) => f.endpoint === endpoint && f.config_id !== feedId
                                    ).map(
                                      (feed) => ({label: `${feed.name} (${feed.config_id})`, value: feed.config_id})
                                    )}/>
                            </Box>
                        </Box>
                      ) : 
                        <Text variant='body' color='gray-lighter' sx={{ my:4 }}>
                          Not configured
                        </Text>
                      }
                    {/* <Box display="flex" alignItems="center" gap={2} mt={4}>
                      <Text variant='h1' > Contextual Information</Text>
                      {
                        isEditMode &&
                        <Button onClick={() => setOpenContextualInfoDialog(true)}>
                          <Tooltip title="Add or remove exclusion rules">
                            <EditIcon sx={{color:primaryColor}}/>
                          </Tooltip>
                        </Button> 
                      }
                      <Dialog open={openContextualInfoDialog} onClose={() => setOpenContextualInfoDialog(false)}>
                        <DialogTitle>Select Contextual Information To Include</DialogTitle>
                        <DialogContent>
                          {['app_fid', 'geo_location'].map((param) => (
                            <FormControlLabel
                              key={param}
                              control={<Checkbox checked={selectedContextualInfoParams.includes(param)} onChange={handleContextualInfoParamsChange} name={param} />}
                              label={param}
                              disabled={visibility === 'public'} // Disable in public mode
                            />
                          ))}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setOpenContextualInfoDialog(false)}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                    <Box my={2}>
                      {selectedContextualInfoParams.includes('app_fid') && (
                          <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Source App 
                            </Text>
                            <Autocomplete
                              freeSolo
                              options={appFidOptions}
                              value={contextualAppFid}
                              onChange={(e, newValue) => setContextualAppFid(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ mt: 2 }}
                            />
                          </Box>
                      ) }
                      {selectedContextualInfoParams.includes('geo_location') && (
                          <Box mt={4}>
                            <Text variant='fieldHeading' color='brand-light'>
                              Geo Location
                            </Text>
                            <Autocomplete
                              options={geoLocations.map((location) => location.lat + ',' + location.lon + ' (' + location.city + ')')}
                              value={selectedContextualGeoLocation || ''}
                              onChange={(e, newValue) => setSelectedContextualGeoLocation(newValue)}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                              sx={{ mt: 2 }}
                            />
                          </Box>
                      ) }
                    </Box> */}
                    {/* {
                        !selectedContextualInfoParams.includes('app_fid') &&
                        !selectedContextualInfoParams.includes('geo_location') &&

                        <Text variant='body' color='gray-lighter' sx={{ my:4 }}>
                          Not configured
                        </Text>
                        
                      } */}

                  </>
              }

              {/* <Box display="flex" gap={2}>

                {!isMobile && !isPreviewOpen &&
                <Button
                  onClick={togglePreviewDrawer}
                  // startIcon={<PreviewIcon stye={{color: 'white'}}/>}
                  sx={{ position: 'fixed', width: 60, height: 60, borderRadius: '50%', right:40, bottom: 40,  backgroundColor: primaryColor, '&:hover': { backgroundColor: '#ed9ed2' }}}
                  
                >
                  <PreviewIcon sx={{color: 'white'}}/>
                </Button>}
              </Box> */}

              <Snackbar open={showSnackbar} 
              autoHideDuration={15000} 
              onClose={() => setShowSnackbar(false)}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>

            {/* {isMobile ? (
              <Box
                sx={{
                  width: '100%',
                  mt: 3, 
                  position: 'relative', 
                  bottom: 0,
                  display: feedId ? 'block' : 'none', 
                }}
              >
                <Typography variant="h6">Feed Preview</Typography>
                <Box
                  sx={{
                    mt: 2,
                    overflowY: 'auto',
                    height: '80vh', 
                  }}
                >
                  {feedId ? (
                    <iframe
                      src={`${playgroundUrl}/Feed_Config?feed-id=${feedId}`}
                      width="100%" // Full width
                      height="100%" // Full height within the container
                      style={{ border: 'none' }}
                      title="Feed Preview"
                    ></iframe>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Please select a feed.
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              // Desktop view: render the drawer as usual
              <Drawer
                anchor="right"
                open={isPreviewOpen}
                onClose={togglePreviewDrawer}
                PaperProps={{
                  sx: {
                    width: '600px',
                    height: '100%',
                    overflow: 'hidden',
                  },
                }}
              >
                <Box p={2} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box display="flex" flexDirection={'row'}>
                    <Typography variant="h6" flexGrow={1} >Preview Feed</Typography>
                    <IconButton onClick={() => setIsPreviewOpen(false)}>
                      <CloseIcon />
                    </IconButton> 

                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      overflowY: 'auto',
                      mt: 2,
                    }}
                  >
                    {feedId ? (
                      <iframe
                        src={`${playgroundUrl}/Feed_Config?feed-id=${feedId}`}
                        width="100%"
                        height="100%"
                        style={{ border: 'none', minHeight: 'calc(100vh - 120px)' }}
                        title="Feed Preview"
                      ></iframe>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        Please select a feed.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Drawer>
            )} */}

            </Box>
          }

        </>
      )}
    </Grid>
    { !isShowcaseMode &&
    <Grid item xs={12} md={6} sm={4} sx={{borderLeftWidth: 1, borderLeftColor: '#e0e0e0'}}>
      <Box mt={1}>
        <Text variant='h1'>Sample Code</Text>
        <Text variant='body' color='gray-light' sx={{mt:2}}>Example usage of feed_id in the APIs:</Text>
        <SyntaxHighlighter language="javascript" style={solarizedDarkAtom} showLineNumbers customStyle={customStyle}>
                {`// API request body:
{
  // use your feed id like this below
  "feed_id": '${feedId}',

  //other parameters for the request that 
  // you can use
  "user_id": "1",
  "return_metadata": true,
  "top_k": 10

   // additonal parameters to override the 
   // feed configurations saved in this feed_id

 }`}
          </SyntaxHighlighter>
        </Box>
        <>
        {isMobile ? (
              <Box
                sx={{
                  width: '100%',
                  mt: 3, 
                  position: 'relative', 
                  bottom: 0,
                  display: feedId ? 'block' : 'none', 
                }}
              >
                <Typography variant="h6">Feed Preview</Typography>
                <Box
                  sx={{
                    mt: 2,
                    overflowY: 'auto',
                    height: '80vh', 
                  }}
                >
                  {feedId ? (
                    <iframe
                      key={iframeKey}
                      src={`${playgroundUrl}/Feed_Config?feed-id=${feedId}`}
                      width="100%" // Full width
                      height="100%" // Full height within the container
                      style={{ border: 'none' }}
                      title="Feed Preview"
                    ></iframe>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Please select a feed.
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              // Desktop view: render the drawer as usual
              // <Drawer
              //   anchor="right"
              //   open={isPreviewOpen}
              //   onClose={togglePreviewDrawer}
              //   PaperProps={{
              //     sx: {
              //       width: '600px',
              //       height: '100%',
              //       overflow: 'hidden',
              //     },
              //   }}
              // >
                <Box p={0} sx={{ height: '70%', width: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
                  <Box display="flex" flexDirection={'row'}>
                    <Typography variant="h6" flexGrow={1} >Feed Preview</Typography>
                    {/* <IconButton onClick={() => setIsPreviewOpen(false)}>
                      <CloseIcon />
                    </IconButton>  */}

                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      overflowY: 'auto',
                      mt: 2,
                    }}
                  >
                    {feedId ? (
                      <iframe
                        key={iframeKey}
                        src={`${playgroundUrl}/Feed_Config?feed-id=${feedId}`}
                        width="100%"
                        height="100%"
                        style={{ border: 'none', minHeight: 'calc(100vh - 120px)' }}
                        title="Feed Preview"
                      ></iframe>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        Please select a feed.
                      </Typography>
                    )}
                  </Box>
                </Box>
              // </Drawer>
            )}
        
        </>

      </Grid>    
    }

    </Grid>
  );
}
