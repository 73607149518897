import { Button } from '@mui/material';
import { colors } from 'helper/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    gray: {
      main: colors['gray-light'],
    },
    primary: {
      main: colors['brand'],
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});
export function OnOffButton(props){

  return (

    <ThemeProvider theme={theme}>
      <Button
        variant={ "outlined" }
        color={ props.on ? "primary" : "gray" }
        onClick={props.onClick}
        sx={{ 
          // backgroundColor: props.on ? colors.brand : 'white', 
          height: 36, 
          borderRadius: 18, 
          px: 4 }}
      >
          {props.children}
      </Button>
    </ThemeProvider>
  );
}