import { Dialog, DialogTitle, DialogContent, DialogActions, Stack, Avatar, Box } from '@mui/material';
import { Text } from 'mbd-components/text';
import { PrimaryButton } from 'mbd-components/primary-button';
import logo_image from '../../assets/logo_image.png';

export function BrandedDialog(props) {
  const { open, onClose, title, texts, buttonText = 'OK' } = props;

  const avatarStyle = {
    width: 35, 
    height: 35
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
          <Stack px={2} direction={'row'} my={2} alignItems={'center'} justifyContent={'space-between'}>
            {title}
            <Avatar src={logo_image} alt="Company Logo" style={avatarStyle}/>
          </Stack>
      </DialogTitle>

      <DialogContent>
        <Box px={2}>
          {
            props.texts && props.texts.map((text, index) => (
              <Text key={index} variant='body' sx={{mb: 2}}>
                {text}
              </Text>
            ))
          }
        </Box>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', mb: 3}}>
        <PrimaryButton onClick={onClose}>{buttonText}</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}