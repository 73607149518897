exports.colors = {
  'black': '#000000',
  'gray1': '#222222',
  'gray2': '#444444',
  'gray3': '#666666',
  'gray4': '#888888',
  'gray5': '#AAAAAA',
  'gray6': '#CCCCCC',
  'gray7': '#EEEEEE',
  'brand': '#E01296',
  'brand-light': '#ED9ED2',
  'brand-lighter': '#fCD9ED',
  'brand-faded': '#D4C2CE',
  'brand-secondary-green': '#99CC98',
  'gray': '#475568',
  'gray-light': '#97979A',
  'gray-lighter': '#A9B0B8',
  'gray-lightest': '#F0F2F2',
  'offWhite': '#F0F0F0',
  'white': '#ffffff',
}