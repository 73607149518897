import { Box, Image } from '@mui/material';
import { colors } from 'helper/colors';
import mbdLoader from '../../components/loader/images/mbd-loader.gif'

export function Loader(props){

  return (
    <Box>
      <img src={ mbdLoader } width="30" alt='mbd spinner'/>
    </Box>  
  );
}