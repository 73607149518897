import { useState, useEffect } from 'react';
import { Stack, Box, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import Axios from 'axios';

import { useAPI, Event } from '../../components/lib';
import { Text } from '../text';
import { PrimaryButton } from '../primary-button';
import { Card } from '../card';
import { apiKeyNames } from '../../helper/enum';
import { obsfucateKey } from '../../helper/utils.js';
import { 
  ContentCopy as CopyIcon,
  VisibilityOutlined as VisibilityIcon,
  VisibilityOffOutlined as VisibilityOffIcon,
  Key as KeyIcon,
} from '@mui/icons-material';

export function DefaultApiKeyCard(props){

  const fetchKeys = useAPI('/api/key');
  const [key, setKey] = useState('');
  const [displayKey, setDisplayKey] = useState('');
  const [isRevealKey, setIsRevealKey] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchDefaultKey = async () => {
      if (fetchKeys.data && fetchKeys?.data?.length > 0){

        // choose default key from list
        let defaultKey = fetchKeys.data.find((k) => k.name === apiKeyNames.DEFAULT);
        if (!defaultKey) {
          defaultKey = fetchKeys.data.find((k) => k.active === true);
        }
        if(defaultKey){
          // fetch the full key once and store in state
          const fullKey = await Axios.get(`/api/key/${defaultKey.id}`);
          if (fullKey.data?.data[0].key) {
            setKey(fullKey.data?.data[0].key);
            setDisplayKey(obsfucateKey(fullKey.data?.data[0].key));
          }
        }
      }
    }
    fetchDefaultKey();
  }, [fetchKeys]);

  const makeEventName = (event) => {
    const prefix = props.eventPrefix && props.eventPrefix !== '' ? `${props.eventPrefix}_` : '';
    return `${prefix}${event}`;
  }

  const revealKey = () => {
    Event(makeEventName('reveal_key'));
    setIsRevealKey(true);
    setDisplayKey(key);
  }

  const hideKey = () => {
    Event(makeEventName('hide_key'));
    setIsRevealKey(false);
    setDisplayKey(obsfucateKey(key, isMobile));
  }

  const copyKey = () => {
    Event(makeEventName('copy_key'));
    navigator.clipboard.writeText(key);
  }

  const handleViewDocs = () => {
    Event(makeEventName('view_docs'));
    window.open('https://docs.mbd.xyz', '_blank');
  };

  const NormalModeApiCard = () => 
    <Card 
      title="Your API Key"
      description="To call our APIs, you will need an API key.  We have 
        created one for you here.  You can manage your keys 
        on API Keys page under Account."
      icon={<KeyIcon />}
      >
      <Text variant='body' color='gray' sx={{mb: 1}}>
        Your default API key:
      </Text>
      <Stack direction='row' sx={{mb: 4}} alignItems={'center'}>
        <Text variant='body' color='brand-faded' sx={{minWidth: 600}}>{displayKey}</Text>
        <IconButton onClick={ isRevealKey ? hideKey : revealKey} color="error" sx={{width: 22, height: 22}}>
          <Tooltip title={ isRevealKey ? "Hide key" : "Show key"}>
            { isRevealKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </Tooltip>
        </IconButton>
        <IconButton onClick={ copyKey } color="error" sx={{width: 22, height: 22, ml: 2}}>
          <Tooltip title="Copy key">
            <CopyIcon />
          </Tooltip>
        </IconButton>
        <Box ml={4}>
          <PrimaryButton onClick={() => { handleViewDocs() }}> See API Docs </PrimaryButton>  
        </Box>
      </Stack>
    </Card>

  const CompactModeApiCard = () => 
    <Box bgcolor={'#fff'} p={4} my={4} borderRadius={2}>
      <Box>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2} alignItems={'center'} >  
          <Text variant='h3' color='gray' sx={{fontWeight: 500}}>
            Your default API key:
          </Text>
          <Text variant='body' color='brand-faded' sx={{minWidth: 600}}>{displayKey}</Text>
          <Stack direction={isMobile ? 'column' : 'row'} sx={{mb: 4}} alignItems={'center'}>  
            <IconButton onClick={ isRevealKey ? hideKey : revealKey} color="error" sx={{width: 22, height: 22}}>
              <Tooltip title={ isRevealKey ? "Hide key" : "Show key"}>
                { isRevealKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </Tooltip>
            </IconButton>
            <IconButton onClick={ copyKey } color="error" sx={{width: 22, height: 22, ml: 2}}>
              <Tooltip title="Copy key">
                <CopyIcon />
              </Tooltip>
            </IconButton>
            <Box ml={4}>
              <PrimaryButton onClick={() => { handleViewDocs() }}> See API Docs </PrimaryButton>  
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Box>

  return (
    <>
      { props.mode === 'compact' ? <CompactModeApiCard /> : <NormalModeApiCard /> }
    </>
  );
}