import {useState, React, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { Box,  useMediaQuery, useTheme, Grid, IconButton, Stack, Tooltip, TextField, Chip } from '@mui/material';
import '@farcaster/auth-kit/styles.css';
import { Animate, Feedback, useAPI, AuthContext, Event} from 'components/lib';

import { BrandedBox } from 'mbd-components/branded-box';
import { fetchFeeds, fetchAuthorDetails } from 'helper/utils.js';
import { Card } from 'mbd-components/card';
import { Text } from 'mbd-components/text';
import { PrimaryButton } from 'mbd-components/primary-button';
import { SecondaryButton } from 'mbd-components/secondary-button';
import { OnOffButton } from 'mbd-components/on-off-button';
import { DefaultApiKeyCard } from 'mbd-components/default-api-key-card';
import { NonEditableFeedDetails } from 'mbd-components/non-editable-feed-details';
import { 
  Autorenew as RefreshIcon,
  LightbulbOutlined as LightbulbIcon,
  FeedOutlined as FeedIcon,
} from '@mui/icons-material';

import { colors } from 'helper/colors.js';

export function GettingStarted(props){

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [iframeKey, setIframeKey] = useState(Date.now());

  const [displayName, setDisplayName] = useState('');
  const [selectedFeedId, setSelectedFeedId] = useState('feed_3');
  const [endpoint, setEndpoint] = useState('/casts/feed/for-you');
  const [description, setDescription] = useState('');
  const [timePeriodDays, setTimePeriodDays] = useState(30);
  const [impressionCount, setImpressionCount] = useState(0);
  const [includeAppFids, setIncludeAppFids] = useState([]);
  const [includeGeoLocations, setIncludeGeoLocations] = useState([]);
  const [includeAuthorIds, setIncludeAuthorIds] = useState([]);
  const [includeChannels, setIncludeChannels] = useState([]);
  const [includeAiLabels, setIncludeAiLabels] = useState([]);
  const [includeEmbedDomains, setIncludeEmbedDomains] = useState([]);
  const [includePublicationTypes, setIncludePublicationTypes] = useState([]);
  const [excludeAuthorIds, setExcludeAuthorIds] = useState([]);
  const [excludeAiLabels, setExcludeAiLabels] = useState([]);
  const [excludeGeoLocations, setExcludeGeoLocations] = useState([]);
  const [showPromotionSection, setShowPromotionSection] = useState(false);
  const [promotionFeedId, setPromotionFeedId] = useState('');
  const [promotionFeedName, setPromotionFeedName] = useState('');
  const [promotionName, setPromotionName] = useState('');
  const [promotionPercentage, setPromotionPercentage] = useState('');
  const [showColdStartSection, setShowColdStartSection] = useState(false);
  const [coldStartFeedId, setColdStartFeedId] = useState('');
  const [coldStartFeedName, setColdStartFeedName] = useState('');
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
  const [templateFeedDetails, setTemplateFeedDetails] = useState([]);

  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  let darkMode = user?.dark_mode || false;

  const theme = useTheme();

  useEffect(() => {
    if (authContext.user?.farcaster_profile) {
      setDisplayName(authContext.user?.farcaster_profile?.displayName);
    }
    else {
      setDisplayName(authContext.user?.name);
    }
  }, [authContext.user]);

  useEffect(() => {
    const fetchTemplates = async () => {
      const feeds = await fetchFeeds('template', authContext?.user?.account_id);
      setTemplateFeedDetails(feeds.sort((a, b) => {
        // sort by priority if it exists
        if (a.priority && b.priority) {
          return a.priority - b.priority;
        }
        else if (a.priority) {
          return -1;
        }
        else if (b.priority) {
          return 1;
        }

        // otherwise sort by feed number
        const [, feedNumber1] = a.config_id.split('_');
        const [, feedNumber2] = b.config_id.split('_');
        return feedNumber1 > feedNumber2 ? 1 : -1}
      ));  
    }
    fetchTemplates();

  }, []);

  useEffect(() => {

    const processTemplateDetails = async () => {
    // display details of the selected template
    if (templateFeedDetails && templateFeedDetails.length >= selectedTemplateIndex + 1) {
      const template = templateFeedDetails[selectedTemplateIndex] || null;
      const config = template.config;
      console.log('config:', config);
      if (template.endpoint) {
        setEndpoint(template.endpoint);
      }
      else {
        setEndpoint('');
      }
      if (template.description) {
        setDescription(template.description);
      }
      else {
        setDescription('');
      }
      if (config.filters.start_timestamp) {
        if (config.filters.start_timestamp.startsWith('days_ago:')) {
          const [,days] = config.filters.start_timestamp.split(':');
          if (!isNaN(parseInt(days))) {
            setTimePeriodDays(days)
          }
        }
      }      
      if (config.filters.app_fids && config.filters.app_fids.length > 0) {
        setIncludeAppFids(config.filters.app_fids)
      }
      else {
        setIncludeAppFids([]);
      }
      if (config.filters.geo_locations && config.filters.geo_locations.length > 0) {
        setIncludeGeoLocations(config.filters.geo_locations)
      }
      else {
        setIncludeGeoLocations([]);
      }
      if (config.filters.author_ids && config.filters.author_ids.length > 0) {

        const authorDetails = await fetchAuthorDetails(config.filters.author_ids);
        setIncludeAuthorIds(authorDetails.map( a => a.fname));
        // setIncludeAuthorIds(config.filters.author_ids)
      }
      else {
        setIncludeAuthorIds([]);
      }
      if (config.filters.ai_labels && config.filters.ai_labels.length > 0) {
        setIncludeAiLabels(config.filters.ai_labels)
      }
      else {
        setIncludeAiLabels([]);
      }
      if (config.filters.embed_domains && config.filters.embed_domains.length > 0) {
        setIncludeEmbedDomains(config.filters.embed_domains)
      }
      else {
        setIncludeEmbedDomains([]);
      }
      if(config.filters.publication_types && config.filters.publication_types.length > 0) {
        setIncludePublicationTypes(config.filters.publication_types);
      }
      else {
        setIncludePublicationTypes([]);
      }
      if(config.filters.channels && config.filters.channels.length > 0) {
        setIncludeChannels(config.filters.channels);
      }
      else {
        setIncludeChannels([]);
      }
      if(config.filters.remove_ai_labels && config.filters.remove_ai_labels.length > 0) {
        setExcludeAiLabels(config.filters.remove_ai_labels);
      }
      else {
        setExcludeAiLabels([]);
      }
      if(config.filters.remove_author_ids && config.filters.remove_author_ids.length > 0) {
        setExcludeAuthorIds(config.filters.remove_author_ids);
      }
      else {
        setExcludeAuthorIds([]);
      }
      if (config.filters.remove_geo_locations && config.filters.remove_geo_locations.length > 0) {
        setExcludeGeoLocations(config.filters.remove_geo_locations);
      }
      else {
        setExcludeGeoLocations([]);
      }
      if (config.promotion_filters && config.promotion_filters.length > 0) {
        setShowPromotionSection(true);
        setPromotionFeedId(config.promotion_filters[0].feed_id);
        setPromotionName(config.promotion_filters[0].promotion_name);
        setPromotionPercentage(config.promotion_filters[0].percent + '%');
      }
      else {
        setShowPromotionSection(false);
      }
      if (config.cold_start && config.cold_start.hasOwnProperty('cold_start_feed')) {
        setShowColdStartSection(true);
        setColdStartFeedId(config.cold_start.cold_start_feed.feed_id);
      }
      else {
        setShowColdStartSection(false);
      }
      if(config.impression_count) {
        setImpressionCount(config.impression_count);
      }
      else {
        setImpressionCount(0);
      }
      setSelectedFeedId(template.config_id);
        setIframeKey(Date.now())
    }
    }
    processTemplateDetails();
  }, [selectedTemplateIndex, templateFeedDetails])

  return (
    <Animate type='pop'>      
    <Text variant='body' color={ darkMode ? 'white' : 'brand-light'}>
          Welcome { displayName }! 
      </Text>
      <DefaultApiKeyCard eventPrefix='getting_started' />


      <Card 
        title="Create A Feed"
        description="Now try create a new feed here! Play around with different filter options, and 
          see how the feed changes. Once you're ready, save a copy of the feed, and you will have a 
          feed_id availble for use in your API calls. "
        icon={<FeedIcon />}
        > 

        <Text variant='body' color='gray' sx={{mt: 4, mb: 8}}>
          Below is a live feed of Farcaster casts generated by our model, optimized for the likelihood of the 
          viewing user interacting with them.  The feed changes when you choose to personalize the feed for 
          different users who are active on Farcaster. This feed uses our <a href='https://docs.mbd.xyz/reference/post_casts-feed-for-you' target='_blank' style={{color: colors.brand}}>/for-you</a> API endpoint.
        </Text>
        <Grid container mt={4}>
          <Grid item xs={12} sm={6}>
            <Stack direction={'row'} alignItems={'center'} mb={4} display={'flex'}>
              <Text variant='h2' color='gray' sx={{mr: 2}}>
                Choose a User ID below.
              </Text>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', flexDirection:'row', width: '100%'}}>
              <Box sx={{ flexGrow: 1}}>
              </Box>
              <Box >
                <Stack direction='row' spacing={2} mx={2}>
                  <SecondaryButton onClick={ () => {
                    Event('getting_started_refresh_feed');
                    setIframeKey(Date.now())} } startIcon={<RefreshIcon/>  
                  }> 
                    Refresh Feed 
                  </SecondaryButton>
                  <PrimaryButton onClick={() => {
                    Event('getting_started_customize_feed');
                    navigate('/feed-builder', { state: { feedId: selectedFeedId, mode: 'template', initialNewFeed:templateFeedDetails[selectedTemplateIndex] } })
                  }}> 
                    Customize This Feed
                  </PrimaryButton>  
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ overflowY: 'auto', height: '100%', pb: 5}}>
              <iframe
                key={iframeKey}
                src={`${process.env.REACT_APP_PLAYGROUND_URL}/Feed_Config?feed-id=${selectedFeedId}`}
                width="100%" // Full width
                height="100%" // Full height within the container
                style={{ border: 'none' }}
                title="Feed Preview"
              ></iframe>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction='row' mt={4} spacing={1} sx={{flexWrap: 'wrap', gap: '16px 8px'}} >
                {
                  templateFeedDetails?.length > 0 && templateFeedDetails.map((template, index) => 
                    <OnOffButton key={`template-${index}`} on={selectedTemplateIndex === index } 
                      onClick={ () => { 
                        setSelectedTemplateIndex(index);
                        Event('getting_started_select_template', {template_name: templateFeedDetails[index].short_name});
                    }} > 
                      {templateFeedDetails[index].short_name} 
                    </OnOffButton>
                  )
                }
            </Stack>
            <Box my={6} sx={{borderRadius: 2, borderWidth:3, backgroundColor: colors.white, borderColor: colors.gray3, p:4}}>
              <NonEditableFeedDetails
                endpoint={endpoint}
                description={description}
                timePeriodDays={timePeriodDays}
                impressionCount={impressionCount}
                includeAppFids={includeAppFids}
                includeGeoLocations={includeGeoLocations}
                includeAuthorIds={includeAuthorIds}
                channels={includeChannels}
                includeAiLabels={includeAiLabels}
                embedDomains={includeEmbedDomains}
                includePublicationTypes={includePublicationTypes}
                excludeAuthorIds={excludeAuthorIds}
                excludeAiLabels={excludeAiLabels}
                excludeGeoLocations={excludeGeoLocations}
                showPromotionSection={showPromotionSection}
                selectedPromotionFeedId={promotionFeedId}
                selectedPromotionFeedName={promotionFeedName}
                promotionName={promotionName}
                promotionPercentage={promotionPercentage}
                showColdStartSection={showColdStartSection}
                coldStartFeedId={coldStartFeedId}
                coldStartFeedName={coldStartFeedName}
              />
            </Box>

          </Grid>
        </Grid>
      </Card>
      <Card 
        title="Key Concepts"
        description="Here are a few key concepts to help you get started."
        icon={<LightbulbIcon />}
        >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <BrandedBox 
              eventPrefix='getting_started'
              title="Feed ID?" 
              shortText="The easiest way to integrate a feed into your application!"
              expandedText="The easiest way to integrate a feed into your application is through Feed IDs.  
                mbd’s feeds are highly configurable, by passing different parameters to our feed 
                API calls. Feed IDs allow you to  encapsulate all your configuration parameters 
                into just one feed_id parameter, making the code super easy to integrate into 
                your application. You can then quickly experiment and and observe the feed’s 
                behavior using the console here, and change the behavior of your feed without 
                having to change any code.  "/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <BrandedBox 
              eventPrefix='getting_started'
              title="Impression Count"
              shortText="Keep your feed fresh by not showing the same content to the user again."
              expandedText="Our recommendation system learns from user interactions, and the feed
                changes when the user interacts with the feed.  Before the user interacts with the
                any content, if the API is called again soon, the same content might be returned in
                the feed.  To avoid this, we have the impression_count parameter.  When you set an 
                impression_count of n when calling the feed API, our system considers the first n
                items in the feed as already shown to the user, and will not return the same items again."
            />
          </Grid> 
          <Grid item xs={12} sm={6} md={4}>
            <BrandedBox 
              eventPrefix='getting_started'
              title="Promotion"
              shortText="Mix two feeds with different types of content into one feed to show the user."
              expandedText="Promotion Feeds are a way to mix two different feeds.  Say you configured a feed 
                recommending web3 and music content to the viewing user, but you want to include
                30% of content from people the viewing user is following.  You can include a 
                promotion feed of the user’s following feed, and mix it with the main feed, 
                specifying the 30% percentage.  This way you can control the type of content you wish 
                your users to see, while making sure they see some content from people they are following."
            />
          </Grid>  
        </Grid>
      </Card>

      <Feedback />

    </Animate>
  );
}
