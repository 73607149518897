import Axios from 'axios';
import { geoLocations } from './geo_locations';

export async function fetchFeeds (type, account_id) {
  try {
    let result = await Axios({
      url: '/api/feed/configs',
      method: 'post',
      data: {
        visibility: type,
        console_account_id: account_id || null,
      },
    });
    return result?.data?.data || [];
  } catch (error) {
    console.error(`Error fetching ${type} feeds:`, error);
    return [];
  }
};

  // Fetch author details based on author ids
  export async function fetchAuthorDetails (authorIds)  {
    if (!authorIds || authorIds.length === 0) return [];

    try {
      let result  = await Axios({
        url: '/api/farcaster/users/details',
        method: 'post',
        data: { fids: authorIds },
      });

      return result?.data?.data || [];
    } catch (error) {
      console.error('Error fetching author details:', error);
      return [];
    }
  };

export async function updateUser (data) {
  try {
    let result = await Axios({
      url: '/api/user',
      method: 'patch',
      data: data,
    });
    return result?.data;
  } catch (error) {
    console.error('Error updating user:', error);
    return [];
  }
};

export function obsfucateKey (key, compact = false) {
  const result = key.replace(/^(mbd-\w{4})(.*)(\w{4})$/, (match, start, middle, end) => {
    return `${start}${'*'.repeat(compact ? 4 : middle.length)}${end}`;
  });
  return result;
}

export function getLocationName (location) {
  const locationName = location.city || location.county || location.state_district;
  return locationName + ', ' + location.country;
}

export function getGeloLocationDisplayString (savedGeoLocation) {
  const geoLocationInfo = geoLocations.find( location => location.location === 'geo:' + savedGeoLocation);
  return geoLocationInfo ? savedGeoLocation + ' (' + getLocationName(geoLocationInfo) + ')' : '';
}