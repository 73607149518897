import {useState, React, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { GroupCardsContainer, usePlans, useAPI } from 'components/lib';
import Axios from 'axios';
import { Box, Grid, Typography, Stack } from '@mui/material';
import { computeUnitsPerApiCall, mbdUsagePlans } from 'helper/constants';

import { Card } from 'mbd-components/card';
import { Text } from 'mbd-components/text';
import { PrimaryButton } from 'mbd-components/primary-button';
import { 
  LeaderboardRounded as EmptyIcon,
} from '@mui/icons-material';

import { colors } from 'helper/colors.js';


// TODO:  move this to common settings
const GRAPH_COLORS = [
  "#A5B8E0", 
  "#76C7F2", 
  "#80D6B7", 
  "#8CD790",
  "#5BC8C7", 
  "#A3D477", 
  "#F39C9F", 
  "#FFB785", 
  "#FFD67B", 
  "#FFF07C", 
  "#FFB07C", 
  "#FF9AA2", 
  "#FF99A8", 
  "#F8A5A8", 
  "#D6A3F9" 
] 

// TODO: move some of these to utils
const MONTHS = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const isSameMonth = (date1, date2) => {
  return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
};

const getFebruaryDays = (year) => {
    return isLeapYear(year) ? 29 : 28;
};

const getDaysDifference = (startDate, endDate) => {
  return  Math.floor(Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));  
}

const getDay31ForMonth = (monthIndex) => {
  const dateMap = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  return dateMap[monthIndex];
}

const getDateString = (date) => {
  return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
}

const getNearestSameDayThisOrLastMonth = (date) => {
  const today = new Date();
  if (isSameMonth(date, today)) {
    // trial or current start date cannot be in the future, so should be safe to return that date
    return date;
  }
  else {
    let lastMonthDate = date.getDate();
    let lastMonthIndex = today.getMonth() - 1;
    const lastMonthYear = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
    if (lastMonthIndex === -1){
      lastMonthIndex = 11;
    }
    if (date.getDate() === 31){
      lastMonthDate = getDay31ForMonth(lastMonthIndex);
    }
    else if (date.getDate() === 30 || date.getDate() === 29) {
      // if last month is February, set date to last day of February
      if (lastMonthIndex === 1){ 
        lastMonthDate = getFebruaryDays(lastMonthYear);
      }
    }
    return new Date(lastMonthYear, lastMonthIndex, lastMonthDate);
  }
}

const getUsagePeriod = (subscriptionData) => {
  let today = new Date();
  let start = new Date(subscriptionData.currentPeriodStart);
  let end = new Date(subscriptionData.currentPeriodEnd);
  let trialStart = subscriptionData.trialStart ? new Date(subscriptionData.trialStart) : null;
  let trialEnd = subscriptionData.trialEnd ? new Date(subscriptionData.trialEnd) : null;
  
  // if there is a trial period
  if (trialStart && trialEnd){
    const trailPeriodDays = getDaysDifference(trialStart, trialEnd);
    if (trialEnd > today){ 
      // if trial period is longer than 30 days, use trial start date to find the nearest same day this or last month
      if (trailPeriodDays > 30){
        start = getNearestSameDayThisOrLastMonth(trialStart);
        end = new Date(start.getFullYear(), start.getMonth() + 1, start.getDate());
      }
      // still in trial period within a month, use trial period as usage period start and end
      else {
        start = trialStart;
        end = trialEnd;
      }
    }
  }
  if(subscriptionData.subscriptionInterval === 'year'){
    if (!isSameMonth(start, today)){
      start = getNearestSameDayThisOrLastMonth(start);
      end = new Date(start.getFullYear(), start.getMonth() + 1, start.getDate() - 1);
    }
  }
  return {start, end};
}

export function Usage(props){

  const navigate = useNavigate();
  const subscription = useAPI('/api/account/subscription');
  const plansData = usePlans();


  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [perApiComputeUsage, setPerApiComputeUsage] = useState([]);
  const [totalCopmuteUsed, setTotalComputeUsed] = useState(0);
  const [userComputeQuota, setUserComputeQuota] = useState(0);
  const [groupCardsData, setGroupCardsData] = useState({});

  const [plan, setPlan] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [usagePeriodStartDate, setUsagePeriodStartDate] = useState(null);
  const [usagePeriodEndDate, setUsagePeriodEndDate] = useState(null);
  const [isTrial, setIsTrial] = useState(false);
  const [isRequiresPaymentMethod, setIsRequiresPaymentMethod] = useState(false);


  useEffect(() => {
    let subscriptionData = {};
    if(subscription?.data?.object){
      subscriptionData.currentPeriodStart = subscription?.data?.object?.current_period_start || null;
      subscriptionData.currentPeriodEnd = subscription?.data?.object?.current_period_end || null;
      subscriptionData.trialStart = subscription?.data?.object?.trial_start || null;
      subscriptionData.trialEnd = subscription?.data?.object?.trial_end || null;
      subscriptionData.subscriptionInterval = subscription?.data?.object?.interval || null;
      subscriptionData.plan = plansData.data?.active || 'price_1PxWhPBLZFYgMsTFMV2pgwOR';    
      setPlan(subscriptionData.plan);
      setSubscriptionInfo(subscriptionData);

      if (subscription?.data?.status === 'trialing'){
        setIsTrial(true);
      }
      
      console.log('subscriptionData', subscriptionData);
      const {start, end} = getUsagePeriod(subscriptionData);
      console.log('start', start);
      console.log('end', end);
      setUsagePeriodStartDate(start);
      setUsagePeriodEndDate(end);
    }
    if (subscription?.data?.status === 'no subscription' || (subscriptionData.plan && subscriptionData.plan.startsWith('enterprise'))){
      // assume current month for now
      let today = new Date();
      let start = new Date(today.getFullYear(), today.getMonth(), 1);
      let end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      setUsagePeriodStartDate(start);
      setUsagePeriodEndDate(end);
      setIsLoading(false);
    }
    else if (subscription?.data?.status === 'requires_payment_method'){
      setIsRequiresPaymentMethod(true);
      setIsLoading(false);
      setIsEmpty(true);
    }

  }, [subscription, plansData]);


  const getComputeUsagePieChartData = (apiData) => {

    let chartData = [];
    Object.keys(computeUnitsPerApiCall).forEach((key, i) => {
      let sum = 0;
      apiData.forEach((entry) => {
        if (entry.path.includes(key)) {
          sum += entry.total_consumed;
        }
      });
      sum *= computeUnitsPerApiCall[key];
      let datapoint = {
        id: i,
        value: sum, // Math.random() * (100000 - 50000) + 50000, // to test with random values
        label: `${key} (${sum} CU)`,
        color: GRAPH_COLORS[i]
      }
      chartData.push(datapoint);
    }
    );

    return chartData;
  };

  const getGroupedTilesData = (consumptionData) => {
    const groupedTilesData = {
      "casts-feed": {},
      "users-feed": {},
      "search": {},
      "labels": {}
    }
    for(const consumptionObject of consumptionData){
      const endpoint = consumptionObject?.path;
      if(endpoint.includes('casts-feed')){
        if (!groupedTilesData["casts-feed"][endpoint]){
          groupedTilesData["casts-feed"][endpoint] = 0;
        }
        groupedTilesData["casts-feed"][endpoint] += consumptionObject?.total_consumed || 0;
      }
      else if (endpoint.includes('users-feed')){
        if (!groupedTilesData["users-feed"][endpoint]){
          groupedTilesData["users-feed"][endpoint] = 0;
        }
        groupedTilesData["users-feed"][endpoint] += consumptionObject?.total_consumed || 0;
      }
      else if (endpoint.includes('search')){
        if (!groupedTilesData["search"][endpoint]){
          groupedTilesData["search"][endpoint] = 0;
        }
        groupedTilesData["search"][endpoint] += consumptionObject?.total_consumed || 0;
      }
      else if (endpoint.includes('labels')){
        if (!groupedTilesData["labels"][endpoint]){
          groupedTilesData["labels"][endpoint] = 0;
        }
        groupedTilesData["labels"][endpoint] += consumptionObject?.total_consumed || 0;
      }
    }
    return groupedTilesData;
  }
 

  useEffect(() => {

    const getGraphData = async () => {
      setIsLoading(true);
      let  result = await Axios({
        url: '/api/dashboard/api_usage_v2',
        method: 'get',
        params: {fromTimestamp: getDateString(usagePeriodStartDate), toTimestamp: getDateString(usagePeriodEndDate) }
      });
      const consumptionData = result?.data?.data || [];

      if(consumptionData && Array.isArray(consumptionData) && consumptionData.length){

        const groupedTilesData = getGroupedTilesData(consumptionData);
        setGroupCardsData(groupedTilesData);
        
        const pieChartData = getComputeUsagePieChartData(consumptionData);
        setPerApiComputeUsage(pieChartData);

        const totalComputeUsed = pieChartData.reduce((sum, current) => sum + current.value, 0);
        setTotalComputeUsed(totalComputeUsed);

        const planDetails = mbdUsagePlans[plan] || null;

        if(planDetails){
          setUserComputeQuota(planDetails.cuQuota);
        }else{
          setUserComputeQuota(1);
        }
      }
      else{
        setIsEmpty(true);
      }
      setIsLoading(false);
    };

    if (usagePeriodStartDate && usagePeriodEndDate){
      getGraphData();
    }
    
    

  }, [plan, usagePeriodStartDate, usagePeriodEndDate]);




  const PercentUsageBar = (props) =>               
    <BarChart
      xAxis={[{
        scaleType: 'band',
        data: ['usage'],
        categoryGapRatio:0.5,
      }]}
      series={[
        {
          data: [props.percent],
          label: '% cu used',
          stack: 'usage',
          color: ['#76AFE8'],
          cornerRadius: 5,
          barGapRatio: 0.1,
          barLabel: `{props.percent}%`
        },
        {
          data:[100-props.percent],
          label: '% remaining',
          stack: 'usage',
          color: ['#F8F8F8'],
          cornerRadius: 5,
          barGapRatio: 0.1
        }

      ]}
      slotProps={{ legend: { 
        hidden: true,
        position: { vertical: 'bottom', horizontal: 'middle' },
      } }}
      barThickness={10}
      borderRadius={5}
      height={280}
      margin = {{top: 20}}
      leftAxis={null}
      bottomAxis={null}
      barLabel = {(item, context) => <p style={{color:'#ffffff'}}>`${item.value}%`</p>}
    />
  
    const PerAPICUPieChart = (props) => 
      <PieChart
        series={[
          {
            data: props.data,
            cornerRadius: 3,
          }
        ]}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            labelStyle: {
              fontSize: 9,
              fill: '#666666'
            },
            itemMarkWidth:9,
            itemMarkHeight:9,
            itemGap:9,
            margin: { top: 20}
          },
        
        }}
        margin={{top: 20}}
        height={250}
      />

  const EmptyState = () => 
    <Box alignItems={'center'} justifyItems={'center'} sx={{height: 200, width: '100%'}}>
      <EmptyIcon sx={{marginTop: 4, fontSize: 46, color: colors['gray-lightest']}} />
      <Text variant="small" color='gray-lighter' sx={{textAlign: 'center'}}>No usage data available yet.</Text>
    </Box>

  const Heading = () => {
    if (isRequiresPaymentMethod){
      return <Stack direction="row" spacing={2} alignItems="center" ml={4}>
          <Text variant="body" color='brand-light' sx={{fontWeight: 'bold'}}>Please update your payment method to continue using our service.</Text>
          <PrimaryButton onClick={() => navigate('/account/billing')}>Manage Plan</PrimaryButton>
        </Stack>
    }
    else if (usagePeriodStartDate && usagePeriodEndDate) {
      return <Text variant="body" color='brand-light' sx={{fontWeight: 'bold', ml:4}}>Current Usage Period: {getDateString(usagePeriodStartDate)} - {getDateString(usagePeriodEndDate)} {`${isTrial ? '(Trial)': ''}`}</Text>
    }
    else {
      return <></>
    }
    
  }

  return (
    <Box>
      <Heading />
      <Card 
        title='Monthly Compute Units (CU) Usage' 
        description="Here is a summary of your CU usage for the current usage period.  Different API calls require different CUs per call.  The total CU usage is the sum of CU usage across all API calls." 
        loading={isLoading}>

        {isEmpty ? 
            <EmptyState />
          :
            <Grid container minHeight={350}>
              <Grid item justifyContent={'center'} xs={12} sm={4} md={3}>
                <Box alignItems={'center'}>
                    <Text variant="h2" sx={{textAlign: 'center'}}>Total CU Usage</Text>
                    <PercentUsageBar percent={totalCopmuteUsed !== 0 && userComputeQuota !==0  ? totalCopmuteUsed/userComputeQuota*100 : 0}/>
                    <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
                      {`${totalCopmuteUsed !== 0 && userComputeQuota !==0 ? (totalCopmuteUsed/userComputeQuota*100).toFixed(1) : 0}%`}
                    </Typography>
                    {totalCopmuteUsed !== 0 && userComputeQuota !==0 &&
                    <Typography variant="h6" align="center" style={{color: '#94a3b8', fontSize:12}}>
                      {`${totalCopmuteUsed} / ${userComputeQuota}`}
                    </Typography>}
                </Box>
              </Grid>
              <Grid itemjustifyContent={'center'} xs={12} sm={8} md={9}>
                  <Box alignItems={'center'}>
                    <Text variant="h2" sx={{textAlign: 'center'}}>API CU Usage Distribution</Text>
                    {
                      perApiComputeUsage.length > 0 ?
                      <PerAPICUPieChart data={perApiComputeUsage}/>
                      :
                      <Box alignItems={'center'} justifyItems={'center'} sx={{height: 250}}>
                        <Text variant="body" color='gray-light' sx={{textAlign: 'center'}}>No usage data available</Text>
                      </Box>
                    }
                  </Box>
              </Grid>
            </Grid>
        }
      </Card> 
        <Card title='Monthly API Usage' 
        description="This is your API usage (invocations) for the current usage period, grouped by the type of API." 
        loading={isLoading}>
          {
            isEmpty ?
              <EmptyState />
            :
              <GroupCardsContainer groupedTilesData={groupCardsData} />
          }
        </Card> 
      </Box>
  );
}
