export const languages = [
"af",
"als",
"am",
"an",
"ar",
"arz",
"as",
"ast",
"av",
"az",
"azb",
"bar",
"bcl",
"be",
"bg",
"bn",
"bo",
"br",
"bs",
"ca",
"cbk",
"ce",
"ceb",
"cs",
"cv",
"cy",
"da",
"de",
"diq",
"dsb",
"dv",
"el",
"eml",
"en",
"eo",
"es",
"et",
"eu",
"fa",
"fi",
"fr",
"fy",
"ga",
"gd",
"gl",
"gn",
"gom",
"gu",
"gv",
"he",
"hi",
"hr",
"hsb",
"hu",
"hy",
"ia",
"id",
"ie",
"ilo",
"io",
"is",
"it",
"ja",
"jbo",
"jv",
"ka",
"kk",
"km",
"kn",
"ko",
"krc",
"ku",
"kw",
"ky",
"la",
"lb",
"li",
"lmo",
"lo",
"lt",
"lv",
"mg",
"min",
"mk",
"ml",
"mr",
"ms",
"my",
"mzn",
"nah",
"nds",
"ne",
"nl",
"nn",
"no",
"oc",
"or",
"pa",
"pl",
"pms",
"pnb",
"ps",
"pt",
"qu",
"ro",
"ru",
"sa",
"sco",
"sd",
"sh",
"si",
"sk",
"sl",
"so",
"sq",
"sr",
"su",
"sv",
"sw",
"ta",
"te",
"tg",
"th",
"tk",
"tl",
"tr",
"tt",
"ug",
"uk",
"ur",
"uz",
"vep",
"vi",
"vo",
"wa",
"war",
"wuu",
"yo",
"yue",
"zh"
]