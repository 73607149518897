export const geoLocations = [
  {
    "location": "geo:46.72,9.17",
    "count": 24281,
    "city": "Lumnezia",
    "county": "Surselva",
    "state": "Grisons",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:10.82,106.63",
    "count": 9145,
    "city": "Ho Chi Minh City",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:41.01,28.98",
    "count": 5138,
    "city": "Istanbul",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:40.71,-74.01",
    "count": 5062,
    "city": "New York",
    "county": "New York County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.05,-118.24",
    "count": 4431,
    "city": "Los Angeles",
    "county": "Los Angeles County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.86,2.35",
    "count": 2930,
    "city": "Paris",
    "county": null,
    "state": "Ile-de-France",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:37.57,126.98",
    "count": 2623,
    "city": "Seoul",
    "county": "Yongsan",
    "state": "Seoul",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:40.65,-80.18",
    "count": 2426,
    "city": "Baden",
    "county": "Beaver County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:21.03,105.83",
    "count": 2173,
    "city": "Hà Nội",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:10.99,106.66",
    "count": 2140,
    "city": "Thủ Dầu Một",
    "county": null,
    "state": "Bình Dương Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:51.51,-0.13",
    "count": 1884,
    "city": "London",
    "county": null,
    "state": "England",
    "state_district": "Greater London",
    "country": "United Kingdom"
  },
  {
    "location": "geo:-33.87,151.21",
    "count": 1598,
    "city": "Sydney",
    "county": "Sydney",
    "state": "New South Wales",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:10.68,107.75",
    "count": 1501,
    "city": "La Gi",
    "county": null,
    "state": "Bình Thuận Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:40.42,-3.70",
    "count": 1495,
    "city": "Madrid",
    "county": null,
    "state": "Community of Madrid",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:52.52,13.40",
    "count": 1468,
    "city": "Berlin",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:37.48,126.63",
    "count": 1351,
    "city": "Incheon",
    "county": "Nam",
    "state": "Incheon",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:37.77,-122.42",
    "count": 1302,
    "city": "San Francisco",
    "county": "San Francisco County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.65,-79.38",
    "count": 1302,
    "city": "Toronto",
    "county": "Toronto",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:35.68,139.77",
    "count": 1252,
    "city": "Tokyo",
    "county": "Chuo",
    "state": "Tokyo",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:35.13,-117.99",
    "count": 1152,
    "city": "California City",
    "county": "Kern County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:64.15,-21.94",
    "count": 1132,
    "city": "Reykjavik",
    "county": null,
    "state": null,
    "state_district": "Capital Region",
    "country": "Iceland"
  },
  {
    "location": "geo:13.76,100.50",
    "count": 1072,
    "city": "Bangkok",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Thailand"
  },
  {
    "location": "geo:25.76,-80.19",
    "count": 1049,
    "city": "Miami",
    "county": "Miami-Dade County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:25.20,55.27",
    "count": 1048,
    "city": "Dubai",
    "county": null,
    "state": "Dubai",
    "state_district": null,
    "country": "United Arab Emirates"
  },
  {
    "location": "geo:25.03,121.57",
    "count": 1046,
    "city": "Taipei",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Taiwan"
  },
  {
    "location": "geo:35.85,128.52",
    "count": 1029,
    "city": "Daegu",
    "county": "Dalseo",
    "state": "Daegu",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:21.72,104.90",
    "count": 1029,
    "city": "Yên Bái",
    "county": null,
    "state": "Yên Bái Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:21.39,-77.91",
    "count": 948,
    "city": "Camagüey",
    "county": null,
    "state": "Camagüey",
    "state_district": null,
    "country": "Cuba"
  },
  {
    "location": "geo:38.72,-9.14",
    "count": 901,
    "city": "Lisbon",
    "county": null,
    "state": "Lisbon",
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:41.39,2.17",
    "count": 850,
    "city": "Barcelona",
    "county": null,
    "state": "Barcelona",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:49.28,-123.12",
    "count": 805,
    "city": "Vancouver",
    "county": "Metro Vancouver Regional District",
    "state": "British Columbia",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:11.94,108.46",
    "count": 768,
    "city": "Đà Lạt",
    "county": null,
    "state": "Lâm Đồng Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:9.15,105.20",
    "count": 746,
    "city": "Cà Mau",
    "county": null,
    "state": "Cà Mau Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:22.32,114.17",
    "count": 719,
    "city": "Kowloon",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Hong Kong"
  },
  {
    "location": "geo:13.08,80.27",
    "count": 714,
    "city": "Chennai",
    "county": null,
    "state": "Tamil Nadu",
    "state_district": "Chennai",
    "country": "India"
  },
  {
    "location": "geo:47.50,19.04",
    "count": 702,
    "city": "Budapest",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Hungary"
  },
  {
    "location": "geo:-34.60,-58.38",
    "count": 697,
    "city": "Buenos Aires",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Argentina"
  },
  {
    "location": "geo:33.68,-117.83",
    "count": 618,
    "city": "Irvine",
    "county": "Orange County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:1.28,103.85",
    "count": 600,
    "city": "Singapore",
    "county": "Central Region",
    "state": null,
    "state_district": null,
    "country": "Singapore"
  },
  {
    "location": "geo:52.23,21.01",
    "count": 580,
    "city": "Warsaw",
    "county": null,
    "state": "Masovian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:35.67,139.77",
    "count": 561,
    "city": "Tokyo",
    "county": "Chuo",
    "state": "Tokyo",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:10.41,107.14",
    "count": 554,
    "city": "Vũng Tàu",
    "county": "Long Điền",
    "state": "Bà Rịa - Vũng Tàu Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:32.76,-97.33",
    "count": 533,
    "city": "Fort Worth",
    "county": "Tarrant County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:12.91,74.86",
    "count": 528,
    "city": "Kavoor",
    "county": "Mangaluru taluk",
    "state": "Karnataka",
    "state_district": "Dakshina Kannada",
    "country": "India"
  },
  {
    "location": "geo:19.08,72.88",
    "count": 528,
    "city": "Mumbai",
    "county": null,
    "state": "Maharashtra",
    "state_district": null,
    "country": "India"
  },
  {
    "location": "geo:52.48,-1.89",
    "count": 524,
    "city": "Birmingham",
    "county": null,
    "state": "England",
    "state_district": "West Midlands Combined Authority",
    "country": "United Kingdom"
  },
  {
    "location": "geo:21.36,105.35",
    "count": 516,
    "city": "Việt Trì",
    "county": null,
    "state": "Phú Thọ Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:51.04,-114.07",
    "count": 510,
    "city": "Calgary",
    "county": "Calgary",
    "state": "Alberta",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:50.14,30.74",
    "count": 498,
    "city": "Ukrainka",
    "county": null,
    "state": "Kyiv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:50.11,8.68",
    "count": 496,
    "city": "Frankfurt",
    "county": null,
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:47.50,9.74",
    "count": 491,
    "city": "Bregenz",
    "county": "Bezirk Bregenz",
    "state": "Vorarlberg",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:45.47,9.18",
    "count": 488,
    "city": "Milan",
    "county": "Milan",
    "state": "Lombardy",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:7.38,3.95",
    "count": 450,
    "city": null,
    "county": "Ona Ara",
    "state": "Oyo State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:28.54,-81.38",
    "count": 450,
    "city": "Orlando",
    "county": "Orange County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:53.55,9.99",
    "count": 431,
    "city": "Hamburg",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:39.15,-123.21",
    "count": 413,
    "city": "Ukiah",
    "county": "Mendocino County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-43.83,170.28",
    "count": 410,
    "city": null,
    "county": "Mackenzie District",
    "state": "Canterbury",
    "state_district": null,
    "country": "New Zealand"
  },
  {
    "location": "geo:33.89,35.50",
    "count": 401,
    "city": "Beirut",
    "county": null,
    "state": "Beirut Governorate",
    "state_district": null,
    "country": "Lebanon"
  },
  {
    "location": "geo:53.90,27.56",
    "count": 395,
    "city": "Minsk",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Belarus"
  },
  {
    "location": "geo:37.43,-122.25",
    "count": 392,
    "city": "Redwood City",
    "county": "San Mateo County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-12.98,-38.50",
    "count": 391,
    "city": "Salvador",
    "county": "Região Metropolitana de Salvador",
    "state": "Bahia",
    "state_district": "Região Geográfica Intermediária de Salvador",
    "country": "Brazil"
  },
  {
    "location": "geo:23.80,90.42",
    "count": 385,
    "city": "Dhaka",
    "county": null,
    "state": "Dhaka Division",
    "state_district": "Dhaka District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:50.45,30.52",
    "count": 379,
    "city": "Kyiv",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:42.40,-82.19",
    "count": 366,
    "city": "Chatham-Kent",
    "county": null,
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:39.93,32.86",
    "count": 345,
    "city": "Altındağ",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:9.06,7.50",
    "count": 341,
    "city": null,
    "county": "Municipal Area Council",
    "state": "Federal Capital Territory",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:44.90,8.86",
    "count": 339,
    "city": "Tortona",
    "county": null,
    "state": "Alessandria",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:30.27,-97.74",
    "count": 336,
    "city": "Austin",
    "county": "Travis County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.74,-104.99",
    "count": 331,
    "city": "Denver",
    "county": null,
    "state": "Colorado",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.90,12.48",
    "count": 326,
    "city": "Rome",
    "county": null,
    "state": "Rome",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:37.18,33.22",
    "count": 323,
    "city": "Karaman",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:52.37,4.90",
    "count": 318,
    "city": "Amsterdam",
    "county": null,
    "state": "North Holland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:10.41,76.36",
    "count": 305,
    "city": null,
    "county": "Chalakkudy",
    "state": "Kerala",
    "state_district": "Thrissur",
    "country": "India"
  },
  {
    "location": "geo:45.50,-73.57",
    "count": 299,
    "city": "Montreal",
    "county": "Montreal",
    "state": "Quebec",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:45.52,-122.68",
    "count": 295,
    "city": "Portland",
    "county": "Multnomah County",
    "state": "Oregon",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:60.17,24.94",
    "count": 294,
    "city": "Helsinki",
    "county": "Uusimaa",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:13.09,-85.73",
    "count": 293,
    "city": "El Tuma - La Dalia",
    "county": null,
    "state": "Matagalpa",
    "state_district": null,
    "country": "Nicaragua"
  },
  {
    "location": "geo:14.35,108.00",
    "count": 293,
    "city": "Kon Tum",
    "county": null,
    "state": "Kon Tum Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:33.70,73.04",
    "count": 290,
    "city": "Islamabad",
    "county": null,
    "state": "Islamabad Capital Territory",
    "state_district": null,
    "country": "Pakistan"
  },
  {
    "location": "geo:41.88,-87.63",
    "count": 290,
    "city": "Chicago",
    "county": "Cook County",
    "state": "Illinois",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.08,-106.65",
    "count": 289,
    "city": "Albuquerque",
    "county": "Bernalillo County",
    "state": "New Mexico",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:44.89,34.14",
    "count": 279,
    "city": "Перовское сельское поселение",
    "county": "Simferopol District",
    "state": "Republic of Crimea",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:37.44,-122.14",
    "count": 277,
    "city": "Palo Alto",
    "county": "Santa Clara County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:29.38,-94.90",
    "count": 273,
    "city": "Texas City",
    "county": "Galveston County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:13.78,109.22",
    "count": 271,
    "city": "Quy Nhơn",
    "county": null,
    "state": "Bình Định Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:4.85,6.97",
    "count": 268,
    "city": null,
    "county": "Obio/Akpor",
    "state": "Rivers State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:49.49,8.47",
    "count": 265,
    "city": "Mannheim",
    "county": null,
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:35.99,-78.90",
    "count": 255,
    "city": "Durham",
    "county": "Durham County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:36.85,-2.45",
    "count": 253,
    "city": "Almeria",
    "county": null,
    "state": "Andalusia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:32.72,-117.16",
    "count": 253,
    "city": "San Diego",
    "county": "San Diego County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:59.93,30.36",
    "count": 245,
    "city": "Saint Petersburg",
    "county": null,
    "state": "Saint Petersburg",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:48.89,2.13",
    "count": 240,
    "city": "Le Vésinet",
    "county": "Yvelines",
    "state": "Ile-de-France",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:42.36,-71.06",
    "count": 238,
    "city": "Boston",
    "county": "Suffolk County",
    "state": "Massachusetts",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.61,-122.33",
    "count": 231,
    "city": "Seattle",
    "county": "King County",
    "state": "Washington",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:4.71,-74.07",
    "count": 229,
    "city": "Bogota",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Colombia"
  },
  {
    "location": "geo:26.33,127.81",
    "count": 227,
    "city": "Okinawa",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:39.74,-77.11",
    "count": 222,
    "city": "Littlestown",
    "county": "Adams County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:23.40,104.22",
    "count": 222,
    "city": "Wenshan City",
    "county": null,
    "state": "Yunnan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:11.47,107.31",
    "count": 219,
    "city": "Xã Đắk Lua",
    "county": "Tân Phú District",
    "state": "Đồng Nai Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:44.95,-93.09",
    "count": 217,
    "city": "St. Paul",
    "county": "Ramsey County",
    "state": "Minnesota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:46.47,30.73",
    "count": 214,
    "city": "Odesa",
    "county": null,
    "state": "Odesa Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:28.68,77.22",
    "count": 211,
    "city": "Delhi",
    "county": null,
    "state": "Delhi",
    "state_district": "Central Delhi",
    "country": "India"
  },
  {
    "location": "geo:12.67,108.04",
    "count": 209,
    "city": "Buôn Ma Thuột",
    "county": null,
    "state": "Đắk Lắk Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:7.78,4.54",
    "count": 207,
    "city": null,
    "county": "Osogbo",
    "state": "Osun State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:38.82,-76.75",
    "count": 204,
    "city": "Upper Marlboro",
    "county": "Prince George's County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-34.92,-57.95",
    "count": 202,
    "city": "La Plata",
    "county": null,
    "state": "Buenos Aires",
    "state_district": "Partido de La Plata",
    "country": "Argentina"
  },
  {
    "location": "geo:35.79,-78.78",
    "count": 201,
    "city": "Cary",
    "county": "Wake County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:21.06,105.90",
    "count": 194,
    "city": "Hà Nội",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:34.69,135.50",
    "count": 190,
    "city": "Osaka",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:33.75,-84.39",
    "count": 188,
    "city": "Atlanta",
    "county": "Fulton County",
    "state": "Georgia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.01,135.77",
    "count": 187,
    "city": "Kyoto-shi",
    "county": "Kyoto",
    "state": "Kyoto Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:50.94,6.96",
    "count": 187,
    "city": "Cologne",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:48.14,11.58",
    "count": 181,
    "city": "Munich",
    "county": null,
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:43.60,1.44",
    "count": 181,
    "city": "Toulouse",
    "county": "Haute-Garonne",
    "state": "Occitania",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:32.18,-96.88",
    "count": 180,
    "city": "Italy",
    "county": "Ellis County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:54.89,10.40",
    "count": 179,
    "city": "Ærø Municipality",
    "county": null,
    "state": "Region of Southern Denmark",
    "state_district": null,
    "country": "Denmark"
  },
  {
    "location": "geo:35.78,-78.37",
    "count": 174,
    "city": "Wendell",
    "county": "Wake County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:53.75,-105.25",
    "count": 171,
    "city": "Candle Lake",
    "county": "Division No. 15",
    "state": "Saskatchewan",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:50.08,14.44",
    "count": 170,
    "city": "Capital City of Prague",
    "county": null,
    "state": "Prague",
    "state_district": null,
    "country": "Czechia"
  },
  {
    "location": "geo:40.41,49.87",
    "count": 169,
    "city": "Baku City",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Azerbaijan"
  },
  {
    "location": "geo:-23.56,-46.64",
    "count": 166,
    "city": "São Paulo",
    "county": "Região Metropolitana de São Paulo",
    "state": "São Paulo",
    "state_district": "Região Geográfica Intermediária de São Paulo",
    "country": "Brazil"
  },
  {
    "location": "geo:-37.81,144.96",
    "count": 165,
    "city": "Melbourne",
    "county": null,
    "state": "Victoria",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:-37.82,144.96",
    "count": 165,
    "city": "Melbourne",
    "county": null,
    "state": "Victoria",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:52.96,-1.07",
    "count": 165,
    "city": "Gedling",
    "county": "Nottinghamshire",
    "state": "England",
    "state_district": "East Midlands",
    "country": "United Kingdom"
  },
  {
    "location": "geo:45.44,10.99",
    "count": 160,
    "city": "Verona",
    "county": null,
    "state": "Verona",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:38.91,-77.04",
    "count": 160,
    "city": "Washington",
    "county": "District of Columbia",
    "state": "District of Columbia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.01,-105.27",
    "count": 157,
    "city": "Boulder",
    "county": "Boulder County",
    "state": "Colorado",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:45.76,4.84",
    "count": 156,
    "city": "Lyon",
    "county": "Métropole de Lyon",
    "state": "Auvergne-Rhône-Alpes",
    "state_district": "Rhône",
    "country": "France"
  },
  {
    "location": "geo:49.33,-123.16",
    "count": 156,
    "city": "West Vancouver",
    "county": "Greater Vancouver",
    "state": "British Columbia",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:-31.42,-64.19",
    "count": 156,
    "city": "Cordoba",
    "county": "Pedanía Capital",
    "state": "Córdoba",
    "state_district": "Departamento Capital",
    "country": "Argentina"
  },
  {
    "location": "geo:37.98,23.73",
    "count": 155,
    "city": "Athens",
    "county": "Regional Unit of Central Athens",
    "state": "Attica",
    "state_district": "Attica",
    "country": "Greece"
  },
  {
    "location": "geo:37.29,127.45",
    "count": 154,
    "city": null,
    "county": "Icheon",
    "state": "Kyeongki-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:-6.18,106.83",
    "count": 153,
    "city": "Special capital Region of Jakarta",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:20.84,85.10",
    "count": 151,
    "city": "Angul",
    "county": "Anugul",
    "state": "Odisha",
    "state_district": "Angul",
    "country": "India"
  },
  {
    "location": "geo:30.19,71.49",
    "count": 148,
    "city": "Multan",
    "county": "Multan District",
    "state": "Punjab",
    "state_district": null,
    "country": "Pakistan"
  },
  {
    "location": "geo:-22.91,-43.17",
    "count": 147,
    "city": "Rio de Janeiro",
    "county": "Região Metropolitana do Rio de Janeiro",
    "state": "Rio de Janeiro",
    "state_district": "Região Geográfica Intermediária do Rio de Janeiro",
    "country": "Brazil"
  },
  {
    "location": "geo:55.76,37.62",
    "count": 142,
    "city": "Moscow",
    "county": null,
    "state": "Moscow",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:36.90,30.71",
    "count": 141,
    "city": "Antalya",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:22.57,88.36",
    "count": 140,
    "city": "Kolkata",
    "county": null,
    "state": "West Bengal",
    "state_district": "Kolkata",
    "country": "India"
  },
  {
    "location": "geo:37.87,-122.27",
    "count": 139,
    "city": "Berkeley",
    "county": "Alameda County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.36,-74.42",
    "count": 137,
    "city": "Atlantic City",
    "county": "Atlantic County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:32.78,-96.80",
    "count": 136,
    "city": "Dallas",
    "county": "Dallas County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:64.76,-147.35",
    "count": 135,
    "city": "North Pole",
    "county": "Fairbanks North Star",
    "state": "Alaska",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.78,-78.64",
    "count": 134,
    "city": "Raleigh",
    "county": "Wake County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.62,-123.46",
    "count": 130,
    "city": "Hyampom",
    "county": "Trinity County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.17,129.07",
    "count": 129,
    "city": "Busan",
    "county": "Busanjin",
    "state": "Busan",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:14.92,-23.51",
    "count": 126,
    "city": "Platô",
    "county": "Praia",
    "state": null,
    "state_district": null,
    "country": "Cape Verde"
  },
  {
    "location": "geo:-1.24,116.85",
    "count": 126,
    "city": "Balikpapan",
    "county": null,
    "state": "East Kalimantan",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:43.30,5.37",
    "count": 125,
    "city": "Marseille",
    "county": "1st Arr.",
    "state": "Bouches-du-Rhône",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:38.17,-122.26",
    "count": 124,
    "city": "American Canyon",
    "county": "Napa County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-27.47,153.03",
    "count": 120,
    "city": "Brisbane",
    "county": "Brisbane",
    "state": "Queensland",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:16.05,108.20",
    "count": 117,
    "city": "Da Nang",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:41.16,-8.63",
    "count": 117,
    "city": "Porto",
    "county": null,
    "state": "Porto",
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:12.97,77.59",
    "count": 114,
    "city": "Bengaluru",
    "county": "Bangalore North",
    "state": "Karnataka",
    "state_district": "Bengaluru Urban",
    "country": "India"
  },
  {
    "location": "geo:40.97,-5.66",
    "count": 114,
    "city": "Salamanca",
    "county": null,
    "state": "Salamanca",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:46.71,17.32",
    "count": 112,
    "city": "Marcali járás",
    "county": "Somogy",
    "state": null,
    "state_district": null,
    "country": "Hungary"
  },
  {
    "location": "geo:42.60,-77.31",
    "count": 110,
    "city": "Naples",
    "county": "Yates County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.61,3.88",
    "count": 108,
    "city": "Montpellier",
    "county": "Hérault",
    "state": "Occitania",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:3.15,101.69",
    "count": 108,
    "city": "Kuala Lumpur",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:40.95,-90.37",
    "count": 107,
    "city": "Galesburg",
    "county": "Knox County",
    "state": "Illinois",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.95,-75.17",
    "count": 106,
    "city": "Philadelphia",
    "county": "Philadelphia County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.38,8.54",
    "count": 102,
    "city": "Zurich",
    "county": "District Zurich",
    "state": "Zurich",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:44.06,0.43",
    "count": 102,
    "city": "Francescas",
    "county": "Francescas",
    "state": "Lot-et-Garonne",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:45.69,9.67",
    "count": 101,
    "city": "Bergamo",
    "county": null,
    "state": "Bergamo",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:52.75,5.97",
    "count": 101,
    "city": "Nederland",
    "county": null,
    "state": "Overijssel",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:20.98,85.75",
    "count": 99,
    "city": null,
    "county": "Bhuban",
    "state": "Odisha",
    "state_district": "Dhenkanal",
    "country": "India"
  },
  {
    "location": "geo:4.98,8.34",
    "count": 98,
    "city": "Calabar",
    "county": "Calabar Municipal",
    "state": "Cross River State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:-25.43,-49.27",
    "count": 98,
    "city": "Curitiba",
    "county": "Região Metropolitana de Curitiba",
    "state": "Paraná",
    "state_district": "Região Geográfica Intermediária de Curitiba",
    "country": "Brazil"
  },
  {
    "location": "geo:43.77,11.26",
    "count": 97,
    "city": "Florence",
    "county": null,
    "state": "Firenze",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:50.13,8.93",
    "count": 97,
    "city": "Hanau",
    "county": "Main-Kinzig-Kreis",
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:31.60,130.56",
    "count": 97,
    "city": "Kagoshima-shi",
    "county": null,
    "state": "Kagoshima Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:52.87,-118.08",
    "count": 96,
    "city": "Municipality of Jasper",
    "county": null,
    "state": "Alberta",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:42.42,18.77",
    "count": 95,
    "city": "Kotor",
    "county": "Kotor Municipality",
    "state": null,
    "state_district": null,
    "country": "Montenegro"
  },
  {
    "location": "geo:-20.25,30.99",
    "count": 94,
    "city": null,
    "county": "Masvingo",
    "state": "Masvingo Province",
    "state_district": null,
    "country": "Zimbabwe"
  },
  {
    "location": "geo:55.50,25.61",
    "count": 94,
    "city": "Utena",
    "county": "Utenos miesto seniūnija",
    "state": "Utena County",
    "state_district": "Utenos rajono savivaldybė",
    "country": "Lithuania"
  },
  {
    "location": "geo:-36.79,175.02",
    "count": 93,
    "city": "Oneroa",
    "county": "Auckland",
    "state": "Auckland Region",
    "state_district": null,
    "country": "New Zealand"
  },
  {
    "location": "geo:50.06,19.94",
    "count": 93,
    "city": "Krakow",
    "county": null,
    "state": "Lesser Poland Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:50.85,4.36",
    "count": 92,
    "city": "Brussels",
    "county": "Brussels-Capital",
    "state": null,
    "state_district": null,
    "country": "Belgium"
  },
  {
    "location": "geo:39.47,-0.38",
    "count": 92,
    "city": "Valencia",
    "county": null,
    "state": "Valencia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:28.61,77.21",
    "count": 92,
    "city": "New Delhi",
    "county": null,
    "state": "Delhi",
    "state_district": null,
    "country": "India"
  },
  {
    "location": "geo:36.17,-115.14",
    "count": 92,
    "city": "Las Vegas",
    "county": "Clark County",
    "state": "Nevada",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-16.89,145.56",
    "count": 90,
    "city": "Koah",
    "county": "Tablelands",
    "state": "Queensland",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:6.52,3.38",
    "count": 90,
    "city": null,
    "county": "Shomolu",
    "state": "Lagos State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:48.15,17.11",
    "count": 90,
    "city": "Bratislava",
    "county": null,
    "state": "Region of Bratislava",
    "state_district": null,
    "country": "Slovakia"
  },
  {
    "location": "geo:28.08,-80.61",
    "count": 89,
    "city": "Melbourne",
    "county": "Brevard County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:7.84,3.94",
    "count": 89,
    "city": "Oyo",
    "county": "Oyo West",
    "state": "Oyo State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:-19.92,-43.94",
    "count": 89,
    "city": "Belo Horizonte",
    "county": "Região Metropolitana de Belo Horizonte",
    "state": "Minas Gerais",
    "state_district": "Região Geográfica Intermediária de Belo Horizonte",
    "country": "Brazil"
  },
  {
    "location": "geo:53.35,-6.26",
    "count": 88,
    "city": "Dublin",
    "county": "County Dublin",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:6.60,3.35",
    "count": 88,
    "city": "Ikeja",
    "county": null,
    "state": "Lagos State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:49.84,24.03",
    "count": 88,
    "city": "Lviv",
    "county": null,
    "state": "Lviv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:45.44,12.32",
    "count": 87,
    "city": "Venice",
    "county": "Venezia",
    "state": "Veneto",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:37.69,-97.33",
    "count": 85,
    "city": "Wichita",
    "county": "Sedgwick County",
    "state": "Kansas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.27,-79.94",
    "count": 85,
    "city": "Roanoke",
    "county": "City of Roanoke",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.24,-124.11",
    "count": 85,
    "city": "Coos Bay",
    "county": "Coos County",
    "state": "Oregon",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:32.02,34.79",
    "count": 84,
    "city": "Holon",
    "county": null,
    "state": "Tel-Aviv District",
    "state_district": "Tel Aviv Subdistrict",
    "country": "Israel"
  },
  {
    "location": "geo:59.33,18.07",
    "count": 84,
    "city": "Stockholm",
    "county": "Stockholm County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:38.30,-76.51",
    "count": 83,
    "city": "California",
    "county": "St. Mary's County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:8.98,-79.52",
    "count": 83,
    "city": "Bella Vista",
    "county": "Distrito de Panamá",
    "state": "Panamá Province",
    "state_district": null,
    "country": "Panama"
  },
  {
    "location": "geo:56.97,24.11",
    "count": 82,
    "city": "Riga",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Latvia"
  },
  {
    "location": "geo:24.75,90.42",
    "count": 81,
    "city": "Mymensingh",
    "county": "Mymensingh Sadar Upazila",
    "state": "Mymensingh Division",
    "state_district": "Mymensingh District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:7.81,4.53",
    "count": 80,
    "city": "Oshogbo",
    "county": "Olorunda",
    "state": "Osun State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:-7.80,110.36",
    "count": 80,
    "city": "Yogyakarta",
    "county": null,
    "state": "Special Region of Yogyakarta",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:37.61,-82.32",
    "count": 77,
    "city": "Belfry",
    "county": "Pike County",
    "state": "Kentucky",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.44,32.06",
    "count": 76,
    "city": "Cherkasy",
    "county": null,
    "state": "Cherkasy Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:22.60,88.26",
    "count": 75,
    "city": null,
    "county": "Domjur",
    "state": "West Bengal",
    "state_district": "Howrah",
    "country": "India"
  },
  {
    "location": "geo:51.05,13.74",
    "count": 75,
    "city": "Dresden",
    "county": null,
    "state": "Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:23.52,87.31",
    "count": 75,
    "city": "Durgapur",
    "county": "Faridpur Durgapur",
    "state": "West Bengal",
    "state_district": "Paschim Bardhaman",
    "country": "India"
  },
  {
    "location": "geo:48.21,16.37",
    "count": 75,
    "city": "Vienna",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:48.48,-81.33",
    "count": 74,
    "city": "Timmins",
    "county": "Cochrane",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:14.60,120.98",
    "count": 74,
    "city": "Manila",
    "county": null,
    "state": null,
    "state_district": "Capital District",
    "country": "Philippines"
  },
  {
    "location": "geo:34.39,-118.54",
    "count": 74,
    "city": "Santa Clarita",
    "county": "Los Angeles County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.06,141.35",
    "count": 74,
    "city": "Sapporo",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:24.45,54.38",
    "count": 73,
    "city": "Al Mushrif",
    "county": "Abu Dhabi",
    "state": "Abu Dhabi Emirate",
    "state_district": null,
    "country": "United Arab Emirates"
  },
  {
    "location": "geo:21.16,-86.85",
    "count": 73,
    "city": "Cancún",
    "county": null,
    "state": "Quintana Roo",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:-6.92,107.72",
    "count": 72,
    "city": "Bandung",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-0.07,-78.58",
    "count": 71,
    "city": "Nono",
    "county": "Quito Canton",
    "state": "Pichincha",
    "state_district": null,
    "country": "Ecuador"
  },
  {
    "location": "geo:45.42,-75.70",
    "count": 71,
    "city": "Ottawa",
    "county": "Ottawa",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:19.43,-99.13",
    "count": 70,
    "city": "Mexico City",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:35.69,139.70",
    "count": 70,
    "city": "Shinjuku",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:14.68,121.04",
    "count": 67,
    "city": "Quezon City",
    "county": null,
    "state": null,
    "state_district": "Eastern Manila District",
    "country": "Philippines"
  },
  {
    "location": "geo:23.13,113.26",
    "count": 66,
    "city": "Yuexiu District",
    "county": null,
    "state": "Guangdong Province",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:38.42,27.14",
    "count": 66,
    "city": "İzmir",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:8.54,4.54",
    "count": 65,
    "city": "Ilorin",
    "county": "Ilorin East",
    "state": "Kwara State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:59.44,24.75",
    "count": 64,
    "city": "Tallinn",
    "county": "Harju County",
    "state": null,
    "state_district": null,
    "country": "Estonia"
  },
  {
    "location": "geo:23.02,72.57",
    "count": 63,
    "city": "Paldi",
    "county": "SabarmatiTaluka",
    "state": "Gujarat",
    "state_district": "Ahmedabad",
    "country": "India"
  },
  {
    "location": "geo:43.22,-2.82",
    "count": 63,
    "city": "Usansolo",
    "county": null,
    "state": "Biscay",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:7.52,4.02",
    "count": 63,
    "city": "Elegbada",
    "county": "Lagelu",
    "state": "Oyo State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:42.57,-81.68",
    "count": 63,
    "city": "West Elgin",
    "county": null,
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:51.22,6.78",
    "count": 62,
    "city": "Dusseldorf",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:24.86,67.00",
    "count": 62,
    "city": "Karachi Division",
    "county": null,
    "state": "Sindh",
    "state_district": null,
    "country": "Pakistan"
  },
  {
    "location": "geo:29.76,-95.37",
    "count": 62,
    "city": "Houston",
    "county": "Harris County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:45.95,-66.44",
    "count": 61,
    "city": null,
    "county": "Maugerville Parish",
    "state": "New Brunswick",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:48.97,-0.08",
    "count": 61,
    "city": "Caen",
    "county": "Calvados",
    "state": "Normandy",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:43.08,25.62",
    "count": 60,
    "city": "Veliko Tarnovo",
    "county": null,
    "state": "Veliko Tarnovo",
    "state_district": "Veliko Tarnovo",
    "country": "Bulgaria"
  },
  {
    "location": "geo:39.17,-77.27",
    "count": 60,
    "city": "Germantown",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:7.07,125.61",
    "count": 60,
    "city": "Davao City",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:7.88,98.39",
    "count": 60,
    "city": "Phuket",
    "county": "Mueang Phuket",
    "state": null,
    "state_district": null,
    "country": "Thailand"
  },
  {
    "location": "geo:41.12,16.87",
    "count": 60,
    "city": "Bari",
    "county": "Bari",
    "state": "Apulia",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:31.52,74.36",
    "count": 59,
    "city": "Jinnah Park",
    "county": null,
    "state": "Punjab",
    "state_district": null,
    "country": "Pakistan"
  },
  {
    "location": "geo:-23.16,-47.06",
    "count": 59,
    "city": "Itupeva",
    "county": null,
    "state": "São Paulo",
    "state_district": "Região Geográfica Intermediária de Campinas",
    "country": "Brazil"
  },
  {
    "location": "geo:37.34,-121.89",
    "count": 58,
    "city": "San Jose",
    "county": "Santa Clara County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:6.44,3.82",
    "count": 58,
    "city": "Mopo Onijebu",
    "county": "Ibeju Lekki",
    "state": "Lagos State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:7.85,4.49",
    "count": 58,
    "city": "Ilobu",
    "county": "Egbedore",
    "state": "Osun State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:42.33,-83.05",
    "count": 56,
    "city": "Detroit",
    "county": "Wayne County",
    "state": "Michigan",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.17,-105.10",
    "count": 56,
    "city": "Longmont",
    "county": "Boulder County",
    "state": "Colorado",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-77.85,166.67",
    "count": 56,
    "city": "McMurdo Station",
    "county": null,
    "state": null,
    "state_district": null,
    "country": null
  },
  {
    "location": "geo:29.43,-98.49",
    "count": 55,
    "city": "San Antonio",
    "county": "Bexar County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.93,-73.90",
    "count": 55,
    "city": "Yonkers",
    "county": "Westchester County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.48,-82.48",
    "count": 55,
    "city": "Pikeville",
    "county": "Pike County",
    "state": "Kentucky",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.10,-94.58",
    "count": 55,
    "city": "Kansas City",
    "county": "Jackson County",
    "state": "Missouri",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.23,-102.41",
    "count": 54,
    "city": "Earth",
    "county": "Lamb County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:51.99,-0.36",
    "count": 54,
    "city": "Shillington",
    "county": "Central Bedfordshire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:41.69,44.80",
    "count": 54,
    "city": "Tbilisi",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Georgia"
  },
  {
    "location": "geo:54.82,24.83",
    "count": 54,
    "city": "Kampinė",
    "county": "Kazokiškių seniūnija",
    "state": "Vilnius County",
    "state_district": "Elektrėnai Municipality",
    "country": "Lithuania"
  },
  {
    "location": "geo:53.08,8.80",
    "count": 54,
    "city": "Bremen",
    "county": null,
    "state": "Bremen",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:31.23,121.47",
    "count": 54,
    "city": "Shanghai",
    "county": null,
    "state": "Shanghai",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:16.43,102.82",
    "count": 54,
    "city": "Khon Kaen",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Thailand"
  },
  {
    "location": "geo:34.28,-119.29",
    "count": 53,
    "city": "Ventura",
    "county": "Ventura County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:44.98,-93.27",
    "count": 53,
    "city": "Minneapolis",
    "county": "Hennepin County",
    "state": "Minnesota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.23,-80.84",
    "count": 53,
    "city": "Charlotte",
    "county": "Mecklenburg County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:54.69,25.28",
    "count": 52,
    "city": "Vilnius",
    "county": null,
    "state": "Vilnius County",
    "state_district": "Vilnius city municipality",
    "country": "Lithuania"
  },
  {
    "location": "geo:42.26,70.03",
    "count": 52,
    "city": "Қазақстан",
    "county": "Tole Bi District",
    "state": "Turkistan Region",
    "state_district": null,
    "country": "Kazakhstan"
  },
  {
    "location": "geo:48.78,9.18",
    "count": 52,
    "city": "Stuttgart",
    "county": null,
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.37,5.17",
    "count": 52,
    "city": "Reusel",
    "county": null,
    "state": "North Brabant",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:26.63,88.41",
    "count": 51,
    "city": null,
    "county": "Tetulia",
    "state": "Rangpur Division",
    "state_district": "Panchagarh District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:-41.96,-71.54",
    "count": 51,
    "city": "El Bolsón",
    "county": null,
    "state": "Río Negro Province",
    "state_district": "Departamento Bariloche",
    "country": "Argentina"
  },
  {
    "location": "geo:42.93,10.53",
    "count": 50,
    "city": "Piombino",
    "county": null,
    "state": "Livorno",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:18.79,98.99",
    "count": 50,
    "city": "Chiang Mai",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Thailand"
  },
  {
    "location": "geo:-45.03,168.66",
    "count": 49,
    "city": "Queenstown",
    "county": "Queenstown-Lakes District",
    "state": "Otago Region",
    "state_district": null,
    "country": "New Zealand"
  },
  {
    "location": "geo:52.16,5.39",
    "count": 49,
    "city": "Amersfoort",
    "county": null,
    "state": "Utrecht",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:45.19,5.72",
    "count": 49,
    "city": "Grenoble",
    "county": "Isère",
    "state": "Auvergne-Rhône-Alpes",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:48.52,34.61",
    "count": 48,
    "city": "Kamianske",
    "county": null,
    "state": "Dnipropetrovsk Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:55.98,-4.95",
    "count": 48,
    "city": "Sandbank",
    "county": "Argyll and Bute",
    "state": "Scotland",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:54.09,28.31",
    "count": 48,
    "city": "Zhodzina",
    "county": null,
    "state": "Minsk Region",
    "state_district": null,
    "country": "Belarus"
  },
  {
    "location": "geo:52.84,-1.83",
    "count": 47,
    "city": "East Staffordshire",
    "county": "Staffordshire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:5.30,-75.56",
    "count": 47,
    "city": "Filadelfia",
    "county": null,
    "state": "Caldas",
    "state_district": "Alto Occidente",
    "country": "Colombia"
  },
  {
    "location": "geo:43.71,7.26",
    "count": 47,
    "city": "Nice",
    "county": "Nice",
    "state": "Alpes-Maritimes",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:39.99,-0.05",
    "count": 47,
    "city": "Castelló de la Plana",
    "county": null,
    "state": "Valencian Community",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:40.75,-95.81",
    "count": 47,
    "city": "Percival",
    "county": "Fremont County",
    "state": "Iowa",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:17.41,78.48",
    "count": 46,
    "city": "Hyderabad",
    "county": "Himayatnagar mandal",
    "state": "Telangana",
    "state_district": "Hyderabad",
    "country": "India"
  },
  {
    "location": "geo:35.72,51.33",
    "count": 45,
    "city": "Tehran",
    "county": "Tehran County",
    "state": null,
    "state_district": null,
    "country": "Iran"
  },
  {
    "location": "geo:51.68,-9.45",
    "count": 45,
    "city": "Bantry",
    "county": "County Cork",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:39.90,116.41",
    "count": 45,
    "city": "Beijing",
    "county": null,
    "state": "Beijing",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:25.59,85.14",
    "count": 45,
    "city": "Patna",
    "county": "Patna Rural",
    "state": "Bihar",
    "state_district": "Patna",
    "country": "India"
  },
  {
    "location": "geo:28.12,-15.44",
    "count": 44,
    "city": "Las Palmas de Gran Canaria",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:-6.26,106.81",
    "count": 44,
    "city": "Special capital Region of Jakarta",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:35.01,25.74",
    "count": 44,
    "city": "Ierapetra Municipal Unit",
    "county": "Lasithi Regional Unit",
    "state": "Region of Crete",
    "state_district": "Region of Crete",
    "country": "Greece"
  },
  {
    "location": "geo:-38.01,-57.54",
    "count": 44,
    "city": "Mar del Plata",
    "county": null,
    "state": "Buenos Aires",
    "state_district": "Partido de General Pueyrredón",
    "country": "Argentina"
  },
  {
    "location": "geo:21.31,-157.86",
    "count": 44,
    "city": "Honolulu",
    "county": "Honolulu County",
    "state": "Hawaii",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:23.26,77.41",
    "count": 44,
    "city": "Bhopal",
    "county": "Huzur Tahsil",
    "state": "Madhya Pradesh",
    "state_district": "Bhopal",
    "country": "India"
  },
  {
    "location": "geo:52.66,-8.63",
    "count": 44,
    "city": "Limerick",
    "county": "County Limerick",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:39.49,-78.07",
    "count": 43,
    "city": "Hedgesville",
    "county": "Berkeley County",
    "state": "West Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:14.64,-91.23",
    "count": 43,
    "city": "Santiago Atitlán",
    "county": null,
    "state": "Sololá",
    "state_district": null,
    "country": "Guatemala"
  },
  {
    "location": "geo:25.32,82.97",
    "count": 43,
    "city": "Varanasi",
    "county": "Sadar",
    "state": "Uttar Pradesh",
    "state_district": "Varanasi",
    "country": "India"
  },
  {
    "location": "geo:59.91,10.75",
    "count": 43,
    "city": "Oslo",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Norway"
  },
  {
    "location": "geo:40.89,-74.01",
    "count": 43,
    "city": "Teaneck",
    "county": "Bergen County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.26,-6.60",
    "count": 42,
    "city": "Kenitra",
    "county": "Pachalik de Kenitra باشوية القنيطرة",
    "state": null,
    "state_district": "Kénitra Province",
    "country": "Morocco"
  },
  {
    "location": "geo:44.52,-80.00",
    "count": 42,
    "city": "Wasaga Beach",
    "county": null,
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:-3.46,114.81",
    "count": 42,
    "city": "Banjarbaru",
    "county": null,
    "state": "South Kalimantan",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:31.54,-110.76",
    "count": 41,
    "city": "Patagonia",
    "county": "Santa Cruz County",
    "state": "Arizona",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:9.52,100.05",
    "count": 41,
    "city": "Baan Chaweng",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Thailand"
  },
  {
    "location": "geo:25.63,88.13",
    "count": 40,
    "city": "Raiganj",
    "county": "Raiganj",
    "state": "West Bengal",
    "state_district": "Uttar Dinajpur",
    "country": "India"
  },
  {
    "location": "geo:50.62,3.07",
    "count": 40,
    "city": "Lille",
    "county": "Nord",
    "state": "Hauts-de-France",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:41.08,-85.14",
    "count": 40,
    "city": "Fort Wayne",
    "county": "Allen County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-3.73,-38.53",
    "count": 39,
    "city": "Fortaleza",
    "county": null,
    "state": "Ceará",
    "state_district": "Região Geográfica Intermediária de Fortaleza",
    "country": "Brazil"
  },
  {
    "location": "geo:54.47,9.84",
    "count": 39,
    "city": "Eckernförde",
    "county": "Rendsburg-Eckernförde",
    "state": "Schleswig-Holstein",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:49.07,33.41",
    "count": 39,
    "city": "Kremenchuk",
    "county": null,
    "state": "Poltava Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:45.60,9.94",
    "count": 38,
    "city": "Erbusco",
    "county": "Brescia",
    "state": "Lombardy",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:38.64,-9.23",
    "count": 38,
    "city": "Costa da Caparica",
    "county": null,
    "state": "Setúbal District",
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:25.13,121.74",
    "count": 38,
    "city": "Keelung",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Taiwan"
  },
  {
    "location": "geo:51.32,-0.56",
    "count": 38,
    "city": "Woking",
    "county": "Surrey",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:61.78,34.36",
    "count": 38,
    "city": "Petrozavodsk",
    "county": "Petrozavodsk Urban Okrug",
    "state": "Republic of Karelia",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:7.01,125.09",
    "count": 38,
    "city": "Kidapawan",
    "county": null,
    "state": "North Cotabato",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:51.42,5.41",
    "count": 38,
    "city": "Veldhoven",
    "county": null,
    "state": "North Brabant",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:-15.80,-47.89",
    "count": 38,
    "city": "Brasília",
    "county": "Região Integrada de Desenvolvimento do Distrito Federal e Entorno",
    "state": "Federal District",
    "state_district": "Região Geográfica Intermediária do Distrito Federal",
    "country": "Brazil"
  },
  {
    "location": "geo:32.49,-81.08",
    "count": 38,
    "city": "Ridgeland",
    "county": "Jasper County",
    "state": "South Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-13.53,-71.97",
    "count": 38,
    "city": "Santiago",
    "county": null,
    "state": "Cusco",
    "state_district": null,
    "country": "Peru"
  },
  {
    "location": "geo:-27.60,-48.55",
    "count": 38,
    "city": "Florianópolis",
    "county": null,
    "state": "Santa Catarina",
    "state_district": null,
    "country": "Brazil"
  },
  {
    "location": "geo:36.64,-4.69",
    "count": 37,
    "city": "Alhaurín el Grande",
    "county": "Valle del Guadalhorce",
    "state": "Andalusia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:-6.74,110.87",
    "count": 37,
    "city": "Kudus",
    "county": null,
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-6.19,106.82",
    "count": 37,
    "city": "Special capital Region of Jakarta",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:25.99,-97.19",
    "count": 37,
    "city": "Brownsville",
    "county": "Cameron County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.66,139.70",
    "count": 36,
    "city": "Shibuya",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:50.95,17.29",
    "count": 36,
    "city": "Oława",
    "county": "Oława County",
    "state": "Lower Silesian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:44.64,8.43",
    "count": 36,
    "city": "Melazzo",
    "county": null,
    "state": "Alessandria",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:37.38,126.93",
    "count": 36,
    "city": "Anyang-Si",
    "county": "Dongan",
    "state": "Kyeongki-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:47.64,-91.20",
    "count": 35,
    "city": "Isabella",
    "county": "Lake County",
    "state": "Minnesota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:30.25,-81.52",
    "count": 34,
    "city": "Jacksonville",
    "county": "Duval County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-36.85,174.76",
    "count": 34,
    "city": "Auckland Central",
    "county": "Auckland",
    "state": "Auckland Region",
    "state_district": null,
    "country": "New Zealand"
  },
  {
    "location": "geo:55.68,12.57",
    "count": 34,
    "city": "Copenhagen",
    "county": null,
    "state": "Capital Region of Denmark",
    "state_district": null,
    "country": "Denmark"
  },
  {
    "location": "geo:18.52,73.86",
    "count": 34,
    "city": "Pune",
    "county": null,
    "state": "Maharashtra",
    "state_district": "Pune District",
    "country": "India"
  },
  {
    "location": "geo:6.45,7.51",
    "count": 34,
    "city": "Enugu",
    "county": "Enugu North",
    "state": "Enugu State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:8.78,7.08",
    "count": 34,
    "city": null,
    "county": "Kwali",
    "state": "Federal Capital Territory",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:39.36,-77.53",
    "count": 34,
    "city": "Jefferson",
    "county": "Frederick County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:17.99,-92.95",
    "count": 33,
    "city": "Villahermosa",
    "county": "Centro",
    "state": "Tabasco",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:41.50,-81.69",
    "count": 33,
    "city": "Cleveland",
    "county": "Cuyahoga County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:42.74,0.79",
    "count": 33,
    "city": "Es Lancères",
    "county": "Val d'Aran",
    "state": "Catalonia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:41.79,2.78",
    "count": 33,
    "city": "Vidreres",
    "county": "la Selva",
    "state": "Catalonia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:41.71,-86.90",
    "count": 33,
    "city": "Michigan City",
    "county": "LaPorte County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.99,22.21",
    "count": 33,
    "city": "Municipality of Probishtip",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "North Macedonia"
  },
  {
    "location": "geo:23.45,91.18",
    "count": 33,
    "city": "Cumilla",
    "county": "Cumilla Adarsha Sadar Upazila",
    "state": "Chattogram Division",
    "state_district": "Cumilla District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:42.93,-8.00",
    "count": 32,
    "city": "Melide",
    "county": "Terra de Melide",
    "state": "Galicia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:38.96,-76.93",
    "count": 32,
    "city": "Riverdale Park",
    "county": "Prince George's County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-23.32,-51.17",
    "count": 32,
    "city": "Londrina",
    "county": null,
    "state": "Paraná",
    "state_district": "Região Geográfica Intermediária de Londrina",
    "country": "Brazil"
  },
  {
    "location": "geo:40.91,20.56",
    "count": 32,
    "city": "Bashkia Pogradec",
    "county": "Korçë County",
    "state": "Southern Albania",
    "state_district": null,
    "country": "Albania"
  },
  {
    "location": "geo:-29.52,-62.17",
    "count": 32,
    "city": null,
    "county": null,
    "state": "Santiago del Estero",
    "state_district": "Departamento Aguirre",
    "country": "Argentina"
  },
  {
    "location": "geo:36.28,-89.68",
    "count": 32,
    "city": null,
    "county": "Pemiscot County",
    "state": "Missouri",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.39,-122.08",
    "count": 32,
    "city": "Mountain View",
    "county": "Santa Clara County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:36.30,-89.75",
    "count": 32,
    "city": "Hayti",
    "county": "Pemiscot County",
    "state": "Missouri",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:22.80,86.20",
    "count": 32,
    "city": "Jamshedpur",
    "county": "Golmuri-Cum-Jugsalai",
    "state": "Jharkhand",
    "state_district": "East Singhbhum",
    "country": "India"
  },
  {
    "location": "geo:-5.40,105.27",
    "count": 31,
    "city": "Bandar Lampung",
    "county": null,
    "state": "Lampung",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:33.73,75.15",
    "count": 31,
    "city": "Anantnag",
    "county": "Anantnag",
    "state": "Jammu and Kashmir",
    "state_district": "Anantnag",
    "country": "India"
  },
  {
    "location": "geo:40.51,-74.40",
    "count": 31,
    "city": "Edison",
    "county": "Middlesex County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:42.98,-81.25",
    "count": 31,
    "city": "London",
    "county": "Middlesex",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:38.49,-77.97",
    "count": 31,
    "city": "Culpeper",
    "county": "Culpeper County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:50.91,-1.10",
    "count": 31,
    "city": "Winchester",
    "county": "Hampshire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:37.55,-121.99",
    "count": 31,
    "city": "Fremont",
    "county": "Alameda County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:51.92,4.48",
    "count": 31,
    "city": "Rotterdam",
    "county": null,
    "state": "South Holland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:52.20,0.13",
    "count": 31,
    "city": "Cambridge",
    "county": "Cambridgeshire",
    "state": "England",
    "state_district": "Cambridgeshire and Peterborough",
    "country": "United Kingdom"
  },
  {
    "location": "geo:43.48,-1.56",
    "count": 30,
    "city": "Biarritz",
    "county": "Biarritz",
    "state": "Pyrénées-Atlantiques",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:37.39,-5.98",
    "count": 30,
    "city": "Seville",
    "county": null,
    "state": "Andalusia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:12.25,109.19",
    "count": 30,
    "city": "Nha Trang",
    "county": null,
    "state": "Khánh Hòa Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:39.12,-5.14",
    "count": 30,
    "city": "Talarrubias",
    "county": null,
    "state": "Extremadura",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:59.26,25.96",
    "count": 30,
    "city": "Tapa",
    "county": "Lääne-Viru County",
    "state": null,
    "state_district": null,
    "country": "Estonia"
  },
  {
    "location": "geo:46.95,7.45",
    "count": 30,
    "city": "Bern",
    "county": "Bern-Mittelland administrative district",
    "state": "Bern",
    "state_district": "Bernese Mittelland administrative region",
    "country": "Switzerland"
  },
  {
    "location": "geo:35.18,136.91",
    "count": 30,
    "city": "Nagoya",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:52.74,9.10",
    "count": 29,
    "city": "Samtgemeinde Grafschaft Hoya",
    "county": "Landkreis Nienburg/Weser",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:44.81,20.46",
    "count": 29,
    "city": "Belgrade",
    "county": "City of Belgrade",
    "state": "Central Serbia",
    "state_district": null,
    "country": "Serbia"
  },
  {
    "location": "geo:3.45,-76.53",
    "count": 29,
    "city": "Cali",
    "county": null,
    "state": "Valle del Cauca",
    "state_district": "Sur",
    "country": "Colombia"
  },
  {
    "location": "geo:12.92,100.88",
    "count": 29,
    "city": "Pattaya City",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Thailand"
  },
  {
    "location": "geo:40.72,-74.04",
    "count": 29,
    "city": "Jersey City",
    "county": "Hudson County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.94,17.28",
    "count": 29,
    "city": "Mosonmagyaróvári járás",
    "county": "Győr-Moson-Sopron",
    "state": null,
    "state_district": null,
    "country": "Hungary"
  },
  {
    "location": "geo:40.26,23.22",
    "count": 29,
    "city": "Municipality of Nea Propontida",
    "county": "Chalkidiki Regional Unit",
    "state": "Macedonia and Thrace",
    "state_district": "Central Macedonia",
    "country": "Greece"
  },
  {
    "location": "geo:-33.45,-70.67",
    "count": 29,
    "city": "Santiago",
    "county": "Provincia de Santiago",
    "state": "Santiago Metropolitan Region",
    "state_district": null,
    "country": "Chile"
  },
  {
    "location": "geo:33.32,-81.14",
    "count": 29,
    "city": "Denmark",
    "county": "Bamberg County",
    "state": "South Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:29.67,-97.51",
    "count": 28,
    "city": "Harwood",
    "county": "Gonzales County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-16.69,-49.27",
    "count": 28,
    "city": "Goiânia",
    "county": null,
    "state": "Goiás",
    "state_district": "Região Geográfica Intermediária de Goiânia",
    "country": "Brazil"
  },
  {
    "location": "geo:25.75,89.26",
    "count": 28,
    "city": "Rangpur",
    "county": "Rangpur Sadar Upazila",
    "state": "Rangpur Division",
    "state_district": "Rangpur District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:52.03,8.53",
    "count": 28,
    "city": "Bielefeld",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:37.35,-121.96",
    "count": 28,
    "city": "Santa Clara",
    "county": "Santa Clara County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:32.57,74.10",
    "count": 28,
    "city": "Gujrat",
    "county": "Gujrat District",
    "state": "Punjab",
    "state_district": "Gujrat Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:42.35,-3.69",
    "count": 28,
    "city": "Burgos",
    "county": null,
    "state": "Castile and León",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:3.04,101.45",
    "count": 28,
    "city": "Klang City",
    "county": null,
    "state": "Selangor",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:51.11,17.04",
    "count": 28,
    "city": "Wrocław",
    "county": null,
    "state": "Lower Silesian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:36.07,120.38",
    "count": 28,
    "city": "Shinan District",
    "county": null,
    "state": "Shandong",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:-75.25,-0.07",
    "count": 28,
    "city": null,
    "county": null,
    "state": null,
    "state_district": null,
    "country": null
  },
  {
    "location": "geo:24.90,91.86",
    "count": 28,
    "city": "Sylhet",
    "county": "Sylhet Sadar Upazila",
    "state": "Sylhet Division",
    "state_district": "Sylhet District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:44.06,12.57",
    "count": 28,
    "city": "Rimini",
    "county": null,
    "state": "Rimini",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:52.01,4.36",
    "count": 27,
    "city": "Delft",
    "county": null,
    "state": "South Holland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:43.86,11.98",
    "count": 27,
    "city": "San Piero in Bagno",
    "county": null,
    "state": "Forli'-Cesena",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:45.07,7.69",
    "count": 27,
    "city": "Turin",
    "county": "Torino",
    "state": "Piedmont",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:46.38,28.15",
    "count": 27,
    "city": "Berezeni",
    "county": "Vaslui",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:36.36,127.39",
    "count": 27,
    "city": "Daejon",
    "county": "Seo",
    "state": "Daejon",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:48.12,-1.68",
    "count": 27,
    "city": "Rennes",
    "county": "Ille-et-Vilaine",
    "state": "Brittany",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:46.89,7.50",
    "count": 27,
    "city": "Belp",
    "county": "Bern-Mittelland administrative district",
    "state": "Bern",
    "state_district": "Bernese Mittelland administrative region",
    "country": "Switzerland"
  },
  {
    "location": "geo:37.80,-122.27",
    "count": 26,
    "city": "Oakland",
    "county": "Alameda County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:42.67,-73.01",
    "count": 26,
    "city": "Drury",
    "county": "Berkshire County",
    "state": "Massachusetts",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.49,139.46",
    "count": 26,
    "city": "Yokohama-shi",
    "county": "Yokohama",
    "state": "Kanagawa Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:20.18,-75.42",
    "count": 26,
    "city": "El Salvador",
    "county": null,
    "state": "Guantánamo",
    "state_district": null,
    "country": "Cuba"
  },
  {
    "location": "geo:29.95,-90.08",
    "count": 26,
    "city": "New Orleans",
    "county": "Orleans Parish",
    "state": "Louisiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:42.00,21.43",
    "count": 26,
    "city": "Skopje",
    "county": "City of Skopje",
    "state": null,
    "state_district": null,
    "country": "North Macedonia"
  },
  {
    "location": "geo:29.35,71.69",
    "count": 26,
    "city": "Bahawalpur City Tehsil",
    "county": "Bahawalpur District",
    "state": "Punjab",
    "state_district": "Bahawalpur Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:44.12,15.23",
    "count": 26,
    "city": "Zadar",
    "county": "Zadar County",
    "state": null,
    "state_district": null,
    "country": "Croatia"
  },
  {
    "location": "geo:28.29,79.99",
    "count": 26,
    "city": null,
    "county": "Powayan",
    "state": "Uttar Pradesh",
    "state_district": "Shahjahanpur",
    "country": "India"
  },
  {
    "location": "geo:5.56,-0.20",
    "count": 26,
    "city": "Accra",
    "county": "Korle-Klottey Municipal District",
    "state": "Greater Accra Region",
    "state_district": null,
    "country": "Ghana"
  },
  {
    "location": "geo:55.86,-4.26",
    "count": 26,
    "city": "Glasgow",
    "county": "Glasgow City",
    "state": "Scotland",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:46.88,17.44",
    "count": 26,
    "city": "Tapolca",
    "county": "Veszprém",
    "state": null,
    "state_district": null,
    "country": "Hungary"
  },
  {
    "location": "geo:39.53,-119.81",
    "count": 26,
    "city": "Reno",
    "county": "Washoe County",
    "state": "Nevada",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:44.13,-70.82",
    "count": 25,
    "city": "Waterford",
    "county": "Oxford County",
    "state": "Maine",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:7.16,80.46",
    "count": 25,
    "city": "Uggoda",
    "county": null,
    "state": "Sabaragamuwa Province",
    "state_district": "Kegalle District",
    "country": "Sri Lanka"
  },
  {
    "location": "geo:36.11,-90.21",
    "count": 25,
    "city": "Senath",
    "county": "Dunklin County",
    "state": "Missouri",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:28.05,81.61",
    "count": 25,
    "city": "Nepalgunj-10",
    "county": "Banke",
    "state": "Lumbini Province",
    "state_district": null,
    "country": "Nepal"
  },
  {
    "location": "geo:53.13,23.17",
    "count": 25,
    "city": "Białystok",
    "county": null,
    "state": "Podlaskie Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:53.79,-1.76",
    "count": 25,
    "city": "Bradford",
    "county": null,
    "state": "England",
    "state_district": "West Yorkshire",
    "country": "United Kingdom"
  },
  {
    "location": "geo:38.81,-9.44",
    "count": 25,
    "city": "Mucifal",
    "county": null,
    "state": "Lisbon",
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:41.26,-95.93",
    "count": 25,
    "city": "Omaha",
    "county": "Douglas County",
    "state": "Nebraska",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.81,-77.64",
    "count": 25,
    "city": "Haymarket",
    "county": "Prince William County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:56.98,13.34",
    "count": 25,
    "city": "Hylte kommun",
    "county": "Halland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:22.38,91.83",
    "count": 25,
    "city": "Chattogram",
    "county": null,
    "state": "Chattogram Division",
    "state_district": "Chattogram District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:-33.92,150.81",
    "count": 25,
    "city": "Austral",
    "county": "Liverpool",
    "state": "New South Wales",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:25.79,-80.13",
    "count": 25,
    "city": "Miami Beach",
    "county": "Miami-Dade County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:36.73,3.09",
    "count": 25,
    "city": "Kouba",
    "county": "Hussein Dey District",
    "state": "Algiers",
    "state_district": null,
    "country": "Algeria"
  },
  {
    "location": "geo:20.97,107.04",
    "count": 25,
    "city": "Hạ Long",
    "county": null,
    "state": "Quảng Ninh Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:53.41,-2.99",
    "count": 24,
    "city": "Liverpool",
    "county": null,
    "state": "England",
    "state_district": "Liverpool City Region",
    "country": "United Kingdom"
  },
  {
    "location": "geo:40.71,19.94",
    "count": 24,
    "city": "Berat",
    "county": "Berat County",
    "state": "Southern Albania",
    "state_district": null,
    "country": "Albania"
  },
  {
    "location": "geo:-6.92,107.62",
    "count": 24,
    "city": "Bandung",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-3.32,114.59",
    "count": 24,
    "city": "Banjarmasin",
    "county": null,
    "state": "South Kalimantan",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:25.69,32.64",
    "count": 24,
    "city": "Luxor",
    "county": null,
    "state": "Luxor",
    "state_district": null,
    "country": "Egypt"
  },
  {
    "location": "geo:39.92,-105.09",
    "count": 24,
    "city": "Broomfield",
    "county": "Broomfield County",
    "state": "Colorado",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.44,-76.29",
    "count": 23,
    "city": "Mathews",
    "county": "Mathews County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.44,-80.00",
    "count": 23,
    "city": "Pittsburgh",
    "county": "Allegheny County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.90,-97.14",
    "count": 23,
    "city": "Winnipeg",
    "county": null,
    "state": "Manitoba",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:-33.92,18.42",
    "count": 23,
    "city": "Cape Town",
    "county": "City of Cape Town",
    "state": "Western Cape",
    "state_district": null,
    "country": "South Africa"
  },
  {
    "location": "geo:56.47,37.57",
    "count": 23,
    "city": null,
    "county": "Dmitrovsky District",
    "state": "Moscow Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:22.54,114.06",
    "count": 23,
    "city": "Futian District",
    "county": null,
    "state": "Guangdong Province",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:25.44,89.31",
    "count": 23,
    "city": null,
    "county": "Pirganj Upazila (Rangpur)",
    "state": "Rangpur Division",
    "state_district": "Rangpur District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:25.29,51.53",
    "count": 23,
    "city": "Doha",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Qatar"
  },
  {
    "location": "geo:38.63,-90.20",
    "count": 23,
    "city": "St. Louis",
    "county": "City of St. Louis",
    "state": "Missouri",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:26.91,75.79",
    "count": 22,
    "city": "Jaipur",
    "county": "Jaipur Tehsil",
    "state": "Rajasthan",
    "state_district": "Jaipur",
    "country": "India"
  },
  {
    "location": "geo:-6.28,106.71",
    "count": 22,
    "city": "South Tangerang",
    "county": null,
    "state": "Banten",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:49.23,28.47",
    "count": 22,
    "city": "Vinnytsia",
    "county": null,
    "state": "Vinnytsia Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:-6.24,106.98",
    "count": 22,
    "city": "Bekasi",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:39.08,-77.65",
    "count": 22,
    "city": "Leesburg",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.70,-80.01",
    "count": 22,
    "city": "Mars",
    "county": "Butler County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.02,-80.61",
    "count": 22,
    "city": "Poland",
    "county": "Mahoning County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.08,8.74",
    "count": 22,
    "city": "Spaichingen",
    "county": "Landkreis Tuttlingen",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-7.26,112.75",
    "count": 22,
    "city": "Surabaya",
    "county": null,
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:29.96,-92.04",
    "count": 22,
    "city": "Erath",
    "county": "Vermilion Parish",
    "state": "Louisiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:36.16,-86.78",
    "count": 22,
    "city": "Nashville",
    "county": "Davidson County",
    "state": "Tennessee",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-30.04,-51.21",
    "count": 22,
    "city": "Porto Alegre",
    "county": "Metropolitan Region of Porto Alegre",
    "state": "Rio Grande do Sul",
    "state_district": "Região Geográfica Intermediária de Porto Alegre",
    "country": "Brazil"
  },
  {
    "location": "geo:47.68,-122.21",
    "count": 22,
    "city": "Kirkland",
    "county": "King County",
    "state": "Washington",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:53.48,-2.24",
    "count": 22,
    "city": "Manchester",
    "county": null,
    "state": "England",
    "state_district": "Greater Manchester",
    "country": "United Kingdom"
  },
  {
    "location": "geo:49.36,7.52",
    "count": 22,
    "city": "Bruchmühlbach-Miesau",
    "county": "Landkreis Kaiserslautern",
    "state": "Rhineland-Palatinate",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-33.76,-66.64",
    "count": 21,
    "city": "Comisión Municipal de Beazley",
    "county": null,
    "state": "San Luis",
    "state_district": "Juan Martín de Pueyrredón",
    "country": "Argentina"
  },
  {
    "location": "geo:45.96,12.51",
    "count": 21,
    "city": "Sacile",
    "county": "Pordenone",
    "state": "Friuli – Venezia Giulia",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:49.87,4.93",
    "count": 21,
    "city": "Laforêt",
    "county": "Dinant",
    "state": "Namur",
    "state_district": null,
    "country": "Belgium"
  },
  {
    "location": "geo:51.50,11.97",
    "count": 21,
    "city": "Halle (Saale)",
    "county": null,
    "state": "Saxony-Anhalt",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-23.09,-47.21",
    "count": 21,
    "city": "Indaiatuba",
    "county": "Região Metropolitana de Campinas",
    "state": "São Paulo",
    "state_district": "Região Geográfica Intermediária de Campinas",
    "country": "Brazil"
  },
  {
    "location": "geo:43.00,-71.45",
    "count": 21,
    "city": "Manchester",
    "county": "Hillsborough County",
    "state": "New Hampshire",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.71,-80.38",
    "count": 21,
    "city": "Centre Wellington",
    "county": "Wellington",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:-6.23,106.90",
    "count": 21,
    "city": "Special capital Region of Jakarta",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:49.89,-119.50",
    "count": 21,
    "city": "Kelowna",
    "county": "Central Okanagan",
    "state": "British Columbia",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:41.65,41.64",
    "count": 21,
    "city": "Batumi",
    "county": null,
    "state": "Autonomous Republic of Adjara",
    "state_district": null,
    "country": "Georgia"
  },
  {
    "location": "geo:38.53,-8.89",
    "count": 21,
    "city": "Setúbal",
    "county": "Setúbal",
    "state": null,
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:49.60,15.58",
    "count": 21,
    "city": "Havlíčkův Brod",
    "county": "Vysočina Region",
    "state": "Southeast",
    "state_district": null,
    "country": "Czechia"
  },
  {
    "location": "geo:53.27,-9.05",
    "count": 21,
    "city": "Galway",
    "county": "County Galway",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:43.59,-80.30",
    "count": 21,
    "city": "Guelph/Eramosa",
    "county": "Wellington County",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:6.25,-75.57",
    "count": 21,
    "city": "Medellín",
    "county": null,
    "state": "Antioquia",
    "state_district": "Valle de Aburrá",
    "country": "Colombia"
  },
  {
    "location": "geo:43.51,16.44",
    "count": 21,
    "city": "Split",
    "county": "Split-Dalmatia County",
    "state": null,
    "state_district": null,
    "country": "Croatia"
  },
  {
    "location": "geo:6.66,100.32",
    "count": 20,
    "city": "Padang Besar",
    "county": null,
    "state": "Perlis",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:38.80,-77.05",
    "count": 20,
    "city": "Alexandria",
    "county": "City of Alexandria",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:21.57,105.83",
    "count": 20,
    "city": "Thái Nguyên",
    "county": null,
    "state": "Thái Nguyên Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:40.76,-111.89",
    "count": 20,
    "city": "Salt Lake City",
    "county": "Salt Lake County",
    "state": "Utah",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:44.51,-88.01",
    "count": 20,
    "city": "Green Bay",
    "county": "Brown County",
    "state": "Wisconsin",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.56,22.14",
    "count": 20,
    "city": "Municipal Unit of Lidoriki",
    "county": "Regional Unit of Phocis",
    "state": "Thessaly and Central Greece",
    "state_district": "Central Greece",
    "country": "Greece"
  },
  {
    "location": "geo:16.46,107.59",
    "count": 19,
    "city": "Huế",
    "county": null,
    "state": "Thừa Thiên Huế Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:48.84,12.96",
    "count": 19,
    "city": "Deggendorf",
    "county": "Landkreis Deggendorf",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.17,71.43",
    "count": 19,
    "city": "Astana",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Kazakhstan"
  },
  {
    "location": "geo:22.34,103.84",
    "count": 19,
    "city": "Sa Pa",
    "county": "Sa Pa",
    "state": "Lào Cai Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:34.06,-117.65",
    "count": 19,
    "city": "Ontario",
    "county": "San Bernardino County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:46.81,-71.21",
    "count": 19,
    "city": "Quebec",
    "county": "Quebec",
    "state": "Quebec",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:10.47,107.44",
    "count": 19,
    "city": "Phuoc Thuan Commune",
    "county": "Xuyên Mộc District",
    "state": "Bà Rịa - Vũng Tàu Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:54.35,18.65",
    "count": 19,
    "city": "Gdańsk",
    "county": null,
    "state": "Pomeranian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:17.69,83.22",
    "count": 19,
    "city": "Visakhapatnam",
    "county": "Visakhapatnam (Urban)",
    "state": "Andhra Pradesh",
    "state_district": "Visakhapatnam",
    "country": "India"
  },
  {
    "location": "geo:51.62,7.96",
    "count": 19,
    "city": "Kirchwelver",
    "county": "Kreis Soest",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:40.19,44.52",
    "count": 19,
    "city": "Yerevan",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Armenia"
  },
  {
    "location": "geo:47.66,9.46",
    "count": 18,
    "city": "Friedrichshafen",
    "county": "Bodenseekreis",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:44.06,-121.32",
    "count": 18,
    "city": "Bend",
    "county": "Deschutes County",
    "state": "Oregon",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:54.13,29.56",
    "count": 18,
    "city": "Цяцерынскі сельскі Савет",
    "county": "Kruhlaye District",
    "state": "Mahilyow Region",
    "state_district": null,
    "country": "Belarus"
  },
  {
    "location": "geo:38.84,-77.43",
    "count": 18,
    "city": "Centreville",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.77,-72.67",
    "count": 18,
    "city": "Hartford",
    "county": "Hartford County",
    "state": "Connecticut",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-33.02,-71.55",
    "count": 18,
    "city": "Viña del Mar",
    "county": "Provincia de Valparaíso",
    "state": "Valparaiso Region",
    "state_district": null,
    "country": "Chile"
  },
  {
    "location": "geo:38.77,-90.47",
    "count": 18,
    "city": "Bridgeton",
    "county": "St. Louis County",
    "state": "Missouri",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.79,38.22",
    "count": 18,
    "city": "Adıyaman",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:21.15,79.09",
    "count": 17,
    "city": "Nagpur",
    "county": "Nagpur Urban Taluka",
    "state": "Maharashtra",
    "state_district": "Nagpur",
    "country": "India"
  },
  {
    "location": "geo:37.98,-122.03",
    "count": 17,
    "city": "Concord",
    "county": "Contra Costa County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:11.26,75.78",
    "count": 17,
    "city": "Kozhikode",
    "county": "Kozhikode",
    "state": "Kerala",
    "state_district": "Kozhikode",
    "country": "India"
  },
  {
    "location": "geo:50.56,8.50",
    "count": 17,
    "city": "Wetzlar",
    "county": "Lahn-Dill-Kreis",
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:34.02,71.52",
    "count": 17,
    "city": "Peshawar City Tehsil",
    "county": "Peshawar District",
    "state": "Khyber Pakhtunkhwa",
    "state_district": "Peshawar Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:13.24,121.24",
    "count": 17,
    "city": "Santa Maria",
    "county": null,
    "state": "Oriental Mindoro",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:52.27,6.16",
    "count": 17,
    "city": "Deventer",
    "county": null,
    "state": "Overijssel",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:47.09,51.92",
    "count": 17,
    "city": "Atyrau",
    "county": "Atyrau",
    "state": "Atyrau Region",
    "state_district": null,
    "country": "Kazakhstan"
  },
  {
    "location": "geo:36.81,10.18",
    "count": 17,
    "city": "Tunis",
    "county": null,
    "state": "Tunis",
    "state_district": null,
    "country": "Tunisia"
  },
  {
    "location": "geo:22.84,89.54",
    "count": 17,
    "city": "Boro Boyra",
    "county": "Dighalia",
    "state": "Khulna Division",
    "state_district": "Khulna District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:44.43,26.10",
    "count": 17,
    "city": "Bucharest",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:54.42,-6.45",
    "count": 17,
    "city": "Portadown",
    "county": "County Armagh",
    "state": "Northern Ireland",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:61.50,23.75",
    "count": 17,
    "city": "Tampere",
    "county": "Pirkanmaa",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:-7.08,107.45",
    "count": 17,
    "city": "Panyocokan",
    "county": "Bandung",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-32.89,-68.85",
    "count": 17,
    "city": "Mendoza",
    "county": "Sección 2ª Barrio Cívico",
    "state": "Mendoza",
    "state_district": "Departamento Capital",
    "country": "Argentina"
  },
  {
    "location": "geo:51.43,-0.55",
    "count": 17,
    "city": "Borough of Runnymede",
    "county": "Surrey",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:44.77,-76.69",
    "count": 17,
    "city": "Central Frontenac",
    "county": "Frontenac",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:47.17,8.52",
    "count": 17,
    "city": "Zug",
    "county": null,
    "state": "Zug",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:33.84,-117.91",
    "count": 16,
    "city": "Anaheim",
    "county": "Orange County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:22.80,104.98",
    "count": 16,
    "city": "Hà Giang",
    "county": "Vị Xuyên District",
    "state": "Hà Giang Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:28.38,36.57",
    "count": 16,
    "city": "Tabuk",
    "county": null,
    "state": "Tabuk Province",
    "state_district": null,
    "country": "Saudi Arabia"
  },
  {
    "location": "geo:43.24,76.88",
    "count": 16,
    "city": "Almaty",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Kazakhstan"
  },
  {
    "location": "geo:8.85,7.10",
    "count": 16,
    "city": null,
    "county": "Kwali",
    "state": "Federal Capital Territory",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:40.27,-76.89",
    "count": 16,
    "city": "Harrisburg",
    "county": "Dauphin County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.76,30.54",
    "count": 16,
    "city": "Afyonkarahisar",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:10.24,105.96",
    "count": 16,
    "city": "Vĩnh Long",
    "county": null,
    "state": "Vĩnh Long Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:39.24,-77.28",
    "count": 16,
    "city": "Clarksburg",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.28,36.34",
    "count": 16,
    "city": "Samsun",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:53.60,7.20",
    "count": 16,
    "city": "Norden (City)",
    "county": "Landkreis Aurich",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:50.00,36.31",
    "count": 16,
    "city": "Kharkiv",
    "county": null,
    "state": "Kharkiv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:28.59,76.27",
    "count": 16,
    "city": "Charkhi Dadri",
    "county": "Dadri",
    "state": "Haryana",
    "state_district": "Charkhi Dadri",
    "country": "India"
  },
  {
    "location": "geo:-6.93,106.93",
    "count": 16,
    "city": "Sukabumi",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:47.01,28.86",
    "count": 16,
    "city": "Chișinău",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Moldova"
  },
  {
    "location": "geo:39.68,-75.75",
    "count": 16,
    "city": "Newark",
    "county": "New Castle County",
    "state": "Delaware",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.50,-80.85",
    "count": 16,
    "city": "Davidson",
    "county": "Mecklenburg County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:23.61,89.84",
    "count": 16,
    "city": "Faridpur",
    "county": "Faridpur Sadar Upazila",
    "state": "Dhaka Division",
    "state_district": "Faridpur District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:39.23,-76.48",
    "count": 16,
    "city": "Sparrows Point",
    "county": "Baltimore County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:55.95,-3.19",
    "count": 16,
    "city": "City of Edinburgh",
    "county": null,
    "state": "Scotland",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:38.95,-77.53",
    "count": 16,
    "city": "Sterling",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:14.25,121.14",
    "count": 15,
    "city": "Cabuyao",
    "county": null,
    "state": "Laguna",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:-0.22,-78.51",
    "count": 15,
    "city": "Quito",
    "county": "Quito Canton",
    "state": "Pichincha",
    "state_district": null,
    "country": "Ecuador"
  },
  {
    "location": "geo:10.48,-66.90",
    "count": 15,
    "city": "Caracas",
    "county": "Municipio Libertador",
    "state": "Capital District",
    "state_district": null,
    "country": "Venezuela"
  },
  {
    "location": "geo:50.11,36.70",
    "count": 15,
    "city": "Вовчанська міська громада",
    "county": null,
    "state": "Kharkiv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:37.39,-76.32",
    "count": 15,
    "city": "Mathews",
    "county": "Mathews County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.38,-111.80",
    "count": 15,
    "city": "American Fork",
    "county": "Utah County",
    "state": "Utah",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:27.77,-82.64",
    "count": 15,
    "city": "St. Petersburg",
    "county": "Pinellas County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.34,36.15",
    "count": 15,
    "city": "Atakum",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:41.34,-75.52",
    "count": 15,
    "city": "Moscow",
    "county": "Lackawanna County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:28.46,77.03",
    "count": 15,
    "city": "Gurgaon",
    "county": null,
    "state": "Haryana",
    "state_district": null,
    "country": "India"
  },
  {
    "location": "geo:21.28,105.72",
    "count": 15,
    "city": "Phúc Yên",
    "county": null,
    "state": "Vĩnh Phúc Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:41.76,-72.74",
    "count": 15,
    "city": "West Hartford",
    "county": "Hartford County",
    "state": "Connecticut",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:54.71,20.45",
    "count": 15,
    "city": "Kaliningrad",
    "county": "Kaliningrad",
    "state": "Kaliningrad",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:47.66,26.27",
    "count": 15,
    "city": "Suceava",
    "county": "Suceava",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:35.72,140.22",
    "count": 15,
    "city": "Sakura",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:51.86,7.48",
    "count": 15,
    "city": "Senden",
    "county": "Kreis Coesfeld",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:24.56,-81.78",
    "count": 15,
    "city": "Key West",
    "county": "Monroe",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.26,-76.99",
    "count": 15,
    "city": "Glenelg",
    "county": "Howard County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.08,4.36",
    "count": 15,
    "city": "Voorburg",
    "county": null,
    "state": "South Holland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:43.32,-1.98",
    "count": 15,
    "city": "San Sebastian",
    "county": null,
    "state": "Gipuzkoa",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:-34.42,150.89",
    "count": 15,
    "city": "Wollongong",
    "county": "Wollongong",
    "state": "New South Wales",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:23.68,87.69",
    "count": 15,
    "city": "Bolpur",
    "county": "Bolpur Sriniketan",
    "state": "West Bengal",
    "state_district": "Birbhum",
    "country": "India"
  },
  {
    "location": "geo:-24.19,-65.30",
    "count": 15,
    "city": "Municipio de San Salvador de Jujuy",
    "county": null,
    "state": "Jujuy",
    "state_district": "Departamento Doctor Manuel Belgrano",
    "country": "Argentina"
  },
  {
    "location": "geo:32.78,-79.93",
    "count": 15,
    "city": "Charleston",
    "county": "Charleston County",
    "state": "South Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-36.08,146.91",
    "count": 14,
    "city": "Albury",
    "county": "Albury",
    "state": "New South Wales",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:-34.09,139.17",
    "count": 14,
    "city": "Eudunda",
    "county": null,
    "state": "South Australia",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:44.26,-64.55",
    "count": 14,
    "city": null,
    "county": "Lunenburg",
    "state": "Nova Scotia",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:42.24,-8.72",
    "count": 14,
    "city": "Vigo",
    "county": null,
    "state": "Pontevedra",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:22.97,88.43",
    "count": 14,
    "city": "Kalyani City",
    "county": "Kalyani",
    "state": "West Bengal",
    "state_district": "Nadia",
    "country": "India"
  },
  {
    "location": "geo:35.88,-106.30",
    "count": 14,
    "city": "Los Alamos",
    "county": "Los Alamos County",
    "state": "New Mexico",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.86,139.65",
    "count": 14,
    "city": "Saitama",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:42.01,-89.33",
    "count": 14,
    "city": "Oregon",
    "county": "Ogle County",
    "state": "Illinois",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:24.37,88.60",
    "count": 14,
    "city": "Rajshahi",
    "county": null,
    "state": "Rajshahi Division",
    "state_district": "Rajshahi District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:30.32,78.03",
    "count": 14,
    "city": "Dehradun",
    "county": "Dehradun",
    "state": "Uttarakhand",
    "state_district": "Dehradun",
    "country": "India"
  },
  {
    "location": "geo:31.57,-97.97",
    "count": 14,
    "city": "Jonesboro",
    "county": "Hamilton County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:23.91,89.12",
    "count": 14,
    "city": "Kushtia",
    "county": null,
    "state": "Khulna Division",
    "state_district": "Kushtia District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:53.20,5.80",
    "count": 14,
    "city": "Leeuwarden",
    "county": null,
    "state": "Frisia",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:32.94,73.73",
    "count": 14,
    "city": "Jhelum",
    "county": "Jhelum District",
    "state": "Punjab",
    "state_district": "Rawalpindi Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:11.59,43.15",
    "count": 14,
    "city": "Djibouti",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Djibouti"
  },
  {
    "location": "geo:44.62,21.18",
    "count": 14,
    "city": "Pozarevac",
    "county": "Branicevo Administrative District",
    "state": "Central Serbia",
    "state_district": null,
    "country": "Serbia"
  },
  {
    "location": "geo:-12.05,-77.04",
    "count": 14,
    "city": "Lima Metropolitan Area",
    "county": null,
    "state": "Lima",
    "state_district": null,
    "country": "Peru"
  },
  {
    "location": "geo:54.98,-1.62",
    "count": 14,
    "city": "Newcastle upon Tyne",
    "county": null,
    "state": "England",
    "state_district": "North of Tyne",
    "country": "United Kingdom"
  },
  {
    "location": "geo:-33.91,25.58",
    "count": 14,
    "city": "Gqeberha",
    "county": "Nelson Mandela Bay",
    "state": "Eastern Cape",
    "state_district": null,
    "country": "South Africa"
  },
  {
    "location": "geo:41.52,0.87",
    "count": 14,
    "city": "Les Borges Blanques",
    "county": null,
    "state": "Lleida",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:34.02,-118.49",
    "count": 14,
    "city": "Santa Monica",
    "county": "Los Angeles County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.35,-0.49",
    "count": 14,
    "city": "Alicante",
    "county": null,
    "state": "Valencian Community",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:-25.42,30.10",
    "count": 14,
    "city": "Dullstroom",
    "county": "Nkangala",
    "state": "Mpumalanga",
    "state_district": null,
    "country": "South Africa"
  },
  {
    "location": "geo:26.45,80.33",
    "count": 14,
    "city": "Kanpur",
    "county": null,
    "state": "Uttar Pradesh",
    "state_district": "Kanpur Nagar",
    "country": "India"
  },
  {
    "location": "geo:39.98,-75.31",
    "count": 14,
    "city": "Haverford",
    "county": "Delaware County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.77,-86.16",
    "count": 14,
    "city": "Indianapolis",
    "county": "Marion County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:51.33,6.57",
    "count": 14,
    "city": "Krefeld",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:43.46,-76.23",
    "count": 14,
    "city": "Mexico",
    "county": "Oswego County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.84,35.14",
    "count": 13,
    "city": "Zaporizhzhia",
    "county": null,
    "state": "Zaporizhia Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:51.90,-8.48",
    "count": 13,
    "city": "Cork",
    "county": "County Cork",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:49.32,8.44",
    "count": 13,
    "city": "Speyer",
    "county": null,
    "state": "Rhineland-Palatinate",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:49.46,-2.54",
    "count": 13,
    "city": "St Peter Port",
    "county": null,
    "state": "Guernsey",
    "state_district": null,
    "country": "Guernsey"
  },
  {
    "location": "geo:26.85,80.95",
    "count": 13,
    "city": "Lucknow",
    "county": "Lucknow",
    "state": "Uttar Pradesh",
    "state_district": "Lucknow",
    "country": "India"
  },
  {
    "location": "geo:54.51,30.43",
    "count": 13,
    "city": "Orsha",
    "county": "Orsha District",
    "state": "Vitsebsk Region",
    "state_district": null,
    "country": "Belarus"
  },
  {
    "location": "geo:32.07,-84.23",
    "count": 13,
    "city": "Americus",
    "county": "Sumter County",
    "state": "Georgia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:50.36,7.59",
    "count": 13,
    "city": "Koblenz",
    "county": null,
    "state": "Rhineland-Palatinate",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-15.60,-56.09",
    "count": 13,
    "city": "Cuiabá",
    "county": "Região Metropolitana do Vale do Rio Cuiabá",
    "state": "Mato Grosso",
    "state_district": "Região Geográfica Intermediária de Cuiabá",
    "country": "Brazil"
  },
  {
    "location": "geo:52.19,-9.46",
    "count": 13,
    "city": "Killeentierna ED",
    "county": "County Kerry",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:52.43,30.99",
    "count": 13,
    "city": "Homyel",
    "county": null,
    "state": "Homyel Region",
    "state_district": null,
    "country": "Belarus"
  },
  {
    "location": "geo:-31.95,115.86",
    "count": 13,
    "city": "City of Perth",
    "county": null,
    "state": "Western Australia",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:34.07,-90.88",
    "count": 13,
    "city": null,
    "county": "Desha County",
    "state": "Arkansas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.31,69.47",
    "count": 13,
    "city": "Tashkent",
    "county": "Yangi Tashkent District",
    "state": null,
    "state_district": null,
    "country": "Uzbekistan"
  },
  {
    "location": "geo:65.99,57.56",
    "count": 13,
    "city": "Usinsk",
    "county": "Usinsk Urban Okrug",
    "state": "Komi Republic",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:37.78,29.10",
    "count": 13,
    "city": "Denizli",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:20.45,106.34",
    "count": 13,
    "city": "Thái Bình",
    "county": null,
    "state": "Thái Bình Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:32.65,-16.91",
    "count": 13,
    "city": "Funchal",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:20.97,-89.59",
    "count": 13,
    "city": "Mérida",
    "county": "Mérida",
    "state": "Yucatán",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:51.28,3.76",
    "count": 13,
    "city": "Terneuzen",
    "county": null,
    "state": "Zeeland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:32.99,-117.27",
    "count": 13,
    "city": "Solana Beach",
    "county": "San Diego County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-43.25,-65.31",
    "count": 13,
    "city": "Trelew",
    "county": null,
    "state": "Chubut Province",
    "state_district": "Departamento Rawson",
    "country": "Argentina"
  },
  {
    "location": "geo:43.76,11.19",
    "count": 13,
    "city": "Scadicci",
    "county": null,
    "state": "Firenze",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:42.42,1.79",
    "count": 13,
    "city": "Meranges",
    "county": "Cerdanya",
    "state": "Catalonia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:56.84,60.65",
    "count": 13,
    "city": "Yekaterinburg",
    "county": "Yekaterinburg Municipality",
    "state": "Sverdlovsk Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:5.41,100.33",
    "count": 13,
    "city": "George Town",
    "county": null,
    "state": "Penang",
    "state_district": "Central George Town",
    "country": "Malaysia"
  },
  {
    "location": "geo:-4.43,-41.46",
    "count": 13,
    "city": "Pedro II",
    "county": null,
    "state": "Piauí",
    "state_district": "Região Geográfica Intermediária de Parnaíba",
    "country": "Brazil"
  },
  {
    "location": "geo:38.97,-77.39",
    "count": 13,
    "city": "Herndon",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.96,-77.36",
    "count": 12,
    "city": "Reston",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.27,127.00",
    "count": 12,
    "city": "Suwon",
    "county": "Paldal",
    "state": "Kyeongki-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:-2.53,-44.30",
    "count": 12,
    "city": "São Luís",
    "county": null,
    "state": "Maranhão",
    "state_district": "Região Geográfica Intermediária de São Luís",
    "country": "Brazil"
  },
  {
    "location": "geo:48.85,-95.76",
    "count": 12,
    "city": "Roseau",
    "county": "Roseau County",
    "state": "Minnesota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:29.55,71.63",
    "count": 12,
    "city": "Lodhran Tehsil",
    "county": null,
    "state": "Punjab",
    "state_district": null,
    "country": "Pakistan"
  },
  {
    "location": "geo:60.98,32.97",
    "count": 12,
    "city": "Olonets",
    "county": "Olonetsky District",
    "state": "Republic of Karelia",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:5.57,95.38",
    "count": 12,
    "city": "Darussalam",
    "county": "Aceh Besar",
    "state": "Aceh",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:42.13,-80.09",
    "count": 12,
    "city": "Erie",
    "county": "Erie County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:25.62,88.64",
    "count": 12,
    "city": "Dinajpur",
    "county": "Dinajpur Sadar Upazila",
    "state": "Rangpur Division",
    "state_district": "Dinajpur District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:20.84,106.69",
    "count": 12,
    "city": "Hải Phòng",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:-20.14,-44.89",
    "count": 12,
    "city": "Divinópolis",
    "county": null,
    "state": "Minas Gerais",
    "state_district": "Região Geográfica Intermediária de Divinópolis",
    "country": "Brazil"
  },
  {
    "location": "geo:51.76,19.46",
    "count": 12,
    "city": "Łódź",
    "county": null,
    "state": "Łódź Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:50.98,11.32",
    "count": 12,
    "city": "Weimar",
    "county": null,
    "state": "Thuringia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:45.57,-66.63",
    "count": 12,
    "city": null,
    "county": "Blissville Parish",
    "state": "New Brunswick",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:41.18,-79.64",
    "count": 12,
    "city": "Richland",
    "county": "Clarion County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.55,25.60",
    "count": 12,
    "city": "Ternopil",
    "county": null,
    "state": "Ternopil Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:21.18,106.07",
    "count": 12,
    "city": "Bắc Ninh",
    "county": null,
    "state": "Bắc Ninh province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:35.34,25.14",
    "count": 12,
    "city": "Heraklion",
    "county": "Heraklion Regional Unit",
    "state": "Region of Crete",
    "state_district": "Region of Crete",
    "country": "Greece"
  },
  {
    "location": "geo:45.54,10.21",
    "count": 12,
    "city": "Brescia",
    "county": null,
    "state": "Brescia",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:37.87,32.49",
    "count": 12,
    "city": "Meram",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:52.60,11.85",
    "count": 12,
    "city": "Stendal",
    "county": "Stendal",
    "state": "Saxony-Anhalt",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:33.57,73.02",
    "count": 12,
    "city": "Rawalpindi",
    "county": "Rawalpindi District",
    "state": "Punjab",
    "state_district": "Rawalpindi Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:10.92,106.71",
    "count": 12,
    "city": "Thuận An",
    "county": null,
    "state": "Bình Dương Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:18.40,80.50",
    "count": 12,
    "city": "Bommanapalli",
    "county": "Wazeed mandal",
    "state": "Telangana",
    "state_district": "Mulugu",
    "country": "India"
  },
  {
    "location": "geo:52.50,5.07",
    "count": 12,
    "city": "Volendam",
    "county": null,
    "state": "North Holland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:53.57,13.28",
    "count": 12,
    "city": "Neubrandenburg",
    "county": "Mecklenburgische Seenplatte",
    "state": "Mecklenburg-Vorpommern",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:9.56,118.44",
    "count": 12,
    "city": null,
    "county": null,
    "state": "Palawan",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:24.22,55.72",
    "count": 12,
    "city": "Al Muwaij'i",
    "county": "Al Ain",
    "state": "Abu Dhabi Emirate",
    "state_district": null,
    "country": "United Arab Emirates"
  },
  {
    "location": "geo:38.44,-78.25",
    "count": 12,
    "city": "Decapolis",
    "county": "Madison County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:21.28,106.20",
    "count": 12,
    "city": "Bắc Giang",
    "county": null,
    "state": "Bắc Giang Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:-8.67,115.21",
    "count": 11,
    "city": "Denpasar",
    "county": null,
    "state": "Bali",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:51.22,4.41",
    "count": 11,
    "city": "Antwerp",
    "county": "Antwerp",
    "state": "Antwerp",
    "state_district": null,
    "country": "Belgium"
  },
  {
    "location": "geo:0.34,6.73",
    "count": 11,
    "city": "São Tomé",
    "county": "Água Grande",
    "state": null,
    "state_district": null,
    "country": "São Tomé and Príncipe"
  },
  {
    "location": "geo:7.98,3.59",
    "count": 11,
    "city": "Iseyin",
    "county": null,
    "state": "Oyo State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:35.36,-79.87",
    "count": 11,
    "city": "Troy",
    "county": "Montgomery County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:42.79,-86.11",
    "count": 11,
    "city": "Holland",
    "county": "Allegan County",
    "state": "Michigan",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.56,69.21",
    "count": 11,
    "city": "Kabul",
    "county": "Kabul District",
    "state": "Kabul Province",
    "state_district": null,
    "country": "Afghanistan"
  },
  {
    "location": "geo:43.04,-70.83",
    "count": 11,
    "city": "Greenland",
    "county": "Rockingham County",
    "state": "New Hampshire",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:59.72,8.01",
    "count": 11,
    "city": "Vinje",
    "county": "Telemark",
    "state": null,
    "state_district": null,
    "country": "Norway"
  },
  {
    "location": "geo:11.87,75.37",
    "count": 11,
    "city": "Kannur",
    "county": "Kannur",
    "state": "Kerala",
    "state_district": "Kannur",
    "country": "India"
  },
  {
    "location": "geo:36.43,129.06",
    "count": 11,
    "city": "Cheongsong-Eup",
    "county": "Cheongsong",
    "state": "Gyeongsangbuk-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:53.99,-1.54",
    "count": 11,
    "city": "Harrogate",
    "county": "North Yorkshire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:51.17,7.08",
    "count": 11,
    "city": "Solingen",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:32.57,73.48",
    "count": 11,
    "city": "Mandi Bahauddin",
    "county": null,
    "state": "Punjab",
    "state_district": "Gujrat Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:38.75,-77.48",
    "count": 11,
    "city": "Manassas",
    "county": "Prince William County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.38,9.73",
    "count": 11,
    "city": "Hanover",
    "county": "Region Hannover",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:30.55,75.96",
    "count": 11,
    "city": "Malerkotla",
    "county": "Malerkotla Tahsil",
    "state": "Punjab",
    "state_district": "Malerkotla",
    "country": "India"
  },
  {
    "location": "geo:40.92,-74.17",
    "count": 11,
    "city": "Paterson",
    "county": "Passaic County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:68.38,23.63",
    "count": 11,
    "city": "Enontekiö",
    "county": "Lapland",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:33.26,73.31",
    "count": 11,
    "city": "Gujar Khan",
    "county": "Rawalpindi District",
    "state": "Punjab",
    "state_district": "Rawalpindi Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:59.00,18.35",
    "count": 11,
    "city": "Haninge kommun",
    "county": "Stockholm County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:7.73,4.44",
    "count": 11,
    "city": "Ede",
    "county": "Ede North",
    "state": "Osun State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:-14.43,77.27",
    "count": 11,
    "city": null,
    "county": null,
    "state": null,
    "state_district": null,
    "country": null
  },
  {
    "location": "geo:38.30,-77.46",
    "count": 11,
    "city": "Fredericksburg",
    "county": "City of Fredericksburg",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:30.05,70.65",
    "count": 11,
    "city": "Dera Ghazi Khan",
    "county": null,
    "state": "Punjab",
    "state_district": "Dera Ghazi Khan Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:27.82,88.56",
    "count": 11,
    "city": null,
    "county": "Chungthang",
    "state": "Sikkim",
    "state_district": "Mangan",
    "country": "India"
  },
  {
    "location": "geo:51.10,3.99",
    "count": 11,
    "city": "Lokeren",
    "county": "Sint-Niklaas",
    "state": "East Flanders",
    "state_district": null,
    "country": "Belgium"
  },
  {
    "location": "geo:14.47,78.82",
    "count": 11,
    "city": "Kadapa",
    "county": "Kadapa",
    "state": "Andhra Pradesh",
    "state_district": "YSR",
    "country": "India"
  },
  {
    "location": "geo:23.00,120.23",
    "count": 11,
    "city": "Tainan",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Taiwan"
  },
  {
    "location": "geo:38.33,-86.46",
    "count": 11,
    "city": "English",
    "county": "Crawford County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:10.45,-74.36",
    "count": 11,
    "city": "Pivijay",
    "county": null,
    "state": "Magdalena",
    "state_district": null,
    "country": "Colombia"
  },
  {
    "location": "geo:-41.37,146.27",
    "count": 11,
    "city": "West Kentish",
    "county": "Kentish",
    "state": "Tasmania",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:52.09,5.12",
    "count": 11,
    "city": "Utrecht",
    "county": null,
    "state": "Utrecht",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:38.04,114.51",
    "count": 11,
    "city": "Shijiazhuang",
    "county": null,
    "state": "Hebei",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:35.10,138.86",
    "count": 11,
    "city": "Numazu",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:20.94,106.31",
    "count": 11,
    "city": "Hải Dương",
    "county": null,
    "state": "Hải Dương Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:26.71,-80.05",
    "count": 11,
    "city": "West Palm Beach",
    "county": "Palm Beach County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:29.22,79.51",
    "count": 11,
    "city": "Haldwani",
    "county": null,
    "state": "Uttarakhand",
    "state_district": "Nainital",
    "country": "India"
  },
  {
    "location": "geo:15.12,108.80",
    "count": 10,
    "city": "Quảng Ngãi",
    "county": null,
    "state": "Quảng Ngãi Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:43.13,131.91",
    "count": 10,
    "city": "Vladivostok",
    "county": "Vladivostok City District",
    "state": "Primorsky Krai",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:17.25,-88.76",
    "count": 10,
    "city": "Belmopan",
    "county": null,
    "state": "Cayo District",
    "state_district": null,
    "country": "Belize"
  },
  {
    "location": "geo:44.09,-91.75",
    "count": 10,
    "city": "Minnesota City",
    "county": "Winona County",
    "state": "Minnesota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:45.81,12.19",
    "count": 10,
    "city": "Nervesa della Battaglia",
    "county": "Province of Treviso",
    "state": "Veneto",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:45.80,15.17",
    "count": 10,
    "city": "Novo Mesto",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Slovenia"
  },
  {
    "location": "geo:44.65,-63.59",
    "count": 10,
    "city": "Halifax",
    "county": "Halifax County",
    "state": "Nova Scotia",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:-6.98,108.49",
    "count": 10,
    "city": "Kuningan",
    "county": "Kuningan",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:65.85,24.15",
    "count": 10,
    "city": "Tornio",
    "county": "Lapland",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:63.32,22.48",
    "count": 10,
    "city": "Vörå",
    "county": "Ostrobothnia",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:62.01,-6.77",
    "count": 10,
    "city": "Tórshavn",
    "county": null,
    "state": "Streymoy region",
    "state_district": null,
    "country": "Faroe Islands"
  },
  {
    "location": "geo:25.04,-77.35",
    "count": 10,
    "city": "Nassau",
    "county": null,
    "state": "New Providence",
    "state_district": null,
    "country": "The Bahamas"
  },
  {
    "location": "geo:26.73,88.40",
    "count": 10,
    "city": "Siliguri",
    "county": "Siliguri Subdivsion",
    "state": "West Bengal",
    "state_district": "Darjeeling",
    "country": "India"
  },
  {
    "location": "geo:26.76,83.37",
    "count": 10,
    "city": "Gorakhpur",
    "county": "Gorakhpur",
    "state": "Uttar Pradesh",
    "state_district": "Gorakhpur",
    "country": "India"
  },
  {
    "location": "geo:10.05,105.75",
    "count": 10,
    "city": "Cần Thơ",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:54.79,9.45",
    "count": 10,
    "city": "Flensburg",
    "county": null,
    "state": "Schleswig-Holstein",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:29.69,76.99",
    "count": 10,
    "city": "Karnal",
    "county": "Karnal",
    "state": "Haryana",
    "state_district": "Karnal",
    "country": "India"
  },
  {
    "location": "geo:29.79,72.18",
    "count": 10,
    "city": "Mailsi",
    "county": null,
    "state": "Punjab",
    "state_district": null,
    "country": "Pakistan"
  },
  {
    "location": "geo:30.42,-87.22",
    "count": 10,
    "city": "Pensacola",
    "county": "Escambia County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:53.22,-3.06",
    "count": 10,
    "city": "Connah's Quay",
    "county": "Flintshire",
    "state": "Wales",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:-32.96,-60.69",
    "count": 10,
    "city": "Rosario",
    "county": null,
    "state": "Santa Fe",
    "state_district": "Departamento Rosario",
    "country": "Argentina"
  },
  {
    "location": "geo:-33.05,-71.61",
    "count": 10,
    "city": "Valparaíso",
    "county": "Provincia de Valparaíso",
    "state": "Valparaiso Region",
    "state_district": null,
    "country": "Chile"
  },
  {
    "location": "geo:53.05,8.63",
    "count": 10,
    "city": "Delmenhorst",
    "county": null,
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:33.59,130.40",
    "count": 10,
    "city": "Fukuoka",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:10.32,123.89",
    "count": 10,
    "city": "Cebu City",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:46.93,26.93",
    "count": 10,
    "city": "Roman",
    "county": "Neamț",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:35.23,128.88",
    "count": 10,
    "city": "Gimhae",
    "county": "Gimhae",
    "state": "Gyeongsangnam-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:51.94,8.87",
    "count": 10,
    "city": "Detmold",
    "county": "Kreis Lippe",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:14.62,120.58",
    "count": 10,
    "city": "Orion",
    "county": null,
    "state": "Bataan",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:36.51,6.33",
    "count": 10,
    "city": "Grarem Gouga",
    "county": "Grarem Gouga District",
    "state": "Mila",
    "state_district": null,
    "country": "Algeria"
  },
  {
    "location": "geo:14.44,79.99",
    "count": 10,
    "city": "Nellore",
    "county": "Nellore",
    "state": "Andhra Pradesh",
    "state_district": "Sri Potti Sriramulu Nellore",
    "country": "India"
  },
  {
    "location": "geo:38.10,-86.27",
    "count": 10,
    "city": "Central",
    "county": "Harrison County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:14.04,121.65",
    "count": 10,
    "city": "Tayabas",
    "county": null,
    "state": "Quezon",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:39.08,-77.48",
    "count": 10,
    "city": "Lansdowne",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.62,-104.95",
    "count": 10,
    "city": "Centennial",
    "county": "Arapahoe County",
    "state": "Colorado",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:13.63,79.42",
    "count": 10,
    "city": "Tirupati",
    "county": "Tirupati (Urban)",
    "state": "Andhra Pradesh",
    "state_district": "Tirupati",
    "country": "India"
  },
  {
    "location": "geo:39.77,30.53",
    "count": 10,
    "city": "Eskisehir",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:49.35,8.15",
    "count": 10,
    "city": "Neustadt an der Weinstraße",
    "county": null,
    "state": "Rhineland-Palatinate",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:40.20,-83.01",
    "count": 10,
    "city": "Lewis Center",
    "county": "Delaware County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.39,-78.44",
    "count": 10,
    "city": "Blair Township",
    "county": "Blair County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.77,8.23",
    "count": 10,
    "city": "Baden-Baden",
    "county": null,
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:40.85,14.27",
    "count": 10,
    "city": "Naples",
    "county": null,
    "state": "Napoli",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:13.11,109.30",
    "count": 10,
    "city": "Tuy Hòa",
    "county": null,
    "state": "Phú Yên Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:48.46,35.05",
    "count": 10,
    "city": "Dnipro",
    "county": null,
    "state": "Dnipropetrovsk Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:41.05,-78.45",
    "count": 10,
    "city": "Plymptonville",
    "county": "Clearfield County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.33,-74.36",
    "count": 10,
    "city": "Florida",
    "county": "Orange County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.62,7.67",
    "count": 10,
    "city": "Lörrach",
    "county": "Landkreis Lörrach",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:42.80,-80.41",
    "count": 10,
    "city": "Norfolk",
    "county": null,
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:51.26,7.15",
    "count": 9,
    "city": "Wuppertal",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:41.30,69.24",
    "count": 9,
    "city": "Tashkent",
    "county": "Chilanzar district",
    "state": null,
    "state_district": null,
    "country": "Uzbekistan"
  },
  {
    "location": "geo:44.01,-77.14",
    "count": 9,
    "city": "Prince Edward",
    "county": "Prince Edward",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:40.77,-84.62",
    "count": 9,
    "city": "Ohio City",
    "county": "Van Wert County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.39,39.99",
    "count": 9,
    "city": "Sirius",
    "county": null,
    "state": "Krasnodar Krai",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:51.89,11.05",
    "count": 9,
    "city": "Halberstadt",
    "county": "Landkreis Harz",
    "state": "Saxony-Anhalt",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-0.25,42.62",
    "count": 9,
    "city": "Gaduud",
    "county": "Kismayo District",
    "state": "Lower Juba",
    "state_district": null,
    "country": "Somalia"
  },
  {
    "location": "geo:47.21,38.92",
    "count": 9,
    "city": "Taganrog",
    "county": null,
    "state": "Rostov Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:13.50,-89.44",
    "count": 9,
    "city": "El Zonte",
    "county": null,
    "state": "La Libertad",
    "state_district": null,
    "country": "El Salvador"
  },
  {
    "location": "geo:-6.98,110.41",
    "count": 9,
    "city": "Semarang",
    "county": null,
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:50.78,6.08",
    "count": 9,
    "city": "Aachen",
    "county": "Städteregion Aachen",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:49.44,7.75",
    "count": 9,
    "city": "Kaiserslautern",
    "county": null,
    "state": "Rhineland-Palatinate",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:43.74,7.42",
    "count": 9,
    "city": "Beausoleil",
    "county": "Maritime Alps",
    "state": "Provence-Alpes-Côte d'Azur",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:45.48,-74.30",
    "count": 9,
    "city": "Rigaud",
    "county": "Francheville",
    "state": "Quebec",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:47.80,13.04",
    "count": 9,
    "city": "Salzburg",
    "county": null,
    "state": "Salzburg",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:42.89,-78.88",
    "count": 9,
    "city": "Buffalo",
    "county": "Erie County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:46.00,8.95",
    "count": 9,
    "city": "Lugano",
    "county": "Distretto di Lugano",
    "state": "Ticino",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:31.10,77.16",
    "count": 9,
    "city": "Shimla",
    "county": null,
    "state": "Himachal Pradesh",
    "state_district": "Shimla",
    "country": "India"
  },
  {
    "location": "geo:49.84,7.87",
    "count": 9,
    "city": "Bad Kreuznach",
    "county": "Landkreis Bad Kreuznach",
    "state": "Rhineland-Palatinate",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:54.28,-8.48",
    "count": 9,
    "city": "Sligo",
    "county": "County Sligo",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:49.78,22.77",
    "count": 9,
    "city": "Przemyśl",
    "county": null,
    "state": "Subcarpathian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:20.53,105.92",
    "count": 9,
    "city": "Phủ Lý",
    "county": null,
    "state": "Hà Nam Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:12.00,8.59",
    "count": 9,
    "city": "Hotoro North",
    "county": "Nassarawa",
    "state": "Kano State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:37.51,22.37",
    "count": 9,
    "city": "Municipal Unit of Tripoli",
    "county": "Arcadia Regional Unit",
    "state": "Peloponnese, Western Greece and the Ionian",
    "state_district": "Peloponnese Region",
    "country": "Greece"
  },
  {
    "location": "geo:39.47,75.99",
    "count": 9,
    "city": "Kashgar City",
    "county": null,
    "state": "Xinjiang",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:41.73,125.94",
    "count": 9,
    "city": "Tonghua",
    "county": null,
    "state": "Jilin",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:24.13,89.07",
    "count": 9,
    "city": "Ishwardi",
    "county": "Ishwardi Upazila",
    "state": "Rajshahi Division",
    "state_district": "Pabna District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:50.80,8.77",
    "count": 9,
    "city": "Marburg",
    "county": "Landkreis Marburg-Biedenkopf",
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-33.45,-70.66",
    "count": 9,
    "city": "Santiago",
    "county": "Provincia de Santiago",
    "state": "Santiago Metropolitan Region",
    "state_district": null,
    "country": "Chile"
  },
  {
    "location": "geo:49.90,10.90",
    "count": 9,
    "city": "Bamberg",
    "county": null,
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:40.63,48.64",
    "count": 9,
    "city": "Shamakhi",
    "county": null,
    "state": null,
    "state_district": "Shamakhi District",
    "country": "Azerbaijan"
  },
  {
    "location": "geo:33.77,-118.19",
    "count": 9,
    "city": "Long Beach",
    "county": "Los Angeles County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:32.06,118.80",
    "count": 9,
    "city": "Xuanwu District",
    "county": null,
    "state": "Jiangsu",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:46.52,6.63",
    "count": 9,
    "city": "Lausanne",
    "county": "District de Lausanne",
    "state": "Vaud",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:10.24,106.38",
    "count": 9,
    "city": "Bến Tre",
    "county": null,
    "state": "Bến Tre Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:64.07,-21.95",
    "count": 9,
    "city": "Hafnarfjordur",
    "county": null,
    "state": null,
    "state_district": "Capital Region",
    "country": "Iceland"
  },
  {
    "location": "geo:-6.82,39.29",
    "count": 9,
    "city": "Dar es-Salaam",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Tanzania"
  },
  {
    "location": "geo:22.55,85.80",
    "count": 9,
    "city": "Chaibasa",
    "county": "Chaibasa",
    "state": "Jharkhand",
    "state_district": "West Singhbhum",
    "country": "India"
  },
  {
    "location": "geo:45.67,12.24",
    "count": 9,
    "city": "Treviso",
    "county": null,
    "state": "Treviso",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:39.19,-0.56",
    "count": 9,
    "city": "l'Alcúdia",
    "county": null,
    "state": "Valencian Community",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:20.46,85.88",
    "count": 9,
    "city": "Cuttack",
    "county": "Cuttack (M.Corp.) P.S",
    "state": "Odisha",
    "state_district": "Cuttack",
    "country": "India"
  },
  {
    "location": "geo:35.73,139.72",
    "count": 9,
    "city": "Toshima",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:49.98,9.14",
    "count": 9,
    "city": "Aschaffenburg",
    "county": null,
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:39.68,-83.93",
    "count": 9,
    "city": "Xenia",
    "county": "Greene County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-30.03,-52.89",
    "count": 9,
    "city": "Cachoeira do Sul",
    "county": null,
    "state": "Rio Grande do Sul",
    "state_district": "Região Geográfica Intermediária de Santa Maria",
    "country": "Brazil"
  },
  {
    "location": "geo:51.51,7.47",
    "count": 9,
    "city": "Dortmund",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:39.01,-77.43",
    "count": 9,
    "city": "Sterling",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.92,24.71",
    "count": 9,
    "city": "Ivano-Frankivsk",
    "county": null,
    "state": "Ivano-Frankivsk Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:13.69,-89.19",
    "count": 9,
    "city": "San Salvador",
    "county": null,
    "state": "San Salvador",
    "state_district": "San Salvador Centro",
    "country": "El Salvador"
  },
  {
    "location": "geo:29.87,121.54",
    "count": 9,
    "city": "Haishu District",
    "county": null,
    "state": "Zhejiang",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:-41.29,174.78",
    "count": 9,
    "city": "Wellington",
    "county": "Wellington City",
    "state": "Wellington",
    "state_district": null,
    "country": "New Zealand"
  },
  {
    "location": "geo:22.31,73.18",
    "count": 9,
    "city": "Vadodara",
    "county": "Vadodara Rural Taluka",
    "state": "Gujarat",
    "state_district": "Vadodara",
    "country": "India"
  },
  {
    "location": "geo:52.90,5.58",
    "count": 9,
    "city": "De Fryske Marren",
    "county": null,
    "state": "Frisia",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:50.61,10.69",
    "count": 9,
    "city": "Suhl",
    "county": null,
    "state": "Thuringia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.72,8.76",
    "count": 8,
    "city": "Paderborn",
    "county": "Kreis Paderborn",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:25.20,85.52",
    "count": 8,
    "city": "Bihar Sharif",
    "county": "Bihar",
    "state": "Bihar",
    "state_district": "Nalanda",
    "country": "India"
  },
  {
    "location": "geo:43.36,-8.41",
    "count": 8,
    "city": "A Coruña",
    "county": "A Coruña",
    "state": "Galicia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:20.86,-89.62",
    "count": 8,
    "city": "Xmatkuil",
    "county": "Mérida",
    "state": "Yucatán",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:48.47,7.95",
    "count": 8,
    "city": "Offenburg",
    "county": "Ortenaukreis",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:21.82,90.14",
    "count": 8,
    "city": null,
    "county": "Kalapara Upazila",
    "state": "Barishal Division",
    "state_district": "Patuakhali District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:45.50,9.42",
    "count": 8,
    "city": "Melzo",
    "county": null,
    "state": "Milano",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:37.97,-87.57",
    "count": 8,
    "city": "Evansville",
    "county": "Vanderburgh County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.23,-84.41",
    "count": 8,
    "city": "Russia",
    "county": "Shelby County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:46.57,26.91",
    "count": 8,
    "city": "Bacău",
    "county": "Bacău",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:44.75,33.86",
    "count": 8,
    "city": "Bakhchysarai",
    "county": "Bakhchysarai Raion",
    "state": "Autonomous Republic of Crimea",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:36.07,-78.25",
    "count": 8,
    "city": "Louisburg",
    "county": "Franklin County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:33.01,114.02",
    "count": 8,
    "city": "Zhumadian",
    "county": null,
    "state": "Henan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:7.26,5.21",
    "count": 8,
    "city": null,
    "county": "Akure North",
    "state": "Ondo State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:26.79,82.20",
    "count": 8,
    "city": "Ayodhya",
    "county": "Faizabad",
    "state": "Uttar Pradesh",
    "state_district": "Ayodhya",
    "country": "India"
  },
  {
    "location": "geo:30.04,31.24",
    "count": 8,
    "city": "Cairo",
    "county": null,
    "state": "Cairo",
    "state_district": null,
    "country": "Egypt"
  },
  {
    "location": "geo:42.22,-78.28",
    "count": 8,
    "city": "Cuba",
    "county": "Allegany County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:54.40,13.61",
    "count": 8,
    "city": "Binz",
    "county": "Vorpommern-Rügen",
    "state": "Mecklenburg-Vorpommern",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:52.28,8.05",
    "count": 8,
    "city": "Osnabrück",
    "county": null,
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-8.65,116.52",
    "count": 8,
    "city": "Pancor",
    "county": null,
    "state": "West Nusa Tenggara",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:39.20,-76.86",
    "count": 8,
    "city": "Columbia",
    "county": "Howard County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:50.97,6.12",
    "count": 8,
    "city": "Geilenkirchen",
    "county": "Kreis Heinsberg",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:18.42,-72.77",
    "count": 8,
    "city": "Commune Grand-Goâve",
    "county": null,
    "state": "West",
    "state_district": "Arrondissement de Léogâne",
    "country": "Haiti"
  },
  {
    "location": "geo:34.54,-91.97",
    "count": 8,
    "city": "England",
    "county": "Lonoke County",
    "state": "Arkansas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:36.60,127.30",
    "count": 8,
    "city": null,
    "county": "Sejong",
    "state": "Chungcheongnam-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:51.42,6.88",
    "count": 8,
    "city": "Mülheim an der Ruhr",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:38.89,-77.43",
    "count": 8,
    "city": "Chantilly",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:15.99,73.68",
    "count": 8,
    "city": "Kudal",
    "county": "Kudal Taluka",
    "state": "Maharashtra",
    "state_district": "Sindhudurg",
    "country": "India"
  },
  {
    "location": "geo:37.71,-80.99",
    "count": 8,
    "city": "Shady Spring",
    "county": "Raleigh County",
    "state": "West Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:27.26,84.02",
    "count": 8,
    "city": null,
    "county": "Sidhaw",
    "state": "Bihar",
    "state_district": "West Champaran",
    "country": "India"
  },
  {
    "location": "geo:60.49,-151.06",
    "count": 8,
    "city": "Soldotna",
    "county": "Kenai Peninsula",
    "state": "Alaska",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.29,-76.61",
    "count": 8,
    "city": "Baltimore",
    "county": "City of Baltimore",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.79,9.95",
    "count": 8,
    "city": "Würzburg",
    "county": null,
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.00,5.89",
    "count": 8,
    "city": "Sittard",
    "county": null,
    "state": "Limburg",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:16.43,80.57",
    "count": 8,
    "city": "Mangalagiri",
    "county": "Mangalagiri",
    "state": "Andhra Pradesh",
    "state_district": "Guntur",
    "country": "India"
  },
  {
    "location": "geo:6.12,125.17",
    "count": 8,
    "city": "General Santos",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:25.68,94.11",
    "count": 8,
    "city": "Kohima",
    "county": "Kohima Sadar",
    "state": "Nagaland",
    "state_district": "Kohima",
    "country": "India"
  },
  {
    "location": "geo:44.23,-76.49",
    "count": 8,
    "city": "Kingston",
    "county": "Frontenac",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:52.64,-1.14",
    "count": 8,
    "city": "Leicester",
    "county": "City of Leicester",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:33.53,-5.12",
    "count": 8,
    "city": "Ifrane",
    "county": "Pachalik d'Ifrane ⵜⴰⴱⴰⵛⴰⵏⵜ ⵏ ⵉⴼⵔⴰⵏ باشوية إفران",
    "state": null,
    "state_district": "Ifrane Province",
    "country": "Morocco"
  },
  {
    "location": "geo:36.87,-94.88",
    "count": 8,
    "city": "Miami",
    "county": "Ottawa County",
    "state": "Oklahoma",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.16,4.48",
    "count": 8,
    "city": "Leiden",
    "county": null,
    "state": "South Holland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:36.99,126.85",
    "count": 8,
    "city": "Pyeongtaek-si",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:43.31,-81.76",
    "count": 8,
    "city": "Lambton Shores",
    "county": null,
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:5.96,6.85",
    "count": 8,
    "city": "Ozubulu",
    "county": "Ekwusigo",
    "state": "Anambra State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:51.22,7.63",
    "count": 8,
    "city": "Lüdenscheid",
    "county": "Märkischer Kreis",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:49.45,11.07",
    "count": 8,
    "city": "Nuremberg",
    "county": null,
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:27.90,78.09",
    "count": 8,
    "city": "Aligarh",
    "county": "Koil",
    "state": "Uttar Pradesh",
    "state_district": "Aligarh",
    "country": "India"
  },
  {
    "location": "geo:33.51,130.53",
    "count": 8,
    "city": "Chikushino-shi",
    "county": null,
    "state": "Fukuoka Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:51.19,6.43",
    "count": 8,
    "city": "Mönchengladbach",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:20.30,85.82",
    "count": 8,
    "city": "Bhubaneswar Municipal Corporation",
    "county": "Bhubaneswar (M.Corp.)",
    "state": "Odisha",
    "state_district": "Khordha",
    "country": "India"
  },
  {
    "location": "geo:47.07,15.44",
    "count": 8,
    "city": "Graz",
    "county": null,
    "state": "Styria",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:-28.00,153.42",
    "count": 8,
    "city": "Surfers Paradise",
    "county": "Gold Coast",
    "state": "Queensland",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:-1.22,16.80",
    "count": 8,
    "city": "Mossaka",
    "county": "Mossaka",
    "state": "Cuvette Department",
    "state_district": null,
    "country": "Congo-Brazzaville"
  },
  {
    "location": "geo:43.10,-79.04",
    "count": 8,
    "city": "Niagara Falls",
    "county": "Niagara County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:51.96,7.63",
    "count": 8,
    "city": "Münster",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:23.07,87.32",
    "count": 8,
    "city": "Bishnupur",
    "county": "Vishnupur",
    "state": "West Bengal",
    "state_district": "Bankura",
    "country": "India"
  },
  {
    "location": "geo:50.26,19.02",
    "count": 8,
    "city": "Katowice",
    "county": null,
    "state": "Silesian Voivodeship",
    "state_district": "Metropolis GZM",
    "country": "Poland"
  },
  {
    "location": "geo:50.56,9.68",
    "count": 8,
    "city": "Fulda",
    "county": "Landkreis Fulda",
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:24.85,89.37",
    "count": 8,
    "city": "Bogura",
    "county": null,
    "state": "Rajshahi Division",
    "state_district": "Bogura District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:-7.42,109.24",
    "count": 8,
    "city": "Kranji",
    "county": null,
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-8.37,114.15",
    "count": 8,
    "city": "Genteng",
    "county": "Banyuwangi",
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-23.62,-46.78",
    "count": 8,
    "city": "Taboão da Serra",
    "county": "Região Metropolitana de São Paulo",
    "state": "São Paulo",
    "state_district": "Região Geográfica Intermediária de São Paulo",
    "country": "Brazil"
  },
  {
    "location": "geo:23.83,91.29",
    "count": 8,
    "city": "Agartala",
    "county": "Mohanpur",
    "state": "Tripura",
    "state_district": "West Tripura",
    "country": "India"
  },
  {
    "location": "geo:51.91,10.43",
    "count": 8,
    "city": "Goslar",
    "county": "Landkreis Goslar",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-7.97,112.63",
    "count": 8,
    "city": "Malang",
    "county": "Kota Malang",
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:44.93,7.54",
    "count": 8,
    "city": "None",
    "county": null,
    "state": "Turin",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:39.87,48.07",
    "count": 8,
    "city": "Imishli",
    "county": null,
    "state": null,
    "state_district": "Imishli District",
    "country": "Azerbaijan"
  },
  {
    "location": "geo:23.73,84.56",
    "count": 8,
    "city": null,
    "county": "Latehar",
    "state": "Jharkhand",
    "state_district": "Latehar",
    "country": "India"
  },
  {
    "location": "geo:39.90,-79.72",
    "count": 8,
    "city": "Uniontown",
    "county": "Fayette County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.91,-77.50",
    "count": 8,
    "city": "South Riding",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:42.50,27.46",
    "count": 8,
    "city": "Burgas",
    "county": null,
    "state": "Burgas",
    "state_district": "Burgas",
    "country": "Bulgaria"
  },
  {
    "location": "geo:10.96,106.84",
    "count": 8,
    "city": "Biên Hòa",
    "county": null,
    "state": "Đồng Nai Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:23.67,86.15",
    "count": 8,
    "city": "Bokaro",
    "county": "Chas",
    "state": "Jharkhand",
    "state_district": "Bokaro",
    "country": "India"
  },
  {
    "location": "geo:35.47,-97.52",
    "count": 7,
    "city": "Oklahoma City",
    "county": "Oklahoma County",
    "state": "Oklahoma",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:5.18,97.14",
    "count": 7,
    "city": "Lhokseumawe",
    "county": null,
    "state": "Aceh",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:23.23,87.08",
    "count": 7,
    "city": "Bankura",
    "county": "Bankura - I",
    "state": "West Bengal",
    "state_district": "Bankura",
    "country": "India"
  },
  {
    "location": "geo:69.65,18.96",
    "count": 7,
    "city": "Tromsø",
    "county": "Troms",
    "state": null,
    "state_district": null,
    "country": "Norway"
  },
  {
    "location": "geo:48.88,12.58",
    "count": 7,
    "city": "Straubing",
    "county": "Straubing",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:24.81,120.97",
    "count": 7,
    "city": "Hsinchu",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Taiwan"
  },
  {
    "location": "geo:52.41,16.93",
    "count": 7,
    "city": "Poznan",
    "county": null,
    "state": "Greater Poland Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:10.39,-75.48",
    "count": 7,
    "city": "Cartagena",
    "county": null,
    "state": "Bolívar",
    "state_district": "Dique",
    "country": "Colombia"
  },
  {
    "location": "geo:-9.80,37.92",
    "count": 7,
    "city": "Liwale",
    "county": null,
    "state": "Lindi Region",
    "state_district": "Liwale",
    "country": "Tanzania"
  },
  {
    "location": "geo:39.16,-76.62",
    "count": 7,
    "city": "Glen Burnie",
    "county": "Anne Arundel County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.89,8.69",
    "count": 7,
    "city": "Pforzheim",
    "county": null,
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:39.22,-77.86",
    "count": 7,
    "city": "Shannondale",
    "county": "Jefferson County",
    "state": "West Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:2.35,102.11",
    "count": 7,
    "city": "Masjid Tanah",
    "county": null,
    "state": "Malacca",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:54.12,29.56",
    "count": 7,
    "city": "Цяцерынскі сельскі Савет",
    "county": "Kruhlaye District",
    "state": "Mahilyow Region",
    "state_district": null,
    "country": "Belarus"
  },
  {
    "location": "geo:-23.53,-46.79",
    "count": 7,
    "city": "Osasco",
    "county": "Região Metropolitana de São Paulo",
    "state": "São Paulo",
    "state_district": "Região Geográfica Intermediária de São Paulo",
    "country": "Brazil"
  },
  {
    "location": "geo:39.46,-121.29",
    "count": 7,
    "city": "Brownsville",
    "county": "Yuba County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-2.42,115.24",
    "count": 7,
    "city": "Amuntai",
    "county": "Hulu Sungai Utara",
    "state": "South Kalimantan",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:64.17,-51.74",
    "count": 7,
    "city": "Nuuk",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Greenland"
  },
  {
    "location": "geo:24.10,88.27",
    "count": 7,
    "city": "Cossimbazar",
    "county": "Berhampore",
    "state": "West Bengal",
    "state_district": "Murshidabad",
    "country": "India"
  },
  {
    "location": "geo:18.44,73.80",
    "count": 7,
    "city": "Dhayari",
    "county": "Haveli",
    "state": "Maharashtra",
    "state_district": "Pune District",
    "country": "India"
  },
  {
    "location": "geo:43.27,6.64",
    "count": 7,
    "city": "Saint-Tropez",
    "county": "Saint-Tropez",
    "state": "Var",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:49.42,26.99",
    "count": 7,
    "city": "Khmelnytskyi",
    "county": null,
    "state": "Khmelnytskyi Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:49.40,8.67",
    "count": 7,
    "city": "Heidelberg",
    "county": null,
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:30.49,79.22",
    "count": 7,
    "city": "Bhulkhana Chatti",
    "county": "Ukhimath",
    "state": "Uttarakhand",
    "state_district": "Rudraprayag",
    "country": "India"
  },
  {
    "location": "geo:6.69,3.24",
    "count": 7,
    "city": "Otta",
    "county": "Ado Odo/Ota",
    "state": "Ogun State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:49.31,6.75",
    "count": 7,
    "city": "Saarlouis",
    "county": "Landkreis Saarlouis",
    "state": "Saarland",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:35.84,127.13",
    "count": 7,
    "city": "Jeonju-Si",
    "county": "Deokjin",
    "state": "Jeollabuk-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:30.27,120.16",
    "count": 7,
    "city": "Gongshu District",
    "county": null,
    "state": "Zhejiang",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:54.31,13.08",
    "count": 7,
    "city": "Stralsund",
    "county": "Vorpommern-Rügen",
    "state": "Mecklenburg-Vorpommern",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.57,6.75",
    "count": 7,
    "city": "Dinslaken",
    "county": "Kreis Wesel",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:42.33,-72.64",
    "count": 7,
    "city": "Northampton",
    "county": "Hampshire County",
    "state": "Massachusetts",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:36.15,45.48",
    "count": 7,
    "city": "سردشت",
    "county": "Sardasht County",
    "state": "West Azerbaijan Province",
    "state_district": null,
    "country": "Iran"
  },
  {
    "location": "geo:-36.22,-65.44",
    "count": 7,
    "city": "Victorica",
    "county": null,
    "state": "La Pampa",
    "state_district": "Departamento Loventué",
    "country": "Argentina"
  },
  {
    "location": "geo:47.68,9.17",
    "count": 7,
    "city": "Constance",
    "county": "Landkreis Konstanz",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:46.16,-1.15",
    "count": 7,
    "city": "La Rochelle",
    "county": "Charente-Maritime",
    "state": "Nouvelle-Aquitaine",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:-29.36,-50.81",
    "count": 7,
    "city": "Canela",
    "county": null,
    "state": "Rio Grande do Sul",
    "state_district": "Região Geográfica Intermediária de Caxias do Sul",
    "country": "Brazil"
  },
  {
    "location": "geo:52.34,-2.28",
    "count": 7,
    "city": "Wyre Forest",
    "county": "Worcestershire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:36.68,-121.80",
    "count": 7,
    "city": "Marina",
    "county": "Monterey County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:51.37,7.46",
    "count": 7,
    "city": "Hagen",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:36.72,-4.43",
    "count": 7,
    "city": "Málaga",
    "county": "Málaga-Costa del Sol",
    "state": "Andalusia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:28.98,79.40",
    "count": 7,
    "city": "Rudrapur",
    "county": "Kichha",
    "state": "Uttarakhand",
    "state_district": "Udham Singh Nagar",
    "country": "India"
  },
  {
    "location": "geo:36.74,-119.79",
    "count": 7,
    "city": "Fresno",
    "county": "Fresno County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.98,-114.67",
    "count": 7,
    "city": "Jackpot",
    "county": "Elko County",
    "state": "Nevada",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:36.81,139.92",
    "count": 7,
    "city": null,
    "county": "Yaita",
    "state": "Tochigi Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:-28.65,-65.88",
    "count": 7,
    "city": "Municipio de Huillapima",
    "county": null,
    "state": "Catamarca",
    "state_district": "Departamento Capayán",
    "country": "Argentina"
  },
  {
    "location": "geo:36.99,35.33",
    "count": 7,
    "city": "Kayalıbağ Mahallesi",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:51.25,22.57",
    "count": 7,
    "city": "Lublin",
    "county": null,
    "state": "Lublin Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:51.22,6.91",
    "count": 7,
    "city": "Erkrath",
    "county": "Kreis Mettmann",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:43.95,4.81",
    "count": 7,
    "city": "Avignon",
    "county": "Vaucluse",
    "state": "Provence-Alpes-Côte d'Azur",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:28.28,78.01",
    "count": 7,
    "city": "Shikarpur",
    "county": "Shikarpur",
    "state": "Uttar Pradesh",
    "state_district": "Bulandshahr",
    "country": "India"
  },
  {
    "location": "geo:55.79,49.12",
    "count": 7,
    "city": "Kazan",
    "county": null,
    "state": "Tatarstan",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:44.04,10.14",
    "count": 7,
    "city": "Massa",
    "county": null,
    "state": "Massa-Carrara",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:27.93,78.39",
    "count": 7,
    "city": "Chharra",
    "county": "Atrauli",
    "state": "Uttar Pradesh",
    "state_district": "Aligarh",
    "country": "India"
  },
  {
    "location": "geo:51.09,6.59",
    "count": 7,
    "city": "Grevenbroich",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:34.77,-84.97",
    "count": 7,
    "city": "Dalton",
    "county": "Whitfield County",
    "state": "Georgia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:19.28,73.05",
    "count": 7,
    "city": "Bhiwandi - Nizampur",
    "county": "Bhiwandi Taluka",
    "state": "Maharashtra",
    "state_district": "Thane",
    "country": "India"
  },
  {
    "location": "geo:56.67,12.86",
    "count": 7,
    "city": "Halmstad",
    "county": "Halland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:45.82,15.98",
    "count": 7,
    "city": "City of Zagreb",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Croatia"
  },
  {
    "location": "geo:50.96,13.94",
    "count": 7,
    "city": "Pirna",
    "county": "Sächsische Schweiz-Osterzgebirge",
    "state": "Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:57.71,11.97",
    "count": 7,
    "city": "Gothenburg",
    "county": "Västra Götaland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:41.24,-81.44",
    "count": 7,
    "city": "Hudson",
    "county": "Summit County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.26,9.05",
    "count": 7,
    "city": "Bückeburg",
    "county": "Landkreis Schaumburg",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:50.88,8.02",
    "count": 7,
    "city": "Siegen",
    "county": "Kreis Siegen-Wittgenstein",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:10.38,105.42",
    "count": 7,
    "city": "Long Xuyên",
    "county": null,
    "state": "An Giang Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:44.90,110.11",
    "count": 7,
    "city": "Sainshand",
    "county": null,
    "state": "Dornogovi",
    "state_district": null,
    "country": "Mongolia"
  },
  {
    "location": "geo:41.05,-73.54",
    "count": 7,
    "city": "Stamford",
    "county": "Fairfield County",
    "state": "Connecticut",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.48,24.58",
    "count": 7,
    "city": "Yaremche",
    "county": null,
    "state": "Ivano-Frankivsk Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:26.64,-81.86",
    "count": 7,
    "city": "Fort Myers",
    "county": "Lee County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.13,64.72",
    "count": 7,
    "city": "Esenmeňli",
    "county": "Halach District",
    "state": "Lebap Region",
    "state_district": null,
    "country": "Turkmenistan"
  },
  {
    "location": "geo:40.80,-81.38",
    "count": 7,
    "city": "Canton",
    "county": "Stark County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:17.09,73.89",
    "count": 7,
    "city": "Shitur",
    "county": "Shahuwadi",
    "state": "Maharashtra",
    "state_district": "Kolhapur District",
    "country": "India"
  },
  {
    "location": "geo:11.04,124.62",
    "count": 7,
    "city": "Ormoc",
    "county": null,
    "state": "Leyte",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:53.89,11.46",
    "count": 7,
    "city": "Wismar",
    "county": "Nordwestmecklenburg",
    "state": "Mecklenburg-Vorpommern",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:18.28,83.34",
    "count": 7,
    "city": "Gajapatinagaram",
    "county": "Gajapathinagaram",
    "state": "Andhra Pradesh",
    "state_district": "Vizianagaram",
    "country": "India"
  },
  {
    "location": "geo:59.72,11.46",
    "count": 7,
    "city": "Aurskog-Høland",
    "county": "Akershus",
    "state": null,
    "state_district": null,
    "country": "Norway"
  },
  {
    "location": "geo:7.15,3.36",
    "count": 7,
    "city": "Abeokuta",
    "county": "Abeokuta South",
    "state": "Ogun State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:50.06,4.49",
    "count": 7,
    "city": "Couvin",
    "county": "Philippeville",
    "state": "Namur",
    "state_district": null,
    "country": "Belgium"
  },
  {
    "location": "geo:40.35,-74.66",
    "count": 7,
    "city": "Princeton",
    "county": "Mercer County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:9.93,78.12",
    "count": 7,
    "city": "Madurai",
    "county": "Madurai South",
    "state": "Tamil Nadu",
    "state_district": "Madurai",
    "country": "India"
  },
  {
    "location": "geo:25.44,-100.97",
    "count": 7,
    "city": "Saltillo",
    "county": "Saltillo",
    "state": "Coahuila de Zaragoza",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:38.92,-77.23",
    "count": 7,
    "city": "Mclean",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:19.88,75.34",
    "count": 6,
    "city": "Chhatrapati Sambhajinagar",
    "county": "Chhatrapati Sambhajinagar",
    "state": "Maharashtra",
    "state_district": "Chhatrapati Sambhajinagar District",
    "country": "India"
  },
  {
    "location": "geo:20.65,106.06",
    "count": 6,
    "city": "Hưng Yên",
    "county": null,
    "state": "Hưng Yên Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:4.21,101.98",
    "count": 6,
    "city": null,
    "county": null,
    "state": "Pahang",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:20.70,83.48",
    "count": 6,
    "city": "Balangir",
    "county": "Balangir",
    "state": "Odisha",
    "state_district": "Balangir",
    "country": "India"
  },
  {
    "location": "geo:-2.98,104.78",
    "count": 6,
    "city": "Palembang",
    "county": null,
    "state": "South Sumatra",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:57.46,10.00",
    "count": 6,
    "city": "Hjørring",
    "county": null,
    "state": "North Denmark Region",
    "state_district": null,
    "country": "Denmark"
  },
  {
    "location": "geo:37.84,-94.35",
    "count": 6,
    "city": "Nevada",
    "county": "Vernon County",
    "state": "Missouri",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.99,2.23",
    "count": 6,
    "city": "Franconville",
    "county": "Val-d'Oise",
    "state": "Ile-de-France",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:21.38,-98.99",
    "count": 6,
    "city": "Xilitla",
    "county": "Xilitla",
    "state": "San Luis Potosí",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:-8.54,118.46",
    "count": 6,
    "city": "Dompu",
    "county": null,
    "state": "West Nusa Tenggara",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:43.60,39.73",
    "count": 6,
    "city": "Sochi",
    "county": "town district of Sochi",
    "state": "Krasnodar Krai",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:30.11,-9.28",
    "count": 6,
    "city": "caïdat d'Imi Mqourn",
    "county": "cercle de Biougra",
    "state": null,
    "state_district": "Chtouka-Ait Baha Province",
    "country": "Morocco"
  },
  {
    "location": "geo:38.85,-77.31",
    "count": 6,
    "city": "Fairfax",
    "county": "City of Fairfax",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:21.99,83.10",
    "count": 6,
    "city": "Kharsia",
    "county": "Kharsia Tahsil",
    "state": "Chhattisgarh",
    "state_district": "Raigarh",
    "country": "India"
  },
  {
    "location": "geo:7.99,3.56",
    "count": 6,
    "city": "Iseyin",
    "county": null,
    "state": "Oyo State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:9.12,105.09",
    "count": 6,
    "city": "Xã Thạnh Phú",
    "county": "Cái Nước District",
    "state": "Cà Mau Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:30.19,53.17",
    "count": 6,
    "city": "Madar-e-Soleyman",
    "county": "Pasargad County",
    "state": "Fars Province",
    "state_district": null,
    "country": "Iran"
  },
  {
    "location": "geo:42.85,-106.30",
    "count": 6,
    "city": "Casper",
    "county": "Natrona County",
    "state": "Wyoming",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:11.15,76.38",
    "count": 6,
    "city": null,
    "county": "Nilambur",
    "state": "Kerala",
    "state_district": "Malappuram",
    "country": "India"
  },
  {
    "location": "geo:50.88,6.89",
    "count": 6,
    "city": "Hürth",
    "county": "Rhein-Erft-Kreis",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:50.05,10.22",
    "count": 6,
    "city": "Schweinfurt",
    "county": "Schweinfurt",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:35.96,-83.92",
    "count": 6,
    "city": "Knoxville",
    "county": "Knox County",
    "state": "Tennessee",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-43.53,172.64",
    "count": 6,
    "city": "Christchurch Central",
    "county": "Christchurch City",
    "state": "Canterbury Region",
    "state_district": null,
    "country": "New Zealand"
  },
  {
    "location": "geo:50.81,6.26",
    "count": 6,
    "city": "Eschweiler",
    "county": "Städteregion Aachen",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:42.43,19.26",
    "count": 6,
    "city": "Podgorica",
    "county": "Podgorica Capital City",
    "state": null,
    "state_district": null,
    "country": "Montenegro"
  },
  {
    "location": "geo:61.49,21.80",
    "count": 6,
    "city": "Pori",
    "county": "Satakunta",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:30.26,-9.50",
    "count": 6,
    "city": "Sidi Bibi",
    "county": "cercle de Biougra",
    "state": null,
    "state_district": "Chtouka-Ait Baha Province",
    "country": "Morocco"
  },
  {
    "location": "geo:-4.10,-81.05",
    "count": 6,
    "city": "Máncora",
    "county": null,
    "state": "Piura",
    "state_district": null,
    "country": "Peru"
  },
  {
    "location": "geo:6.19,125.35",
    "count": 6,
    "city": "Alegria",
    "county": null,
    "state": "Sarangani",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:-8.12,-79.03",
    "count": 6,
    "city": "Trujillo",
    "county": null,
    "state": "La Libertad",
    "state_district": null,
    "country": "Peru"
  },
  {
    "location": "geo:17.77,106.42",
    "count": 6,
    "city": "Ba Đồn",
    "county": null,
    "state": "Quảng Bình Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:30.73,76.78",
    "count": 6,
    "city": "Chandigarh",
    "county": null,
    "state": "Chandigarh",
    "state_district": "Chandigarh",
    "country": "India"
  },
  {
    "location": "geo:38.09,69.34",
    "count": 6,
    "city": "Danghara",
    "county": "Danghara District",
    "state": "Khatlon Region",
    "state_district": null,
    "country": "Tajikistan"
  },
  {
    "location": "geo:39.96,-76.73",
    "count": 6,
    "city": "York",
    "county": "York County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.19,-77.61",
    "count": 6,
    "city": "Waterford",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:24.65,68.85",
    "count": 6,
    "city": "بدين",
    "county": "Badin District",
    "state": "Sindh",
    "state_district": "Banbhore Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:49.24,7.00",
    "count": 6,
    "city": "Saarbrücken",
    "county": "Regionalverband Saarbrücken",
    "state": "Saarland",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:39.90,-76.16",
    "count": 6,
    "city": "Quarryville",
    "county": "Lancaster County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-7.63,111.53",
    "count": 6,
    "city": "Madiun",
    "county": null,
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:38.20,-120.68",
    "count": 6,
    "city": "San Andreas",
    "county": "Calaveras County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.32,8.55",
    "count": 6,
    "city": "Hockenheim",
    "county": "Rhein-Neckar-Kreis",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:15.50,73.83",
    "count": 6,
    "city": "Panaji",
    "county": "Tiswadi",
    "state": "Goa",
    "state_district": "North Goa",
    "country": "India"
  },
  {
    "location": "geo:39.83,-77.23",
    "count": 6,
    "city": "Gettysburg",
    "county": "Adams County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:45.14,7.77",
    "count": 6,
    "city": "Settimo Torinese",
    "county": "Torino",
    "state": "Piedmont",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:18.18,74.61",
    "count": 6,
    "city": null,
    "county": "Baramati",
    "state": "Maharashtra",
    "state_district": "Pune District",
    "country": "India"
  },
  {
    "location": "geo:48.51,32.26",
    "count": 6,
    "city": "Kropyvnytskyi",
    "county": null,
    "state": "Kirovohrad Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:38.99,-77.03",
    "count": 6,
    "city": "Silver Spring",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:51.99,9.25",
    "count": 6,
    "city": "Bad Pyrmont",
    "county": "Landkreis Hameln-Pyrmont",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-1.46,-48.50",
    "count": 6,
    "city": "Belém",
    "county": null,
    "state": "Pará",
    "state_district": "Região Geográfica Intermediária de Belém",
    "country": "Brazil"
  },
  {
    "location": "geo:45.29,-74.85",
    "count": 6,
    "city": "North Glengarry",
    "county": "Stormont, Dundas and Glengarry",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:42.50,-71.58",
    "count": 6,
    "city": "Harvard",
    "county": "Worcester County",
    "state": "Massachusetts",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.34,132.91",
    "count": 6,
    "city": "Takehara",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:26.29,83.50",
    "count": 6,
    "city": "Barhalganj",
    "county": "Gola",
    "state": "Uttar Pradesh",
    "state_district": "Gorakhpur",
    "country": "India"
  },
  {
    "location": "geo:35.74,139.85",
    "count": 6,
    "city": "Tokyo",
    "county": "Katsushika",
    "state": "Tokyo",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:21.53,39.16",
    "count": 6,
    "city": "Jeddah",
    "county": null,
    "state": "Makkah Region",
    "state_district": null,
    "country": "Saudi Arabia"
  },
  {
    "location": "geo:30.57,104.07",
    "count": 6,
    "city": "Chengdu",
    "county": null,
    "state": "Sichuan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:26.25,50.61",
    "count": 6,
    "city": "Muharraq",
    "county": null,
    "state": "Muharraq Governorate",
    "state_district": null,
    "country": "Bahrain"
  },
  {
    "location": "geo:13.96,99.75",
    "count": 6,
    "city": "บ้านท่าเรือ",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Thailand"
  },
  {
    "location": "geo:31.45,73.13",
    "count": 6,
    "city": "Malik Pur",
    "county": null,
    "state": "Punjab",
    "state_district": null,
    "country": "Pakistan"
  },
  {
    "location": "geo:40.69,-75.22",
    "count": 6,
    "city": "Easton",
    "county": "Northampton County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.98,-77.64",
    "count": 6,
    "city": "Aldie",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.59,2.78",
    "count": 6,
    "city": "Melun",
    "county": "Seine-et-Marne",
    "state": "Ile-de-France",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:9.58,6.55",
    "count": 6,
    "city": null,
    "county": "Bosso",
    "state": "Niger State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:52.39,13.06",
    "count": 6,
    "city": "Potsdam",
    "county": null,
    "state": "Brandenburg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:21.42,39.82",
    "count": 6,
    "city": "Makkah Al Mukarramah",
    "county": null,
    "state": "Makkah Region",
    "state_district": null,
    "country": "Saudi Arabia"
  },
  {
    "location": "geo:39.13,-77.77",
    "count": 6,
    "city": "Round Hill",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:26.00,95.69",
    "count": 6,
    "city": "Hkamti Township",
    "county": null,
    "state": "Sagaing Region",
    "state_district": null,
    "country": "Myanmar"
  },
  {
    "location": "geo:40.62,-79.15",
    "count": 6,
    "city": "Indiana",
    "county": "Indiana County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.10,-84.51",
    "count": 6,
    "city": "Cincinnati",
    "county": "Hamilton County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-9.97,-67.81",
    "count": 6,
    "city": "Rio Branco",
    "county": null,
    "state": "Acre",
    "state_district": "Região Geográfica Intermediária de Rio Branco",
    "country": "Brazil"
  },
  {
    "location": "geo:42.51,1.54",
    "count": 6,
    "city": "les Escaldes",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Andorra"
  },
  {
    "location": "geo:24.19,86.30",
    "count": 6,
    "city": "Giridih",
    "county": "Giridih",
    "state": "Jharkhand",
    "state_district": "Giridih",
    "country": "India"
  },
  {
    "location": "geo:38.72,-77.79",
    "count": 6,
    "city": "Warrenton",
    "county": "Fauquier County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.71,139.78",
    "count": 6,
    "city": "Taito",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:24.25,89.92",
    "count": 6,
    "city": "Tangail",
    "county": "Tangail Sadar Upazila",
    "state": "Dhaka Division",
    "state_district": "Tangail District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:25.43,77.66",
    "count": 6,
    "city": "Shivpuri",
    "county": "Shivpuri NagarTahsil",
    "state": "Madhya Pradesh",
    "state_district": "Shivpuri",
    "country": "India"
  },
  {
    "location": "geo:39.33,-77.74",
    "count": 6,
    "city": "Harpers Ferry",
    "county": "Jefferson County",
    "state": "West Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.76,2.36",
    "count": 6,
    "city": "Chevilly-Larue",
    "county": "Val-de-Marne",
    "state": "Ile-de-France",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:59.86,17.64",
    "count": 6,
    "city": "Uppsala",
    "county": "Uppsala County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:38.79,0.17",
    "count": 6,
    "city": "Javea",
    "county": null,
    "state": "Alicante",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:6.22,7.08",
    "count": 6,
    "city": "Awka",
    "county": "Awka South",
    "state": "Anambra State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:49.48,6.08",
    "count": 6,
    "city": "Dudelange",
    "county": "Canton Esch-sur-Alzette",
    "state": null,
    "state_district": null,
    "country": "Luxembourg"
  },
  {
    "location": "geo:34.01,-6.85",
    "count": 6,
    "city": "Rabat",
    "county": "باشوية الرباط",
    "state": null,
    "state_district": "Rabat Prefecture",
    "country": "Morocco"
  },
  {
    "location": "geo:22.72,75.86",
    "count": 6,
    "city": "Indore",
    "county": "Juni Indore Tahsil",
    "state": "Madhya Pradesh",
    "state_district": "Indore",
    "country": "India"
  },
  {
    "location": "geo:44.18,-81.64",
    "count": 6,
    "city": "Kincardine",
    "county": null,
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:43.19,44.90",
    "count": 6,
    "city": "Surkhakhi",
    "county": "Nazranovsky District",
    "state": "Ingushetia",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:41.80,12.60",
    "count": 6,
    "city": "Ciampino",
    "county": null,
    "state": "Rome",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:27.71,85.32",
    "count": 6,
    "city": "Kathmandu",
    "county": "Kathmandu",
    "state": "Bagmati Province",
    "state_district": null,
    "country": "Nepal"
  },
  {
    "location": "geo:36.85,-75.98",
    "count": 6,
    "city": "Virginia Beach",
    "county": "City of Virginia Beach",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.14,10.39",
    "count": 6,
    "city": "Salzgitter",
    "county": null,
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:46.59,7.91",
    "count": 6,
    "city": "Lauterbrunnen",
    "county": "Interlaken-Oberhasli administrative district",
    "state": "Bern",
    "state_district": "Oberland administrative region",
    "country": "Switzerland"
  },
  {
    "location": "geo:43.83,-79.56",
    "count": 6,
    "city": "Vaughan",
    "county": "York",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:55.27,9.90",
    "count": 6,
    "city": "Assens Municipality",
    "county": null,
    "state": "Region of Southern Denmark",
    "state_district": null,
    "country": "Denmark"
  },
  {
    "location": "geo:27.55,76.63",
    "count": 6,
    "city": null,
    "county": "Alwar Tehsil",
    "state": "Rajasthan",
    "state_district": "Alwar",
    "country": "India"
  },
  {
    "location": "geo:41.49,13.83",
    "count": 6,
    "city": "Cassino",
    "county": null,
    "state": "Frosinone",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:46.14,-122.94",
    "count": 6,
    "city": "Longview",
    "county": "Cowlitz County",
    "state": "Washington",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:18.34,81.88",
    "count": 6,
    "city": "Malakanagiri",
    "county": "Malkangiri",
    "state": "Odisha",
    "state_district": "Malkangiri",
    "country": "India"
  },
  {
    "location": "geo:44.28,-76.72",
    "count": 6,
    "city": "Loyalist",
    "county": null,
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:52.63,10.08",
    "count": 6,
    "city": "Celle",
    "county": "Landkreis Celle",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.85,8.29",
    "count": 6,
    "city": "Rheda-Wiedenbrück",
    "county": "Kreis Gütersloh",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:50.09,9.06",
    "count": 6,
    "city": "Alzenau",
    "county": "Landkreis Aschaffenburg",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:28.98,77.71",
    "count": 6,
    "city": "Meerut",
    "county": "Meerut",
    "state": "Uttar Pradesh",
    "state_district": "Meerut",
    "country": "India"
  },
  {
    "location": "geo:7.49,4.55",
    "count": 6,
    "city": "Ife",
    "county": "Ife Central",
    "state": "Osun State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:10.28,11.17",
    "count": 6,
    "city": null,
    "county": "Gombe",
    "state": "Gombe State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:27.22,77.50",
    "count": 6,
    "city": "Bharatpur",
    "county": "Bharatpur Tehsil",
    "state": "Rajasthan",
    "state_district": "Bharatpur",
    "country": "India"
  },
  {
    "location": "geo:36.69,128.13",
    "count": 6,
    "city": null,
    "county": "Mungyeong",
    "state": "Gyeongsangbuk-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:33.45,-112.07",
    "count": 6,
    "city": "Phoenix",
    "county": "Maricopa County",
    "state": "Arizona",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.12,8.68",
    "count": 6,
    "city": "Herford",
    "county": "Kreis Herford",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:30.96,76.79",
    "count": 6,
    "city": "Baddi",
    "county": "Baddi",
    "state": "Himachal Pradesh",
    "state_district": "Solan",
    "country": "India"
  },
  {
    "location": "geo:52.11,11.61",
    "count": 6,
    "city": "Magdeburg",
    "county": null,
    "state": "Saxony-Anhalt",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:39.61,-77.70",
    "count": 5,
    "city": "Hagerstown",
    "county": "Washington County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:32.76,-111.67",
    "count": 5,
    "city": "Arizona City",
    "county": "Pinal County",
    "state": "Arizona",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.08,74.80",
    "count": 5,
    "city": "Srinagar",
    "county": "Srinagar (South)",
    "state": "Jammu and Kashmir",
    "state_district": "Srinagar",
    "country": "India"
  },
  {
    "location": "geo:53.24,7.47",
    "count": 5,
    "city": "Leer (Ostfriesland)",
    "county": "Landkreis Leer",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:32.61,-85.48",
    "count": 5,
    "city": "Auburn",
    "county": "Lee County",
    "state": "Alabama",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.26,-85.16",
    "count": 5,
    "city": "Rome",
    "county": "Floyd County",
    "state": "Georgia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:5.33,-4.02",
    "count": 5,
    "city": "Abidjan",
    "county": null,
    "state": "Abidjan",
    "state_district": null,
    "country": "Côte d'Ivoire"
  },
  {
    "location": "geo:53.34,-1.27",
    "count": 5,
    "city": "Sheffield",
    "county": null,
    "state": "England",
    "state_district": "South Yorkshire",
    "country": "United Kingdom"
  },
  {
    "location": "geo:32.19,74.19",
    "count": 5,
    "city": "Gujujranwala City Tehsil",
    "county": null,
    "state": "Punjab",
    "state_district": "Gujranwala Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:-20.40,-43.51",
    "count": 5,
    "city": "Ouro Preto",
    "county": null,
    "state": "Minas Gerais",
    "state_district": "Região Geográfica Intermediária de Belo Horizonte",
    "country": "Brazil"
  },
  {
    "location": "geo:14.96,76.45",
    "count": 5,
    "city": "Hire Kereyagina Halli",
    "county": "Sanduru taluk",
    "state": "Karnataka",
    "state_district": "Ballari",
    "country": "India"
  },
  {
    "location": "geo:-34.72,-58.25",
    "count": 5,
    "city": "Quilmes",
    "county": null,
    "state": "Buenos Aires",
    "state_district": "Partido de Quilmes",
    "country": "Argentina"
  },
  {
    "location": "geo:34.75,113.62",
    "count": 5,
    "city": "Zhongyuan District",
    "county": null,
    "state": "Henan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:-8.52,179.20",
    "count": 5,
    "city": "Funafuti",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Tuvalu"
  },
  {
    "location": "geo:35.03,10.96",
    "count": 5,
    "city": "Hazeg",
    "county": "‫حزق‬",
    "state": "Sfax",
    "state_district": "معتمدية جبنيانة",
    "country": "Tunisia"
  },
  {
    "location": "geo:31.85,-102.37",
    "count": 5,
    "city": "Odessa",
    "county": "Ector County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:31.82,75.38",
    "count": 5,
    "city": "Qadian",
    "county": "Batala Tahsil",
    "state": "Punjab",
    "state_district": "Gurdaspur",
    "country": "India"
  },
  {
    "location": "geo:53.80,-1.55",
    "count": 5,
    "city": "Leeds",
    "county": null,
    "state": "England",
    "state_district": "West Yorkshire",
    "country": "United Kingdom"
  },
  {
    "location": "geo:53.87,10.69",
    "count": 5,
    "city": "Lübeck",
    "county": null,
    "state": "Schleswig-Holstein",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:43.09,-79.08",
    "count": 5,
    "city": "Niagara Falls",
    "county": "Niagara",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:8.70,77.45",
    "count": 5,
    "city": "Ambasamudram",
    "county": "Ambasamudram",
    "state": "Tamil Nadu",
    "state_district": "Tirunelveli",
    "country": "India"
  },
  {
    "location": "geo:51.89,10.18",
    "count": 5,
    "city": "Seesen",
    "county": "Landkreis Goslar",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:30.87,-83.55",
    "count": 5,
    "city": "Morven",
    "county": "Brooks County",
    "state": "Georgia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:51.88,5.59",
    "count": 5,
    "city": "Druten",
    "county": null,
    "state": "Gelderland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:51.82,68.36",
    "count": 5,
    "city": "Atbasar",
    "county": "Atbasar District",
    "state": "Akmola Region",
    "state_district": null,
    "country": "Kazakhstan"
  },
  {
    "location": "geo:16.01,108.22",
    "count": 5,
    "city": "Da Nang",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:51.69,4.44",
    "count": 5,
    "city": "Moerdijk",
    "county": null,
    "state": "North Brabant",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:36.14,-5.46",
    "count": 5,
    "city": "Algeciras",
    "county": "Campo de Gibraltar",
    "state": "Andalusia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:36.36,6.64",
    "count": 5,
    "city": "Constantine",
    "county": "Constantine District",
    "state": "Constantine",
    "state_district": null,
    "country": "Algeria"
  },
  {
    "location": "geo:36.47,140.09",
    "count": 5,
    "city": null,
    "county": "Mashiko",
    "state": "Tochigi Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:54.60,-5.93",
    "count": 5,
    "city": "Belfast",
    "county": "County Antrim",
    "state": "Northern Ireland",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:20.95,72.95",
    "count": 5,
    "city": "Navsari",
    "county": "Navsari Taluka",
    "state": "Gujarat",
    "state_district": "Navsari",
    "country": "India"
  },
  {
    "location": "geo:28.57,-81.59",
    "count": 5,
    "city": "Winter Garden",
    "county": "Orange County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:14.57,121.08",
    "count": 5,
    "city": "Pasig",
    "county": null,
    "state": null,
    "state_district": "Eastern Manila District",
    "country": "Philippines"
  },
  {
    "location": "geo:28.49,-80.58",
    "count": 5,
    "city": "Patrick Afb",
    "county": "Brevard County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:55.51,11.29",
    "count": 5,
    "city": "Kalundborg",
    "county": null,
    "state": "Region Zealand",
    "state_district": null,
    "country": "Denmark"
  },
  {
    "location": "geo:1.45,103.60",
    "count": 5,
    "city": "Iskandar Puteri",
    "county": null,
    "state": "Johor",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:28.23,112.94",
    "count": 5,
    "city": "Changsha",
    "county": null,
    "state": "Hunan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:27.81,69.58",
    "count": 5,
    "city": "Daharki Taluka",
    "county": "Ghotki District",
    "state": "Sindh",
    "state_district": "Sukkur Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:-27.78,-64.26",
    "count": 5,
    "city": "Santiago del Estero",
    "county": null,
    "state": "Santiago del Estero",
    "state_district": "Departamento Juan Francisco Borges",
    "country": "Argentina"
  },
  {
    "location": "geo:56.33,44.01",
    "count": 5,
    "city": "Nizhny Novgorod",
    "county": "Nizhny Novgorod",
    "state": "Nizhny Novgorod Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:14.46,75.92",
    "count": 5,
    "city": "Davanagere",
    "county": "Davanagere Taluku",
    "state": "Karnataka",
    "state_district": "Davanagere",
    "country": "India"
  },
  {
    "location": "geo:27.57,80.68",
    "count": 5,
    "city": "Sitapur",
    "county": "Sitapur",
    "state": "Uttar Pradesh",
    "state_district": "Sitapur",
    "country": "India"
  },
  {
    "location": "geo:37.46,121.45",
    "count": 5,
    "city": "Laishan District",
    "county": null,
    "state": "Shandong",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:56.54,21.05",
    "count": 5,
    "city": "Liepaja",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Latvia"
  },
  {
    "location": "geo:50.97,-0.79",
    "count": 5,
    "city": "Chichester",
    "county": "West Sussex",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:37.78,127.05",
    "count": 5,
    "city": "Yangju-Si",
    "county": "Yangju",
    "state": "Kyeongki-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:26.94,-82.03",
    "count": 5,
    "city": "Punta Gorda",
    "county": "Charlotte County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:26.93,-82.36",
    "count": 5,
    "city": "Englewood",
    "county": "Charlotte County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:50.96,16.35",
    "count": 5,
    "city": "Strzegom",
    "county": "Świdnica County",
    "state": "Lower Silesian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:57.99,31.35",
    "count": 5,
    "city": "Staraya Russa",
    "county": "Starorussky District",
    "state": "Novgorod Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:3.78,9.98",
    "count": 5,
    "city": "Dizangué",
    "county": "Sanaga-Maritime",
    "state": "Littoral",
    "state_district": null,
    "country": "Cameroon"
  },
  {
    "location": "geo:37.92,-122.31",
    "count": 5,
    "city": "El Cerrito",
    "county": "Contra Costa County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:26.69,76.92",
    "count": 5,
    "city": "Shri Mahaveerji",
    "county": "Hindaun Tehsil",
    "state": "Rajasthan",
    "state_district": "Karauli",
    "country": "India"
  },
  {
    "location": "geo:21.07,106.33",
    "count": 5,
    "city": null,
    "county": "Nam Sách District",
    "state": "Hải Dương Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:26.49,89.53",
    "count": 5,
    "city": "Alipurduar",
    "county": "Alipurduar - I",
    "state": "West Bengal",
    "state_district": "Alipurduar",
    "country": "India"
  },
  {
    "location": "geo:26.37,-80.13",
    "count": 5,
    "city": "Boca Raton",
    "county": "Palm Beach County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:21.16,81.43",
    "count": 5,
    "city": null,
    "county": "Patan Tahsil",
    "state": "Chhattisgarh",
    "state_district": "Durg",
    "country": "India"
  },
  {
    "location": "geo:59.37,16.51",
    "count": 5,
    "city": "Eskilstuna",
    "county": "Södermanland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:50.54,2.98",
    "count": 5,
    "city": "Lille",
    "county": "Nord",
    "state": "Hauts-de-France",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:38.56,68.79",
    "count": 5,
    "city": "Dushanbe",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Tajikistan"
  },
  {
    "location": "geo:19.04,-98.21",
    "count": 5,
    "city": "Puebla City",
    "county": "Municipio de Puebla",
    "state": "Puebla",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:50.26,10.96",
    "count": 5,
    "city": "Coburg",
    "county": null,
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:50.20,10.08",
    "count": 5,
    "city": "Bad Kissingen",
    "county": "Landkreis Bad Kissingen",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-25.59,-49.40",
    "count": 5,
    "city": "Araucária",
    "county": "Região Metropolitana de Curitiba",
    "state": "Paraná",
    "state_district": "Região Geográfica Intermediária de Curitiba",
    "country": "Brazil"
  },
  {
    "location": "geo:-25.52,-54.59",
    "count": 5,
    "city": "Foz do Iguaçu",
    "county": null,
    "state": "Paraná",
    "state_district": "Região Geográfica Intermediária de Cascavel",
    "country": "Brazil"
  },
  {
    "location": "geo:38.86,-77.77",
    "count": 5,
    "city": "The Plains",
    "county": "Fauquier County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.91,1.42",
    "count": 5,
    "city": "Ibiza",
    "county": "Ibiza",
    "state": "Balearic Islands",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:38.92,-78.19",
    "count": 5,
    "city": "Lake Frederick",
    "county": "Warren County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:60.80,8.89",
    "count": 5,
    "city": "Syno",
    "county": "Buskerud",
    "state": null,
    "state_district": null,
    "country": "Norway"
  },
  {
    "location": "geo:25.24,86.98",
    "count": 5,
    "city": "Bhagalpur",
    "county": "Jagdishpur",
    "state": "Bihar",
    "state_district": "Bhagalpur",
    "country": "India"
  },
  {
    "location": "geo:-6.17,106.76",
    "count": 5,
    "city": "Special capital Region of Jakarta",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:25.10,89.02",
    "count": 5,
    "city": "Joypurhat",
    "county": "Joypurhat Sadar Upazila",
    "state": "Rajshahi Division",
    "state_district": "Joypurhat District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:39.08,-77.15",
    "count": 5,
    "city": "Rockville",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:46.61,13.85",
    "count": 5,
    "city": "Villach",
    "county": null,
    "state": "Carinthia",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:49.61,6.13",
    "count": 5,
    "city": "Luxembourg",
    "county": "Canton Luxembourg",
    "state": null,
    "state_district": null,
    "country": "Luxembourg"
  },
  {
    "location": "geo:39.14,-77.20",
    "count": 5,
    "city": "Gaithersburg",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:24.59,88.27",
    "count": 5,
    "city": "Chapainawabganj",
    "county": "Nawabganj Upazila",
    "state": "Rajshahi Division",
    "state_district": "Chapai Nawabganj District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:24.51,75.75",
    "count": 5,
    "city": "Bhanpura",
    "county": "Bhanpura Tahsil",
    "state": "Madhya Pradesh",
    "state_district": "Mandsaur",
    "country": "India"
  },
  {
    "location": "geo:24.41,88.98",
    "count": 5,
    "city": "Natore",
    "county": "Natore Sadar Upazila",
    "state": "Rajshahi Division",
    "state_district": "Natore District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:24.34,86.01",
    "count": 5,
    "city": null,
    "county": "Dhanwar",
    "state": "Jharkhand",
    "state_district": "Giridih",
    "country": "India"
  },
  {
    "location": "geo:49.48,8.44",
    "count": 5,
    "city": "Ludwigshafen am Rhein",
    "county": null,
    "state": "Rhineland-Palatinate",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:39.42,-77.32",
    "count": 5,
    "city": "New Market",
    "county": "Frederick County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.44,27.87",
    "count": 5,
    "city": "Altıeylül",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:49.44,10.96",
    "count": 5,
    "city": "Zirndorf",
    "county": "Fürth (district)",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:39.60,-76.94",
    "count": 5,
    "city": "Westminster",
    "county": "Carroll County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:6.46,-10.63",
    "count": 5,
    "city": "Crozierville",
    "county": "Careysburg",
    "state": "Montserrado County",
    "state_district": null,
    "country": "Liberia"
  },
  {
    "location": "geo:47.05,8.31",
    "count": 5,
    "city": "Lucerne",
    "county": null,
    "state": "Lucerne",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:49.39,13.04",
    "count": 5,
    "city": "Kdyně",
    "county": "Plzeň Region",
    "state": "Southwest",
    "state_district": null,
    "country": "Czechia"
  },
  {
    "location": "geo:49.38,20.13",
    "count": 5,
    "city": "gmina Bukowina Tatrzańska",
    "county": "Tatra County",
    "state": "Lesser Poland Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:16.74,-92.64",
    "count": 5,
    "city": "San Cristóbal",
    "county": "San Cristóbal de las Casas",
    "state": "Chiapas",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:13.55,99.89",
    "count": 5,
    "city": "Lum Din",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Thailand"
  },
  {
    "location": "geo:39.96,-83.00",
    "count": 5,
    "city": "Columbus",
    "county": "Franklin County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-23.50,-46.88",
    "count": 5,
    "city": "Barueri",
    "county": "Região Metropolitana de São Paulo",
    "state": "São Paulo",
    "state_district": "Região Geográfica Intermediária de São Paulo",
    "country": "Brazil"
  },
  {
    "location": "geo:6.93,79.86",
    "count": 5,
    "city": "Colombo",
    "county": null,
    "state": "Western Province",
    "state_district": "Colombo District",
    "country": "Sri Lanka"
  },
  {
    "location": "geo:40.24,-78.09",
    "count": 5,
    "city": "Todd Township",
    "county": "Huntingdon County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.85,-93.92",
    "count": 5,
    "city": "Chapple Township",
    "county": "Rainy River District",
    "state": "Ontario",
    "state_district": "Northwestern Ontario",
    "country": "Canada"
  },
  {
    "location": "geo:40.34,-76.41",
    "count": 5,
    "city": "Lebanon",
    "county": "Lebanon County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-7.78,113.20",
    "count": 5,
    "city": "Kota Probolinggo",
    "county": null,
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-7.15,111.89",
    "count": 5,
    "city": "Bojonegoro",
    "county": "Bojonegoro",
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:40.52,-74.41",
    "count": 5,
    "city": "Edison",
    "county": "Middlesex County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.59,-105.08",
    "count": 5,
    "city": "Fort Collins",
    "county": "Larimer County",
    "state": "Colorado",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.71,9.00",
    "count": 5,
    "city": "Sindelfingen (Stadt)",
    "county": "Landkreis Böblingen",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:48.65,-93.98",
    "count": 5,
    "city": "Chapple Township",
    "county": "Rainy River District",
    "state": "Ontario",
    "state_district": "Northwestern Ontario",
    "country": "Canada"
  },
  {
    "location": "geo:40.66,-74.21",
    "count": 5,
    "city": "Elizabeth",
    "county": "Union County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.52,9.06",
    "count": 5,
    "city": "Tübingen",
    "county": "Landkreis Tübingen",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:21.74,-78.79",
    "count": 5,
    "city": "Pueblo de Venezuela",
    "county": null,
    "state": "Ciego de Avila",
    "state_district": null,
    "country": "Cuba"
  },
  {
    "location": "geo:40.82,140.75",
    "count": 5,
    "city": "Aomori",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:18.13,76.75",
    "count": 5,
    "city": "Nilanga",
    "county": "Nilanga",
    "state": "Maharashtra",
    "state_district": "Latur District",
    "country": "India"
  },
  {
    "location": "geo:45.13,-72.80",
    "count": 5,
    "city": "Dunham",
    "county": "Brome-Missisquoi",
    "state": "Quebec",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:48.40,11.74",
    "count": 5,
    "city": "Freising",
    "county": "Landkreis Freising",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:45.52,26.64",
    "count": 5,
    "city": "Jghiab",
    "county": "Buzău",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:44.74,-73.13",
    "count": 5,
    "city": "St. Albans",
    "county": "Franklin County",
    "state": "Vermont",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:45.86,15.80",
    "count": 5,
    "city": "Zaprešić",
    "county": "Zagreb County",
    "state": null,
    "state_district": null,
    "country": "Croatia"
  },
  {
    "location": "geo:48.06,8.46",
    "count": 5,
    "city": "Villingen-Schwenningen",
    "county": "Schwarzwald-Baar-Kreis",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:47.88,10.63",
    "count": 5,
    "city": "Kaufbeuren",
    "county": null,
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:22.34,87.34",
    "count": 5,
    "city": "Kharagpur",
    "county": "Kharagpur-I",
    "state": "West Bengal",
    "state_district": "Paschim Medinipur",
    "country": "India"
  },
  {
    "location": "geo:43.85,10.67",
    "count": 5,
    "city": "Montecarlo",
    "county": null,
    "state": "Lucca",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:-4.18,-38.13",
    "count": 5,
    "city": "Beberibe",
    "county": null,
    "state": "Ceará",
    "state_district": "Região Geográfica Intermediária de Fortaleza",
    "country": "Brazil"
  },
  {
    "location": "geo:43.80,131.94",
    "count": 5,
    "city": "Ussurijsk",
    "county": "Уссурийский городской округ",
    "state": "Primorsky Krai",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:41.98,2.82",
    "count": 5,
    "city": "Girona",
    "county": null,
    "state": "Girona",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:46.20,-59.96",
    "count": 5,
    "city": null,
    "county": "Cape Breton",
    "state": "Nova Scotia",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:47.79,9.61",
    "count": 5,
    "city": "Ravensburg",
    "county": "Landkreis Ravensburg",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:47.69,17.65",
    "count": 5,
    "city": "Gyor",
    "county": "Győr-Moson-Sopron",
    "state": null,
    "state_district": null,
    "country": "Hungary"
  },
  {
    "location": "geo:43.67,-70.28",
    "count": 5,
    "city": "Portland",
    "county": "Cumberland County",
    "state": "Maine",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:46.23,-1.54",
    "count": 5,
    "city": "La Rochelle",
    "county": "Charente-Maritime",
    "state": "Nouvelle-Aquitaine",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:12.90,31.23",
    "count": 5,
    "city": "Umm Ruwaba",
    "county": "Um Rawaba",
    "state": "North Kordofan State",
    "state_district": null,
    "country": "Sudan"
  },
  {
    "location": "geo:46.52,-95.38",
    "count": 5,
    "city": "New York Mills",
    "county": "Otter Tail County",
    "state": "Minnesota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.63,7.91",
    "count": 5,
    "city": "Wehr",
    "county": "Landkreis Waldshut",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:46.54,-102.87",
    "count": 5,
    "city": "New England",
    "county": "Hettinger County",
    "state": "North Dakota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:12.84,79.70",
    "count": 5,
    "city": "Kanchipuram",
    "county": null,
    "state": "Tamil Nadu",
    "state_district": null,
    "country": "India"
  },
  {
    "location": "geo:42.63,-83.03",
    "count": 5,
    "city": "Shelby",
    "county": "Macomb County",
    "state": "Michigan",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.41,8.40",
    "count": 5,
    "city": "Dietikon",
    "county": "Bezirk Dietikon",
    "state": "Zurich",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:43.46,-80.52",
    "count": 5,
    "city": "Waterloo",
    "county": "Waterloo",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:47.39,0.72",
    "count": 5,
    "city": "Saint-Pierre-des-Corps",
    "county": "Indre-et-Loire",
    "state": "Centre-Val de Loire",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:52.61,-8.34",
    "count": 5,
    "city": "The Municipal District of Cappamore — Kilmallock",
    "county": "County Limerick",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:52.75,13.47",
    "count": 5,
    "city": "Wandlitz",
    "county": "Barnim",
    "state": "Brandenburg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-15.42,28.28",
    "count": 5,
    "city": "Lusaka",
    "county": null,
    "state": "Lusaka Province",
    "state_district": "Lusaka District",
    "country": "Zambia"
  },
  {
    "location": "geo:52.84,13.79",
    "count": 5,
    "city": "Eberswalde 2",
    "county": "Barnim",
    "state": "Brandenburg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:59.40,13.51",
    "count": 4,
    "city": "Karlstad",
    "county": "Värmland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:50.40,8.08",
    "count": 4,
    "city": "Limburg an der Lahn",
    "county": "Landkreis Limburg-Weilburg",
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:48.68,29.25",
    "count": 4,
    "city": "Ladyzhyn",
    "county": null,
    "state": "Vinnytsia Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:-20.15,28.59",
    "count": 4,
    "city": "Bulawayo",
    "county": "Bulawayo",
    "state": "Bulawayo Province",
    "state_district": null,
    "country": "Zimbabwe"
  },
  {
    "location": "geo:59.38,28.18",
    "count": 4,
    "city": "Narva",
    "county": "Ida-Viru County",
    "state": null,
    "state_district": null,
    "country": "Estonia"
  },
  {
    "location": "geo:26.12,91.71",
    "count": 4,
    "city": "Guwahati",
    "county": "Guwahati",
    "state": "Assam",
    "state_district": "Kamrup Metropolitan",
    "country": "India"
  },
  {
    "location": "geo:30.68,-82.57",
    "count": 4,
    "city": "Fargo",
    "county": "Clinch County",
    "state": "Georgia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.50,43.37",
    "count": 4,
    "city": "Van",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:31.33,37.36",
    "count": 4,
    "city": "Al Qurayyat",
    "county": null,
    "state": "Al Jawf Region",
    "state_district": null,
    "country": "Saudi Arabia"
  },
  {
    "location": "geo:22.70,88.10",
    "count": 4,
    "city": null,
    "county": "Jangipara",
    "state": "West Bengal",
    "state_district": "Hooghly",
    "country": "India"
  },
  {
    "location": "geo:22.67,106.26",
    "count": 4,
    "city": "Cao Bằng",
    "county": null,
    "state": "Cao Bằng Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:22.63,120.30",
    "count": 4,
    "city": "Kaohsiung",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Taiwan"
  },
  {
    "location": "geo:26.14,-81.79",
    "count": 4,
    "city": "Naples",
    "county": "Collier County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:26.26,82.07",
    "count": 4,
    "city": "Sultanpur",
    "county": "Sultanpur",
    "state": "Uttar Pradesh",
    "state_district": "Sultanpur",
    "country": "India"
  },
  {
    "location": "geo:16.58,121.29",
    "count": 4,
    "city": "Baretbet",
    "county": null,
    "state": "Nueva Vizcaya",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:45.40,-71.89",
    "count": 4,
    "city": "Sherbrooke",
    "county": "Sherbrooke",
    "state": "Quebec",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:52.20,9.08",
    "count": 4,
    "city": "Rinteln",
    "county": "Landkreis Schaumburg",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:50.72,12.50",
    "count": 4,
    "city": "Zwickau-Mitte",
    "county": "Zwickau",
    "state": "Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:59.34,18.08",
    "count": 4,
    "city": "Stockholm",
    "county": "Stockholm County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:21.75,70.95",
    "count": 4,
    "city": null,
    "county": "Gondal Taluka",
    "state": "Gujarat",
    "state_district": "Rajkot",
    "country": "India"
  },
  {
    "location": "geo:5.91,-4.01",
    "count": 4,
    "city": "Bécédi-Brignan",
    "county": null,
    "state": "Lagunes",
    "state_district": "Mé",
    "country": "Côte d'Ivoire"
  },
  {
    "location": "geo:31.33,75.58",
    "count": 4,
    "city": "Jalandhar",
    "county": "Jalandhar I Tahsil",
    "state": "Punjab",
    "state_district": "Jalandhar",
    "country": "India"
  },
  {
    "location": "geo:-22.11,-41.47",
    "count": 4,
    "city": "Quissamã",
    "county": null,
    "state": "Rio de Janeiro",
    "state_district": "Região Geográfica Intermediária de Macaé-Rio das Ostras-Cabo Frio",
    "country": "Brazil"
  },
  {
    "location": "geo:19.27,73.37",
    "count": 4,
    "city": "Murbad",
    "county": "Murbad Taluka",
    "state": "Maharashtra",
    "state_district": "Thane",
    "country": "India"
  },
  {
    "location": "geo:34.68,33.04",
    "count": 4,
    "city": "Limassol",
    "county": "Limassol Municipality",
    "state": "Cyprus",
    "state_district": "Limassol District",
    "country": "Cyprus"
  },
  {
    "location": "geo:10.24,77.50",
    "count": 4,
    "city": "Kodaikanal",
    "county": "Kodaikanal",
    "state": "Tamil Nadu",
    "state_district": "Dindigul",
    "country": "India"
  },
  {
    "location": "geo:45.02,-76.37",
    "count": 4,
    "city": "Lanark Highlands",
    "county": "Lanark",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:21.06,86.50",
    "count": 4,
    "city": "Bhadrak",
    "county": "Bhadrak Rural",
    "state": "Odisha",
    "state_district": "Bhadrak",
    "country": "India"
  },
  {
    "location": "geo:50.74,7.10",
    "count": 4,
    "city": "Bonn",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:48.50,36.57",
    "count": 4,
    "city": "Українська сільська громада",
    "county": null,
    "state": "Dnipropetrovsk Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:-38.14,176.24",
    "count": 4,
    "city": "Rotorua",
    "county": "Rotorua District",
    "state": "Bay of Plenty Region",
    "state_district": null,
    "country": "New Zealand"
  },
  {
    "location": "geo:43.05,-76.15",
    "count": 4,
    "city": "Syracuse",
    "county": "Onondaga County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.06,-81.08",
    "count": 4,
    "city": "Fayetteville",
    "county": "Fayette County",
    "state": "West Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.05,15.44",
    "count": 4,
    "city": "Itala",
    "county": "Messina",
    "state": "Sicily",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:38.04,-84.52",
    "count": 4,
    "city": "Lexington-Fayette",
    "county": "Fayette County",
    "state": "Kentucky",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:46.80,26.69",
    "count": 4,
    "city": "Români",
    "county": "Neamț",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:41.05,39.28",
    "count": 4,
    "city": "Vakfıkebir",
    "county": null,
    "state": "Trabzon",
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:26.64,84.90",
    "count": 4,
    "city": "Motihari",
    "county": "Motihari",
    "state": "Bihar",
    "state_district": "East Champaran",
    "country": "India"
  },
  {
    "location": "geo:43.07,-89.40",
    "count": 4,
    "city": "Madison",
    "county": "Dane County",
    "state": "Wisconsin",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-8.59,-35.11",
    "count": 4,
    "city": "Sirinhaém",
    "county": null,
    "state": "Pernambuco",
    "state_district": "Região Geográfica Intermediária do Recife",
    "country": "Brazil"
  },
  {
    "location": "geo:38.03,-78.48",
    "count": 4,
    "city": "Charlottesville",
    "county": "City of Charlottesville",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:26.65,-80.28",
    "count": 4,
    "city": "Wellington",
    "county": "Palm Beach County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:50.81,19.12",
    "count": 4,
    "city": "Częstochowa",
    "county": null,
    "state": "Silesian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:26.66,-80.27",
    "count": 4,
    "city": "Wellington",
    "county": "Palm Beach County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:44.84,-0.58",
    "count": 4,
    "city": "Bordeaux",
    "county": "Bordeaux",
    "state": "Gironde",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:35.35,129.04",
    "count": 4,
    "city": null,
    "county": "Yangsan",
    "state": "Gyeongsangnam-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:21.90,77.90",
    "count": 4,
    "city": "Betul",
    "county": "Betul Tahsil",
    "state": "Madhya Pradesh",
    "state_district": "Betul",
    "country": "India"
  },
  {
    "location": "geo:50.89,12.08",
    "count": 4,
    "city": "Gera",
    "county": null,
    "state": "Thuringia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:52.26,5.04",
    "count": 4,
    "city": "Wijdemeren",
    "county": null,
    "state": "North Holland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:41.22,-115.28",
    "count": 4,
    "city": null,
    "county": "Elko County",
    "state": "Nevada",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:11.05,106.76",
    "count": 4,
    "city": "Tân Uyên",
    "county": null,
    "state": "Bình Dương Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:50.93,11.59",
    "count": 4,
    "city": "Jena",
    "county": null,
    "state": "Thuringia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:41.26,-74.36",
    "count": 4,
    "city": "Warwick",
    "county": "Orange County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:44.58,11.36",
    "count": 4,
    "city": "Progresso",
    "county": null,
    "state": "Bologna",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:-8.09,111.96",
    "count": 4,
    "city": "Bendiljati Kulon",
    "county": "Tulungagung",
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:5.70,-55.22",
    "count": 4,
    "city": "Lelydorp",
    "county": "Wanica",
    "state": "Wanica",
    "state_district": null,
    "country": "Suriname"
  },
  {
    "location": "geo:26.99,48.38",
    "count": 4,
    "city": "As Sarrar",
    "county": null,
    "state": "Eastern Province",
    "state_district": null,
    "country": "Saudi Arabia"
  },
  {
    "location": "geo:-14.27,-170.70",
    "count": 4,
    "city": "Maʻopūtasi County",
    "county": "Eastern District",
    "state": "American Samoa",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:27.31,88.67",
    "count": 4,
    "city": "Gangtok",
    "county": null,
    "state": "Sikkim",
    "state_district": "Gangtok",
    "country": "India"
  },
  {
    "location": "geo:7.24,38.61",
    "count": 4,
    "city": "Shashamane",
    "county": null,
    "state": "Oromia Region",
    "state_district": "West Arsi",
    "country": "Ethiopia"
  },
  {
    "location": "geo:45.82,20.32",
    "count": 4,
    "city": "City of Kikinda",
    "county": "North Banat Administrative District",
    "state": "Vojvodina",
    "state_district": null,
    "country": "Serbia"
  },
  {
    "location": "geo:46.58,97.65",
    "count": 4,
    "city": null,
    "county": "Khüreemaral",
    "state": "Bayankhongor",
    "state_district": null,
    "country": "Mongolia"
  },
  {
    "location": "geo:56.49,84.95",
    "count": 4,
    "city": "Tomsk",
    "county": "городской округ Томск",
    "state": "Tomsk Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:51.08,-4.06",
    "count": 4,
    "city": "North Devon",
    "county": "Devon",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:31.52,34.60",
    "count": 4,
    "city": "Sderot",
    "county": null,
    "state": "South District",
    "state_district": "Ashkelon Subdistrict",
    "country": "Israel"
  },
  {
    "location": "geo:21.97,71.58",
    "count": 4,
    "city": "Ugamedi",
    "county": "Gadhada Taluka",
    "state": "Gujarat",
    "state_district": "Botad",
    "country": "India"
  },
  {
    "location": "geo:27.53,78.12",
    "count": 4,
    "city": "Hathras",
    "county": "Hathras",
    "state": "Uttar Pradesh",
    "state_district": "Hathras",
    "country": "India"
  },
  {
    "location": "geo:48.00,7.84",
    "count": 4,
    "city": "Freiburg im Breisgau",
    "county": null,
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:48.00,11.34",
    "count": 4,
    "city": "Starnberg",
    "county": "Landkreis Starnberg",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:37.37,-77.61",
    "count": 4,
    "city": "Chesterfield Court House",
    "county": "Chesterfield County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:19.30,72.85",
    "count": 4,
    "city": "Mira-Bhayander",
    "county": "Thane Taluka",
    "state": "Maharashtra",
    "state_district": "Thane",
    "country": "India"
  },
  {
    "location": "geo:41.54,1.87",
    "count": 4,
    "city": "Esparreguera",
    "county": null,
    "state": "Barcelona",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:41.59,-93.62",
    "count": 4,
    "city": "Des Moines",
    "county": "Polk County",
    "state": "Iowa",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-28.03,-48.62",
    "count": 4,
    "city": "Garopaba",
    "county": null,
    "state": "Santa Catarina",
    "state_district": null,
    "country": "Brazil"
  },
  {
    "location": "geo:28.28,-99.10",
    "count": 4,
    "city": null,
    "county": "La Salle County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.15,126.86",
    "count": 4,
    "city": "Gwangju",
    "county": "Seo",
    "state": "Gwangju",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:28.41,77.32",
    "count": 4,
    "city": "Faridabad",
    "county": "Faridabad",
    "state": "Haryana",
    "state_district": "Faridabad",
    "country": "India"
  },
  {
    "location": "geo:20.74,-88.13",
    "count": 4,
    "city": null,
    "county": "Valladolid",
    "state": "Yucatán",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:51.24,12.72",
    "count": 4,
    "city": "Grimma",
    "county": "Landkreis Leipzig",
    "state": "Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:43.91,12.91",
    "count": 4,
    "city": "Pesaro",
    "county": null,
    "state": "Pesaro E Urbino",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:51.25,6.98",
    "count": 4,
    "city": "Mettmann",
    "county": "Kreis Mettmann",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:32.65,74.21",
    "count": 4,
    "city": "Jalalpur Jattan",
    "county": "Gujrat District",
    "state": "Punjab",
    "state_district": "Gujrat Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:36.98,-121.90",
    "count": 4,
    "city": "Aptos",
    "county": "Santa Cruz County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-34.96,117.35",
    "count": 4,
    "city": "Denmark",
    "county": "Denmark",
    "state": "Western Australia",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:55.43,9.94",
    "count": 4,
    "city": "Middelfart Municipality",
    "county": null,
    "state": "Region of Southern Denmark",
    "state_district": null,
    "country": "Denmark"
  },
  {
    "location": "geo:12.92,79.13",
    "count": 4,
    "city": "Vellore",
    "county": "Vellore",
    "state": "Tamil Nadu",
    "state_district": "Vellore",
    "country": "India"
  },
  {
    "location": "geo:51.31,9.49",
    "count": 4,
    "city": "Kassel",
    "county": null,
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:41.96,-70.67",
    "count": 4,
    "city": "Plymouth",
    "county": "Plymouth County",
    "state": "Massachusetts",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.14,-0.47",
    "count": 4,
    "city": "Bedford",
    "county": "Bedford",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:55.21,-2.08",
    "count": 4,
    "city": "Benshaw Bog",
    "county": "Northumberland",
    "state": "England",
    "state_district": "North of Tyne",
    "country": "United Kingdom"
  },
  {
    "location": "geo:52.60,6.39",
    "count": 4,
    "city": "Hardenberg",
    "county": null,
    "state": "Overijssel",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:54.63,-5.85",
    "count": 4,
    "city": "Holywood",
    "county": "County Down",
    "state": "Northern Ireland",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:-42.88,147.33",
    "count": 4,
    "city": "Hobart",
    "county": "Hobart",
    "state": "Tasmania",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:51.46,7.02",
    "count": 4,
    "city": "Essen",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:52.27,10.52",
    "count": 4,
    "city": "Brunswick",
    "county": null,
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:33.83,-118.07",
    "count": 4,
    "city": "Hawaiian Gardens",
    "county": "Los Angeles County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.92,33.62",
    "count": 4,
    "city": "Larnaca",
    "county": "Δήμος Λάρνακας",
    "state": "Cyprus",
    "state_district": "Larnaca District",
    "country": "Cyprus"
  },
  {
    "location": "geo:-29.52,-51.18",
    "count": 4,
    "city": "Presidente Lucena",
    "county": null,
    "state": "Rio Grande do Sul",
    "state_district": "Região Geográfica Intermediária de Porto Alegre",
    "country": "Brazil"
  },
  {
    "location": "geo:-29.52,-68.56",
    "count": 4,
    "city": "Guandacol",
    "county": null,
    "state": "La Rioja",
    "state_district": "Coronel Felipe Varela",
    "country": "Argentina"
  },
  {
    "location": "geo:29.57,106.55",
    "count": 4,
    "city": "Yuzhong District",
    "county": null,
    "state": "Chongqing",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:36.20,138.25",
    "count": 4,
    "city": "Nagawa",
    "county": "Chiisagata County",
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:52.58,20.57",
    "count": 4,
    "city": "gmina Joniec",
    "county": "Płońsk County",
    "state": "Masovian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:46.20,6.14",
    "count": 4,
    "city": "Geneva",
    "county": null,
    "state": "Geneva",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:51.52,30.75",
    "count": 4,
    "city": "Slavutych",
    "county": null,
    "state": "Kyiv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:10.30,106.42",
    "count": 4,
    "city": "Xã Giao Long",
    "county": "Chau Thanh District",
    "state": "Bến Tre Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:54.32,10.12",
    "count": 4,
    "city": "Kiel",
    "county": null,
    "state": "Schleswig-Holstein",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:12.53,80.01",
    "count": 4,
    "city": "Maduranthakam",
    "county": null,
    "state": "Tamil Nadu",
    "state_district": "Chengalpattu",
    "country": "India"
  },
  {
    "location": "geo:51.54,10.16",
    "count": 4,
    "city": "Samtgemeinde Radolfshausen",
    "county": "Landkreis Göttingen",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:39.94,-82.01",
    "count": 4,
    "city": "Zanesville",
    "county": "Muskingum County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.30,23.54",
    "count": 4,
    "city": "Stebnyk",
    "county": null,
    "state": "Lviv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:51.55,46.02",
    "count": 4,
    "city": "Saratov",
    "county": "Saratov",
    "state": "Saratov Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:23.69,86.97",
    "count": 4,
    "city": "Asansol",
    "county": "Asansol Kulti Township",
    "state": "West Bengal",
    "state_district": "Paschim Bardhaman",
    "country": "India"
  },
  {
    "location": "geo:22.23,-97.86",
    "count": 4,
    "city": "Tampico",
    "county": "Tampico",
    "state": "Tamaulipas",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:42.98,-78.79",
    "count": 4,
    "city": "Buffalo",
    "county": "Erie County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:24.00,86.00",
    "count": 4,
    "city": "Dumri",
    "county": "Dumri",
    "state": "Jharkhand",
    "state_district": "Giridih",
    "country": "India"
  },
  {
    "location": "geo:40.01,-75.70",
    "count": 4,
    "city": "Downingtown",
    "county": "Chester County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:8.54,77.10",
    "count": 4,
    "city": null,
    "county": "Kattakkada",
    "state": "Kerala",
    "state_district": "Thiruvananthapuram",
    "country": "India"
  },
  {
    "location": "geo:39.37,-77.27",
    "count": 4,
    "city": "Monrovia",
    "county": "Frederick County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.29,-77.20",
    "count": 4,
    "city": "Damascus",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.26,-121.02",
    "count": 4,
    "city": "Nevada City",
    "county": "Nevada County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.10,-75.13",
    "count": 4,
    "city": "Jenkintown",
    "county": "Montgomery County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.76,127.66",
    "count": 4,
    "city": "Yongsu",
    "county": "Yeosu",
    "state": "Jeollanam-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:40.13,46.17",
    "count": 4,
    "city": null,
    "county": null,
    "state": null,
    "state_district": "Kalbajar District",
    "country": "Azerbaijan"
  },
  {
    "location": "geo:18.68,105.68",
    "count": 4,
    "city": "Vinh",
    "county": null,
    "state": "Nghệ An Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:32.07,72.69",
    "count": 4,
    "city": "Sargodha",
    "county": "Sargodha District",
    "state": "Punjab",
    "state_district": "Sargodha Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:49.59,34.55",
    "count": 4,
    "city": "Poltava",
    "county": null,
    "state": "Poltava Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:16.86,-99.88",
    "count": 4,
    "city": "Acapulco",
    "county": "Acapulco de Juárez",
    "state": "Guerrero",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:39.12,-0.45",
    "count": 4,
    "city": "Carcaixent",
    "county": null,
    "state": "Valencia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:24.98,55.40",
    "count": 4,
    "city": "Al Yufrah 4",
    "county": null,
    "state": "Dubai",
    "state_district": null,
    "country": "United Arab Emirates"
  },
  {
    "location": "geo:40.23,-76.14",
    "count": 4,
    "city": "Denver",
    "county": "Lancaster County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.04,-77.12",
    "count": 4,
    "city": "Rockville",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-7.35,108.13",
    "count": 4,
    "city": "Desa Cipakat",
    "county": "Tasikmalaya",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-6.17,39.20",
    "count": 4,
    "city": "Miembeni",
    "county": null,
    "state": "Zanzibar Urban/West",
    "state_district": "Mjini",
    "country": "Tanzania"
  },
  {
    "location": "geo:39.02,-77.21",
    "count": 4,
    "city": "Potomac",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:9.77,-74.55",
    "count": 4,
    "city": "Plato",
    "county": null,
    "state": "Magdalena",
    "state_district": null,
    "country": "Colombia"
  },
  {
    "location": "geo:-1.78,34.71",
    "count": 4,
    "city": "Koreri",
    "county": null,
    "state": "Mara Region",
    "state_district": "Serengeti",
    "country": "Tanzania"
  },
  {
    "location": "geo:35.96,-80.01",
    "count": 4,
    "city": "High Point",
    "county": "Guilford County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.96,-77.45",
    "count": 4,
    "city": "Sterling",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.99,8.42",
    "count": 4,
    "city": "Rüsselsheim am Main",
    "county": "Kreis Groß-Gerau",
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.67,39.19",
    "count": 4,
    "city": "Voronezh",
    "county": "Voronezh",
    "state": "Voronezh Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:60.80,25.73",
    "count": 4,
    "city": "Orimattila",
    "county": "Päijät-Häme",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:40.35,-75.03",
    "count": 4,
    "city": "New Hope",
    "county": "Bucks County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.64,30.97",
    "count": 4,
    "city": "Domanivka",
    "county": null,
    "state": "Mykolaiv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:32.71,44.28",
    "count": 4,
    "city": "Saddat al Hindiyah",
    "county": null,
    "state": "Babil Governorate",
    "state_district": null,
    "country": "Iraq"
  },
  {
    "location": "geo:-22.16,166.44",
    "count": 4,
    "city": "Dumbéa",
    "county": "South Province",
    "state": null,
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:1.35,103.82",
    "count": 4,
    "city": "Singapore",
    "county": "North Region",
    "state": null,
    "state_district": null,
    "country": "Singapore"
  },
  {
    "location": "geo:38.83,-77.20",
    "count": 4,
    "city": "Annandale",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:25.66,-100.40",
    "count": 4,
    "city": "San Pedro Garza García",
    "county": "San Pedro Garza García",
    "state": "Nuevo León",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:38.80,-77.61",
    "count": 4,
    "city": "Gainesville",
    "county": "Prince William County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:60.45,22.25",
    "count": 4,
    "city": "Turku",
    "county": "Southwest Finland",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:51.75,-1.26",
    "count": 4,
    "city": "Oxford",
    "county": "Oxfordshire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:25.83,89.68",
    "count": 4,
    "city": "Kurigram",
    "county": "Kurigram Sadar Upazila",
    "state": "Rangpur Division",
    "state_district": "Kurigram District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:25.92,89.45",
    "count": 4,
    "city": "Lalmonirhat",
    "county": "Lalmonirhat Sadar",
    "state": "Rangpur Division",
    "state_district": "লালমনিরহাট জেলা",
    "country": "Bangladesh"
  },
  {
    "location": "geo:54.22,-5.89",
    "count": 4,
    "city": "Newcastle",
    "county": "County Down",
    "state": "Northern Ireland",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:32.73,74.86",
    "count": 4,
    "city": "Jammu",
    "county": "Jammu",
    "state": "Jammu and Kashmir",
    "state_district": "Jammu",
    "country": "India"
  },
  {
    "location": "geo:50.59,27.64",
    "count": 3,
    "city": "Zviahel",
    "county": null,
    "state": "Zhytomyr Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:18.12,75.02",
    "count": 3,
    "city": "Indapur",
    "county": "Indapur",
    "state": "Maharashtra",
    "state_district": "Pune District",
    "country": "India"
  },
  {
    "location": "geo:18.40,-93.21",
    "count": 3,
    "city": "Paraíso",
    "county": "Paraíso",
    "state": "Tabasco",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:18.46,-69.94",
    "count": 3,
    "city": "Santo Domingo",
    "county": null,
    "state": "Distrito Nacional",
    "state_district": null,
    "country": "Dominican Republic"
  },
  {
    "location": "geo:18.94,-103.96",
    "count": 3,
    "city": "Ciudad de Armería",
    "county": "Armería",
    "state": "Colima",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:18.95,73.72",
    "count": 3,
    "city": null,
    "county": "Khed",
    "state": "Maharashtra",
    "state_district": "Pune District",
    "country": "India"
  },
  {
    "location": "geo:19.49,-155.91",
    "count": 3,
    "city": "Captain Cook",
    "county": "Hawaiʻi County",
    "state": "Hawaii",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-19.94,-44.05",
    "count": 3,
    "city": "Contagem",
    "county": "Região Metropolitana de Belo Horizonte",
    "state": "Minas Gerais",
    "state_district": "Região Geográfica Intermediária de Belo Horizonte",
    "country": "Brazil"
  },
  {
    "location": "geo:21.17,72.83",
    "count": 3,
    "city": null,
    "county": "Majura Taluka",
    "state": "Gujarat",
    "state_district": "Surat",
    "country": "India"
  },
  {
    "location": "geo:-21.76,-43.34",
    "count": 3,
    "city": "Juiz de Fora",
    "county": null,
    "state": "Minas Gerais",
    "state_district": "Região Geográfica Intermediária de Juiz de Fora",
    "country": "Brazil"
  },
  {
    "location": "geo:22.71,88.75",
    "count": 3,
    "city": null,
    "county": "Baduria",
    "state": "West Bengal",
    "state_district": "North 24 Parganas",
    "country": "India"
  },
  {
    "location": "geo:22.85,108.30",
    "count": 3,
    "city": "Nanning",
    "county": null,
    "state": "Guangxi",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:22.88,88.01",
    "count": 3,
    "city": "Tarkeshwar",
    "county": "Tarakeswar",
    "state": "West Bengal",
    "state_district": "Hooghly",
    "country": "India"
  },
  {
    "location": "geo:-23.57,-46.04",
    "count": 3,
    "city": "Biritiba Mirim",
    "county": "Região Metropolitana de São Paulo",
    "state": "São Paulo",
    "state_district": "Região Geográfica Intermediária de São Paulo",
    "country": "Brazil"
  },
  {
    "location": "geo:23.62,90.50",
    "count": 3,
    "city": "Narayanganj",
    "county": "Narayanganj Sadar Upazila",
    "state": "Dhaka Division",
    "state_district": "Narayanganj District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:23.97,87.69",
    "count": 3,
    "city": null,
    "county": "Mayureswar - II",
    "state": "West Bengal",
    "state_district": "Birbhum",
    "country": "India"
  },
  {
    "location": "geo:24.63,87.85",
    "count": 3,
    "city": "Pakaur",
    "county": "Pakaur",
    "state": "Jharkhand",
    "state_district": "Dumka",
    "country": "India"
  },
  {
    "location": "geo:24.69,108.09",
    "count": 3,
    "city": "Jinchengjiang",
    "county": null,
    "state": "Guangxi",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:24.71,46.68",
    "count": 3,
    "city": "Riyadh",
    "county": null,
    "state": "Riyadh Region",
    "state_district": null,
    "country": "Saudi Arabia"
  },
  {
    "location": "geo:25.14,90.19",
    "count": 3,
    "city": null,
    "county": "Nalitabari Upazila",
    "state": "Mymensingh Division",
    "state_district": "Sherpur District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:25.31,79.64",
    "count": 3,
    "city": "Kulpahar",
    "county": "Kulpahar",
    "state": "Uttar Pradesh",
    "state_district": "Mahoba",
    "country": "India"
  },
  {
    "location": "geo:25.94,88.84",
    "count": 3,
    "city": "Nilphamari",
    "county": "Nilphamari Sadar Upazila",
    "state": "Rangpur Division",
    "state_district": "Nilphamari District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:26.07,119.30",
    "count": 3,
    "city": "Taijiang District",
    "county": null,
    "state": "Fujian",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:26.07,-81.43",
    "count": 3,
    "city": null,
    "county": "Collier County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-26.21,28.03",
    "count": 3,
    "city": "Johannesburg",
    "county": "Johannesburg",
    "state": "Gauteng",
    "state_district": null,
    "country": "South Africa"
  },
  {
    "location": "geo:26.24,73.02",
    "count": 3,
    "city": "Jodhpur",
    "county": "Jodhpur Tehsil",
    "state": "Rajasthan",
    "state_district": "Jodhpur",
    "country": "India"
  },
  {
    "location": "geo:26.43,91.00",
    "count": 3,
    "city": null,
    "county": "Sarupeta (Pt)",
    "state": "Assam",
    "state_district": "Bajali",
    "country": "India"
  },
  {
    "location": "geo:-26.56,18.13",
    "count": 3,
    "city": "Keetmanshoop",
    "county": null,
    "state": "Karas",
    "state_district": null,
    "country": "Namibia"
  },
  {
    "location": "geo:26.75,92.10",
    "count": 3,
    "city": "Udalguri",
    "county": "Udalguri tehsil",
    "state": "Assam",
    "state_district": "Udalguri",
    "country": "India"
  },
  {
    "location": "geo:27.34,-82.53",
    "count": 3,
    "city": "Sarasota",
    "county": "Sarasota County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:27.95,-82.46",
    "count": 3,
    "city": "Tampa",
    "county": "Hillsborough County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:28.25,-81.28",
    "count": 3,
    "city": "St. Cloud",
    "county": "Osceola County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:28.64,75.38",
    "count": 3,
    "city": "sadulpur",
    "county": "Rajgarh Tehsil",
    "state": "Rajasthan",
    "state_district": "Churu",
    "country": "India"
  },
  {
    "location": "geo:28.84,78.77",
    "count": 3,
    "city": "Moradabad",
    "county": "Moradabad",
    "state": "Uttar Pradesh",
    "state_district": "Moradabad",
    "country": "India"
  },
  {
    "location": "geo:-28.85,151.17",
    "count": 3,
    "city": "Texas",
    "county": "Goondiwindi",
    "state": "Queensland",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:29.07,-110.96",
    "count": 3,
    "city": "Hermosillo",
    "county": "Hermosillo",
    "state": "Sonora",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:-2.94,-48.95",
    "count": 3,
    "city": "Tailândia",
    "county": null,
    "state": "Pará",
    "state_district": "Região Geográfica Intermediária de Belém",
    "country": "Brazil"
  },
  {
    "location": "geo:29.85,77.89",
    "count": 3,
    "city": "Roorkee",
    "county": "Roorkee",
    "state": "Uttarakhand",
    "state_district": "Haridwar",
    "country": "India"
  },
  {
    "location": "geo:30.31,-104.02",
    "count": 3,
    "city": "Marfa",
    "county": "Presidio County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:30.37,-9.53",
    "count": 3,
    "city": "Dcheira El Jihadia",
    "county": "Pachalik de Dcheïra El Jihadia",
    "state": null,
    "state_district": "Inezgane-Ait Melloul Prefecture",
    "country": "Morocco"
  },
  {
    "location": "geo:30.48,79.06",
    "count": 3,
    "city": "Nagjagai",
    "county": "Ukhimath",
    "state": "Uttarakhand",
    "state_district": "Rudraprayag",
    "country": "India"
  },
  {
    "location": "geo:30.57,-81.44",
    "count": 3,
    "city": "Fernandina Beach",
    "county": "Nassau County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:30.68,-95.65",
    "count": 3,
    "city": "Huntsville",
    "county": "Walker County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:31.00,31.63",
    "count": 3,
    "city": "Bani Ebeid",
    "county": null,
    "state": "Ad Dakahliya",
    "state_district": null,
    "country": "Egypt"
  },
  {
    "location": "geo:3.11,101.82",
    "count": 3,
    "city": "Kajang Municipal Council",
    "county": null,
    "state": "Selangor",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:31.77,35.21",
    "count": 3,
    "city": "Jerusalem",
    "county": null,
    "state": "Jerusalem District",
    "state_district": "Jerusalem Subdistrict",
    "country": "Israel"
  },
  {
    "location": "geo:31.87,-86.99",
    "count": 3,
    "city": "Pine Apple",
    "county": "Wilcox County",
    "state": "Alabama",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:32.03,-8.80",
    "count": 3,
    "city": "Jnane Bouih اجنان بويه",
    "county": "cercle de Ahmar",
    "state": null,
    "state_district": "Youssoufia Province",
    "country": "Morocco"
  },
  {
    "location": "geo:32.08,-81.09",
    "count": 3,
    "city": "Savannah",
    "county": "Chatham County",
    "state": "Georgia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-32.48,-58.24",
    "count": 3,
    "city": "Concepción del Uruguay",
    "county": "Distrito Molino",
    "state": "Entre Ríos Province",
    "state_district": "Departamento Uruguay",
    "country": "Argentina"
  },
  {
    "location": "geo:32.68,-115.50",
    "count": 3,
    "city": "Calexico",
    "county": "Imperial County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:15.56,-92.48",
    "count": 3,
    "city": "La Soledad",
    "county": "Honduras de la Sierra",
    "state": "Chiapas",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:-33.28,115.72",
    "count": 3,
    "city": "Australind",
    "county": "Harvey",
    "state": "Western Australia",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:34.04,-118.69",
    "count": 3,
    "city": "Malibu",
    "county": "Los Angeles County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.12,35.65",
    "count": 3,
    "city": "Jbeil",
    "county": null,
    "state": "Keserwan-Jbeil Governorate",
    "state_district": "Jbeil District",
    "country": "Lebanon"
  },
  {
    "location": "geo:34.14,-118.26",
    "count": 3,
    "city": "Glendale",
    "county": "Los Angeles County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.23,-5.71",
    "count": 3,
    "city": "Sidi Kacem",
    "county": "Pachalik de Sidi Kacem باشوية سيدي قاسم",
    "state": null,
    "state_district": "Sidi Kacem Province",
    "country": "Morocco"
  },
  {
    "location": "geo:34.66,133.92",
    "count": 3,
    "city": "Okayama",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:34.98,138.38",
    "count": 3,
    "city": "Shizuoka",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:14.84,-91.52",
    "count": 3,
    "city": "Municipio de Quetzaltenango",
    "county": null,
    "state": "Quetzaltenango",
    "state_district": null,
    "country": "Guatemala"
  },
  {
    "location": "geo:35.19,33.38",
    "count": 3,
    "city": "Nicosia",
    "county": "Nicosia Muncipality",
    "state": "Cyprus",
    "state_district": "Nicosia District",
    "country": "Cyprus"
  },
  {
    "location": "geo:35.84,129.21",
    "count": 3,
    "city": "Gyeongju-si",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:35.87,14.52",
    "count": 3,
    "city": "Fgura",
    "county": null,
    "state": "South Eastern Region",
    "state_district": null,
    "country": "Malta"
  },
  {
    "location": "geo:35.90,14.51",
    "count": 3,
    "city": "Valletta",
    "county": null,
    "state": "South Eastern Region",
    "state_district": null,
    "country": "Malta"
  },
  {
    "location": "geo:35.91,-100.38",
    "count": 3,
    "city": "Canadian",
    "county": "Hemphill County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:36.42,25.43",
    "count": 3,
    "city": "Thira Municipal Unit",
    "county": "Thira Regional Unit",
    "state": "Aegean",
    "state_district": "South Aegean",
    "country": "Greece"
  },
  {
    "location": "geo:36.70,137.86",
    "count": 3,
    "city": "Hakuba",
    "county": "Kita-Azumi County",
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:37.13,-89.12",
    "count": 3,
    "city": "Olmsted",
    "county": "Pulaski County",
    "state": "Illinois",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.51,-77.65",
    "count": 3,
    "city": "Midlothian",
    "county": "Chesterfield County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:14.21,121.16",
    "count": 3,
    "city": "Calamba",
    "county": null,
    "state": "Laguna",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:37.88,127.73",
    "count": 3,
    "city": "Chuncheon-Si",
    "county": "Chuncheon",
    "state": "Kangwon-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:37.90,29.19",
    "count": 3,
    "city": "Pamukkale",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:38.12,13.36",
    "count": 3,
    "city": "Palermo",
    "county": "Palermo",
    "state": "Sicily",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:38.20,20.44",
    "count": 3,
    "city": "Paliki Municipal Unit",
    "county": "Kefallonia Regional Unit",
    "state": "Peloponnese, Western Greece and the Ionian",
    "state_district": "Ioanian Islands",
    "country": "Greece"
  },
  {
    "location": "geo:3.82,103.33",
    "count": 3,
    "city": "Kuantan",
    "county": null,
    "state": "Pahang",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:38.25,-85.77",
    "count": 3,
    "city": "Louisville",
    "county": "Jefferson County",
    "state": "Kentucky",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.34,-75.08",
    "count": 3,
    "city": "Ocean City",
    "county": "Worcester County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.39,-82.60",
    "count": 3,
    "city": "Catlettsburg",
    "county": "Boyd County",
    "state": "Kentucky",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.50,-90.63",
    "count": 3,
    "city": "Eureka",
    "county": "St. Louis County",
    "state": "Missouri",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.63,-76.91",
    "count": 3,
    "city": "Waldorf",
    "county": "Charles County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.66,-77.25",
    "count": 3,
    "city": "Woodbridge",
    "county": "Prince William County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.96,-85.89",
    "count": 3,
    "city": "Seymour",
    "county": "Jackson County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.97,-0.59",
    "count": 3,
    "city": "L'Alcúdia de Crespins",
    "county": null,
    "state": "Valencia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:38.98,-76.49",
    "count": 3,
    "city": "Annapolis",
    "county": "Anne Arundel County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.98,-77.53",
    "count": 3,
    "city": "Ashburn",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.04,-77.49",
    "count": 3,
    "city": "Ashburn",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.10,-77.29",
    "count": 3,
    "city": "Germantown",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.25,-77.70",
    "count": 3,
    "city": "Lovettsville",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.47,-3.53",
    "count": 3,
    "city": "Madridejos",
    "county": null,
    "state": "Toledo",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:39.54,2.72",
    "count": 3,
    "city": "Palma",
    "county": null,
    "state": "Balearic Islands",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:39.64,-77.72",
    "count": 3,
    "city": "Hagerstown",
    "county": "Washington County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.65,27.89",
    "count": 3,
    "city": "Balıkesir",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:40.03,-74.96",
    "count": 3,
    "city": "Delran",
    "county": "Burlington County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.09,-105.94",
    "count": 3,
    "city": "Granby",
    "county": "Grand County",
    "state": "Colorado",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.30,-73.99",
    "count": 3,
    "city": "Long Branch",
    "county": "Monmouth County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.36,-74.95",
    "count": 3,
    "city": "New Hope",
    "county": "Bucks County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.56,15.31",
    "count": 3,
    "city": "Sicignano degli Alburni",
    "county": "Salerno",
    "state": "Campania",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:40.77,30.39",
    "count": 3,
    "city": "Sakarya",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:40.81,39.61",
    "count": 3,
    "city": "Maçka",
    "county": null,
    "state": "Trabzon",
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:40.90,-78.22",
    "count": 3,
    "city": "Philipsburg",
    "county": "Centre County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.95,-87.45",
    "count": 3,
    "city": "Morocco",
    "county": "Newton County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.10,-80.65",
    "count": 3,
    "city": "Youngstown",
    "county": "Mahoning County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.12,1.24",
    "count": 3,
    "city": "Tarragona",
    "county": "Tarragonès",
    "state": "Catalonia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:13.10,103.20",
    "count": 3,
    "city": "Battambang",
    "county": "Battambang Municipality",
    "state": "Battambang",
    "state_district": null,
    "country": "Cambodia"
  },
  {
    "location": "geo:41.43,-96.50",
    "count": 3,
    "city": "Fremont",
    "county": "Dodge County",
    "state": "Nebraska",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:42.50,-83.37",
    "count": 3,
    "city": "Farmington Hills",
    "county": "Oakland County",
    "state": "Michigan",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:42.70,23.32",
    "count": 3,
    "city": "Sofia",
    "county": "Vazrajdane",
    "state": "Sofia-City",
    "state_district": "Sofia City",
    "country": "Bulgaria"
  },
  {
    "location": "geo:43.04,-87.91",
    "count": 3,
    "city": "Milwaukee",
    "county": "Milwaukee County",
    "state": "Wisconsin",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.16,-77.61",
    "count": 3,
    "city": "Rochester",
    "county": "Monroe County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.21,27.91",
    "count": 3,
    "city": "Varna",
    "county": "Odessos",
    "state": "Varna",
    "state_district": "Varna",
    "country": "Bulgaria"
  },
  {
    "location": "geo:43.21,-77.43",
    "count": 3,
    "city": "Webster",
    "county": "Monroe County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.21,-77.69",
    "count": 3,
    "city": "Rochester",
    "county": "Monroe County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.23,-90.24",
    "count": 3,
    "city": "Town of Buena Vista",
    "county": "Richland County",
    "state": "Wisconsin",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.53,-5.66",
    "count": 3,
    "city": "Gijon",
    "county": null,
    "state": "Asturias",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:43.64,2.05",
    "count": 3,
    "city": "Guitalens-l'Albarède",
    "county": "Vielmur-sur-Agout",
    "state": "Tarn",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:43.67,-92.97",
    "count": 3,
    "city": "Austin",
    "county": "Mower County",
    "state": "Minnesota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.72,7.11",
    "count": 3,
    "city": "Vence",
    "county": "Vence",
    "state": "Alpes-Maritimes",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:43.80,-91.66",
    "count": 3,
    "city": "Money Creek Township",
    "county": "Houston County",
    "state": "Minnesota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.82,28.57",
    "count": 3,
    "city": "Mangalia",
    "county": "Constanța",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:43.93,116.05",
    "count": 3,
    "city": "Xilinhot City",
    "county": null,
    "state": "Inner Mongolia",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:44.43,-64.62",
    "count": 3,
    "city": null,
    "county": "Lunenburg",
    "state": "Nova Scotia",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:11.87,177.38",
    "count": 3,
    "city": null,
    "county": null,
    "state": null,
    "state_district": null,
    "country": null
  },
  {
    "location": "geo:45.56,8.95",
    "count": 3,
    "city": "Parabiago",
    "county": null,
    "state": "Milano",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:45.62,122.84",
    "count": 3,
    "city": "Taobei",
    "county": null,
    "state": "Jilin",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:45.63,-73.51",
    "count": 3,
    "city": "Montréal-Est",
    "county": "Montreal",
    "state": "Quebec",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:45.85,-76.72",
    "count": 3,
    "city": "Mansfield-et-Pontefract",
    "county": "Pontiac",
    "state": "Quebec",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:46.01,-91.48",
    "count": 3,
    "city": "Hayward",
    "county": "Sawyer County",
    "state": "Wisconsin",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:46.23,6.07",
    "count": 3,
    "city": "Meyrin",
    "county": null,
    "state": "Geneva",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:11.53,106.89",
    "count": 3,
    "city": "Đồng Xoài",
    "county": null,
    "state": "Bình Phước Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:47.37,8.79",
    "count": 3,
    "city": "Pfäffikon",
    "county": "Bezirk Pfäffikon",
    "state": "Zurich",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:47.38,9.55",
    "count": 3,
    "city": "Altstätten",
    "county": "Wahlkreis Rheintal",
    "state": "St. Gallen",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:47.40,8.62",
    "count": 3,
    "city": "Dübendorf",
    "county": "Bezirk Uster",
    "state": "Zurich",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:47.41,9.74",
    "count": 3,
    "city": "Dornbirn",
    "county": null,
    "state": "Vorarlberg",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:47.42,9.38",
    "count": 3,
    "city": "St. Gallen",
    "county": "Wahlkreis St. Gallen",
    "state": "St. Gallen",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:11.15,42.71",
    "count": 3,
    "city": "Ali Sabieh",
    "county": null,
    "state": "Ali Sabieh",
    "state_district": null,
    "country": "Djibouti"
  },
  {
    "location": "geo:47.55,7.63",
    "count": 3,
    "city": "Birsfelden",
    "county": "Bezirk Arlesheim",
    "state": "Basel-Landschaft",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:47.58,12.16",
    "count": 3,
    "city": "Kufstein",
    "county": null,
    "state": "Tyrol",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:11.06,7.70",
    "count": 3,
    "city": "Kwarbai B",
    "county": "Zaria",
    "state": "Kaduna State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:4.77,7.03",
    "count": 3,
    "city": "Port-Harcourt",
    "county": null,
    "state": "Rivers State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:47.77,15.31",
    "count": 3,
    "city": "Mariazell",
    "county": "Bezirk Bruck-Mürzzuschlag",
    "state": "Styria",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:47.82,23.15",
    "count": 3,
    "city": "Iojib",
    "county": "Satu Mare",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:47.84,16.53",
    "count": 3,
    "city": "Eisenstadt",
    "county": null,
    "state": "Burgenland",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:47.99,18.26",
    "count": 3,
    "city": "District of Nové Zámky",
    "county": null,
    "state": "Region of Nitra",
    "state_district": null,
    "country": "Slovakia"
  },
  {
    "location": "geo:48.42,19.36",
    "count": 3,
    "city": "District of Detva",
    "county": null,
    "state": "Region of Banská Bystrica",
    "state_district": null,
    "country": "Slovakia"
  },
  {
    "location": "geo:48.76,17.83",
    "count": 3,
    "city": "Nové Mesto nad Váhom",
    "county": null,
    "state": "Region of Trenčín",
    "state_district": null,
    "country": "Slovakia"
  },
  {
    "location": "geo:48.77,11.42",
    "count": 3,
    "city": "Ingolstadt",
    "county": null,
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:48.87,9.19",
    "count": 3,
    "city": "Kornwestheim",
    "county": "Landkreis Ludwigsburg",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:48.94,-119.44",
    "count": 3,
    "city": "Oroville",
    "county": "Okanogan County",
    "state": "Washington",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.01,7.34",
    "count": 3,
    "city": "Sarreguemines",
    "county": "Moselle",
    "state": "Grand Est",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:49.72,18.81",
    "count": 3,
    "city": "Ustroń",
    "county": "Cieszyn County",
    "state": "Silesian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:49.74,13.37",
    "count": 3,
    "city": "Pilsen",
    "county": "Plzeň Region",
    "state": "Southwest",
    "state_district": null,
    "country": "Czechia"
  },
  {
    "location": "geo:10.90,106.75",
    "count": 3,
    "city": "Dĩ An",
    "county": "Dĩ An",
    "state": "Bình Dương Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:50.00,18.47",
    "count": 3,
    "city": "Stare Miasto",
    "county": "Wodzisław County",
    "state": "Silesian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:10.87,78.88",
    "count": 3,
    "city": "Mangammalpuram",
    "county": "Lalgudi",
    "state": "Tamil Nadu",
    "state_district": "Tiruchirappalli",
    "country": "India"
  },
  {
    "location": "geo:10.60,7.50",
    "count": 3,
    "city": "Rigachikun",
    "county": "Igabi",
    "state": "Kaduna State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:50.33,26.65",
    "count": 3,
    "city": "Netishyn",
    "county": null,
    "state": "Khmelnytskyi Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:10.50,7.43",
    "count": 3,
    "city": "Dadi Riba",
    "county": "Kaduna North",
    "state": "Kaduna State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:50.50,-4.65",
    "count": 3,
    "city": "Cardinham",
    "county": "Cornwall",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:50.61,15.51",
    "count": 3,
    "city": "Jilemnice",
    "county": "Liberec Region",
    "state": "Northeast",
    "state_district": null,
    "country": "Czechia"
  },
  {
    "location": "geo:50.78,7.28",
    "count": 3,
    "city": "Hennef",
    "county": "Rhein-Sieg-Kreis",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:50.83,12.92",
    "count": 3,
    "city": "Chemnitz",
    "county": null,
    "state": "Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.17,6.94",
    "count": 3,
    "city": "Hilden",
    "county": "Kreis Mettmann",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.29,5.75",
    "count": 3,
    "city": "Nederweert",
    "county": null,
    "state": "Limburg",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:51.40,5.21",
    "count": 3,
    "city": "Bladel",
    "county": null,
    "state": "North Brabant",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:51.54,7.20",
    "count": 3,
    "city": "Herne",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.67,7.82",
    "count": 3,
    "city": "Hamm",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.74,0.47",
    "count": 3,
    "city": "Chelmsford",
    "county": "Essex",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:51.76,14.33",
    "count": 3,
    "city": "Cottbus - Chóśebuz",
    "county": null,
    "state": "Brandenburg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.88,0.55",
    "count": 3,
    "city": "Braintree",
    "county": "Essex",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:51.99,-2.16",
    "count": 3,
    "city": "Tewkesbury",
    "county": "Gloucestershire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:52.40,13.28",
    "count": 3,
    "city": "Teltow",
    "county": "Potsdam-Mittelmark",
    "state": "Brandenburg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:52.42,10.79",
    "count": 3,
    "city": "Wolfsburg",
    "county": null,
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:52.47,-8.16",
    "count": 3,
    "city": "Tipperary",
    "county": "County Tipperary",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:53.19,-2.89",
    "count": 3,
    "city": "Chester",
    "county": "Cheshire West and Chester",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:53.33,-1.89",
    "count": 3,
    "city": "High Peak",
    "county": "Derbyshire",
    "state": "England",
    "state_district": "East Midlands",
    "country": "United Kingdom"
  },
  {
    "location": "geo:54.09,12.10",
    "count": 3,
    "city": "Rostock",
    "county": null,
    "state": "Mecklenburg-Vorpommern",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-54.80,-68.30",
    "count": 3,
    "city": "Ushuaia",
    "county": null,
    "state": "Tierra del Fuego Province",
    "state_district": "Departamento Ushuaia",
    "country": "Argentina"
  },
  {
    "location": "geo:55.53,60.58",
    "count": 3,
    "city": "Кузнецкое сельское поселение",
    "county": "Argayashsky District",
    "state": "Chelyabinsk Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:55.68,12.51",
    "count": 3,
    "city": "Frederiksberg",
    "county": null,
    "state": "Capital Region of Denmark",
    "state_district": null,
    "country": "Denmark"
  },
  {
    "location": "geo:56.86,12.65",
    "count": 3,
    "city": "Falkenbergs kommun",
    "county": "Halland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:57.26,24.42",
    "count": 3,
    "city": "Saulkrasti",
    "county": null,
    "state": null,
    "state_district": "Saulkrastu novads",
    "country": "Latvia"
  },
  {
    "location": "geo:58.41,15.62",
    "count": 3,
    "city": "Linköping",
    "county": "Östergötland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:59.28,15.21",
    "count": 3,
    "city": "Örebro",
    "county": "Örebro County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:10.02,105.09",
    "count": 3,
    "city": "Rạch Giá",
    "county": null,
    "state": "Kiên Giang Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:-6.04,106.16",
    "count": 3,
    "city": "Serang",
    "county": null,
    "state": "Banten",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:60.67,17.14",
    "count": 3,
    "city": "Gävle",
    "county": "Gävleborg County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:62.50,25.44",
    "count": 3,
    "city": "Uurainen",
    "county": "Central Finland",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:6.28,-75.44",
    "count": 3,
    "city": "Guarne",
    "county": null,
    "state": "Antioquia",
    "state_district": "Oriente",
    "country": "Colombia"
  },
  {
    "location": "geo:-6.30,107.30",
    "count": 3,
    "city": "Karawang",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-6.35,108.33",
    "count": 3,
    "city": "Karangmalang",
    "county": "Indramayu",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:6.36,2.43",
    "count": 3,
    "city": "Cotonou",
    "county": null,
    "state": "Littoral",
    "state_district": null,
    "country": "Benin"
  },
  {
    "location": "geo:-6.40,106.79",
    "count": 3,
    "city": "Depok",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-0.45,18.27",
    "count": 3,
    "city": "Besenge",
    "county": null,
    "state": "Équateur",
    "state_district": null,
    "country": "Democratic Republic of the Congo"
  },
  {
    "location": "geo:7.16,-73.28",
    "count": 3,
    "city": "Lebrija",
    "county": null,
    "state": "Santander",
    "state_district": "Metropolitana",
    "country": "Colombia"
  },
  {
    "location": "geo:-7.22,-39.31",
    "count": 3,
    "city": "Juazeiro do Norte",
    "county": null,
    "state": "Ceará",
    "state_district": "Região Geográfica Intermediária de Juazeiro do Norte",
    "country": "Brazil"
  },
  {
    "location": "geo:-7.47,112.44",
    "count": 3,
    "city": "Mojokerto",
    "county": null,
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-7.55,111.66",
    "count": 3,
    "city": "Pandean",
    "county": "Madiun",
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-7.61,112.31",
    "count": 3,
    "city": "Selorejo",
    "county": "Jombang",
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-8.13,115.34",
    "count": 3,
    "city": "Desa Tejakula",
    "county": "Buleleng",
    "state": "Bali",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-8.22,114.37",
    "count": 3,
    "city": "Tukangkayu",
    "county": "Banyuwangi",
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:8.52,76.94",
    "count": 3,
    "city": "Thiruvananthapuram",
    "county": "Thiruvananthapuram",
    "state": "Kerala",
    "state_district": "Thiruvananthapuram",
    "country": "India"
  },
  {
    "location": "geo:9.87,76.49",
    "count": 3,
    "city": "Piravom",
    "county": "Muvattupuzha",
    "state": "Kerala",
    "state_district": "Ernakulam",
    "country": "India"
  },
  {
    "location": "geo:38.83,-104.82",
    "count": 2,
    "city": "Colorado Springs",
    "county": "El Paso County",
    "state": "Colorado",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:42.93,-80.29",
    "count": 2,
    "city": "Norfolk",
    "county": null,
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:42.90,71.40",
    "count": 2,
    "city": "Taraz",
    "county": null,
    "state": "Jambyl Region",
    "state_district": null,
    "country": "Kazakhstan"
  },
  {
    "location": "geo:47.16,20.18",
    "count": 2,
    "city": "Szolnok",
    "county": "Jász-Nagykun-Szolnok",
    "state": null,
    "state_district": null,
    "country": "Hungary"
  },
  {
    "location": "geo:18.21,-63.06",
    "count": 2,
    "city": "The Valley",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Anguilla"
  },
  {
    "location": "geo:47.39,8.42",
    "count": 2,
    "city": "Urdorf",
    "county": "Bezirk Dietikon",
    "state": "Zurich",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:21.98,108.65",
    "count": 2,
    "city": "Qinzhou",
    "county": null,
    "state": "Guangxi",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:42.61,23.41",
    "count": 2,
    "city": "German",
    "county": null,
    "state": "Sofia-City",
    "state_district": "Sofia City",
    "country": "Bulgaria"
  },
  {
    "location": "geo:47.50,8.72",
    "count": 2,
    "city": "Winterthur",
    "county": "Bezirk Winterthur",
    "state": "Zurich",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:18.42,-66.06",
    "count": 2,
    "city": "Río Piedras",
    "county": "San Juan",
    "state": "Puerto Rico",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-7.58,110.82",
    "count": 2,
    "city": "Surakarta",
    "county": null,
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:12.86,77.36",
    "count": 2,
    "city": "Bettangere",
    "county": "Ramanagara taluk",
    "state": "Karnataka",
    "state_district": "Ramanagara",
    "country": "India"
  },
  {
    "location": "geo:47.68,13.10",
    "count": 2,
    "city": "Hallein",
    "county": "Bezirk Hallein",
    "state": "Salzburg",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:47.70,-116.84",
    "count": 2,
    "city": "Coeur D'Alene",
    "county": "Kootenai County",
    "state": "Idaho",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.73,10.32",
    "count": 2,
    "city": "Kempten (Allgäu)",
    "county": null,
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-7.61,110.99",
    "count": 2,
    "city": "Popongan",
    "county": "Karanganyar",
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:47.81,13.78",
    "count": 2,
    "city": "Ebensee",
    "county": "Bezirk Gmunden",
    "state": "Upper Austria",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:47.99,37.29",
    "count": 2,
    "city": "Kurakhove",
    "county": null,
    "state": "Donetsk Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:47.99,-66.87",
    "count": 2,
    "city": null,
    "county": "Restigouche",
    "state": "New Brunswick",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:41.50,-5.75",
    "count": 2,
    "city": "Zamora",
    "county": null,
    "state": "Zamora",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:41.42,-5.69",
    "count": 2,
    "city": "Casaseca de las Chanas",
    "county": null,
    "state": "Castile and León",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:48.09,12.85",
    "count": 2,
    "city": "Tarsdorf",
    "county": "Bezirk Braunau",
    "state": "Upper Austria",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:48.34,7.88",
    "count": 2,
    "city": "Lahr/Schwarzwald",
    "county": "Ortenaukreis",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:48.37,17.58",
    "count": 2,
    "city": "Trnava",
    "county": null,
    "state": "Region of Trnava",
    "state_district": null,
    "country": "Slovakia"
  },
  {
    "location": "geo:48.43,-123.37",
    "count": 2,
    "city": "Victoria",
    "county": "Capital",
    "state": "British Columbia",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:41.04,-83.65",
    "count": 2,
    "city": "Findlay",
    "county": "Hancock County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.01,-8.64",
    "count": 2,
    "city": "Espinho",
    "county": null,
    "state": "Aveiro",
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:40.69,-89.59",
    "count": 2,
    "city": "Peoria",
    "county": "Peoria County",
    "state": "Illinois",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.66,-73.58",
    "count": 2,
    "city": "Freeport",
    "county": "Nassau County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.57,13.43",
    "count": 2,
    "city": "Passau",
    "county": null,
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:40.66,109.84",
    "count": 2,
    "city": "Hondlon District",
    "county": null,
    "state": "Inner Mongolia",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:13.46,123.64",
    "count": 2,
    "city": "Cale",
    "county": null,
    "state": "Albay",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:-7.70,109.02",
    "count": 2,
    "city": "Kelurahan Sidanegara Kecamatan Cilacap",
    "county": "Cilacap",
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:11.02,76.96",
    "count": 2,
    "city": "Coimbatore",
    "county": "Coimbatore North",
    "state": "Tamil Nadu",
    "state_district": "Coimbatore",
    "country": "India"
  },
  {
    "location": "geo:40.17,-80.25",
    "count": 2,
    "city": "Washington",
    "county": "Washington County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.15,26.41",
    "count": 2,
    "city": "Çanakkale Merkez",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:40.10,-75.47",
    "count": 2,
    "city": "Schuylkill Township",
    "county": "Chester County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.10,-74.79",
    "count": 2,
    "city": "Burlington",
    "county": "Burlington County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.03,-77.62",
    "count": 2,
    "city": "Chambersburg",
    "county": "Franklin County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:4.91,114.84",
    "count": 2,
    "city": "Mukim Sengkurong",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Brunei"
  },
  {
    "location": "geo:49.18,-0.37",
    "count": 2,
    "city": "Caen",
    "county": "Calvados",
    "state": "Normandy",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:39.96,-86.02",
    "count": 2,
    "city": "Fishers",
    "county": "Hamilton County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.94,-8.04",
    "count": 2,
    "city": "Oleiros",
    "county": "Castelo Branco",
    "state": null,
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:39.89,-83.45",
    "count": 2,
    "city": "London",
    "county": "Madison County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:10.93,108.10",
    "count": 2,
    "city": "Phan Thiết",
    "county": null,
    "state": "Bình Thuận Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:39.81,-79.57",
    "count": 2,
    "city": "Wharton Township",
    "county": "Fayette County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.49,-75.03",
    "count": 2,
    "city": "Vineland",
    "county": "Cumberland County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.15,-77.24",
    "count": 2,
    "city": "Gaithersburg",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.68,8.62",
    "count": 2,
    "city": "Bensheim",
    "county": "Bergstraße",
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:49.74,21.73",
    "count": 2,
    "city": "gmina Wojaszówka",
    "county": "Krosno County",
    "state": "Subcarpathian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:49.76,21.14",
    "count": 2,
    "city": "gmina Biecz",
    "county": "Gorlice County",
    "state": "Lesser Poland Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:39.04,125.76",
    "count": 2,
    "city": "P'yŏngyang",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "North Korea"
  },
  {
    "location": "geo:39.00,-77.29",
    "count": 2,
    "city": "Great Falls",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-3.89,-42.23",
    "count": 2,
    "city": "Esperantina",
    "county": null,
    "state": "Piauí",
    "state_district": "Região Geográfica Intermediária de Parnaíba",
    "country": "Brazil"
  },
  {
    "location": "geo:38.93,-77.18",
    "count": 2,
    "city": "Mclean",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:13.97,108.02",
    "count": 2,
    "city": "Pleiku",
    "county": null,
    "state": "Gia Lai Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:49.98,16.97",
    "count": 2,
    "city": "Šumperk",
    "county": "Olomouc Region",
    "state": "Central Moravia",
    "state_district": null,
    "country": "Czechia"
  },
  {
    "location": "geo:38.91,121.61",
    "count": 2,
    "city": "Xigang",
    "county": null,
    "state": "Liaoning",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:38.79,-76.22",
    "count": 2,
    "city": "St. Michaels",
    "county": "Talbot County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:7.86,-11.20",
    "count": 2,
    "city": "Kenema",
    "county": null,
    "state": "Kenema District",
    "state_district": null,
    "country": "Sierra Leone"
  },
  {
    "location": "geo:38.77,-76.08",
    "count": 2,
    "city": "Easton",
    "county": "Talbot County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:50.23,-5.16",
    "count": 2,
    "city": "Frogpool",
    "county": "Cornwall",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:38.72,35.48",
    "count": 2,
    "city": "Kayseri",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:50.26,28.67",
    "count": 2,
    "city": "Zhytomyr",
    "county": null,
    "state": "Zhytomyr Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:-7.88,112.53",
    "count": 2,
    "city": "Kota Batu",
    "county": null,
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:50.33,3.67",
    "count": 2,
    "city": "Avesnes-sur-Helpe",
    "county": "Nord",
    "state": "Hauts-de-France",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:50.37,8.74",
    "count": 2,
    "city": "Bad Nauheim",
    "county": "Wetteraukreis",
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:38.58,-78.50",
    "count": 2,
    "city": "Stanley",
    "county": "Page County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:50.43,-3.69",
    "count": 2,
    "city": "South Hams",
    "county": "Devon",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:50.49,30.33",
    "count": 2,
    "city": "Kotsiubynske",
    "county": null,
    "state": "Kyiv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:50.53,-3.61",
    "count": 2,
    "city": "Teignbridge",
    "county": "Devon",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:38.38,-82.69",
    "count": 2,
    "city": "Cannonsburg",
    "county": "Boyd County",
    "state": "Kentucky",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:50.62,26.25",
    "count": 2,
    "city": "Rivne",
    "county": null,
    "state": "Rivne Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:50.66,6.79",
    "count": 2,
    "city": "Euskirchen",
    "county": "Kreis Euskirchen",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:50.71,-2.44",
    "count": 2,
    "city": "Dorchester",
    "county": "Dorset",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:38.36,125.14",
    "count": 2,
    "city": "Songhwa-up",
    "county": "Songhwa County",
    "state": "South Hwanghae",
    "state_district": null,
    "country": "North Korea"
  },
  {
    "location": "geo:50.72,-1.87",
    "count": 2,
    "city": "Bournemouth",
    "county": "Bournemouth, Christchurch and Poole",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:50.75,25.33",
    "count": 2,
    "city": "Lutsk",
    "county": null,
    "state": "Volyn Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:50.77,7.19",
    "count": 2,
    "city": "Sankt Augustin",
    "county": "Rhein-Sieg-Kreis",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:17.66,75.91",
    "count": 2,
    "city": "Solapur",
    "county": "Solapur North",
    "state": "Maharashtra",
    "state_district": "Solapur District",
    "country": "India"
  },
  {
    "location": "geo:50.91,34.80",
    "count": 2,
    "city": "Sumy",
    "county": null,
    "state": "Sumy Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:-37.88,145.10",
    "count": 2,
    "city": "Chadstone",
    "county": "Monash",
    "state": "Victoria",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:37.81,-0.84",
    "count": 2,
    "city": "San Javier",
    "county": "Campo de Cartagena y Mar Menor",
    "state": "Region of Murcia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:37.75,37.29",
    "count": 2,
    "city": "Çağlayancerit",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:37.74,-81.25",
    "count": 2,
    "city": "Crab Orchard",
    "county": "Raleigh County",
    "state": "West Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.54,-77.44",
    "count": 2,
    "city": "Richmond",
    "county": "City of Richmond",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:51.05,3.73",
    "count": 2,
    "city": "Ghent",
    "county": "Gent",
    "state": "East Flanders",
    "state_district": null,
    "country": "Belgium"
  },
  {
    "location": "geo:37.35,126.97",
    "count": 2,
    "city": null,
    "county": "Uiwang",
    "state": "Kyeongki-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:37.30,-120.48",
    "count": 2,
    "city": "Merced",
    "county": "Merced County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:21.03,-104.37",
    "count": 2,
    "city": "Ixtlán del Río",
    "county": "Ixtlán del Río",
    "state": "Nayarit",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:37.21,-93.29",
    "count": 2,
    "city": "Springfield",
    "county": "Greene County",
    "state": "Missouri",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.08,-94.51",
    "count": 2,
    "city": "Joplin",
    "county": "Jasper County",
    "state": "Missouri",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.03,-89.19",
    "count": 2,
    "city": "Cache",
    "county": "Alexander County",
    "state": "Illinois",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.02,-7.94",
    "count": 2,
    "city": "Faro",
    "county": "Faro",
    "state": null,
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:36.83,28.64",
    "count": 2,
    "city": "Ortaca",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:51.28,7.03",
    "count": 2,
    "city": "Wülfrath",
    "county": "Kreis Mettmann",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.34,7.04",
    "count": 2,
    "city": "Velbert-Mitte",
    "county": "Kreis Mettmann",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.35,26.60",
    "count": 2,
    "city": "Sarny",
    "county": null,
    "state": "Rivne Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:36.54,52.68",
    "count": 2,
    "city": "Babol",
    "county": "Babol County",
    "state": "Mazandaran Province",
    "state_district": null,
    "country": "Iran"
  },
  {
    "location": "geo:51.44,5.98",
    "count": 2,
    "city": "Horst aan de Maas",
    "county": null,
    "state": "Limburg",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:36.50,-6.14",
    "count": 2,
    "city": "Puerto Real",
    "county": "Bay of Cádiz",
    "state": "Andalusia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:51.48,-3.17",
    "count": 2,
    "city": "Cardiff",
    "county": "Cardiff",
    "state": "Wales",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:51.48,7.22",
    "count": 2,
    "city": "Bochum",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:36.31,41.86",
    "count": 2,
    "city": "Sinjar",
    "county": null,
    "state": "Nineveh Governorate",
    "state_district": null,
    "country": "Iraq"
  },
  {
    "location": "geo:36.20,117.09",
    "count": 2,
    "city": "Taishan District",
    "county": null,
    "state": "Shandong",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:51.57,4.77",
    "count": 2,
    "city": "Breda",
    "county": null,
    "state": "North Brabant",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:51.58,5.19",
    "count": 2,
    "city": "Oisterwijk",
    "county": null,
    "state": "North Brabant",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:-5.16,119.44",
    "count": 2,
    "city": "Makassar",
    "county": null,
    "state": "South Sulawesi",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:51.64,-100.00",
    "count": 2,
    "city": null,
    "county": "Rural Municipality of Mossey River",
    "state": "Manitoba",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:51.65,5.29",
    "count": 2,
    "city": "Vught",
    "county": null,
    "state": "North Brabant",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:51.66,6.63",
    "count": 2,
    "city": "Wesel",
    "county": "Kreis Wesel",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:35.93,-94.15",
    "count": 2,
    "city": "West Fork",
    "county": "Washington County",
    "state": "Arkansas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:20.79,97.03",
    "count": 2,
    "city": "Taunggyi",
    "county": null,
    "state": "Shan State",
    "state_district": "South Shan State",
    "country": "Myanmar"
  },
  {
    "location": "geo:14.63,-90.51",
    "count": 2,
    "city": "Guatemala City",
    "county": null,
    "state": "Guatemala Department",
    "state_district": null,
    "country": "Guatemala"
  },
  {
    "location": "geo:8.49,39.83",
    "count": 2,
    "city": "Abomsa",
    "county": null,
    "state": "Oromia Region",
    "state_district": "Arsi",
    "country": "Ethiopia"
  },
  {
    "location": "geo:51.79,6.13",
    "count": 2,
    "city": "Cleves",
    "county": "Kreis Kleve",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.83,8.15",
    "count": 2,
    "city": "Oelde",
    "county": "Kreis Warendorf",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:51.86,21.96",
    "count": 2,
    "city": "gmina Wola Mysłowska",
    "county": "Łuków County",
    "state": "Lublin Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:35.67,7.30",
    "count": 2,
    "city": "F'Kirina",
    "county": "Fkirina District",
    "state": "Oum El Bouaghi",
    "state_district": null,
    "country": "Algeria"
  },
  {
    "location": "geo:18.37,-99.87",
    "count": 2,
    "city": "Teloloapan",
    "county": "Teloloapan",
    "state": "Guerrero",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:-3.55,36.10",
    "count": 2,
    "city": "Makuyuni",
    "county": null,
    "state": "Arusha",
    "state_district": "Monduli",
    "country": "Tanzania"
  },
  {
    "location": "geo:35.44,139.64",
    "count": 2,
    "city": "Yokohama-shi",
    "county": "Yokohama",
    "state": "Kanagawa Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:51.93,1.26",
    "count": 2,
    "city": "Dovercourt",
    "county": "Essex",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:51.94,4.14",
    "count": 2,
    "city": "Rotterdam",
    "county": null,
    "state": "South Holland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:51.95,20.16",
    "count": 2,
    "city": "Skierniewice",
    "county": null,
    "state": "Łódź Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:35.23,-106.66",
    "count": 2,
    "city": "Rio Rancho",
    "county": "Sandoval County",
    "state": "New Mexico",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.18,136.82",
    "count": 2,
    "city": null,
    "county": "Oharu",
    "state": "Aichi Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:52.06,-9.50",
    "count": 2,
    "city": "Killarney",
    "county": "County Kerry",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:35.11,-83.10",
    "count": 2,
    "city": "Cashiers",
    "county": "Jackson County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.92,-78.38",
    "count": 2,
    "city": "Clinton",
    "county": "Sampson County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.81,72.35",
    "count": 2,
    "city": "Kabal Tehsil",
    "county": "Swat District",
    "state": "Khyber Pakhtunkhwa",
    "state_district": "Malakand Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:-34.74,138.63",
    "count": 2,
    "city": "Salisbury North",
    "county": "Salisbury",
    "state": "South Australia",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:52.20,8.58",
    "count": 2,
    "city": "Bünde",
    "county": "Kreis Herford",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:34.72,-80.77",
    "count": 2,
    "city": "Lancaster",
    "county": "Lancaster County",
    "state": "South Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.21,8.80",
    "count": 2,
    "city": "Bad Oeynhausen",
    "county": "Kreis Minden-Lübbecke",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:52.26,26.80",
    "count": 2,
    "city": "Luniniec",
    "county": "Luninets District",
    "state": "Brest Region",
    "state_district": null,
    "country": "Belarus"
  },
  {
    "location": "geo:34.20,117.29",
    "count": 2,
    "city": "Yunlong District",
    "county": null,
    "state": "Jiangsu",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:34.07,134.55",
    "count": 2,
    "city": "Tokushima-shi",
    "county": "Tokushima",
    "state": "Tokushima Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:34.07,-118.40",
    "count": 2,
    "city": "Beverly Hills",
    "county": "Los Angeles County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.34,-6.46",
    "count": 2,
    "city": "Wexford",
    "county": "County Wexford",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:15.17,121.01",
    "count": 2,
    "city": "San Miguel",
    "county": null,
    "state": "Bulacan",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:33.95,-83.36",
    "count": 2,
    "city": "Athens-Clarke County",
    "county": "Clarke County",
    "state": "Georgia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:33.90,-118.22",
    "count": 2,
    "city": "Compton",
    "county": "Los Angeles County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:15.44,73.89",
    "count": 2,
    "city": "Pilar",
    "county": "Tiswadi",
    "state": "Goa",
    "state_district": "North Goa",
    "country": "India"
  },
  {
    "location": "geo:33.75,72.84",
    "count": 2,
    "city": "Taxila",
    "county": "Rawalpindi District",
    "state": "Punjab",
    "state_district": "Rawalpindi Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:33.63,114.70",
    "count": 2,
    "city": "Chuanhui",
    "county": null,
    "state": "Henan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:52.67,0.16",
    "count": 2,
    "city": "Fenland District",
    "county": "Cambridgeshire",
    "state": "England",
    "state_district": "Cambridgeshire and Peterborough",
    "country": "United Kingdom"
  },
  {
    "location": "geo:33.57,-7.59",
    "count": 2,
    "city": "Casablanca",
    "county": "préfecture d’arrondissements Al Fida-Mers Sultan عمالة مقاطعات الفداء مرس السلطان",
    "state": null,
    "state_district": "Prefecture of Casablanca",
    "country": "Morocco"
  },
  {
    "location": "geo:52.85,-8.99",
    "count": 2,
    "city": "Ennis Rural ED",
    "county": "County Clare",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:33.28,-7.23",
    "count": 2,
    "city": "El Gara",
    "county": "Pachalik de El Gara باشوية الكارة",
    "state": null,
    "state_district": "Berrechid Province",
    "country": "Morocco"
  },
  {
    "location": "geo:52.98,-6.04",
    "count": 2,
    "city": "Wicklow Urban ED",
    "county": "County Wicklow",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:53.01,-1.35",
    "count": 2,
    "city": "Amber Valley",
    "county": "Derbyshire",
    "state": "England",
    "state_district": "East Midlands",
    "country": "United Kingdom"
  },
  {
    "location": "geo:33.13,-107.25",
    "count": 2,
    "city": "Truth Or Consequences",
    "county": "Sierra County",
    "state": "New Mexico",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:53.11,20.38",
    "count": 2,
    "city": "Mława",
    "county": "Mława County",
    "state": "Masovian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:20.28,-97.96",
    "count": 2,
    "city": "Xicotepec",
    "county": null,
    "state": "Puebla",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:15.64,78.11",
    "count": 2,
    "city": "Uppalapadu",
    "county": "Orvakal",
    "state": "Andhra Pradesh",
    "state_district": "Kurnool",
    "country": "India"
  },
  {
    "location": "geo:32.60,44.02",
    "count": 2,
    "city": "Karbala",
    "county": null,
    "state": "Karbala",
    "state_district": null,
    "country": "Iraq"
  },
  {
    "location": "geo:20.28,81.93",
    "count": 2,
    "city": null,
    "county": "Nagri Tahsil",
    "state": "Chhattisgarh",
    "state_district": "Dhamtari",
    "country": "India"
  },
  {
    "location": "geo:32.52,-87.84",
    "count": 2,
    "city": "Demopolis",
    "county": "Marengo County",
    "state": "Alabama",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:32.51,-117.04",
    "count": 2,
    "city": "Tijuana",
    "county": "Tijuana",
    "state": "Baja California",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:32.39,119.41",
    "count": 2,
    "city": "Hanjiang District",
    "county": null,
    "state": "Jiangsu",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:32.30,-6.70",
    "count": 2,
    "city": "Suq as-Sabt Awlad an-Nama",
    "county": "Pachalik de Souk Sebt",
    "state": null,
    "state_district": "Fquih Ben Salah Province",
    "country": "Morocco"
  },
  {
    "location": "geo:31.81,74.25",
    "count": 2,
    "city": "Muridke",
    "county": null,
    "state": "Punjab",
    "state_district": null,
    "country": "Pakistan"
  },
  {
    "location": "geo:31.64,-86.74",
    "count": 2,
    "city": "Georgiana",
    "county": "Butler County",
    "state": "Alabama",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:31.43,31.13",
    "count": 2,
    "city": "Kafr El Sheikh",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Egypt"
  },
  {
    "location": "geo:53.95,-8.09",
    "count": 2,
    "city": "Carrick on Shannon ED",
    "county": "County Leitrim",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:53.98,-1.71",
    "count": 2,
    "city": "Timble",
    "county": "North Yorkshire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:54.00,-6.39",
    "count": 2,
    "city": "Dundalk No. 4 Urban ED",
    "county": "County Louth",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:54.01,17.93",
    "count": 2,
    "city": "gmina Kościerzyna",
    "county": "Kościerzyna County",
    "state": "Pomeranian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:30.99,-110.29",
    "count": 2,
    "city": "Cananea",
    "county": "Cananea",
    "state": "Sonora",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:30.93,-6.94",
    "count": 2,
    "city": "Ouarzazate",
    "county": "Pachalik d'Ouarzazate",
    "state": null,
    "state_district": "Ouarzazate Province",
    "country": "Morocco"
  },
  {
    "location": "geo:30.70,70.66",
    "count": 2,
    "city": "Taunsa Sharif",
    "county": "Taunsa District",
    "state": "Punjab",
    "state_district": "Dera Ghazi Khan Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:30.51,79.08",
    "count": 2,
    "city": "Bhainsari",
    "county": "Ukhimath",
    "state": "Uttarakhand",
    "state_district": "Rudraprayag",
    "country": "India"
  },
  {
    "location": "geo:30.29,71.93",
    "count": 2,
    "city": "Khanewal Tehsil",
    "county": null,
    "state": "Punjab",
    "state_district": null,
    "country": "Pakistan"
  },
  {
    "location": "geo:-1.61,103.61",
    "count": 2,
    "city": "Jambi",
    "county": "Danau Sipin",
    "state": "Jambi",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:29.55,103.77",
    "count": 2,
    "city": "Shizhong",
    "county": null,
    "state": "Sichuan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:29.34,104.78",
    "count": 2,
    "city": "东兴寺街道",
    "county": null,
    "state": "Sichuan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:54.79,32.05",
    "count": 2,
    "city": "Smolensk",
    "county": null,
    "state": "Smolensk Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:-28.76,153.35",
    "count": 2,
    "city": "Bexhill",
    "county": "Lismore",
    "state": "New South Wales",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:55.42,-129.83",
    "count": 2,
    "city": "Area A (Nass Valley/Bell Irving)",
    "county": "Regional District of Kitimat-Stikine",
    "state": "British Columbia",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:55.56,37.86",
    "count": 2,
    "city": "Molokovo",
    "county": "Leninsky District",
    "state": "Moscow Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:8.83,7.18",
    "count": 2,
    "city": null,
    "county": "Kwali",
    "state": "Federal Capital Territory",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:28.21,-16.75",
    "count": 2,
    "city": "Guía de Isora",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:28.12,-16.58",
    "count": 2,
    "city": "Granadilla de Abona",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:28.04,-81.95",
    "count": 2,
    "city": "Lakeland",
    "county": "Polk County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-28.01,153.39",
    "count": 2,
    "city": "Benowa",
    "county": "Gold Coast",
    "state": "Queensland",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:27.99,120.70",
    "count": 2,
    "city": "Lucheng District",
    "county": null,
    "state": "Zhejiang",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:27.51,83.44",
    "count": 2,
    "city": "Siddharthanagar-04",
    "county": "Rupandehi",
    "state": "Lumbini Province",
    "state_district": null,
    "country": "Nepal"
  },
  {
    "location": "geo:27.33,88.61",
    "count": 2,
    "city": "Gangtok",
    "county": null,
    "state": "Sikkim",
    "state_district": "Gangtok",
    "country": "India"
  },
  {
    "location": "geo:27.18,78.01",
    "count": 2,
    "city": "Agra",
    "county": null,
    "state": "Uttar Pradesh",
    "state_district": "Agra",
    "country": "India"
  },
  {
    "location": "geo:19.81,105.79",
    "count": 2,
    "city": "Thanh Hóa",
    "county": null,
    "state": "Thanh Hóa Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:26.95,56.27",
    "count": 2,
    "city": "Qeshm",
    "county": "Qeshm County",
    "state": "Hormozgan Province",
    "state_district": null,
    "country": "Iran"
  },
  {
    "location": "geo:26.93,-105.67",
    "count": 2,
    "city": "Parral",
    "county": "Hidalgo del Parral",
    "state": "Chihuahua",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:57.05,9.92",
    "count": 2,
    "city": "Aalborg",
    "county": null,
    "state": "North Denmark Region",
    "state_district": null,
    "country": "Denmark"
  },
  {
    "location": "geo:5.73,115.93",
    "count": 2,
    "city": "Papar",
    "county": null,
    "state": "Sabah",
    "state_district": "West Coast Division",
    "country": "Malaysia"
  },
  {
    "location": "geo:5.76,7.10",
    "count": 2,
    "city": "Owerre-Nkworji",
    "county": "Nkwerre",
    "state": "Imo State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:57.72,12.94",
    "count": 2,
    "city": "Borås",
    "county": "Västra Götaland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:58.01,56.23",
    "count": 2,
    "city": "Perm",
    "county": "Perm District",
    "state": "Perm Krai",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:58.59,16.19",
    "count": 2,
    "city": "Norrköping",
    "county": "Östergötland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:26.53,-80.09",
    "count": 2,
    "city": "Boynton Beach",
    "county": "Palm Beach County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:26.48,85.31",
    "count": 2,
    "city": null,
    "county": "Sheohar",
    "state": "Bihar",
    "state_district": "Sheohar",
    "country": "India"
  },
  {
    "location": "geo:9.02,38.75",
    "count": 2,
    "city": "Goma Kuteba",
    "county": "Lideta",
    "state": "Addis Ababa",
    "state_district": "Addis Ababa",
    "country": "Ethiopia"
  },
  {
    "location": "geo:26.41,80.23",
    "count": 2,
    "city": "Kanpur",
    "county": null,
    "state": "Uttar Pradesh",
    "state_district": "Kanpur Nagar",
    "country": "India"
  },
  {
    "location": "geo:59.44,18.07",
    "count": 2,
    "city": "Täby",
    "county": "Stockholm County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:-25.83,28.11",
    "count": 2,
    "city": null,
    "county": "City of Tshwane",
    "state": "Gauteng",
    "state_district": null,
    "country": "South Africa"
  },
  {
    "location": "geo:59.97,11.05",
    "count": 2,
    "city": "Lillestrøm",
    "county": "Akershus",
    "state": null,
    "state_district": null,
    "country": "Norway"
  },
  {
    "location": "geo:-0.94,38.06",
    "count": 2,
    "city": "Mwingi",
    "county": "Mwingi Central",
    "state": "Kitui County",
    "state_district": null,
    "country": "Kenya"
  },
  {
    "location": "geo:19.28,166.65",
    "count": 2,
    "city": null,
    "county": null,
    "state": null,
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:19.27,105.72",
    "count": 2,
    "city": "Hoàng Mai",
    "county": null,
    "state": "Nghệ An Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:25.44,81.85",
    "count": 2,
    "city": "Prayagraj",
    "county": "Prayagraj",
    "state": "Uttar Pradesh",
    "state_district": "Prayagraj",
    "country": "India"
  },
  {
    "location": "geo:25.37,-80.24",
    "count": 2,
    "city": null,
    "county": "Miami-Dade County",
    "state": "Florida",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:25.21,75.86",
    "count": 2,
    "city": "Kota",
    "county": "Ladpura Tehsil",
    "state": "Rajasthan",
    "state_district": "Kota",
    "country": "India"
  },
  {
    "location": "geo:25.07,91.41",
    "count": 2,
    "city": "Sunamganj",
    "county": "Sunamganj Sadar Upazila",
    "state": "Sylhet Division",
    "state_district": "Sunamganj District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:61.87,6.85",
    "count": 2,
    "city": "Stryn",
    "county": "Vestland",
    "state": null,
    "state_district": null,
    "country": "Norway"
  },
  {
    "location": "geo:16.74,107.19",
    "count": 2,
    "city": "Quảng Trị",
    "county": null,
    "state": "Quảng Trị Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:24.99,121.31",
    "count": 2,
    "city": "Taoyuan City",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Taiwan"
  },
  {
    "location": "geo:-62.20,-58.96",
    "count": 2,
    "city": "Villa Las Estrellas",
    "county": null,
    "state": null,
    "state_district": null,
    "country": null
  },
  {
    "location": "geo:62.39,17.31",
    "count": 2,
    "city": "Sundsvalls Gustav Adolf District",
    "county": "Västernorrland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:-24.66,25.91",
    "count": 2,
    "city": "Gaborone",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Botswana"
  },
  {
    "location": "geo:24.63,77.30",
    "count": 2,
    "city": "Guna",
    "county": "Guna NagarTahsil",
    "state": "Madhya Pradesh",
    "state_district": "Guna",
    "country": "India"
  },
  {
    "location": "geo:24.47,85.95",
    "count": 2,
    "city": "Jhanjh",
    "county": "Dhanwar",
    "state": "Jharkhand",
    "state_district": "Giridih",
    "country": "India"
  },
  {
    "location": "geo:19.26,84.85",
    "count": 2,
    "city": "Kamalapur",
    "county": "Rangeilunda",
    "state": "Odisha",
    "state_district": "Ganjam",
    "country": "India"
  },
  {
    "location": "geo:-6.37,107.17",
    "count": 2,
    "city": "Cicau",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:63.83,20.26",
    "count": 2,
    "city": "Umeå",
    "county": "Västerbotten County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:-18.92,47.52",
    "count": 2,
    "city": "Antananarivo",
    "county": null,
    "state": "Analamanga",
    "state_district": null,
    "country": "Madagascar"
  },
  {
    "location": "geo:23.89,90.41",
    "count": 2,
    "city": "Tongi",
    "county": null,
    "state": "Dhaka Division",
    "state_district": "Gazipur District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:23.78,88.63",
    "count": 2,
    "city": "Meherpur",
    "county": "Meherpur Sadar Upazila",
    "state": "Khulna Division",
    "state_district": "Meherpur District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:6.67,-1.62",
    "count": 2,
    "city": "Kumasi",
    "county": "Kumasi Metropolitan District",
    "state": "Ashanti Region",
    "state_district": null,
    "country": "Ghana"
  },
  {
    "location": "geo:23.72,-15.93",
    "count": 2,
    "city": "Dakhla",
    "county": "Pachalik de Dakhla باشوية الداخلة",
    "state": null,
    "state_district": null,
    "country": "Morocco"
  },
  {
    "location": "geo:-6.80,106.71",
    "count": 2,
    "city": "Lebaksari",
    "county": "Sukabumi",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-6.82,107.14",
    "count": 2,
    "city": "Cianjur",
    "county": "Cianjur",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:23.59,58.38",
    "count": 2,
    "city": "Muscat",
    "county": null,
    "state": "Muscat Governorate",
    "state_district": null,
    "country": "Oman"
  },
  {
    "location": "geo:-23.54,-46.31",
    "count": 2,
    "city": "Suzano",
    "county": "Região Metropolitana de São Paulo",
    "state": "São Paulo",
    "state_district": "Região Geográfica Intermediária de São Paulo",
    "country": "Brazil"
  },
  {
    "location": "geo:23.24,88.43",
    "count": 2,
    "city": "Shantipur",
    "county": "Santipur",
    "state": "West Bengal",
    "state_district": "Nadia",
    "country": "India"
  },
  {
    "location": "geo:22.58,113.08",
    "count": 2,
    "city": "Jiangmen",
    "county": null,
    "state": "Guangdong Province",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:22.29,91.98",
    "count": 2,
    "city": "Patiya",
    "county": null,
    "state": "Chattogram Division",
    "state_district": "Chattogram District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:45.19,-93.55",
    "count": 2,
    "city": "Rogers",
    "county": "Hennepin County",
    "state": "Minnesota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:45.35,28.85",
    "count": 2,
    "city": "Izmail",
    "county": null,
    "state": "Odesa Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:45.11,-79.59",
    "count": 2,
    "city": "Muskoka Lakes",
    "county": "Muskoka",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:22.29,89.96",
    "count": 2,
    "city": "Mathbaria Subdistrict",
    "county": null,
    "state": "Barishal Division",
    "state_district": "Pirojpur District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:44.88,-93.97",
    "count": 2,
    "city": "New Germany",
    "county": "Carver County",
    "state": "Minnesota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:11.79,124.32",
    "count": 2,
    "city": "Maripipi",
    "county": null,
    "state": "Biliran",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:44.80,-106.96",
    "count": 2,
    "city": "Sheridan",
    "county": "Sheridan County",
    "state": "Wyoming",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:44.77,19.65",
    "count": 2,
    "city": "City of Šabac",
    "county": "Macva Administrative District",
    "state": "Central Serbia",
    "state_district": null,
    "country": "Serbia"
  },
  {
    "location": "geo:44.68,34.41",
    "count": 2,
    "city": "Alushta",
    "county": "Alushta City Council",
    "state": "Autonomous Republic of Crimea",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:45.76,126.64",
    "count": 2,
    "city": "Harbin",
    "county": null,
    "state": "Heilongjiang",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:44.53,10.02",
    "count": 2,
    "city": "Berceto",
    "county": "Parma",
    "state": "Emilia-Romagna",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:44.34,1.02",
    "count": 2,
    "city": "Montaigu-de-Quercy",
    "county": "Montaigu-de-Quercy",
    "state": "Tarn-et-Garonne",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:44.33,-79.89",
    "count": 2,
    "city": "Essa",
    "county": "Simcoe",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:45.91,6.13",
    "count": 2,
    "city": "Annecy",
    "county": "Upper Savoy",
    "state": "Auvergne-Rhône-Alpes",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:43.72,20.69",
    "count": 2,
    "city": "Kraljevo",
    "county": "Raska Administrative District",
    "state": "Central Serbia",
    "state_district": null,
    "country": "Serbia"
  },
  {
    "location": "geo:46.19,28.39",
    "count": 2,
    "city": "Baimaclia",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Moldova"
  },
  {
    "location": "geo:43.63,-89.77",
    "count": 2,
    "city": "Wisconsin Dells",
    "county": "Columbia County",
    "state": "Wisconsin",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.45,-80.49",
    "count": 2,
    "city": "Kitchener",
    "county": "Waterloo",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:-12.79,-76.57",
    "count": 2,
    "city": "Asia",
    "county": null,
    "state": "Lima",
    "state_district": null,
    "country": "Peru"
  },
  {
    "location": "geo:11.58,108.99",
    "count": 2,
    "city": "Phan Rang–Tháp Chàm",
    "county": null,
    "state": "Ninh Thuận Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:46.77,23.62",
    "count": 2,
    "city": "Cluj-Napoca",
    "county": "Cluj",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:46.88,-96.79",
    "count": 2,
    "city": "Fargo",
    "county": "Cass County",
    "state": "North Dakota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.14,-79.48",
    "count": 2,
    "city": "Lincoln",
    "county": null,
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:46.97,32.00",
    "count": 2,
    "city": "Mykolaiv",
    "county": null,
    "state": "Mykolaiv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:47.00,1.68",
    "count": 2,
    "city": "Châteauroux",
    "county": "Indre",
    "state": "Centre-Val de Loire",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:45.73,-97.81",
    "count": 1,
    "city": "Britton",
    "county": "Marshall County",
    "state": "South Dakota",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.51,15.08",
    "count": 1,
    "city": "Catania",
    "county": "Catania",
    "state": "Sicily",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:25.71,-99.24",
    "count": 1,
    "city": "China",
    "county": "China",
    "state": "Nuevo León",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:37.32,-122.03",
    "count": 1,
    "city": "San Jose",
    "county": "Santa Clara County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:25.61,100.27",
    "count": 1,
    "city": "Dali City",
    "county": null,
    "state": "Yunnan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:51.16,13.50",
    "count": 1,
    "city": "Meissen",
    "county": "Meißen",
    "state": "Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:45.76,11.73",
    "count": 1,
    "city": "Bassano del Grappa",
    "county": "Vicenza",
    "state": "Veneto",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:37.27,49.59",
    "count": 1,
    "city": "Rasht",
    "county": "Rasht County",
    "state": "Gilan Province",
    "state_district": null,
    "country": "Iran"
  },
  {
    "location": "geo:37.24,127.19",
    "count": 1,
    "city": null,
    "county": "Cheoin",
    "state": "Kyeongki-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:51.18,14.42",
    "count": 1,
    "city": "Bautzen",
    "county": "Bautzen - Budyšin",
    "state": "Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:43.14,-80.26",
    "count": 1,
    "city": "Brantford",
    "county": "Brant",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:51.20,6.69",
    "count": 1,
    "city": "Neuss",
    "county": null,
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:37.15,-88.73",
    "count": 1,
    "city": "Metropolis",
    "county": "Massac County",
    "state": "Illinois",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.10,-8.67",
    "count": 1,
    "city": "Lagos",
    "county": "Faro",
    "state": null,
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:-0.03,109.34",
    "count": 1,
    "city": "Pontianak",
    "county": null,
    "state": "West Kalimantan",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:37.07,37.38",
    "count": 1,
    "city": "Şehitkamil",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:37.07,22.43",
    "count": 1,
    "city": "Municipal Unit of Sparta",
    "county": "Laconia Regional Unit",
    "state": "Peloponnese, Western Greece and the Ionian",
    "state_district": "Peloponnese Region",
    "country": "Greece"
  },
  {
    "location": "geo:-2.54,112.95",
    "count": 1,
    "city": "Sampit",
    "county": "East Kotawaringin",
    "state": "Central Kalimantan",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:11.66,78.15",
    "count": 1,
    "city": "Salem",
    "county": null,
    "state": "Tamil Nadu",
    "state_district": "Salem",
    "country": "India"
  },
  {
    "location": "geo:36.85,-76.29",
    "count": 1,
    "city": "Norfolk",
    "county": "City of Norfolk",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:51.27,10.81",
    "count": 1,
    "city": "Ebeleben",
    "county": "Kyffhäuserkreis",
    "state": "Thuringia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:25.33,89.54",
    "count": 1,
    "city": "Shadullahpur",
    "county": "Sadullapur Subdistrict",
    "state": "Rangpur Division",
    "state_district": "Gaibandha District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:44.53,-78.90",
    "count": 1,
    "city": "Kawartha Lakes",
    "county": null,
    "state": "Ontario",
    "state_district": "Central Ontario",
    "country": "Canada"
  },
  {
    "location": "geo:20.90,74.77",
    "count": 1,
    "city": "Dhule",
    "county": "Dhule Taluka",
    "state": "Maharashtra",
    "state_district": "Dhule District",
    "country": "India"
  },
  {
    "location": "geo:36.83,-119.70",
    "count": 1,
    "city": "Clovis",
    "county": "Fresno County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:36.81,34.64",
    "count": 1,
    "city": "Mersin",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:36.78,31.40",
    "count": 1,
    "city": "Manavgat",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:51.34,12.37",
    "count": 1,
    "city": "Leipzig",
    "county": null,
    "state": "Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:25.18,83.29",
    "count": 1,
    "city": null,
    "county": "Chandauli",
    "state": "Uttar Pradesh",
    "state_district": "Chandauli",
    "country": "India"
  },
  {
    "location": "geo:43.29,17.02",
    "count": 1,
    "city": "Makarska",
    "county": "Split-Dalmatia County",
    "state": null,
    "state_district": null,
    "country": "Croatia"
  },
  {
    "location": "geo:36.74,-79.47",
    "count": 1,
    "city": "Dry Fork",
    "county": "Pittsylvania County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:36.68,48.51",
    "count": 1,
    "city": "Zanjan",
    "county": "Zanjan County",
    "state": "Zanjan Province",
    "state_district": null,
    "country": "Iran"
  },
  {
    "location": "geo:18.41,83.34",
    "count": 1,
    "city": "Marrivalasa",
    "county": "Dattirajeru",
    "state": "Andhra Pradesh",
    "state_district": "Vizianagaram",
    "country": "India"
  },
  {
    "location": "geo:-5.14,105.25",
    "count": 1,
    "city": "Leman Benawi",
    "county": "Lampung Tengah",
    "state": "Lampung",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:36.65,117.12",
    "count": 1,
    "city": "Lixia District",
    "county": null,
    "state": "Shandong",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:51.42,5.46",
    "count": 1,
    "city": "Eindhoven",
    "county": null,
    "state": "North Brabant",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:36.63,127.49",
    "count": 1,
    "city": null,
    "county": "Sangdang",
    "state": "Chungcheongbuk-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:25.06,73.89",
    "count": 1,
    "city": "Rajsamand",
    "county": "Rajsamand Tehsil",
    "state": "Rajasthan",
    "state_district": "Rajsamand",
    "country": "India"
  },
  {
    "location": "geo:0.92,104.47",
    "count": 1,
    "city": "Tanjung Pinang",
    "county": null,
    "state": "Riau Islands",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:61.86,21.51",
    "count": 1,
    "city": "Merikarvia",
    "county": "Satakunta",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:44.52,-75.02",
    "count": 1,
    "city": "Town of Pierrepont",
    "county": "Saint Lawrence County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.10,-75.23",
    "count": 1,
    "city": "Utica",
    "county": "Oneida County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:51.49,19.68",
    "count": 1,
    "city": "Municipality Moszczenica",
    "county": "Piotrków County",
    "state": "Łódź Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:36.44,139.91",
    "count": 1,
    "city": null,
    "county": "Kaminokawa",
    "state": "Tochigi Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:25.02,90.01",
    "count": 1,
    "city": "Sherpur",
    "county": "Sherpur Sadar Upazila",
    "state": "Mymensingh Division",
    "state_district": "Sherpur District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:-2.22,113.91",
    "count": 1,
    "city": "Palangka Raya",
    "county": null,
    "state": "Central Kalimantan",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:36.13,10.38",
    "count": 1,
    "city": "Enfidha",
    "county": "النفيضة",
    "state": "Sousse",
    "state_district": "معتمدية النفيضة",
    "country": "Tunisia"
  },
  {
    "location": "geo:3.60,98.67",
    "count": 1,
    "city": "City of Medan",
    "county": null,
    "state": "North Sumatra",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:9.98,76.30",
    "count": 1,
    "city": "Ernakulam",
    "county": "Kanayannur",
    "state": "Kerala",
    "state_district": "Ernakulam",
    "country": "India"
  },
  {
    "location": "geo:51.54,9.92",
    "count": 1,
    "city": "Göttingen",
    "county": "Landkreis Göttingen",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:35.99,139.66",
    "count": 1,
    "city": "Hasuda-shi",
    "county": "Hasuda",
    "state": "Saitama Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:-62.16,-58.47",
    "count": 1,
    "city": "Henryk Arctowski Polish Antarctic Station",
    "county": null,
    "state": null,
    "state_district": null,
    "country": null
  },
  {
    "location": "geo:35.99,139.09",
    "count": 1,
    "city": "Chichibu",
    "county": "Chichibu County",
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:51.58,0.71",
    "count": 1,
    "city": "Rochford",
    "county": "Essex",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:11.56,104.93",
    "count": 1,
    "city": "Khan Daun Penh",
    "county": null,
    "state": "Phnom Penh",
    "state_district": null,
    "country": "Cambodia"
  },
  {
    "location": "geo:51.60,18.94",
    "count": 1,
    "city": "Zduńska Wola",
    "county": "Zduńska Wola County",
    "state": "Łódź Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:24.99,121.30",
    "count": 1,
    "city": "Taoyuan City",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Taiwan"
  },
  {
    "location": "geo:51.61,7.20",
    "count": 1,
    "city": "Recklinghausen",
    "county": "Kreis Recklinghausen",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:35.97,45.76",
    "count": 1,
    "city": "دهستان پشت آربابا",
    "county": "Baneh County",
    "state": "Kurdistan Province",
    "state_district": null,
    "country": "Iran"
  },
  {
    "location": "geo:24.88,90.72",
    "count": 1,
    "city": "Netrokona",
    "county": null,
    "state": "Mymensingh Division",
    "state_district": "Netrokona District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:43.08,-77.77",
    "count": 1,
    "city": "Churchville",
    "county": "Monroe County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:24.88,102.83",
    "count": 1,
    "city": "Chenggong District",
    "county": null,
    "state": "Yunnan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:9.31,123.31",
    "count": 1,
    "city": "Dumaguete",
    "county": null,
    "state": "Negros Oriental",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:45.92,15.31",
    "count": 1,
    "city": "Jerman Vrh",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Slovenia"
  },
  {
    "location": "geo:35.93,-86.87",
    "count": 1,
    "city": "Franklin",
    "county": "Williamson County",
    "state": "Tennessee",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:35.89,14.40",
    "count": 1,
    "city": "Mtarfa",
    "county": null,
    "state": "Northern Region",
    "state_district": null,
    "country": "Malta"
  },
  {
    "location": "geo:44.28,0.12",
    "count": 1,
    "city": "Nérac",
    "county": "Lot-et-Garonne",
    "state": "Nouvelle-Aquitaine",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:-4.42,-40.91",
    "count": 1,
    "city": "Croatá",
    "county": null,
    "state": "Ceará",
    "state_district": "Região Geográfica Intermediária de Sobral",
    "country": "Brazil"
  },
  {
    "location": "geo:51.74,14.65",
    "count": 1,
    "city": "Forst (Lausitz) - Baršć",
    "county": "Spree-Neiße - Sprjewja-Nysa",
    "state": "Brandenburg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:35.82,-78.31",
    "count": 1,
    "city": "Zebulon",
    "county": "Wake County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-20.77,-41.67",
    "count": 1,
    "city": "Guaçuí",
    "county": null,
    "state": "Espírito Santo",
    "state_district": "Região Geográfica Intermediária de Cachoeiro de Itapemirim",
    "country": "Brazil"
  },
  {
    "location": "geo:35.73,10.58",
    "count": 1,
    "city": "M'Saken",
    "county": "التوارة الجنوبية",
    "state": "Sousse",
    "state_district": "معتمدية مساكن",
    "country": "Tunisia"
  },
  {
    "location": "geo:43.87,-79.87",
    "count": 1,
    "city": "Caledon",
    "county": "Peel",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:35.69,138.69",
    "count": 1,
    "city": null,
    "county": "Yamanashi",
    "state": "Yamanashi Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:51.83,107.58",
    "count": 1,
    "city": "Ulan-Ude",
    "county": "городской округ Улан-Удэ",
    "state": "Buryatia",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:-9.53,-77.53",
    "count": 1,
    "city": "Huaraz",
    "county": null,
    "state": "Ancash",
    "state_district": null,
    "country": "Peru"
  },
  {
    "location": "geo:1.47,124.84",
    "count": 1,
    "city": "Manado",
    "county": null,
    "state": "North Sulawesi",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-6.31,107.32",
    "count": 1,
    "city": "Karawang",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:24.31,91.73",
    "count": 1,
    "city": "Sreemangal",
    "county": "Sreemangal Upazila",
    "state": "Sylhet Division",
    "state_district": "Moulvibazar District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:63.45,16.80",
    "count": 1,
    "city": "Ådals-Liden District",
    "county": "Västernorrland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:14.76,121.04",
    "count": 1,
    "city": "Caloocan",
    "county": null,
    "state": null,
    "state_district": "Northern Manila District",
    "country": "Philippines"
  },
  {
    "location": "geo:35.57,-80.58",
    "count": 1,
    "city": "China Grove",
    "county": "Rowan County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:19.25,-99.60",
    "count": 1,
    "city": "Metepec",
    "county": "Metepec",
    "state": "State of Mexico",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:-35.49,-62.98",
    "count": 1,
    "city": "América",
    "county": null,
    "state": "Buenos Aires",
    "state_district": "Partido de Rivadavia",
    "country": "Argentina"
  },
  {
    "location": "geo:51.90,8.39",
    "count": 1,
    "city": "Gütersloh",
    "county": "Kreis Gütersloh",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-17.83,31.05",
    "count": 1,
    "city": "Harare",
    "county": "Harare",
    "state": "Harare",
    "state_district": null,
    "country": "Zimbabwe"
  },
  {
    "location": "geo:35.44,137.75",
    "count": 1,
    "city": "Achi",
    "county": "Shimoina District",
    "state": null,
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:35.44,136.69",
    "count": 1,
    "city": null,
    "county": "Kitagata",
    "state": "Gifu Prefecture",
    "state_district": null,
    "country": "Japan"
  },
  {
    "location": "geo:-7.45,112.70",
    "count": 1,
    "city": "Sidoarjo",
    "county": "Sidoarjo",
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:46.06,14.51",
    "count": 1,
    "city": "Ljubljana",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Slovenia"
  },
  {
    "location": "geo:35.28,25.46",
    "count": 1,
    "city": "Malia Municipal Unit",
    "county": "Heraklion Regional Unit",
    "state": "Region of Crete",
    "state_district": "Region of Crete",
    "country": "Greece"
  },
  {
    "location": "geo:9.66,80.03",
    "count": 1,
    "city": "Ariyalai",
    "county": null,
    "state": "Northern Province",
    "state_district": "Jaffna District",
    "country": "Sri Lanka"
  },
  {
    "location": "geo:-35.28,149.13",
    "count": 1,
    "city": "City",
    "county": null,
    "state": "Australian Capital Territory",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:51.98,-0.53",
    "count": 1,
    "city": "Tingrith",
    "county": "Central Bedfordshire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:51.98,9.83",
    "count": 1,
    "city": "Alfeld (Leine)",
    "county": "Landkreis Hildesheim",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:64.07,-141.94",
    "count": 1,
    "city": "Chicken",
    "county": "Unorganized Borough",
    "state": "Alaska",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:20.63,-87.07",
    "count": 1,
    "city": "Playa del Carmen",
    "county": null,
    "state": "Quintana Roo",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:24.23,88.25",
    "count": 1,
    "city": "Jiaganj Azimganj",
    "county": "Murshidabad Jiaganj",
    "state": "West Bengal",
    "state_district": "Murshidabad",
    "country": "India"
  },
  {
    "location": "geo:14.79,120.88",
    "count": 1,
    "city": "Bulakan",
    "county": null,
    "state": "Bulacan",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:1.48,103.76",
    "count": 1,
    "city": "Johor Bahru",
    "county": null,
    "state": "Johor",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:52.06,1.15",
    "count": 1,
    "city": "Ipswich",
    "county": "Suffolk",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:-0.68,100.25",
    "count": 1,
    "city": null,
    "county": "Padang Pariaman",
    "state": "West Sumatra",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:24.18,88.28",
    "count": 1,
    "city": "Murshidabad",
    "county": "Murshidabad Jiaganj",
    "state": "West Bengal",
    "state_district": "Murshidabad",
    "country": "India"
  },
  {
    "location": "geo:14.81,74.70",
    "count": 1,
    "city": "Sonaginamane",
    "county": "Shirasi Taluk",
    "state": "Karnataka",
    "state_district": "Uttara Kannada",
    "country": "India"
  },
  {
    "location": "geo:35.00,-80.86",
    "count": 1,
    "city": "Charlotte",
    "county": "Lancaster County",
    "state": "South Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.11,5.72",
    "count": 1,
    "city": "Ede",
    "county": null,
    "state": "Gelderland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:34.99,-80.55",
    "count": 1,
    "city": "Monroe",
    "county": "Union County",
    "state": "North Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:24.03,87.98",
    "count": 1,
    "city": "Khargram",
    "county": "Khargram",
    "state": "West Bengal",
    "state_district": "Murshidabad",
    "country": "India"
  },
  {
    "location": "geo:-14.94,-71.32",
    "count": 1,
    "city": "Espinar",
    "county": null,
    "state": "Cusco",
    "state_district": null,
    "country": "Peru"
  },
  {
    "location": "geo:24.00,91.11",
    "count": 1,
    "city": "Brahmanbaria",
    "county": "Brahmanbaria Sadar Upazila",
    "state": "Chattogram Division",
    "state_district": "Brahmanbaria District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:-34.77,-60.69",
    "count": 1,
    "city": "Irala",
    "county": "Cuartel Irala",
    "state": "Buenos Aires",
    "state_district": "Partido de Bragado",
    "country": "Argentina"
  },
  {
    "location": "geo:23.93,91.12",
    "count": 1,
    "city": "Brahmanbaria",
    "county": "Brahmanbaria Sadar Upazila",
    "state": "Chattogram Division",
    "state_district": "Brahmanbaria District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:34.74,10.76",
    "count": 1,
    "city": "Sfax",
    "county": "المدينة",
    "state": "Sfax",
    "state_district": "معتمدية صفاقس المدينة",
    "country": "Tunisia"
  },
  {
    "location": "geo:46.10,20.41",
    "count": 1,
    "city": "Colonia Bulgară",
    "county": "Timiș",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:4.61,100.90",
    "count": 1,
    "city": "Manong",
    "county": null,
    "state": "Perak",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:65.31,25.38",
    "count": 1,
    "city": "Ii",
    "county": "North Ostrobothnia",
    "state": null,
    "state_district": null,
    "country": "Finland"
  },
  {
    "location": "geo:-34.50,-58.57",
    "count": 1,
    "city": "Boulogne Sur Mer",
    "county": null,
    "state": "Buenos Aires",
    "state_district": "Partido de San Isidro",
    "country": "Argentina"
  },
  {
    "location": "geo:-6.54,107.45",
    "count": 1,
    "city": "Purwakarta",
    "county": "Purwakarta",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:34.39,-100.90",
    "count": 1,
    "city": "Turkey",
    "county": "Hall County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:34.27,108.95",
    "count": 1,
    "city": "Xincheng District",
    "county": null,
    "state": "Shaanxi",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:-6.56,107.76",
    "count": 1,
    "city": "Sukamelang",
    "county": "Subang",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:34.15,-118.14",
    "count": 1,
    "city": "Pasadena",
    "county": "Los Angeles County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:23.79,86.95",
    "count": 1,
    "city": null,
    "county": "Barabani",
    "state": "West Bengal",
    "state_district": "Paschim Bardhaman",
    "country": "India"
  },
  {
    "location": "geo:52.29,104.29",
    "count": 1,
    "city": "Irkutsk",
    "county": "городской округ Иркутск",
    "state": "Irkutsk Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:23.78,89.41",
    "count": 1,
    "city": "Pangsha",
    "county": "Pangsha Upazila",
    "state": "Dhaka Division",
    "state_district": "Rajbari District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:-6.60,106.81",
    "count": 1,
    "city": "Bogor",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-4.38,141.85",
    "count": 1,
    "city": null,
    "county": "Ambunti District",
    "state": "East Sepik",
    "state_district": null,
    "country": "Papua New Guinea"
  },
  {
    "location": "geo:52.37,8.32",
    "count": 1,
    "city": "Bohmte",
    "county": "Landkreis Osnabrück",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:34.04,-80.94",
    "count": 1,
    "city": "Forest Acres",
    "county": "Richland County",
    "state": "South Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:33.99,-95.52",
    "count": 1,
    "city": "Hugo",
    "county": "Choctaw County",
    "state": "Oklahoma",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.39,4.65",
    "count": 1,
    "city": "Haarlem",
    "county": null,
    "state": "North Holland",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:-2.05,112.52",
    "count": 1,
    "city": null,
    "county": "East Kotawaringin",
    "state": "Central Kalimantan",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:23.76,88.94",
    "count": 1,
    "city": "Alamdanga",
    "county": "Alamdanga Upazila",
    "state": "Khulna Division",
    "state_district": "Chuadanga District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:-20.46,-54.62",
    "count": 1,
    "city": "Campo Grande",
    "county": null,
    "state": "Mato Grosso do Sul",
    "state_district": "Região Geográfica Intermediária de Campo Grande",
    "country": "Brazil"
  },
  {
    "location": "geo:43.17,5.61",
    "count": 1,
    "city": "La Ciotat",
    "county": "Bouches-du-Rhône",
    "state": "Provence-Alpes-Côte d'Azur",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:20.44,106.16",
    "count": 1,
    "city": "Nam Định City",
    "county": null,
    "state": "Nam Định Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:33.87,74.89",
    "count": 1,
    "city": "Pulwama",
    "county": "Pulwama tehsil",
    "state": "Jammu and Kashmir",
    "state_district": "Pulwama",
    "country": "India"
  },
  {
    "location": "geo:3.38,-74.80",
    "count": 1,
    "city": "Colombia",
    "county": "Colombia",
    "state": "Huila",
    "state_district": "Norte",
    "country": "Colombia"
  },
  {
    "location": "geo:43.17,-2.63",
    "count": 1,
    "city": "Durango",
    "county": null,
    "state": "Biscay",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:52.55,0.09",
    "count": 1,
    "city": "Fenland District",
    "county": "Cambridgeshire",
    "state": "England",
    "state_district": "Cambridgeshire and Peterborough",
    "country": "United Kingdom"
  },
  {
    "location": "geo:33.85,44.23",
    "count": 1,
    "city": "Ad Dujayl",
    "county": null,
    "state": "Saladin Governorate",
    "state_district": null,
    "country": "Iraq"
  },
  {
    "location": "geo:52.59,-1.98",
    "count": 1,
    "city": "Walsall",
    "county": null,
    "state": "England",
    "state_district": "West Midlands Combined Authority",
    "country": "United Kingdom"
  },
  {
    "location": "geo:33.80,-84.23",
    "count": 1,
    "city": "Stone Mountain",
    "county": "DeKalb County",
    "state": "Georgia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-33.80,139.06",
    "count": 1,
    "city": "Worlds End",
    "county": null,
    "state": "South Australia",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:15.48,78.49",
    "count": 1,
    "city": "Babanagaram",
    "county": "Nandyal",
    "state": "Andhra Pradesh",
    "state_district": "Nandyal",
    "country": "India"
  },
  {
    "location": "geo:-6.72,107.03",
    "count": 1,
    "city": "Sindangjaya",
    "county": "Cianjur",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:15.51,80.05",
    "count": 1,
    "city": "Ongole",
    "county": "Ongole",
    "state": "Andhra Pradesh",
    "state_district": "Prakasam",
    "country": "India"
  },
  {
    "location": "geo:-6.73,108.55",
    "count": 1,
    "city": "Cirebon",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:23.65,90.15",
    "count": 1,
    "city": "Nawabganj",
    "county": "Nawabganj Upazila",
    "state": "Dhaka Division",
    "state_district": "Dhaka District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:52.68,-1.92",
    "count": 1,
    "city": "Lichfield",
    "county": "Staffordshire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:33.58,-101.86",
    "count": 1,
    "city": "Lubbock",
    "county": "Lubbock County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.70,20.09",
    "count": 1,
    "city": "Arilje Municipality",
    "county": "Zlatibor Administrative District",
    "state": "Central Serbia",
    "state_district": null,
    "country": "Serbia"
  },
  {
    "location": "geo:10.30,106.37",
    "count": 1,
    "city": "Xã Phú An Hoà",
    "county": "Chau Thanh District",
    "state": "Bến Tre Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:33.50,126.52",
    "count": 1,
    "city": "Jeju-Si",
    "county": "Jeju",
    "state": "Jaeju-Do",
    "state_district": null,
    "country": "South Korea"
  },
  {
    "location": "geo:33.46,-88.79",
    "count": 1,
    "city": "Mississippi State",
    "county": "Oktibbeha County",
    "state": "Mississippi",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:52.85,8.05",
    "count": 1,
    "city": "Cloppenburg",
    "county": "Cloppenburg district",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-6.81,110.84",
    "count": 1,
    "city": "Kudus",
    "county": null,
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:33.43,-94.04",
    "count": 1,
    "city": "Texarkana",
    "county": "Miller County",
    "state": "Arkansas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:12.30,76.64",
    "count": 1,
    "city": "Mysuru",
    "county": "Mysuru taluk",
    "state": "Karnataka",
    "state_district": "Mysuru",
    "country": "India"
  },
  {
    "location": "geo:33.28,35.55",
    "count": 1,
    "city": "Kafr Kila",
    "county": null,
    "state": "Nabatieh Governorate",
    "state_district": "Marjayoun District",
    "country": "Lebanon"
  },
  {
    "location": "geo:46.85,29.60",
    "count": 1,
    "city": "Tiraspol",
    "county": null,
    "state": "Pridnestrovie",
    "state_district": null,
    "country": "Moldova"
  },
  {
    "location": "geo:16.76,77.14",
    "count": 1,
    "city": "Yadgiri",
    "county": "Yadagiri taluku",
    "state": "Karnataka",
    "state_district": "Yadgir",
    "country": "India"
  },
  {
    "location": "geo:-6.84,107.93",
    "count": 1,
    "city": "lembur tengah",
    "county": "Sumedang",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:53.06,14.28",
    "count": 1,
    "city": "Schwedt/Oder",
    "county": "Uckermark",
    "state": "Brandenburg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:32.99,74.93",
    "count": 1,
    "city": "Katra",
    "county": "Reasi",
    "state": "Jammu and Kashmir",
    "state_district": "Reasi",
    "country": "India"
  },
  {
    "location": "geo:-32.96,27.36",
    "count": 1,
    "city": "Ngqushwa Local Municipality",
    "county": "Amathole District Municipality",
    "state": "Eastern Cape",
    "state_district": null,
    "country": "South Africa"
  },
  {
    "location": "geo:6.84,7.37",
    "count": 1,
    "city": "Nsukka",
    "county": null,
    "state": "Enugu State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:32.89,115.81",
    "count": 1,
    "city": "Yingzhou District",
    "county": null,
    "state": "Anhui",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:53.14,8.21",
    "count": 1,
    "city": "Oldenburg",
    "county": null,
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:53.18,18.60",
    "count": 1,
    "city": "Chełmża",
    "county": "Toruń County",
    "state": "Kuyavian-Pomeranian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:-6.88,106.95",
    "count": 1,
    "city": "Cisarua",
    "county": "Sukabumi",
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:53.20,50.16",
    "count": 1,
    "city": "Samara",
    "county": "Samara",
    "state": "Samara Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:32.84,35.09",
    "count": 1,
    "city": "Kiryat Bialik",
    "county": null,
    "state": "Haifa District",
    "state_district": "Haifa Subdistrict",
    "country": "Israel"
  },
  {
    "location": "geo:23.54,89.17",
    "count": 1,
    "city": "Jhenaidah",
    "county": "Jhenaidah Sadar Upazila",
    "state": "Khulna Division",
    "state_district": "Jhenaidah District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:53.22,-4.20",
    "count": 1,
    "city": "Llanfairpwllgwyngyll",
    "county": "Isle of Anglesey",
    "state": "Wales",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:53.22,6.57",
    "count": 1,
    "city": "Groningen",
    "county": null,
    "state": "Groningen",
    "state_district": null,
    "country": "Netherlands"
  },
  {
    "location": "geo:32.65,-96.32",
    "count": 1,
    "city": "Kaufman",
    "county": "Kaufman County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:53.25,10.42",
    "count": 1,
    "city": "Lüneburg",
    "county": "Lüneburg",
    "state": "Lower Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-7.46,109.29",
    "count": 1,
    "city": "Sokaraja Tengah",
    "county": null,
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:5.33,103.14",
    "count": 1,
    "city": "Kuala Terengganu",
    "county": "Kuala Terengganu",
    "state": "Terengganu",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:53.32,-3.49",
    "count": 1,
    "city": "Rhyl",
    "county": "Denbighshire",
    "state": "Wales",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:6.92,122.08",
    "count": 1,
    "city": "Zamboanga City",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:32.59,73.04",
    "count": 1,
    "city": "Pind Dadan Khan",
    "county": "Jhelum District",
    "state": "Punjab",
    "state_district": "Rawalpindi Division",
    "country": "Pakistan"
  },
  {
    "location": "geo:23.49,87.74",
    "count": 1,
    "city": "Guskara",
    "county": "Ausgram - I",
    "state": "West Bengal",
    "state_district": "Purba Bardhaman",
    "country": "India"
  },
  {
    "location": "geo:-6.93,110.20",
    "count": 1,
    "city": "Kalibuntu Wetan",
    "county": "Kendal",
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-23.42,-51.93",
    "count": 1,
    "city": "Maringá",
    "county": null,
    "state": "Paraná",
    "state_district": "Região Geográfica Intermediária de Maringá",
    "country": "Brazil"
  },
  {
    "location": "geo:53.43,14.55",
    "count": 1,
    "city": "Szczecin",
    "county": null,
    "state": "West Pomeranian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:-6.95,108.97",
    "count": 1,
    "city": "Sitanggal",
    "county": "Brebes",
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:32.22,-80.75",
    "count": 1,
    "city": "Hilton Head",
    "county": "Beaufort County",
    "state": "South Carolina",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-32.05,115.76",
    "count": 1,
    "city": "Fremantle",
    "county": "Fremantle",
    "state": "Western Australia",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:6.96,37.86",
    "count": 1,
    "city": "Boditi",
    "county": null,
    "state": "South Ethiopia Regional State",
    "state_district": "Wolayita",
    "country": "Ethiopia"
  },
  {
    "location": "geo:53.63,-8.18",
    "count": 1,
    "city": "Roscommon",
    "county": "County Roscommon",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:53.68,-114.35",
    "count": 1,
    "city": "Alberta Beach",
    "county": "Lac Ste. Anne",
    "state": "Alberta",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:53.70,-0.87",
    "count": 1,
    "city": "Goole",
    "county": "East Riding of Yorkshire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:53.73,-7.80",
    "count": 1,
    "city": "Longford",
    "county": "County Longford",
    "state": null,
    "state_district": null,
    "country": "Ireland"
  },
  {
    "location": "geo:-5.37,-49.12",
    "count": 1,
    "city": "Marabá",
    "county": null,
    "state": "Pará",
    "state_district": "Região Geográfica Intermediária de Marabá",
    "country": "Brazil"
  },
  {
    "location": "geo:31.69,-106.42",
    "count": 1,
    "city": "Ciudad Juarez",
    "county": "Juárez",
    "state": "Chihuahua",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:46.24,14.36",
    "count": 1,
    "city": "Kranj",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Slovenia"
  },
  {
    "location": "geo:31.55,-97.15",
    "count": 1,
    "city": "Waco",
    "county": "McLennan County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:15.84,108.39",
    "count": 1,
    "city": null,
    "county": "Duy Xuyên District",
    "state": "Quảng Nam Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:23.16,89.21",
    "count": 1,
    "city": "Jashore",
    "county": null,
    "state": "Khulna Division",
    "state_district": "Jashore District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:53.89,25.30",
    "count": 1,
    "city": "Lida",
    "county": "Lida District",
    "state": "Hrodna Region",
    "state_district": null,
    "country": "Belarus"
  },
  {
    "location": "geo:31.16,-81.39",
    "count": 1,
    "city": "St. Simons",
    "county": "Glynn County",
    "state": "Georgia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:53.91,14.25",
    "count": 1,
    "city": "Świnoujście",
    "county": null,
    "state": "West Pomeranian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:23.16,87.05",
    "count": 1,
    "city": null,
    "county": "Onda",
    "state": "West Bengal",
    "state_district": "Bankura",
    "country": "India"
  },
  {
    "location": "geo:23.11,91.98",
    "count": 1,
    "city": "Khagrachhari",
    "county": "Khagrachhari Sadar Upazila",
    "state": "Chattogram Division",
    "state_district": "Khagrachari Hill District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:31.13,-97.52",
    "count": 1,
    "city": "Temple",
    "county": "Bell County",
    "state": "Texas",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:22.88,40.49",
    "count": 1,
    "city": "شبرقان",
    "county": null,
    "state": "Medina Province",
    "state_district": null,
    "country": "Saudi Arabia"
  },
  {
    "location": "geo:31.13,104.40",
    "count": 1,
    "city": "Jingyang District",
    "county": null,
    "state": "Sichuan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:22.78,73.61",
    "count": 1,
    "city": "Godhra",
    "county": "Godhra Taluka",
    "state": "Gujarat",
    "state_district": "Panchmahal",
    "country": "India"
  },
  {
    "location": "geo:-8.68,-35.58",
    "count": 1,
    "city": "Palmares",
    "county": null,
    "state": "Pernambuco",
    "state_district": "Região Geográfica Intermediária do Recife",
    "country": "Brazil"
  },
  {
    "location": "geo:-7.12,-34.88",
    "count": 1,
    "city": "João Pessoa",
    "county": "Região Metropolitana de João Pessoa",
    "state": "Paraíba",
    "state_district": "Região Geográfica Intermediária de João Pessoa",
    "country": "Brazil"
  },
  {
    "location": "geo:22.72,89.07",
    "count": 1,
    "city": "Satkhira",
    "county": "Satkhira Sadar Upazila",
    "state": "Khulna Division",
    "state_district": "Satkhira District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:22.71,90.35",
    "count": 1,
    "city": "Barishal",
    "county": "Barisal Sadar Subdistrict",
    "state": "Barishal Division",
    "state_district": "Barisal District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:30.66,117.49",
    "count": 1,
    "city": "Chizhou",
    "county": null,
    "state": "Anhui",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:18.79,78.91",
    "count": 1,
    "city": "Lingampeta",
    "county": "Jagtial mandal",
    "state": "Telangana",
    "state_district": "Jagtial",
    "country": "India"
  },
  {
    "location": "geo:46.31,28.65",
    "count": 1,
    "city": "Comrat",
    "county": null,
    "state": null,
    "state_district": "Comrat District",
    "country": "Moldova"
  },
  {
    "location": "geo:-7.22,107.90",
    "count": 1,
    "city": "Garut",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:30.08,103.85",
    "count": 1,
    "city": "Meishan",
    "county": null,
    "state": "Sichuan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:30.01,103.04",
    "count": 1,
    "city": "Ya'an",
    "county": null,
    "state": "Sichuan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:29.96,75.71",
    "count": 1,
    "city": "Gidrani",
    "county": "Lehra Tahsil",
    "state": "Punjab",
    "state_district": "Sangrur",
    "country": "India"
  },
  {
    "location": "geo:-29.95,-51.10",
    "count": 1,
    "city": "Cachoeirinha",
    "county": "Metropolitan Region of Porto Alegre",
    "state": "Rio Grande do Sul",
    "state_district": "Região Geográfica Intermediária de Porto Alegre",
    "country": "Brazil"
  },
  {
    "location": "geo:-9.84,-39.48",
    "count": 1,
    "city": "Uauá",
    "county": null,
    "state": "Bahia",
    "state_district": "Região Geográfica Intermediária de Juazeiro",
    "country": "Brazil"
  },
  {
    "location": "geo:29.36,47.91",
    "count": 1,
    "city": "Shuwaikh Medical Health Area Quarter",
    "county": null,
    "state": "Capital Governorate",
    "state_district": null,
    "country": "Kuwait"
  },
  {
    "location": "geo:54.51,8.92",
    "count": 1,
    "city": "Nordsee-Treene",
    "county": "Northern Friesland",
    "state": "Schleswig-Holstein",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:22.36,90.33",
    "count": 1,
    "city": "Patuakhali",
    "county": "Patuakhali Sadar Subdistrict",
    "state": "Barishal Division",
    "state_district": "Patuakhali District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:29.33,76.30",
    "count": 1,
    "city": "Jind",
    "county": "Jind",
    "state": "Haryana",
    "state_district": "Jind",
    "country": "India"
  },
  {
    "location": "geo:29.31,78.50",
    "count": 1,
    "city": "Dhampur",
    "county": "Dhampur",
    "state": "Uttar Pradesh",
    "state_district": "Bijnor",
    "country": "India"
  },
  {
    "location": "geo:2.92,101.66",
    "count": 1,
    "city": "Sepang",
    "county": null,
    "state": "Selangor",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:22.32,72.62",
    "count": 1,
    "city": "Khambhat",
    "county": "Khambhat Taluka",
    "state": "Gujarat",
    "state_district": "Anand",
    "country": "India"
  },
  {
    "location": "geo:29.03,111.70",
    "count": 1,
    "city": "Wuling District",
    "county": null,
    "state": "Hunan",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:2.01,98.98",
    "count": 1,
    "city": "Hutagalung Siwaluompu",
    "county": "North Tapanuli",
    "state": "North Sumatra",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:2.90,102.40",
    "count": 1,
    "city": null,
    "county": null,
    "state": "Negeri Sembilan",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:42.89,-77.28",
    "count": 1,
    "city": "Canandaigua",
    "county": "Ontario County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.10,9.61",
    "count": 1,
    "city": "Malbun",
    "county": "Oberland",
    "state": null,
    "state_district": null,
    "country": "Liechtenstein"
  },
  {
    "location": "geo:10.14,-71.21",
    "count": 1,
    "city": "Lagunillas",
    "county": "Municipio Lagunillas",
    "state": "Zulia State",
    "state_district": null,
    "country": "Venezuela"
  },
  {
    "location": "geo:54.96,20.48",
    "count": 1,
    "city": "Zelenogradsk",
    "county": "Zelenogradsky District",
    "state": "Kaliningrad",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:42.81,-1.65",
    "count": 1,
    "city": "Pamplona",
    "county": "Iruñerria / Comarca de Pamplona",
    "state": "Navarre",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:42.77,-3.54",
    "count": 1,
    "city": "Rucandio",
    "county": null,
    "state": "Burgos",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:47.23,8.82",
    "count": 1,
    "city": "Rapperswil-Jona",
    "county": "Wahlkreis See-Gaster",
    "state": "St. Gallen",
    "state_district": null,
    "country": "Switzerland"
  },
  {
    "location": "geo:47.24,39.70",
    "count": 1,
    "city": "Rostov-on-Don",
    "county": "Rostov-on-Don",
    "state": "Rostov Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:47.25,-122.44",
    "count": 1,
    "city": "Tacoma",
    "county": "Pierce County",
    "state": "Washington",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.36,-68.33",
    "count": 1,
    "city": "Madawaska",
    "county": "Aroostook County",
    "state": "Maine",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:22.14,82.13",
    "count": 1,
    "city": "Bilaspur",
    "county": "Bilaspur Tahsil",
    "state": "Chhattisgarh",
    "state_district": "Bilaspur",
    "country": "India"
  },
  {
    "location": "geo:42.73,-73.69",
    "count": 1,
    "city": "Troy",
    "county": "Rensselaer County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:7.50,134.62",
    "count": 1,
    "city": "Ngerulmud",
    "county": null,
    "state": "Melekeok",
    "state_district": null,
    "country": "Palau"
  },
  {
    "location": "geo:42.70,27.71",
    "count": 1,
    "city": "Chayka district",
    "county": null,
    "state": "Burgas",
    "state_district": "Nesebar",
    "country": "Bulgaria"
  },
  {
    "location": "geo:28.91,-13.78",
    "count": 1,
    "city": "Femés",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:-7.52,109.29",
    "count": 1,
    "city": "Pekunden",
    "county": null,
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:42.64,-78.54",
    "count": 1,
    "city": "Holland",
    "county": "Erie County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:54.98,82.90",
    "count": 1,
    "city": "Novosibirsk",
    "county": null,
    "state": "Novosibirsk Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:-21.87,-51.84",
    "count": 1,
    "city": "Presidente Venceslau",
    "county": null,
    "state": "São Paulo",
    "state_district": "Região Geográfica Intermediária de Presidente Prudente",
    "country": "Brazil"
  },
  {
    "location": "geo:46.37,14.11",
    "count": 1,
    "city": "Bled",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Slovenia"
  },
  {
    "location": "geo:55.25,22.29",
    "count": 1,
    "city": "Tauragė",
    "county": "Tauragės miesto seniūnija",
    "state": "Taurage County",
    "state_district": "Tauragės rajono savivaldybė",
    "country": "Lithuania"
  },
  {
    "location": "geo:7.54,124.73",
    "count": 1,
    "city": "Camalig",
    "county": null,
    "state": "North Cotabato",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:47.53,-122.03",
    "count": 1,
    "city": "Issaquah",
    "county": "King County",
    "state": "Washington",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:7.54,4.06",
    "count": 1,
    "city": "Elesu",
    "county": "Lagelu",
    "state": "Oyo State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:47.56,7.94",
    "count": 1,
    "city": "Säckingen",
    "county": "Landkreis Waldshut",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:28.62,79.81",
    "count": 1,
    "city": "Pilibhit",
    "county": "Pilibhit",
    "state": "Uttar Pradesh",
    "state_district": "Pilibhit",
    "country": "India"
  },
  {
    "location": "geo:7.31,125.68",
    "count": 1,
    "city": "Panabo",
    "county": null,
    "state": "Davao del Norte",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:42.52,12.52",
    "count": 1,
    "city": "Narni",
    "county": null,
    "state": "Terni",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:42.49,1.51",
    "count": 1,
    "city": "Andorra la Vella",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Andorra"
  },
  {
    "location": "geo:42.44,-76.50",
    "count": 1,
    "city": "Ithaca",
    "county": "Tompkins County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:28.54,77.39",
    "count": 1,
    "city": "Dadri",
    "county": null,
    "state": "Uttar Pradesh",
    "state_district": "Gautam Buddha Nagar",
    "country": "India"
  },
  {
    "location": "geo:42.34,-82.00",
    "count": 1,
    "city": "Chatham-Kent",
    "county": null,
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:42.29,72.52",
    "count": 1,
    "city": null,
    "county": "Talas District",
    "state": "Talas Region",
    "state_district": null,
    "country": "Kyrgyzstan"
  },
  {
    "location": "geo:42.26,22.74",
    "count": 1,
    "city": "Granitsa",
    "county": null,
    "state": "Kyustendil",
    "state_district": "Kyustendil",
    "country": "Bulgaria"
  },
  {
    "location": "geo:28.50,-13.86",
    "count": 1,
    "city": "Puerto del Rosario",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:42.11,41.95",
    "count": 1,
    "city": "Lesa",
    "county": "Lanchkhuti Municipality",
    "state": "Guria",
    "state_district": null,
    "country": "Georgia"
  },
  {
    "location": "geo:42.10,-79.24",
    "count": 1,
    "city": "Jamestown",
    "county": "Chautauqua County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:28.36,76.87",
    "count": 1,
    "city": "Gurugram",
    "county": null,
    "state": "Haryana",
    "state_district": null,
    "country": "India"
  },
  {
    "location": "geo:-5.55,119.95",
    "count": 1,
    "city": "Bantaeng",
    "county": null,
    "state": "South Sulawesi",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:47.76,8.84",
    "count": 1,
    "city": "Singen (Hohentwiel)",
    "county": "Landkreis Konstanz",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-7.59,112.05",
    "count": 1,
    "city": "Waung",
    "county": "Nganjuk",
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:20.01,41.47",
    "count": 1,
    "city": "Al Bahah",
    "county": null,
    "state": "Al-Bahah Province",
    "state_district": null,
    "country": "Saudi Arabia"
  },
  {
    "location": "geo:42.05,-71.88",
    "count": 1,
    "city": "Webster",
    "county": "Worcester County",
    "state": "Massachusetts",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.98,-91.67",
    "count": 1,
    "city": "Cedar Rapids",
    "county": "Linn County",
    "state": "Iowa",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:5.55,5.77",
    "count": 1,
    "city": "Effurun",
    "county": "Uvwie",
    "state": "Delta State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:9.95,105.87",
    "count": 1,
    "city": "Mái Dầm",
    "county": "Châu Thành District",
    "state": "Hậu Giang Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:7.61,-72.60",
    "count": 1,
    "city": "Chinácota",
    "county": null,
    "state": "Norte de Santander",
    "state_district": "Suroriente",
    "country": "Colombia"
  },
  {
    "location": "geo:41.86,2.66",
    "count": 1,
    "city": "Santa Coloma de Farners",
    "county": null,
    "state": "Girona",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:47.87,31.02",
    "count": 1,
    "city": "Прибузька сільська громада",
    "county": null,
    "state": "Mykolaiv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:41.67,12.50",
    "count": 1,
    "city": "Pomezia",
    "county": null,
    "state": "Rome",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:4.79,100.72",
    "count": 1,
    "city": "Changkat Jering",
    "county": null,
    "state": "Perak",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:41.64,-88.45",
    "count": 1,
    "city": "Yorkville",
    "county": "Kendall County",
    "state": "Illinois",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:47.96,11.92",
    "count": 1,
    "city": "Baiern",
    "county": "Landkreis Ebersberg",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:21.78,87.75",
    "count": 1,
    "city": "Contai",
    "county": "Contai-I",
    "state": "West Bengal",
    "state_district": "Purba Medinipur",
    "country": "India"
  },
  {
    "location": "geo:45.24,19.82",
    "count": 1,
    "city": "Novi Sad",
    "county": "South Backa Administrative District",
    "state": "Vojvodina",
    "state_district": null,
    "country": "Serbia"
  },
  {
    "location": "geo:5.55,95.32",
    "count": 1,
    "city": "Banda Aceh",
    "county": null,
    "state": "Aceh",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:41.50,-73.97",
    "count": 1,
    "city": "Beacon",
    "county": "Dutchess County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:28.26,117.07",
    "count": 1,
    "city": "Meiyuan Subdistrict",
    "county": null,
    "state": "Jiangxi",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:48.05,10.87",
    "count": 1,
    "city": "Landsberg am Lech",
    "county": "Landkreis Landsberg am Lech",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:45.26,-74.21",
    "count": 1,
    "city": "Les Coteaux",
    "county": "Vaudreuil-Soulanges",
    "state": "Quebec",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:41.41,-75.66",
    "count": 1,
    "city": "Scranton",
    "county": "Lackawanna County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.60,46.58",
    "count": 1,
    "city": "Адиль-Янгиюртовский сельсовет",
    "county": "Babayurtovsky District",
    "state": "Dagestan",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:12.98,7.62",
    "count": 1,
    "city": "Muhammadu Dikko Stadium",
    "county": null,
    "state": "Katsina State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:41.22,-74.87",
    "count": 1,
    "city": "Delaware",
    "county": "Pike County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:41.18,28.89",
    "count": 1,
    "city": "Eyüpsultan",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:48.17,85.27",
    "count": 1,
    "city": "Қазақстан",
    "county": "Marqakol District",
    "state": "East Kazakhstan Region",
    "state_district": null,
    "country": "Kazakhstan"
  },
  {
    "location": "geo:41.14,-102.98",
    "count": 1,
    "city": "Sidney",
    "county": "Cheyenne County",
    "state": "Nebraska",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.29,25.94",
    "count": 1,
    "city": "Chernivtsi",
    "county": null,
    "state": "Chernivtsi Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:48.30,19.18",
    "count": 1,
    "city": "District of Krupina",
    "county": null,
    "state": "Region of Banská Bystrica",
    "state_district": null,
    "country": "Slovakia"
  },
  {
    "location": "geo:48.31,14.29",
    "count": 1,
    "city": "Linz",
    "county": null,
    "state": "Upper Austria",
    "state_district": null,
    "country": "Austria"
  },
  {
    "location": "geo:55.70,13.19",
    "count": 1,
    "city": "Lund",
    "county": "Skåne County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:12.56,76.73",
    "count": 1,
    "city": "Heggadahalli",
    "county": "Pandavapura taluk",
    "state": "Karnataka",
    "state_district": "Mandya",
    "country": "India"
  },
  {
    "location": "geo:41.07,-73.78",
    "count": 1,
    "city": "Valhalla",
    "county": "Westchester County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.40,9.99",
    "count": 1,
    "city": "Ulm",
    "county": null,
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:-7.65,112.90",
    "count": 1,
    "city": "Kota Pasuruan",
    "county": null,
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:55.77,24.29",
    "count": 1,
    "city": "Klevečkinė",
    "county": "Panevėžys eldership",
    "state": "Panevezys County",
    "state_district": "Panevėžio rajono savivaldybė",
    "country": "Lithuania"
  },
  {
    "location": "geo:45.39,-73.96",
    "count": 1,
    "city": "L'Île-Perrot",
    "county": "Vaudreuil-Soulanges",
    "state": "Quebec",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:41.04,-74.29",
    "count": 1,
    "city": "Wanaque",
    "county": "Passaic County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:45.16,9.31",
    "count": 1,
    "city": "Belgioioso",
    "county": null,
    "state": "Pavia",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:13.14,123.74",
    "count": 1,
    "city": "Legazpi",
    "county": null,
    "state": "Albay",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:-13.16,-72.52",
    "count": 1,
    "city": "Machupicchu",
    "county": null,
    "state": "Cusco",
    "state_district": null,
    "country": "Peru"
  },
  {
    "location": "geo:4.85,100.74",
    "count": 1,
    "city": "Taiping",
    "county": null,
    "state": "Perak",
    "state_district": null,
    "country": "Malaysia"
  },
  {
    "location": "geo:40.81,-96.70",
    "count": 1,
    "city": "Lincoln",
    "county": "Lancaster County",
    "state": "Nebraska",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.72,-74.84",
    "count": 1,
    "city": "Califon",
    "county": "Hunterdon County",
    "state": "New Jersey",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:45.41,9.13",
    "count": 1,
    "city": "Assago",
    "county": null,
    "state": "Milano",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:48.54,21.35",
    "count": 1,
    "city": "Gönci járás",
    "county": "Borsod-Abaúj-Zemplén",
    "state": null,
    "state_district": null,
    "country": "Hungary"
  },
  {
    "location": "geo:27.97,34.36",
    "count": 1,
    "city": "Al Nabq",
    "county": null,
    "state": "South Sinai",
    "state_district": null,
    "country": "Egypt"
  },
  {
    "location": "geo:27.64,79.94",
    "count": 1,
    "city": "Shahabad",
    "county": "Shahabad",
    "state": "Uttar Pradesh",
    "state_district": "Hardoi",
    "country": "India"
  },
  {
    "location": "geo:48.57,7.75",
    "count": 1,
    "city": "Strasbourg",
    "county": "Bas-Rhin",
    "state": "Grand Est",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:48.58,19.14",
    "count": 1,
    "city": "Zvolen",
    "county": null,
    "state": "Region of Banská Bystrica",
    "state_district": null,
    "country": "Slovakia"
  },
  {
    "location": "geo:48.58,-89.91",
    "count": 1,
    "city": "Unorganized Thunder Bay District",
    "county": "Thunder Bay District",
    "state": "Ontario",
    "state_district": "Northwestern Ontario",
    "country": "Canada"
  },
  {
    "location": "geo:56.21,92.32",
    "count": 1,
    "city": "сельсовет Памяти 13 Борцов",
    "county": "Yemelyanovsky Rayon",
    "state": "Krasnoyarsk Krai",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:13.35,-88.44",
    "count": 1,
    "city": "Usulután",
    "county": null,
    "state": "Usulután",
    "state_district": null,
    "country": "El Salvador"
  },
  {
    "location": "geo:40.64,22.94",
    "count": 1,
    "city": "Thessaloniki",
    "county": "Thessaloniki Regional Unit",
    "state": "Macedonia and Thrace",
    "state_district": "Central Macedonia",
    "country": "Greece"
  },
  {
    "location": "geo:56.28,9.44",
    "count": 1,
    "city": "Silkeborg",
    "county": null,
    "state": "Central Denmark Region",
    "state_district": null,
    "country": "Denmark"
  },
  {
    "location": "geo:48.72,10.78",
    "count": 1,
    "city": "Donauwörth",
    "county": "Landkreis Donau-Ries",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:48.74,9.31",
    "count": 1,
    "city": "Esslingen am Neckar",
    "county": "Landkreis Esslingen",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:27.59,77.65",
    "count": 1,
    "city": "Mathura",
    "county": "Mathura",
    "state": "Uttar Pradesh",
    "state_district": null,
    "country": "India"
  },
  {
    "location": "geo:40.54,17.44",
    "count": 1,
    "city": "Grottaglie",
    "county": "Taranto",
    "state": "Apulia",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:17.30,-62.72",
    "count": 1,
    "city": "Basseterre",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Saint Kitts and Nevis"
  },
  {
    "location": "geo:40.44,-75.34",
    "count": 1,
    "city": "Quakertown",
    "county": "Bucks County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:40.34,127.51",
    "count": 1,
    "city": null,
    "county": "Sinhung County",
    "state": "South Hamgyong",
    "state_district": null,
    "country": "North Korea"
  },
  {
    "location": "geo:48.80,2.13",
    "count": 1,
    "city": "Versailles",
    "county": "Yvelines",
    "state": "Ile-de-France",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:48.80,2.42",
    "count": 1,
    "city": "Alfortville",
    "county": "Val-de-Marne",
    "state": "Ile-de-France",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:40.33,-89.08",
    "count": 1,
    "city": "Heyworth",
    "county": "McLean County",
    "state": "Illinois",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-40.32,-72.48",
    "count": 1,
    "city": "Lago Ranco",
    "county": "Provincia de Ranco",
    "state": "Los Ríos",
    "state_district": null,
    "country": "Chile"
  },
  {
    "location": "geo:40.30,-83.07",
    "count": 1,
    "city": "Delaware",
    "county": "Delaware County",
    "state": "Ohio",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.59,-79.64",
    "count": 1,
    "city": "Mississauga",
    "county": "Peel",
    "state": "Ontario",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:-7.74,110.66",
    "count": 1,
    "city": "Kalikebo",
    "county": "Klaten",
    "state": "Central Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:40.22,-75.40",
    "count": 1,
    "city": "Collegeville",
    "county": "Montgomery County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:10.96,78.08",
    "count": 1,
    "city": "Karur",
    "county": "Karur",
    "state": "Tamil Nadu",
    "state_district": "Karur",
    "country": "India"
  },
  {
    "location": "geo:27.48,-99.55",
    "count": 1,
    "city": "Nuevo Laredo",
    "county": "Nuevo Laredo",
    "state": "Tamaulipas",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:16.51,80.52",
    "count": 1,
    "city": "Amaravati",
    "county": "Thullur",
    "state": "Andhra Pradesh",
    "state_district": "Guntur",
    "country": "India"
  },
  {
    "location": "geo:21.70,-158.00",
    "count": 1,
    "city": "Kawela Bay",
    "county": "Honolulu County",
    "state": "Hawaii",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:48.95,7.64",
    "count": 1,
    "city": "Haguenau-Wissembourg",
    "county": "Bas-Rhin",
    "state": "Grand Est",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:48.96,4.36",
    "count": 1,
    "city": "Châlons-en-Champagne",
    "county": "Marne",
    "state": "Grand Est",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:56.66,-5.25",
    "count": 1,
    "city": "Duror",
    "county": "Highland",
    "state": "Scotland",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:11.97,109.19",
    "count": 1,
    "city": "Cam Ranh",
    "county": null,
    "state": "Khánh Hòa Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:0.28,34.75",
    "count": 1,
    "city": "Kakamega",
    "county": "Lurambi",
    "state": "Kakamega County",
    "state_district": null,
    "country": "Kenya"
  },
  {
    "location": "geo:45.03,-110.71",
    "count": 1,
    "city": "Gardiner",
    "county": "Park County",
    "state": "Montana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:45.44,4.39",
    "count": 1,
    "city": "Saint-Etienne",
    "county": "Saint-Étienne",
    "state": "Loire",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:49.12,6.18",
    "count": 1,
    "city": "Metz",
    "county": "Moselle",
    "state": "Grand Est",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:49.14,9.21",
    "count": 1,
    "city": "Heilbronn",
    "county": null,
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:49.17,-123.94",
    "count": 1,
    "city": "Nanaimo",
    "county": "Nanaimo",
    "state": "British Columbia",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:56.86,35.92",
    "count": 1,
    "city": "Tver",
    "county": null,
    "state": "Tver Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:49.20,8.12",
    "count": 1,
    "city": "Landau in der Pfalz",
    "county": null,
    "state": "Rhineland-Palatinate",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:13.51,2.13",
    "count": 1,
    "city": "Niamey",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Niger"
  },
  {
    "location": "geo:-2.71,-80.25",
    "count": 1,
    "city": "Posorja",
    "county": "Guayaquil",
    "state": "Guayas",
    "state_district": null,
    "country": "Ecuador"
  },
  {
    "location": "geo:49.25,8.52",
    "count": 1,
    "city": "Waghäusel",
    "county": "Landkreis Karlsruhe",
    "state": "Baden-Württemberg",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:49.26,4.03",
    "count": 1,
    "city": "Reims",
    "county": "Marne",
    "state": "Grand Est",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:39.96,-75.61",
    "count": 1,
    "city": "West Chester",
    "county": "Chester County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.30,19.95",
    "count": 1,
    "city": "Zakopane",
    "county": "Tatra County",
    "state": "Lesser Poland Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:11.97,79.21",
    "count": 1,
    "city": "Tirukkovilur",
    "county": "Tirukkoyilur",
    "state": "Tamil Nadu",
    "state_district": "Kallakurichi",
    "country": "India"
  },
  {
    "location": "geo:39.90,-79.97",
    "count": 1,
    "city": "Cumberland",
    "county": "Greene County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:44.91,8.61",
    "count": 1,
    "city": "Alessandria",
    "county": null,
    "state": "Alessandria",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:39.85,-75.71",
    "count": 1,
    "city": "Kennett Square",
    "county": "Chester County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.55,7.02",
    "count": 1,
    "city": "Cannes",
    "county": "Maritime Alps",
    "state": "Provence-Alpes-Côte d'Azur",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:49.33,28.05",
    "count": 1,
    "city": "Lityn Settlement Hromada",
    "county": null,
    "state": "Vinnytsia Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:57.16,65.56",
    "count": 1,
    "city": "Tyumen",
    "county": "городской округ Тюмень",
    "state": "Tyumen Oblast",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:39.80,-79.81",
    "count": 1,
    "city": "Smithfield",
    "county": "Fayette County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.37,22.42",
    "count": 1,
    "city": "gmina Solina",
    "county": "Lesko County",
    "state": "Subcarpathian Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:39.79,-75.98",
    "count": 1,
    "city": "Oxford",
    "county": "Chester County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.75,-76.31",
    "count": 1,
    "city": "Peach Bottom",
    "county": "York County",
    "state": "Pennsylvania",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.70,-77.33",
    "count": 1,
    "city": "Taneytown",
    "county": "Frederick County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.65,66.97",
    "count": 1,
    "city": "Samarkand City",
    "county": null,
    "state": "Samarqand Region",
    "state_district": null,
    "country": "Uzbekistan"
  },
  {
    "location": "geo:39.52,-87.13",
    "count": 1,
    "city": "Brazil",
    "county": "Clay County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:8.92,-74.46",
    "count": 1,
    "city": "Pinillos",
    "county": "Pinillos",
    "state": "Bolívar",
    "state_district": "Mojana",
    "country": "Colombia"
  },
  {
    "location": "geo:39.43,-86.67",
    "count": 1,
    "city": "Gosport",
    "county": "Owen County",
    "state": "Indiana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.36,-77.32",
    "count": 1,
    "city": "Urbana",
    "county": "Frederick County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.45,20.23",
    "count": 1,
    "city": "gmina Łapsze Niżne",
    "county": "Nowy Targ County",
    "state": "Lesser Poland Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:13.76,121.06",
    "count": 1,
    "city": "Batangas City",
    "county": null,
    "state": "Batangas",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:39.33,-77.66",
    "count": 1,
    "city": "Knoxville",
    "county": "Frederick County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.33,-77.35",
    "count": 1,
    "city": "Frederick",
    "county": "Frederick County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.29,-77.86",
    "count": 1,
    "city": "Charles Town",
    "county": "Jefferson County",
    "state": "West Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.50,11.76",
    "count": 1,
    "city": "Sulzbach-Rosenberg",
    "county": "Landkreis Amberg-Sulzbach",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:39.19,-78.16",
    "count": 1,
    "city": "Winchester",
    "county": "City of Winchester",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.17,-77.20",
    "count": 1,
    "city": "Montgomery Village",
    "county": "Montgomery County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.50,-87.85",
    "count": 1,
    "city": "Belgium",
    "county": "Ozaukee County",
    "state": "Wisconsin",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.13,-77.66",
    "count": 1,
    "city": "Hamilton",
    "county": "Loudoun County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.67,24.54",
    "count": 1,
    "city": "Peremyshliany",
    "county": null,
    "state": "Lviv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:49.68,5.80",
    "count": 1,
    "city": "Arlon",
    "county": "Arlon",
    "state": "Luxembourg",
    "state_district": null,
    "country": "Belgium"
  },
  {
    "location": "geo:26.86,95.53",
    "count": 1,
    "city": "Raho",
    "county": "Laju SDO",
    "state": "Arunachal Pradesh",
    "state_district": "Tirap",
    "country": "India"
  },
  {
    "location": "geo:21.62,87.51",
    "count": 1,
    "city": "Digha",
    "county": "Ramnagar-I",
    "state": "West Bengal",
    "state_district": "Purba Medinipur",
    "country": "India"
  },
  {
    "location": "geo:21.61,39.70",
    "count": 1,
    "city": "Al Jumum",
    "county": null,
    "state": "Makkah Region",
    "state_district": null,
    "country": "Saudi Arabia"
  },
  {
    "location": "geo:57.53,25.41",
    "count": 1,
    "city": "Valmiera",
    "county": null,
    "state": null,
    "state_district": "Valmieras novads",
    "country": "Latvia"
  },
  {
    "location": "geo:49.75,6.64",
    "count": 1,
    "city": "Trier",
    "county": null,
    "state": "Rhineland-Palatinate",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:46.54,-111.93",
    "count": 1,
    "city": "Clancy",
    "county": "Jefferson County",
    "state": "Montana",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.09,-76.82",
    "count": 1,
    "city": "Laurel",
    "county": "Anne Arundel County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:39.04,-78.93",
    "count": 1,
    "city": null,
    "county": "Hardy County",
    "state": "West Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:49.80,30.13",
    "count": 1,
    "city": "Bila Tserkva",
    "county": null,
    "state": "Kyiv Oblast",
    "state_district": null,
    "country": "Ukraine"
  },
  {
    "location": "geo:49.82,20.59",
    "count": 1,
    "city": "gmina Iwkowa",
    "county": "Brzesko County",
    "state": "Lesser Poland Voivodeship",
    "state_district": null,
    "country": "Poland"
  },
  {
    "location": "geo:-26.81,-65.22",
    "count": 1,
    "city": "San Miguel de Tucumán",
    "county": null,
    "state": "Tucumán",
    "state_district": "Departamento Capital",
    "country": "Argentina"
  },
  {
    "location": "geo:39.02,-80.54",
    "count": 1,
    "city": "Weston",
    "county": "Lewis County",
    "state": "West Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.48,11.62",
    "count": 1,
    "city": "Bucine",
    "county": null,
    "state": "Arezzo",
    "state_district": null,
    "country": "Italy"
  },
  {
    "location": "geo:21.43,92.01",
    "count": 1,
    "city": "Cox's Bazar",
    "county": null,
    "state": "Chattogram Division",
    "state_district": "Cox's Bazar District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:26.77,82.15",
    "count": 1,
    "city": "Faizabad",
    "county": "Faizabad",
    "state": "Uttar Pradesh",
    "state_district": "Ayodhya",
    "country": "India"
  },
  {
    "location": "geo:46.57,-70.09",
    "count": 1,
    "city": "Saint-Just-de-Bretenières",
    "county": "Montmagny",
    "state": "Quebec",
    "state_district": null,
    "country": "Canada"
  },
  {
    "location": "geo:58.31,14.29",
    "count": 1,
    "city": "Hjo",
    "county": "Västra Götaland County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:19.54,-154.91",
    "count": 1,
    "city": "Hawaiian Beaches",
    "county": "Hawaiʻi County",
    "state": "Hawaii",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-7.35,108.22",
    "count": 1,
    "city": "Tasikmalaya",
    "county": null,
    "state": "West Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:38.89,-77.22",
    "count": 1,
    "city": "Vienna",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-17.53,-149.57",
    "count": 1,
    "city": "Papeete",
    "county": "Windward Islands",
    "state": "French Polynesia",
    "state_district": null,
    "country": "France"
  },
  {
    "location": "geo:38.88,-77.30",
    "count": 1,
    "city": "Oakton",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.88,-77.17",
    "count": 1,
    "city": "Falls Church",
    "county": null,
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.86,-77.16",
    "count": 1,
    "city": "Falls Church",
    "county": "Fairfax County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:43.00,-78.19",
    "count": 1,
    "city": "Batavia",
    "county": "Genesee County",
    "state": "New York",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:10.89,19.82",
    "count": 1,
    "city": "Zakouma",
    "county": null,
    "state": "Salamat",
    "state_district": null,
    "country": "Chad"
  },
  {
    "location": "geo:50.08,8.24",
    "count": 1,
    "city": "Wiesbaden",
    "county": null,
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:13.99,100.65",
    "count": 1,
    "city": "Rangsit City Municipality",
    "county": "Thanyaburi District",
    "state": null,
    "state_district": null,
    "country": "Thailand"
  },
  {
    "location": "geo:50.10,8.78",
    "count": 1,
    "city": "Offenbach am Main",
    "county": null,
    "state": "Hesse",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:5.88,5.69",
    "count": 1,
    "city": "Sapele",
    "county": "Sapele",
    "state": "Delta State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:-21.26,-52.04",
    "count": 1,
    "city": "Brasilândia",
    "county": null,
    "state": "Mato Grosso do Sul",
    "state_district": "Região Geográfica Intermediária de Campo Grande",
    "country": "Brazil"
  },
  {
    "location": "geo:38.78,-77.61",
    "count": 1,
    "city": "Gainesville",
    "county": "Prince William County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:58.97,5.73",
    "count": 1,
    "city": "Stavanger",
    "county": "Rogaland",
    "state": null,
    "state_district": null,
    "country": "Norway"
  },
  {
    "location": "geo:46.86,-68.00",
    "count": 1,
    "city": "Caribou",
    "county": "Aroostook County",
    "state": "Maine",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:44.64,25.39",
    "count": 1,
    "city": "Jugureni",
    "county": "Dâmbovița",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:59.24,17.98",
    "count": 1,
    "city": "Sjödalen-Fullersta",
    "county": "Stockholm County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:38.69,-75.39",
    "count": 1,
    "city": "Georgetown",
    "county": "Sussex County",
    "state": "Delaware",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:26.42,50.09",
    "count": 1,
    "city": "Dammam",
    "county": null,
    "state": "Eastern Province",
    "state_district": null,
    "country": "Saudi Arabia"
  },
  {
    "location": "geo:50.30,10.47",
    "count": 1,
    "city": "Bad Königshofen im Grabfeld",
    "county": "Landkreis Rhön-Grabfeld",
    "state": "Bavaria",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:22.28,114.16",
    "count": 1,
    "city": "Hong Kong Island",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Hong Kong"
  },
  {
    "location": "geo:-7.40,112.38",
    "count": 1,
    "city": "Kemlagi",
    "county": "Mojokerto",
    "state": "East Java",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:38.60,-77.16",
    "count": 1,
    "city": "Indian Head",
    "county": "Charles County",
    "state": "Maryland",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:26.40,90.27",
    "count": 1,
    "city": "Kokrajhar",
    "county": "Kokrajhar",
    "state": "Assam",
    "state_district": "Kokrajhar",
    "country": "India"
  },
  {
    "location": "geo:-26.27,-53.64",
    "count": 1,
    "city": "Dionísio Cerqueira",
    "county": null,
    "state": "Santa Catarina",
    "state_district": null,
    "country": "Brazil"
  },
  {
    "location": "geo:50.41,14.91",
    "count": 1,
    "city": "Mladá Boleslav",
    "county": null,
    "state": "Central Bohemia",
    "state_district": null,
    "country": "Czechia"
  },
  {
    "location": "geo:26.07,83.19",
    "count": 1,
    "city": "Azamgarh",
    "county": "Azamgarh",
    "state": "Uttar Pradesh",
    "state_district": "Azamgarh",
    "country": "India"
  },
  {
    "location": "geo:21.23,105.59",
    "count": 1,
    "city": "Yên Lạc",
    "county": null,
    "state": "Vĩnh Phúc Province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:5.04,7.91",
    "count": 1,
    "city": "Uyo",
    "county": "Uyo",
    "state": "Akwa Ibom State",
    "state_district": null,
    "country": "Nigeria"
  },
  {
    "location": "geo:25.99,79.45",
    "count": 1,
    "city": "Orai",
    "county": "Orai",
    "state": "Uttar Pradesh",
    "state_district": "Jalaun",
    "country": "India"
  },
  {
    "location": "geo:50.50,12.14",
    "count": 1,
    "city": "Plauen",
    "county": "Vogtlandkreis",
    "state": "Saxony",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:21.12,105.96",
    "count": 1,
    "city": "Từ Sơn",
    "county": null,
    "state": "Bắc Ninh province",
    "state_district": null,
    "country": "Vietnam"
  },
  {
    "location": "geo:-4.56,-37.77",
    "count": 1,
    "city": "Aracati",
    "county": null,
    "state": "Ceará",
    "state_district": "Região Geográfica Intermediária de Quixadá",
    "country": "Brazil"
  },
  {
    "location": "geo:38.50,-0.81",
    "count": 1,
    "city": "Sax",
    "county": null,
    "state": "Valencian Community",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:38.46,-77.39",
    "count": 1,
    "city": "Stafford",
    "county": "Stafford County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:38.45,-28.15",
    "count": 1,
    "city": "Lajes do Pico",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Portugal"
  },
  {
    "location": "geo:38.41,-78.74",
    "count": 1,
    "city": "McGaheysville",
    "county": "Rockingham County",
    "state": "Virginia",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:25.96,89.70",
    "count": 1,
    "city": "Nageshwari",
    "county": "Nageshwari Upazila",
    "state": "Rangpur Division",
    "state_district": "Kurigram District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:17.62,77.77",
    "count": 1,
    "city": "Patloor",
    "county": "Marpalle mandal",
    "state": "Telangana",
    "state_district": "Vikarabad",
    "country": "India"
  },
  {
    "location": "geo:59.51,17.64",
    "count": 1,
    "city": "Bro",
    "county": "Stockholm County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:38.36,-6.16",
    "count": 1,
    "city": "Usagre",
    "county": null,
    "state": "Extremadura",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:50.64,5.57",
    "count": 1,
    "city": "Liège",
    "county": "Liège",
    "state": "Liège",
    "state_district": null,
    "country": "Belgium"
  },
  {
    "location": "geo:5.95,80.55",
    "count": 1,
    "city": "Matara",
    "county": null,
    "state": "Southern Province",
    "state_district": "Matara District",
    "country": "Sri Lanka"
  },
  {
    "location": "geo:5.96,10.16",
    "count": 1,
    "city": "Bamenda",
    "county": "Mezam",
    "state": "Northwest",
    "state_district": null,
    "country": "Cameroon"
  },
  {
    "location": "geo:-2.59,140.67",
    "count": 1,
    "city": "Jayapura",
    "county": null,
    "state": "Papua",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:25.89,88.26",
    "count": 1,
    "city": "Ranisankail",
    "county": "Ranisankail Upazila",
    "state": "Rangpur Division",
    "state_district": "Thakurgaon District",
    "country": "Bangladesh"
  },
  {
    "location": "geo:14.07,-87.17",
    "count": 1,
    "city": "Tegucigalpa",
    "county": "Distrito Central",
    "state": "Francisco Morazán",
    "state_district": null,
    "country": "Honduras"
  },
  {
    "location": "geo:45.64,25.59",
    "count": 1,
    "city": "Brasov",
    "county": "Brașov",
    "state": null,
    "state_district": null,
    "country": "Romania"
  },
  {
    "location": "geo:50.76,-1.30",
    "count": 1,
    "city": "Cowes",
    "county": "Isle of Wight",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:50.77,15.05",
    "count": 1,
    "city": "Liberec",
    "county": "Liberec Region",
    "state": "Northeast",
    "state_district": null,
    "country": "Czechia"
  },
  {
    "location": "geo:5.98,116.08",
    "count": 1,
    "city": "Kota Kinabalu",
    "county": null,
    "state": "Sabah",
    "state_district": "West Coast Division",
    "country": "Malaysia"
  },
  {
    "location": "geo:-38.15,144.36",
    "count": 1,
    "city": "Geelong",
    "county": "Greater Geelong",
    "state": "Victoria",
    "state_district": null,
    "country": "Australia"
  },
  {
    "location": "geo:25.82,-108.22",
    "count": 1,
    "city": "Sinaloa de Leyva",
    "county": "Sinaloa",
    "state": "Sinaloa",
    "state_district": null,
    "country": "Mexico"
  },
  {
    "location": "geo:38.03,-119.97",
    "count": 1,
    "city": null,
    "county": "Tuolumne County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.99,-1.13",
    "count": 1,
    "city": "Murcia",
    "county": "Área Metropolitana de Murcia",
    "state": "Region of Murcia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:14.13,121.56",
    "count": 1,
    "city": "Lucban",
    "county": null,
    "state": "Quezon",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:-8.03,26.79",
    "count": 1,
    "city": "Malemba-Nkulu",
    "county": null,
    "state": "Haut-Lomami",
    "state_district": null,
    "country": "Democratic Republic of the Congo"
  },
  {
    "location": "geo:37.94,23.65",
    "count": 1,
    "city": "Piraeus",
    "county": "Regional Unit of Piraeus",
    "state": "Attica",
    "state_district": "Attica",
    "country": "Greece"
  },
  {
    "location": "geo:50.85,4.42",
    "count": 1,
    "city": "Woluwe-Saint-Lambert - Sint-Lambrechts-Woluwe",
    "county": "Brussels-Capital",
    "state": null,
    "state_district": null,
    "country": "Belgium"
  },
  {
    "location": "geo:50.87,6.69",
    "count": 1,
    "city": "Kerpen",
    "county": "Rhein-Erft-Kreis",
    "state": "North Rhine-Westphalia",
    "state_district": null,
    "country": "Germany"
  },
  {
    "location": "geo:37.93,-83.63",
    "count": 1,
    "city": "Frenchburg",
    "county": "Menifee County",
    "state": "Kentucky",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.92,40.21",
    "count": 1,
    "city": "Bağlar",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:37.91,-122.54",
    "count": 1,
    "city": "Mill Valley",
    "county": "Marin County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:-3.79,102.26",
    "count": 1,
    "city": "Bengkulu",
    "county": "Teluk Segara",
    "state": "Bengkulu",
    "state_district": null,
    "country": "Indonesia"
  },
  {
    "location": "geo:-16.68,46.11",
    "count": 1,
    "city": null,
    "county": null,
    "state": "Boeny",
    "state_district": null,
    "country": "Madagascar"
  },
  {
    "location": "geo:37.89,-4.78",
    "count": 1,
    "city": "Córdoba",
    "county": null,
    "state": "Andalusia",
    "state_district": null,
    "country": "Spain"
  },
  {
    "location": "geo:59.91,17.62",
    "count": 1,
    "city": "Uppsala",
    "county": "Uppsala County",
    "state": null,
    "state_district": null,
    "country": "Sweden"
  },
  {
    "location": "geo:50.95,-0.13",
    "count": 1,
    "city": "Mid Sussex",
    "county": "West Sussex",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:14.14,121.32",
    "count": 1,
    "city": "Calauan",
    "county": null,
    "state": "Laguna",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:37.87,112.55",
    "count": 1,
    "city": "Taiyuan",
    "county": null,
    "state": "Shanxi",
    "state_district": null,
    "country": "China"
  },
  {
    "location": "geo:19.41,73.27",
    "count": 1,
    "city": "Vasind",
    "county": "Shahapur Taluka",
    "state": "Maharashtra",
    "state_district": "Thane",
    "country": "India"
  },
  {
    "location": "geo:44.56,38.09",
    "count": 1,
    "city": "Gelendzhik",
    "county": "городской округ Геленджик",
    "state": "Krasnodar Krai",
    "state_district": null,
    "country": "Russia"
  },
  {
    "location": "geo:-11.69,-62.71",
    "count": 1,
    "city": "São Miguel do Guaporé",
    "county": null,
    "state": "Rondônia",
    "state_district": "Região Geográfica Intermediária de Ji-Paraná",
    "country": "Brazil"
  },
  {
    "location": "geo:37.63,-122.49",
    "count": 1,
    "city": "Pacifica",
    "county": "San Mateo County",
    "state": "California",
    "state_district": null,
    "country": "United States of America"
  },
  {
    "location": "geo:37.57,44.28",
    "count": 1,
    "city": "Yeni Mahalle",
    "county": null,
    "state": null,
    "state_district": null,
    "country": "Turkey"
  },
  {
    "location": "geo:14.30,120.79",
    "count": 1,
    "city": "Naic",
    "county": null,
    "state": "Cavite",
    "state_district": null,
    "country": "Philippines"
  },
  {
    "location": "geo:51.05,-1.28",
    "count": 1,
    "city": "Winchester",
    "county": "Hampshire",
    "state": "England",
    "state_district": null,
    "country": "United Kingdom"
  },
  {
    "location": "geo:60.39,5.32",
    "count": 1,
    "city": "Bergen",
    "county": "Vestland",
    "state": null,
    "state_district": null,
    "country": "Norway"
  }
]
