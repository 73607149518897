export const explanations = {

  feedId: {
    title: 'Feed ID',
    description: [
      'The feed ID is the ID that you will pass to the API, to let mbd fetch the configuration you saved here.',
      'Feed IDs allow you to encapsulate all your configuration parameters into just one feed_id parameter, making the code super easy to integrate into your application. You can then quickly experiment and and observe the feed\’s behavior using the console here, and change the behavior of your feed without having to change any code.'
    ]
  },

  impressionCount: {
    title: 'Impression Count',
    description: [
      'Impression count specifies the number of items in the feed to be considered as "seen" by the user, so the same items will not be returned by the API in subsequent calls for the same user.  This is a mechanism to keep the feed fresh for users, when impression data is not available on chain. When specified, the first count items are considered seen, and will not be returned in subsequent calls.'
    ]
  },

  timeDecay: {
    title: 'Time Decay',
    description: [
      'Time decay is a feature that allows you to control the recency bias of the feed, ie how much weight to give to items that are newer. It works by reducing the score of items that are older over time.  The setting here is a score of how aggressive the decay should be, with 0 being the mildest and 1 being the most aggressive (ie most heavily biased towards newer items).'
    ],
  },

  maxPostsPerAuthor: {
    title: 'Max Posts From Same Author',
    description: [
      'This setting allows you to control the number of posts from the same author that can be included in the feed per API call.  This is useful to prevent a single author from dominating the feed, and to ensure that the feed is more balanced and diverse.'
    ]
  },

  minDistanceBetweenPosts: {
    title: 'Min Distance Between Posts',
    description: [
      'This setting allows you to control the minimum distance between posts from the same author in the feed.  Having posts from the same author close to each other on the feed can be unpleasant for the user, and this setting allows you to control how close they can be to each other.'
    ]
  },

  promotion: {
    title: 'Promotion',
    description: [
      'Promotion allows you to insert content into the main feed.  For example, you may want to content from a specific channel to make up 20% of your feed, or there are some specific items that you want to make sure your users see.',
      'There are two types of promotion: feed and items:',
      '▫️ Feed promotion allows you to insert content from another feed into your main feed at a specific percentage.',
      '▫️ Item promotion allows you to insert specific items into your main feed at specific positions.',
    ]
  },

  coldStart: {
    title: 'Cold Start',
    description: [
      'Cold Start settings allow you to specify a different feed to be served to users who are new to Farcaster, or who have not intereracted with any content for some time.',
      'When a user is new or has not interacted with any content for some time, the model does not have enough information to serve a good personalized feed. In such cases, it is beneficial to serve a feed that is specially configured for these users, ideally curated from a list of good content creators or channels etc that is appropriate for your app\'s audience.ß'
    ],
  }



}