import { Box, Stack } from '@mui/material';
import { Loader } from 'mbd-components/loader';
import { Text } from 'mbd-components/text';
import { React } from 'react';

export function Card(props){

  return (
    <Box bgcolor={'#fff'} p={4} my={4} borderRadius={2}>
      <Stack direction='row' alignItems='center' spacing={2} mb={2}>
        <Text variant='h1'>
          {props.title}
        </Text>
        {props.icon && props.icon}

      </Stack>
      {props.description &&
        <Text variant='body' color='gray-light' fontSize='small' sx={{mb: 6}}>
          {props.description}
        </Text>
      }
      {props.loading ? 
          <Box alignItems={'center'} justifyContent={'center'} justifyItems={'center'} sx={{height: 150, width: '100%'}}>
            <Loader />
          </Box> 
        :
          props.children
      }
    </Box>
  );
}