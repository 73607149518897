import Axios from 'axios';

import '@farcaster/auth-kit/styles.css';
import { AuthKitProvider as FarcasterAuthProvider } from '@farcaster/auth-kit';

// components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { View } from 'components/lib';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from 'helper/colors';

// 404
import { NotFound } from 'views/error/404';

// tailwind css
import '../css/output.css';

// settings
const Settings = require('settings.json');
const StripePromise = loadStripe(Settings[process.env.NODE_ENV].stripe.publishableAPIKey);

const routes = [
  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/website').default,

]

const theme = createTheme({
  palette: {
    gray: {
      main: colors['gray-light'],
    },
    primary: {
      main: colors['brand'],
    },
    secondary: {
      main: colors['brand-secondary-green'],
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

const fcAuthKitConfig = {
  rpcUrl: 'https://mainnet.optimism.io',
  domain: 'mbd.xyz',
  siweUri: 'https://console.mbd.xyz/signin',
};


export default function App(props){

  const user = JSON.parse(localStorage.getItem('user'));
  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;
  
if(user){
  if (user?.token){

    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;

  }

   // set the color mode
   user.dark_mode ?
   document.getElementById('app').classList.add('dark') :
   document.getElementById('app').classList.remove('dark');

}

  // render the routes
  return(
    
    <Elements stripe={ StripePromise }>
      <ThemeProvider theme={theme}>
        <FarcasterAuthProvider config={fcAuthKitConfig}>
          <AuthProvider>
              <BrowserRouter>
                <Routes>
                { routes.map(route => {

                  return (
                    <Route 
                      key={ route.path } 
                      path={ route.path }
                      element={ 
                        
                        route.permission ? 
                          <PrivateRoute permission={ route.permission }>
                            <View display={ route.view } layout={ route.layout } title={ route.title }/>
                          </PrivateRoute> :
                          <View display={ route.view } layout={ route.layout } title={ route.title  }/>

                      }
                    />
                  )
                })}

                { /* 404 */}
                <Route path='*' element={ <View display={ NotFound } layout='home' title='404 Not Found' /> }/>

              </Routes>
            </BrowserRouter>
          </AuthProvider>

        </FarcasterAuthProvider>
      </ThemeProvider>
    </Elements>
  );
}
