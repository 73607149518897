import { Box, Typography } from '@mui/material';

export function Text(props){

  const colors = {
    'brand': '#e01a96',
    'brand-light': '#ed9ed2',
    'brand-lighter': '#fcd9ed',
    'brand-faded': '#D4C2CE',
    'brand-darker': '#2B031C',
    'gray': '#475568',
    'gray-light': '#97979A',
    'gray-lighter': '#b9c0c8',
    'gray-lightest': '#f0f2f2',
    'white': '#ffffff',

    'warning': '#fb998e',
  }

  const variants = {
    'h1': 'h5',
    'h2': 'h6',
    'fieldHeading': 'body2',
    'fieldValue': 'body1',
    'body': 'body2',
    'small': 'body2',
    'warning': 'body2',
  }

  const fontWeights = {
    'h1': 500,
    'h2': 400,
    'h3': 500,
  }

  const fontStyles = {
    'warning': 'italic',
  }

  const fontSizes = {
    'fieldHeading': 14,
    'fieldValue': 16,  
    'small': 10,
    'h1': 28,
    'h3': 16
  }

  const color = colors[props.color] || colors['gray'];
  const fontWeight = props.fontWeight || fontWeights[props.variant] || 'normal';
  const variant = variants[props.variant] || 'body2';
  const fontStyle = fontStyles[props.variant] || 'normal';

  let style = { color: color, fontWeight: fontWeight, fontStyle: fontStyle };

  if (props.fontSize){
    style.fontSize = props.size;  
  }
  else if (props.variant in fontSizes){
    style.fontSize = fontSizes[props.variant];
  } 
  

  return (
      <Typography variant={variant} style={style} noWrap={props.noWrap || false} sx={props.sx || {}}>
          {props.children}
      </Typography>
  );
}