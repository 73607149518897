import {React, useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext, Event } from 'components/lib';
import { Card } from 'mbd-components/card';
import { Text } from 'mbd-components/text';
import { PrimaryButton } from 'mbd-components/primary-button';
import { Box, Stack, Button, TextField } from '@mui/material';
import { fetchFeeds } from 'helper/utils.js';
import { colors } from 'helper/colors';
import { 
  FeedOutlined as FeedIcon,
  UnpublishedOutlined as NoParamsIcon,
  CheckBoxOutlined as CheckIcon,
} from '@mui/icons-material';

export const MyFeedsCard = (props) => {

  const [feedDetails, setFeedDetails] = useState([]);
  const [isParamsSetFlags, setIsParamsSetFlags] = useState([]);
  const [filterText, setFilterText] = useState('');
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const FILTER_FIELDS = [
    'app_fids',
    'geo_locations',
    'channels',
    'ai_labels',
    'author_ids',
    'embed_domains',
    'publication_types',
    'remove_ai_labels',
    'remove_author_ids',
    'remove_geo_locations',
    'promotion_filters'
  ]

  useEffect(() => {
    const fetchTemplates = async () => {
      const feeds = await fetchFeeds('private', authContext?.user?.account_id);
      setFeedDetails(feeds.sort((a, b) => {
        const [, feedNumber1] = a.config_id.split('_');
        const [, feedNumber2] = b.config_id.split('_');
        return parseInt(feedNumber1) > parseInt(feedNumber2) ? -1 : 11}
      ));  
    }
    fetchTemplates();

  }, []);

  useEffect(() => {
    let paramsSetFlags = [];
    if (feedDetails?.length > 0) {
      for (let i = 0; i < feedDetails.length; i++) {
        let paramsSet = false;
        FILTER_FIELDS.forEach((field) => {
          if (feedDetails[i].config.filters[field]?.length > 0) {
            paramsSet = true
          }
        });
        if (feedDetails[i].config.promotion_filters?.length > 0) {
          paramsSet = true;
        }
        if (feedDetails[i].config.cold_start) {
          paramsSet = true;
        }
        paramsSetFlags.push(paramsSet);
      };
      setIsParamsSetFlags(paramsSetFlags);
    }
  },[feedDetails]);

  const makeEventName = (event) => {
    const prefix = props.eventPrefix && props.eventPrefix !== '' ? `${props.eventPrefix}_` : '';
    return `${prefix}${event}`;
  }

  const FieldCheckBox = (props) => {
    return (
      <Box>
        <Stack direction='row' spacing={1} alignItems='center'>
          <CheckIcon color='secondary' fontSize='small'/>
          <Text variant='body'>{props.name}</Text>
        </Stack>
      </Box>
    ) 
  }

  const NoParamsIndicator = (props) => {
    return (
      <Box>
        <Stack direction='row' spacing={1} alignItems='center'>
          <NoParamsIcon color='warning' fontSize='small'/>
          <Text variant='body'>{props.name}</Text>
        </Stack>
      </Box>
    ) 
  }

  const EndpointTag = (props) => {
    const nameMap = {
      'casts/feed/trending': 'trending',
      'casts/feed/for-you': 'for you',
      'casts/feed/popular': 'popular'
    }
    const colorMap = {
      'casts/feed/trending': '#475569',
      'casts/feed/for-you': '#7b68e3',
      'casts/feed/popular': '#7F83AA' 
    }
    return (
      <Box px={1} py={0.5} sx={{backgroundColor: colorMap[props.endpoint], borderRadius: 1}}>
        <Text variant='small' color='white'>{nameMap[props.endpoint]}</Text>
      </Box>
    )
  }

  return (
    <Card 
      title='My Feeds' 
      description='Here are the feeds created by you or your organization.'
      icon={<FeedIcon />}>
        <TextField 
          placeholder='filter feeds by name, description, or endpoint'
          value={filterText} 
          onChange={(e) => setFilterText(e.target.value.toLowerCase())} 
          variant="outlined" 
          sx={{width: 500, marginBottom: 8}}
        />
        {feedDetails?.length > 0 ? 
          <Stack direction='column'>
            {feedDetails.map((details, index) => {
              const config = details.config;
              let containsFilterText = false;
              if ((details.name.toLowerCase().includes(filterText) || 
                  details.description.toLowerCase().includes(filterText)) || 
                  details.endpoint.toLowerCase().includes(filterText) ||
                  details.config_id.includes(filterText)) {
                containsFilterText = true;
              }
              return (
                <>
                { containsFilterText &&
                  <Box py={4} sx={{ borderBottom: 1, borderColor: colors.offWhite}}>
                    <Stack direction='row' spacing={2} alignItems='center'mb={2}>
                      <Button sx={{px:0}} onClick={ () => {
                        Event(makeEventName('click_feed_name'));
                        navigate('/feed-builder', { state: { feedId: details.config_id, mode: 'private' } });
                      }}>
                        <Text variant='h2'>{details.name }</Text>
                      </Button>
                      <Button sx={{px:0}} onClick={ () => {
                        Event(makeEventName('click_feed_id'));
                        navigate('/feed-builder', { state: { feedId: details.config_id, mode: 'private' } });
                      }}>
                        <Text variant='body' color='brand-light' sx={{fontStyle: 'italic' }}>{details.config_id}</Text> 
                      </Button>
                      <EndpointTag endpoint={details.endpoint}/>
  
                    </Stack>
                    <Text variant='body' color='gray-lighter' >
                      {details.description && details.description.length > 0 ? details.description : 'No description available'}
                    </Text>
                    <Stack direction='row' spacing={2} alignItems='center' mt={2} sx={{flexWrap: 'wrap', gap:'16px'}}>
                      { details.impression_count && parseInt(details.impression_count) > 0 && <FieldCheckBox name={details.impression_count}/> } 
                      { FILTER_FIELDS.map((field) => {
                          if (config.filters[field]?.length > 0) {
                            return <FieldCheckBox name={field}/>
                          }
                        })
                      }
                      {
                        config.promotion_filters?.length > 0 && <FieldCheckBox name='promotion_filters'/>
                      }
                      {
                        config.cold_start && <FieldCheckBox name='cold_start'/>
                      }
                      {
                        ! isParamsSetFlags[index] && <NoParamsIndicator name='No parameters are set for this feed_id.'/>
                      }
                    </Stack>
                  </Box>
                }
                </>
              )
          })}
          </Stack>
          :
          <Box>
            <Text variant='body' color='gray'>
              You have not created any feeds yet. Create a new feed or select a template from below to get started.
            </Text>
            <Box mt={4}>
              <PrimaryButton onClick={() => {
                Event(makeEventName('click_get_started'));
                navigate('/getting-started')}}>
                  Get Started
              </PrimaryButton>
            </Box>
          </Box>
        }


    </Card>
  );
};

