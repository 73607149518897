import { Box, Button, Chip, Stack } from '@mui/material';
import { Text } from 'mbd-components/text';
import { colors } from 'helper/colors';
import { getGeloLocationDisplayString } from 'helper/utils';
export function NonEditableFeedDetails(props){

  const NonEditableTextField = (props) => 
    <Box  mr={4} mt={4} sx={{display:'flex', flexDirection: props.horizontal ? 'row' : 'column', alignItems: props.horizontal ? 'center' : 'flex-start'}} >
      <Text variant='fieldHeading' color='brand-light'>
        {props.heading}
      </Text>
      <Text variant='fieldValue' color='gra' sx={{marginLeft: props.horizontal ? 2 : 0, marginTop: props.horizontal ? 0 : 2}}>
        {props.value}
      </Text>
    </Box>

  const NonEditableSelections = (props) =>
    <Box>
      <Box my={2}>
        <Text variant='fieldHeading' color='brand-light'>{props.heading}</Text>
      </Box>
      {
        props.selections.length > 0 ? 
          <Stack direction='row' spacing={1} sx={{flexWrap: 'wrap', gap: '16px'}}>
            {props.selections.map((selection) => <Chip  label={selection} variant='outlined' key={selection} sx={{color:'#97979A'}}/>)}
          </Stack>
        : 
          <Text variant='fieldValue' color='gray-light'>None</Text>
      }
    </Box>

  const Promotion = (props) =>

      <Box>
        <NonEditableTextField horizontal={true} heading="Promotion Type" value={props.promotionTypeValue}/> 
          <Box>
            <Box display="flex" flexDirection={'row'}>
              <NonEditableTextField horizontal={true} heading="Feed ID" value={props.selectedPromotionFeedId}/>
              <NonEditableTextField horizontal={true} heading="Feed Name" value={props.selectedPromotionFeedName}/>
            </Box>
            <Box display="flex" flexDirection={'row'}>
              <NonEditableTextField horizontal={true} heading="Promotion Name" value={props.promotionName}/>
              <NonEditableTextField horizontal={true} heading="Percentage" value={props.promotionPercentage}/>
            </Box>
          </Box>

      </Box>

  console.log('props:', props);
  
  return (
    <Box my={1}>

      <Box display="flex" flexDirection={'row'} alignItems={'center'}>
        <Box>
          <Text variant='h1'>Feed Info</Text>
        </Box>
      </Box>
      <NonEditableTextField horizontal={true} heading="Endpoint" value={props.endpoint}/>
      {
        props.description && props.description !== '' &&
        <NonEditableTextField horizontal={true} heading="Description" value={props.description}/>
      }
      <Box mt={4}>
        <Text variant='h1'>Feed Configurations</Text>
      </Box>

      <Box mt={4}>
        <Text variant='h2'>Feed Freshness</Text>
      </Box>

      <Box display="flex" flexDirection={'row'}>
        {/* <NonEditableTextField horizontal={true} heading="scoring" value={scoring}/> */}
        <NonEditableTextField horizontal={true} heading="Time Period" value={`${props.timePeriodDays} days`}/>
        <NonEditableTextField horizontal={true} heading="Impression Count" value={props.impressionCount}/>
      </Box>
      <Box mt={4}>
        <Text variant='h2'>Include Params</Text>
        <NonEditableSelections heading="Source App FIDs" selections={props.includeAppFids}/>
        <NonEditableSelections heading="Geo Locations" selections={props.includeGeoLocations.map( loc => getGeloLocationDisplayString(loc))}/>
        <NonEditableSelections heading="Author IDs" selections={ props.includeAuthorIds }/>
        <NonEditableSelections heading="Channels" selections={props.channels}/>
        <NonEditableSelections heading="AI Labels" selections={props.includeAiLabels}/>
        <NonEditableSelections heading="Embed Domains" selections={props.embedDomains}/>
        <NonEditableSelections heading="Publication Types" selections={props.includePublicationTypes}/>
      </Box>
      <Box mt={4}>
        <Text variant='h2'>Exclude Params</Text>
        <NonEditableSelections heading="Author IDs" selections={props.excludeAuthorIds}/>
        <NonEditableSelections heading="AI Labels" selections={props.excludeAiLabels}/>
        <NonEditableSelections heading="Geo Locations" selections={props.excludeGeoLocations.map( loc => getGeloLocationDisplayString(loc))}/>
      </Box>
      <Box mt={4}>
        <Text variant='h2'>Promotions</Text>
        {
          props.showPromotionSection ?
            <Promotion 
              promotionTypeValue={props.promotionTypeValue}
              selectedPromotionFeedId={props.selectedPromotionFeedId}
              selectedPromotionFeedName={props.selectedPromotionFeedName}
              promotionName={props.promotionName}
              promotionPercentage={props.promotionPercentage}
            /> 
          : 
            <Text variant='fieldValue' color='gray-light'>None</Text>
        }
      </Box>
      <Box mt={4}>
        <Text variant='h2'>Cold Start</Text>
        {
          props.showColdStartSection ?  
            <Box mt={4}>
              <NonEditableTextField horizontal={true} heading="Feed ID" value={props.coldStartFeedId}/>
            </Box>
          :
            <Text variant='fieldValue' color='gray-light'>None</Text>
        }
      </Box>
    </Box>
  );
}