/***
*
*   DASHBOARD
*
**********/

import {useState, React, useEffect, Fragment, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { Animate, useAPI, AuthContext, usePlans, Event} from 'components/lib';
import Axios from 'axios';
import { Header } from './getStarted.js';
import { MyFeedsCard } from './myFeedsCard.js';
import { Avatar,  useMediaQuery, useTheme, Grid, Snackbar, IconButton, Button as MButton } from '@mui/material';
import logo_image from '../../assets/logo_image.png';
import { FeedTemplateShowcase } from 'views/feed-config/feedTemplateShowcase.js';
import CloseIcon from '@mui/icons-material/Cancel';
import { Text } from 'mbd-components/text';
import { DefaultApiKeyCard } from 'mbd-components/default-api-key-card';
import { FeedTemplateCard } from 'mbd-components/feed-template-card';
import { Card } from 'mbd-components/card'
const _ = require('lodash');



export function Dashboard(props){

  const subscription = useAPI('/api/account/subscription');
  const plans = useAPI('/api/account/plans');
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  let planStartTime = subscription?.data?.object?.current_period_start || null;
  let planEndTime = subscription?.data?.object?.current_period_end || null;
  const trialStart = subscription?.data?.object?.trial_start || null;
  const trialEnd = subscription?.data?.object?.trial_end || null;
  const subscriptionInterval = subscription?.data?.object?.interval || null;

  const [displayName, setDisplayName] = useState('');
  const [publicFeeds, setPublicFeeds] = useState([]); // State for public feeds
  const [privateFeeds, setPrivateFeeds] = useState([]); // State for private feeds

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const plansData = usePlans();
  const plan = plansData.data?.active || 'price_1PxWhPBLZFYgMsTFMV2pgwOR';
  
  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  
  let darkMode = user?.dark_mode || false;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (authContext.user?.farcaster_profile) {
      setDisplayName(authContext.user?.farcaster_profile?.displayName);
    }
    else {
      setDisplayName(authContext.user?.name);
    }
  }, [authContext.user]);

  // Fetch feeds based on visibility type
  const fetchFeeds = async (type) => {
    try {
      let result = await Axios({
        url: '/api/feed/configs',
        method: 'post',
        data: {
          visibility: type,
          console_account_id: authContext?.user?.account_id || null,
        },
      });
      return result?.data?.data || [];
    } catch (error) {
      console.error(`Error fetching ${type} feeds:`, error);
      // setSnackbarMessage(`Error fetching ${type} feeds`);
      // setShowSnackbar(true);
      return [];
    }
  };

  useEffect(() => {
    const fetchInitialFeeds = async () => {
        // Fetch feeds data directly
        let [publicFeedsData, privateFeedsData] = await Promise.all([
            fetchFeeds('template'),
            fetchFeeds('private')
        ]);

        // Set the state for public and private feeds
        setPublicFeeds(publicFeedsData);
        setPrivateFeeds(privateFeedsData);
    };

    fetchInitialFeeds();
  }, []); // Add visibility as a dependency

  useEffect(() => {
    if (subscription && subscription.data?.status === 'trialing') {

      setSnackbarMessage(`Welcome to mbd! Your trial period ends on ${planEndTime}. `);
      setShowSnackbar(true );

    }
    else if (subscription && subscription.data?.status === 'requires_payment_method') {

      setSnackbarMessage(`Your trial period ends on ${trialEnd}. You need to add a payment method to continue using our service.`);
      setShowSnackbar(true );

    }
  }, [subscription, planEndTime]); // Add visibility as a dependency

  const handleFeedClick = (feed) => {
    Event('dashboard_select_my_feed', { feedId: feed.config_id });
    navigate('/feed-builder', { state: { mode: 'template', initialNewFeed:feed } });
  };

  
  const handleCreateNewFeedClick = () => {
    navigate('/feed-builder', { state: { mode: 'private', new: true } });
  };

  const handleClose = (event, reason ) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const goToBilling = (event, reason) => {
    navigate('/account/billing');
  };
  
  return (
    <Animate type='pop'>

      <Header>
        <span style={{justifyContent: 'space-between', display: 'flex'}}>
          <Text variant='body' color={ darkMode ? 'white' : 'brand-light'}>
            Welcome back, {displayName}!
          </Text>
          <Avatar src={logo_image} alt="Company Logo" style={{ width: 25, height: 25, paddingBottom: 5}}/>
          </span>
      </Header>
      <Snackbar open={showSnackbar} 
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={snackbarMessage}
        action={
            <Fragment>
              <MButton size="small" onClick={goToBilling} sx={{color:"#E01296" }}>
                Manage Plan
              </MButton>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Fragment>
        }
      >

      </Snackbar>
      {/* <GetStarted  darkMode = {darkMode}/> */}
      <DefaultApiKeyCard eventPrefix='dashboard' mode='compact'/>

      <MyFeedsCard eventPrefix='dashboard' darkMode={darkMode} />
      <Card 
      title='Feed Templates'
      description='Some useful feed templates to help you get started.'
      >
      <Grid mt={4} container>
        
      {
        publicFeeds.map( feed =>
          <FeedTemplateCard
              title={feed.short_name}
              description={feed.short_description}
              imgUrl={feed.feed_image_url}
              api={feed.endpoint}
              creatorPfp='https://assets.mbd.xyz/images/console/feed-defaults/mbd-logo-dark-bg.jpg'
              creatorName='mbd team'
              selectTemplate={ () => {
                Event('dashboard_select_template');
                navigate('/feed-builder', { state: { mode: 'template', initialNewFeed: feed } })
              }}
            />
        )
        
      }
      </Grid>
      </Card>

      {/* <FeedTemplateShowcase
              publicFeeds={publicFeeds}
              privateFeeds={[]}
              createNewFeed={handleCreateNewFeedClick}  
              selectTemplate={(feed) => {
                Event('dashboard_select_template');
                navigate('/feed-builder', { state: { mode: 'template', initialNewFeed: feed } })
              }}
              editFeed={handleFeedClick}
            />

      <Feedback /> */}

    </Animate>
  );
}
